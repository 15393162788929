package com.fireavert.menu.logic

interface MenuNavigator {
    sealed class Destination {
        object Dashboard : Destination()
        object Properties : Destination()
        object Administration : Destination()
        object Reports : Destination()
        object Help : Destination()
        object Feedback : Destination()
    }

    fun navigate(destination: Destination)
}