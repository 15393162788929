package com.fireavert.properties.logic

interface PropertyNavigator {
    sealed class Destination {
        object Properties : Destination()
        data class Property(val propertyId: Int) : Destination()
        data class RemovePropertyAdmin(val propertyId: Int, val propertyAdminUserId: Int) :
            Destination()
        data class EditProperty(val propertyId: Int) : Destination()
        object AddNewProperty : Destination()
        data class EditManagementCompany(val managementCompanyId: Int) : Destination()
        object NewManagementCompany : Destination()

        object Reload: Destination()
    }

    fun navigate(destination: Destination)
}