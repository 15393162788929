package com.fireavert.gateways.models.request

import kotlinx.serialization.Serializable

@Serializable
data class GatewayImageUploadRequest(
    val file: String,
    val fileSize: Double,
    val fileType: String,
    val fileName: String,
    val gatewayId: String,
)
