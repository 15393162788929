package com.fireavert.administration_page.users.logic.add_user_dialog.models.request

import com.fireavert.user.logic.models.RolesModel
import kotlinx.serialization.Serializable

@Serializable
data class SaveUserInfoRequest(
    val firstName: String = "",
    val lastName: String = "",
    val email: String = "",
    val phoneNumber: String = "",
    val roles: List<RolesModel> = emptyList(),
    val propertyIds: List<Int> = emptyList(),
    var stoveTextNotifications: Boolean = false,
    var stoveEmailNotifications: Boolean = false,
    var leakTextNotifications: Boolean = false,
    var leakEmailNotifications: Boolean = false,
    var tamperTextNotifications: Boolean = false,
    var tamperEmailNotifications: Boolean = false,
    var smokeAlarmTextNotifications: Boolean = false,
    var smokeAlarmEmailNotifications: Boolean = false,
    var dailyReports: Boolean = false,
    var weeklyReports: Boolean = false,
    var monthlyReports: Boolean = false,
    var quarterlyReports: Boolean = false,
    var yearlyReports: Boolean = false,
)
