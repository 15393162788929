package com.fireavert.user.api

import com.fireavert.auth.logic.model.UserPageAuthenticateRequest
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.User
import com.fireavert.user.logic.UserAuth
import com.fireavert.user.logic.UserRepository
import com.fireavert.user.logic.models.requests.*
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ClientUserRepository(
    private val dataCache: UserDataCache,
    private val dataSource: UserDataSource,
    private val ioContext: CoroutineDispatcher,
    private val preferences: Preferences
) : UserRepository {
    override suspend fun authenticateUser(email: String, password: String): Try<UserAuth> =
        withContext(ioContext) {
            val request = UserAuthRequest(email = email, password = password)
            dataSource.getUserAuth(request).map { it.toUserAuth() }
        }

    override suspend fun getUserByToken(token: String): Try<User> = withContext(ioContext) {
        val request = UserByTokenRequest(token = token)
        dataSource.getUserByToken(request).map { it.toUser() }
    }

    override suspend fun authenticateByRefresh(id: Int, refresh: String): Try<UserAuth> =
        withContext(ioContext) {
            val request = UserAuthByRefreshRequest(id = id, refresh = refresh)
            dataSource.getUserAuthByRefresh(request).map { it.toUserAuth() }
        }

    override suspend fun getUserIdByInviteToken(token: String): Try<Int> = withContext(ioContext) {
        val request = UserIdByInviteTokenRequest(token)
        dataSource.getUserIdByInviteToken(request).map { it.userId }
    }

    override suspend fun setPassword(token: String, userId: Int, password: String): Try<Boolean> =
        withContext(ioContext) {
            val request = SetPasswordRequest(token = token, userId = userId, password = password)
            dataSource.setPassword(request).map { it.success }
        }

    override suspend fun getUserByEmail(email: String): Try<User> {
        TODO("Not yet implemented")
    }

    override suspend fun checkUsersPageAccess(userId: Int, propertyId: Int): Try<Boolean> =
        withContext(ioContext) {
            dataSource.checkAdminRights(preferences.token)
        }

    override suspend fun userAdminCheck(id: Int): Try<Boolean> =
        withContext(ioContext) {
            dataSource.checkAdminRights(preferences.token)
        }

    override suspend fun updateUserInformation(user: User): Try<Boolean> {
        TODO("Not yet implemented")
    }

    override suspend fun updateUserRoles(
        userId: Int,
        roles: List<Int>
    ): Try<Boolean> {
        TODO("Not yet implemented")
    }

    override suspend fun removeUserRoles(
        userId: Int,
        roles: List<Int>
    ): Try<Boolean> {
        TODO("Not yet implemented")
    }

    override suspend fun authenticateUserPageAccess(
        userId: Int,
        page: String
    ): Try<Boolean> = withContext(ioContext) {
        dataSource.authenticateUserPageAccess(UserPageAuthenticateRequest(userId, page), preferences.token)
    }
}