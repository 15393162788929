package com.fireavert.administration_page.yardi_integration.logic

import YardiSavePropertyDataRequest
import com.fireavert.administration_page.yardi_integration.presentation.YardiCommonDataScreen
import com.fireavert.common.Try
import com.fireavert.integrations.yardi.common_data.logic.YardiCommonDataClientRepository
import com.fireavert.management_companies.logic.ManagementCompanyRepository

class YardiCommonDataInteractor(
    private val repository: YardiCommonDataClientRepository,
    private val managementCompanyRepository: ManagementCompanyRepository,
    private val screen: YardiCommonDataScreen
) {

    suspend fun onLoad() {
        screen.loading = true

        val credentials = when (val maybe = repository.getYardiCredentialsList()) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }

        screen.availableCredentials = credentials


        val managementCommpanies = when (val maybe = managementCompanyRepository.getAllManagementCompanies()) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }

        screen.managementCompanyList = managementCommpanies



    }

    suspend fun savePropertyData(request: YardiSavePropertyDataRequest) {
        screen.loading = true

        when (val maybe = repository.savePropertyDataBase(request)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
    }

    suspend fun testGetVersionNumber(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testVersionNumber(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }

    suspend fun testPing(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testPing(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }

    suspend fun testGetResidentData(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testGetResidentData(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }

    suspend fun testGetResidentByStatusData(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testGetResidentByStatusData(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }

        screen.data
        screen.loading = false
    }

    suspend fun testGetPropertyList(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testGetPropertyList(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }

    suspend fun testGetResidentsByUnit(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testGetResidentsByUnit(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }

    suspend fun testGetTenantStatus(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testGetTenantStatus(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }

    suspend fun testGetRateLimits(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testGetRateLimits(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }

    suspend fun testGetUnitAttachments(credentialsId: Int){
        screen.loading = true

        val result = when (val maybe = repository.testGetUnitAttachments(credentialsId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }




    suspend fun testGetUnitData(credentialsId: Int){
        screen.loading = true
        val result = when(val maybe = repository.testGetUnitData(credentialsId)){
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                screen.error = maybe.exception.message.toString()
                return
            }
        }
        screen.data = result
        screen.loading = false
    }

}