package com.fireavert.components.icons

import react.FC
import react.dom.html.ReactHTML.img

val WhiteArrowDownIcon = FC {
    img {
        src = "/static/accordion_arrow_white.svg"
        width = 16.0
        height = 16.0
        alt = "+"
    }
}