package com.fireavert.gateways.interface_adaptors

import com.fireavert.gateways.models.GatewayModel

data class GatewayPropertyViewState(
    val loading: Boolean = true,
    val associatedGateways: List<GatewayModel> = emptyList(),
    val allGateways: List<GatewayModel> = emptyList(),
    val imageUploadError: String = "",
    val gatewayDeviceData: Map<String, List<String>> = emptyMap(),
    val gatewayDeviceDataLoading: Boolean = true
)
