package com.fireavert.administration_page.users.interface_adapters.user_section

import com.fireavert.administration_page.users.logic.UserSectionInteractor
import com.fireavert.administration_page.users.logic.UserTrackingRecord
import com.fireavert.common.TableColumn

class UserSectionController(
    private val interactor: UserSectionInteractor,

    ) {
    suspend fun onLoad() = interactor.onLoad()

    fun sortTrackingRecordsByColumn(
        columns: Array<TableColumn>,
        trackingRecords: List<UserTrackingRecord>,
        searchValue: String,
        roleTypes: List<String> = emptyList()
    ): List<UserTrackingRecord> {
        return interactor.searchAndSortTrackingRecordsByColumn(columns, trackingRecords, searchValue, roleTypes)
    }
}