package com.fireavert.components.nav

import com.fireavert.menu.presentation.MenuController
import com.fireavert.menu.presentation.MenuViewModel
import com.fireavert.styles.NavStylesV2
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.Clear
import web.window.WindowTarget


val SideNavV2 = FC<SideNavProps> { props ->
    val viewModel = getKoinInstance<MenuViewModel>()
    val controller = getKoinInstance<MenuController>()

    var showAdministrationMenuItem by useState(false)
    var showClientsMenuItem by useState(false)
    var dashboardNumber by useState(0)
    var propertiesNumber by useState(0)


    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            showAdministrationMenuItem = viewState.showAdministrationMenuItem
            showClientsMenuItem = viewState.showClientsMenuItem
            dashboardNumber = viewState.dashboardNumber
            propertiesNumber = viewState.propertiesNumber
        }.launchIn(viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
        controller.didLoad()
    }

    div {
        className = NavStylesV2.sideNav
        ariaLabel = "SideNav"
        div {
            className = NavStylesV2.sideNavTop
            ReactHTML.img {
                alt = "FireAvert Logo"
                src = "/static/logo_v2.svg"
                className = NavStylesV2.logo
            }
            div {
                css { clear = Clear.both }
            }
        }


        div {
            ariaLabel = "SideNavBottom"
            className = NavStylesV2.sideNavBottom
            NavItemV2 {
                onClick = { controller.clickedDashboard() }
                isActive = props.activeNavOption is NavOption.Dashboard
                iconAlt = "Dashboard Icon"
                iconActiveLocation = "/static/dashboard_icon_20.svg"
                iconInactiveLocation = "/static/dashboard_icon_20.svg"
                text = "Dashboard"
                number = dashboardNumber
            }
            NavItemV2 {
                onClick = { controller.clickedProperties() }
                isActive = props.activeNavOption is NavOption.Properties
                iconAlt = "Properties Icon"
                iconActiveLocation = "/static/properties_icon_22.svg"
                iconInactiveLocation = "/static/properties_icon_22.svg"
                text = "Portfolio"
                number = propertiesNumber
            }
        }
        NavItemV2 {
            onClick = { controller.clickedReports() }
            isActive = props.activeNavOption is NavOption.Reports
            iconAlt = "Reports Icon"
            iconActiveLocation = "/static/reports_icon.svg"
            iconInactiveLocation = "/static/reports_icon.svg"
            text = "Reports"
            number = -1
        }

        if (showAdministrationMenuItem) {
            NavItemV2 {
                onClick = { controller.clickedAdministration() }
                isActive = props.activeNavOption is NavOption.Administration
                iconAlt = "Administration Icon"
                iconActiveLocation = "/static/report_icon.svg"
                iconInactiveLocation = "/static/report_icon.svg"
                text = "Administration"
                number = -1
            }
        }

        NavItemV2 {
            onClick = { controller.clickedHelp() }
            isActive = props.activeNavOption is NavOption.Help
            iconAlt = "Help Icon"
            iconActiveLocation = "/static/help_icon.svg"
            iconInactiveLocation = "/static/help_icon.svg"
            text = "Help"
            number = -1
        }

        NavItemV2 {
            onClick = { controller.clickedFeedback() }
            isActive = props.activeNavOption is NavOption.Feedback
            iconAlt = "Feedback icon"
            iconActiveLocation = "/static/sidebar_icons/feedback_icon.svg"
            iconInactiveLocation = "/static/sidebar_icons/feedback_icon.svg"
            text = "Send Feedback"
            number = -1
        }

        div {
            className = NavStylesV2.privacyPolicyContainer
            a {
                href = "https://fireavert.com/pages/privacy-policy"
                target = WindowTarget._blank
                className = NavStylesV2.privacyPolicyText
                +"Privacy Policy"
            }
        }
    }
}

