package com.fireavert.management_companies.logic

import com.fireavert.common.Try
import com.fireavert.properties.logic.ClientPropertyRepository

class ManagementCompanyInteractor(
    private val screen: ManagementCompanyScreen,
    private val repository: ManagementCompanyRepository,
    private val propertyRepository: ClientPropertyRepository,
) {
    suspend fun onLoadNew(){
        screen.loading = true
        val allProperties = when (val maybeProperty = propertyRepository.getProperties()) {
            is Try.Success -> maybeProperty.value
            is Try.Error -> {
                emptyList()
            }
        }

        val response = when (val maybe = repository.getManagementCompanies()) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                return
            }
        }

        screen.name = response.name
        screen.associatedProperties = response.selectedProperties
        screen.properties = allProperties
        screen.loading = false

    }
    suspend fun onLoadEdit(managementCompanyId: Int) {
        screen.loading = true
        val allProperties = when (val maybeProperty = propertyRepository.getProperties()) {
            is Try.Success -> maybeProperty.value
            is Try.Error -> {
                emptyList()
            }
        }

        val response = when (val maybe = repository.getManagementCompanyById(managementCompanyId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                return
            }
        }

        screen.name = response.name
        screen.associatedProperties = response.selectedProperties
        screen.properties = allProperties
        screen.loading = false
    }

    suspend fun onSaveNew(name: String, propertyIdList: List<Int>): Boolean {
        screen.loading = true
        when (val maybe = repository.saveManagementCompany(name, propertyIdList)) {
            is Try.Success -> {
                screen.loading = true
                return maybe.value
            }
            is Try.Error -> {
                screen.error = "Failed to save management company"
                screen.loading = false
                return false
            }
        }
    }

    suspend fun onSaveEdit(managementCompanyId: Int,  name: String, propertyIdList: List<Int>): Boolean {
        screen.loading = true
        val response = repository.updateManagementCompany(managementCompanyId, name, propertyIdList)
        when (val maybe = response) {
            is Try.Success -> {
                screen.loading = false
                return maybe.value
            }
            is Try.Error -> {
                screen.error = "Failed to save management company"
                screen.loading = false
                return false
            }
        }
    }
}