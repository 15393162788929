package com.fireavert.devices.logic.models


enum class FireAvertStatus {
    Active,
    Listening,
    Offline,
    NotFound,
    Rebooting,
    Error
}