package com.fireavert.units.logic.models

import com.fireavert.devices.logic.models.DeviceType

data class UnitModel(
    val id: Int,
    val propertyName: String,
    val number: String,
    val tenantName: String,
    val tenantEmail: String,
    val tenantPhone: String,
    val propertyId: Int,
    val streetAddress: String,
    val city: String,
    val state: String,
    val zip: String,
    val status: UnitStatus,
    val alarmEventCount: Int,
    val shutoffEventCount: Int,
    val leakEventCount: Int,
    val toiletFlowEventCount: Int,
    val tamperEventCount: Int,
    val activeAlarm: Boolean,
    val activeFireAvert: Boolean,
    val activeLeakSensor: Boolean,
    val activeToiletFlowSensor: Boolean,
    val activeTamperSensor: Boolean,
    val offlineAlarm: Boolean,
    val offlineFireAvert: Boolean,
    val offlineLeakSensor: Boolean,
    val offlineToiletFlowSensor: Boolean,
    val offlineTamperSensor: Boolean,
    val notifyStoveCurrent: Boolean,
    val deviceNotInstalled: List<DeviceType>? = null,
    val buildingId: Int
)