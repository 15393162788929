package com.fireavert.components.nav

import com.fireavert.menu.presentation.MenuController
import com.fireavert.menu.presentation.MenuViewModel
import com.fireavert.styles.NavStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.Clear

const val DASHBOARD_INACTIVE_LOCATION = "/static/dashboard_icon_inactive.svg"
const val DASHBOARD_ACTIVE_LOCATION = "/static/dashboard_icon_active.svg"
const val PROPERTIES_INACTIVE_LOCATION = "/static/building_icon_inactive.svg"
const val PROPERTIES_ACTIVE_LOCATION = "/static/building_icon_active.svg"
const val ADMINISTRATION_ICON_INACTIVE = "/static/administration_inactive.png"
const val ADMINISTRATION_ICON_ACTIVE = "/static/administration_active.png"

sealed class NavOption {
    data object Administration : NavOption()
    data object Dashboard : NavOption()
    data object Properties : NavOption()
    data object Help : NavOption()
    data object Reports : NavOption()
    data object Feedback : NavOption()
}

external interface SideNavProps : Props {
    var activeNavOption: NavOption
}

val SideNav = FC<SideNavProps> { props ->

    val viewModel = getKoinInstance<MenuViewModel>()
    val controller = getKoinInstance<MenuController>()

    var showAdministrationMenuItem by useState(false)
    var showClientsMenuItem by useState(false)
    var dashboardNumber by useState(0)
    var propertiesNumber by useState(0)


    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            showAdministrationMenuItem = viewState.showAdministrationMenuItem
            showClientsMenuItem = viewState.showClientsMenuItem
            dashboardNumber = viewState.dashboardNumber
            propertiesNumber = viewState.propertiesNumber
        }.launchIn(viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
        controller.didLoad()
    }

    div {
        className = NavStyles.sideNav
        ariaLabel = "SideNav"
        div {
            className = NavStyles.sideNavTop
            img {
                alt = "FireAvert Logo"
                src = "/static/logo_white.svg"
                className = NavStyles.logo
            }
            div {
                css { clear = Clear.both }
            }
        }


        div {
            ariaLabel = "SideNavBottom"
            className = NavStyles.sideNavBottom
            NavItem {
                onClick = { controller.clickedDashboard() }
                isActive = props.activeNavOption is NavOption.Dashboard
                iconAlt = "Dashboard Icon"
                iconActiveLocation = DASHBOARD_ACTIVE_LOCATION
                iconInactiveLocation = DASHBOARD_INACTIVE_LOCATION
                text = "Dashboard"
                number = dashboardNumber
            }
            NavItem {
                onClick = { controller.clickedProperties() }
                isActive = props.activeNavOption is NavOption.Properties
                iconAlt = "Properties Icon"
                iconActiveLocation = PROPERTIES_ACTIVE_LOCATION
                iconInactiveLocation = PROPERTIES_INACTIVE_LOCATION
                text = "Properties"
                number = propertiesNumber
            }
            if (showAdministrationMenuItem) {
                NavItem {
                    onClick = { controller.clickedAdministration() }
                    isActive = props.activeNavOption is NavOption.Administration
                    iconAlt = "Administration Icon"
                    iconActiveLocation = ADMINISTRATION_ICON_ACTIVE
                    iconInactiveLocation = ADMINISTRATION_ICON_INACTIVE
                    text = "Administration"
                    number = -1
                }
            }
        }
    }
}