package com.fireavert.user.logic

data class Permission(
    val id: Int,
    val key: String,
    val name: String
) {
    companion object {

        // View Links (all should have these)
        const val ViewHeaderLinks = "VIEW_HEADER_LINKS"

        // Original permissions
        const val ReadClients = "READ_CLIENTS"
        const val CreateClients = "CREATE_CLIENTS"
        const val ReadUsers = "READ_USERS"
        const val CreateUsers = "CREATE_USERS"
        const val ViewAllProperties = "VIEW_ALL_PROPERTIES"
        const val CreateProperty = "CREATE_PROPERTY"
        const val EditProperty = "EDIT_PROPERTY"
        const val DeleteProperty = "DELETE_PROPERTY"
        const val CreateDevices = "CREATE_DEVICES"
        const val EditDevice = "EDIT_DEVICE"
        const val DeleteDevice = "DELETE_DEVICE"
        const val CreateUnits = "CREATE_UNITS"
        const val EditUnits = "EDIT_UNITS"
        const val DeleteUnits = "DELETE_UNITS"
        const val DeletePropertyAdmin = "DELETE_PROPERTY_ADMIN"
        const val ModifyPropertyAdmin = "MODIFY_PROPERTY_ADMIN"
        const val CreatePropertyAdmin = "CREATE_PROPERTY_ADMIN"

        // Management Company Permissions
        const val CreateManagementCompany = "CREATE_MANAGEMENT_COMPANY"
        const val EditManagementCompany = "EDIT_MANAGEMENT_COMPANY"

        // User Creation Permissions
        const val CreateUserSuperAdmin = "CREATE_USER_SUPERADMIN"
        const val CreateUserExecutive = "CREATE_USER_EXECUTIVE"
        const val CreateUserSalesRep = "CREATE_USER_SALES_REP"
        const val CreateUserTenant = "CREATE_USER_TENANT"
        const val CreateUserPropertyAdmin = "CREATE_USER_PROPERTY_ADMIN"
        const val CreateUserUiTester = "CREATE_USER_UI_TESTER"
        const val CreateUserRegionalManager = "CREATE_USER_REGIONAL_MANAGER"
        const val CreateUserMaintenanceUser = "CREATE_USER_MAINTENANCE_USER"
        const val CreateUserSingleFamily = "CREATE_USER_SINGLE_FAMILY"

        // User Edit Permissions
        const val EditUsers = "EDIT_USERS"
        const val EditUserExecutive = "EDIT_USER_EXECUTIVE"
        const val EditUserSalesRep = "EDIT_USER_SALES_REP"
        const val EditUserTenant = "EDIT_USER_TENANT"
        const val EditUserPropertyAdmin = "EDIT_USER_PROPERTY_ADMIN"
        const val EditUserUiTester = "EDIT_USER_UI_TESTER"
        const val EditUserRegionalManager = "EDIT_USER_REGIONAL_MANAGER"
        const val EditUserSingleFamily = "EDIT_USER_SINGLE_FAMILY"

        // User Delete Permissions
        const val DeleteUser = "DELETE_USER"
        const val DeleteUserTenant = "DELETE_USER_TENANT"
        const val DeleteUserSalesRep = "DELETE_USER_SALES_REP"
        const val DeleteUserUiTester = "DELETE_USER_UI_TESTER"
        const val DeleteUserRegionalManager = "DELETE_USER_REGIONAL_MANAGER"
        const val DeleteUserSingleFamily = "DELETE_USER_SINGLE_FAMILY"

        // View Permissions
        const val ViewPropertyManagers = "VIEW_PROPERTY_MANAGERS"
        const val ViewSalesReps = "VIEW_SALES_REPS"
        const val ViewTenants = "VIEW_TENANTS"
        const val ViewUiTesters = "VIEW_UI_TESTERS"
        const val ViewRegionalManagers = "VIEW_REGIONAL_MANAGERS"
        const val ViewSingleFamily = "VIEW_SINGLE_FAMILY"
        const val ViewExecutives = "VIEW_EXECUTIVES"
        const val ViewSuperAdmins = "VIEW_SUPER_ADMINS"
        const val ViewMaintenanceUsers = "VIEW_MAINTENANCE_USERS"

        //Admin Tool Permissions
        const val ViewDeviceConnectivity = "VIEW_DEVICE_CONNECTIVITY"
        const val ViewUserActivity = "VIEW_USER_ACTIVITY"
        const val ViewGatewayTool = "VIEW_GATEWAY_TOOL"
        const val ViewGoogleAnalytics = "VIEW_GOOGLE_ANALYTICS"
        const val ViewSuperAdminTools = "VIEW_SUPER_ADMIN_TOOLS"

        //Gateway Permissions
        const val EditGateways = "EDIT_GATEWAYS"
        const val RefreshGatewayStatus = "REFRESH_GATEWAY_STATUS"

        //Page Views
        //View User list is for the list of 'all' users under a certain users permissions
        const val ViewUserList = "VIEW_USER_LIST"
        const val ViewAdminPage = "VIEW_ADMIN_PAGE"

    }
}
