package com.fireavert.components.dialogs.units

import com.fireavert.logging.Logger
import com.fireavert.styles.DialogStyles
import com.fireavert.units.logic.models.SkippedCSVRow
import com.fireavert.units.presentation.AddNewUnitsCSVDialogController
import com.fireavert.units.presentation.AddNewUnitsCSVDialogViewModel
import com.fireavert.utilities.getKoinInstance
import com.github.doyaaaaaken.kotlincsv.dsl.csvReader
import emotion.react.css
import js.objects.jso
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.IntrinsicType
import react.dom.html.InputHTMLAttributes
import react.dom.html.LabelHTMLAttributes
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.None
import web.cssom.TextAlign
import web.cssom.important
import web.cssom.px
import web.events.EventHandler
import web.file.FileList
import web.file.FileReader
import web.html.HTMLInputElement
import web.html.HTMLLabelElement


val AddNewUnitsCSVDialog = FC {
    val propertyId = useParams()["propertyId"]?.toInt() ?: 0

    val controller = getKoinInstance<AddNewUnitsCSVDialogController>()
    val viewModel = getKoinInstance<AddNewUnitsCSVDialogViewModel>()
    val logger = getKoinInstance<Logger>()

    var isLoading: Boolean by useState(false)
    var loadingMessage: String by useState("")
    var errorMessage: String by useState("")
    var skippedCSVRows: Array<SkippedCSVRow> by useState(emptyArray())
    var checking: Boolean by useState(false)
    var jobModelId: Int by useState(0)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isLoading = viewState.loading
            loadingMessage = viewState.loadingMessage
            errorMessage = viewState.errorMessage
            skippedCSVRows = viewState.skippedCSVRows.toTypedArray()
            checking = viewState.checking
            val jobModel = viewState.jobModel
            if (jobModel == null) {
                jobModelId = 0
            } else {
                jobModelId = jobModel.id
            }
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyId)
        }

        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    if (!checking && jobModelId != 0) {
        checking = true
        viewModel.scope.launch {
            delay(1000L)
            controller.check(propertyId, jobModelId)
        }
    }
    Dialog {
        open = true
        onBackdropClick = {
            if (!viewModel.loading) {
                controller.clickedCancel(propertyId)
            }
        }
        onClose = { _, _ ->
            if (!viewModel.loading) {
                controller.clickedCancel(propertyId)
            }
        }
        maxWidth = Breakpoint.lg
        DialogTitle {
            +"Upload a Unit CSV File"
            className = DialogStyles.dialogTitle
        }
        DialogContent {
            if (isLoading) {
                CircularProgress {}
                div {
                    +loadingMessage
                }
            } else if (viewModel.screenState == AddNewUnitsCSVDialogViewModel.ScreenState.NoPendingData) {
                css {
                    textAlign = TextAlign.center
                }

                Button {
                    +"Upload"
                    css {
                        marginTop = important(20.px)
                    }
                    disabled = errorMessage.isNotBlank()
                    variant = ButtonVariant.contained
                    color = ButtonColor.primary
                    component = IntrinsicType<LabelHTMLAttributes<HTMLLabelElement>>("label")
                    Input {
                        css {
                            display = important(None.none)
                        }
                        type = "file"

                        @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
                        this.inputProps = jso<InputHTMLAttributes<HTMLInputElement>> {
                            this.accept = ".csv"
                        } as InputBaseComponentProps

                        onChange = { inputChangedEvent ->
                            val selectedFiles = inputChangedEvent.target.asDynamic().files as FileList
                            val csvFile = selectedFiles.item(0)
                            if (csvFile == null) {
                                errorMessage = "No CSV File Selected"
                            } else {
                                val reader = FileReader()
                                reader.onerror = EventHandler {
                                    errorMessage = "Failed to load text from file"
                                }
                                reader.onloadstart = EventHandler {
                                    errorMessage = ""
                                    isLoading = true
                                    loadingMessage = "Loading file data..."
                                }
                                reader.onloadend = EventHandler {
                                    isLoading = false
                                    loadingMessage = ""

                                }
                                reader.onload = EventHandler { fileReadEvent ->
                                    val csvText =
                                        fileReadEvent.target.asDynamic().result as String
                                    val csvData = csvReader().readAllWithHeader(csvText)

                                    viewModel.scope.launch {
                                        controller.processCSVData(
                                            propertyId = propertyId,
                                            csvData = csvData
                                        )
                                    }
                                }
                                reader.readAsText(csvFile)
                            }
                        }
                    }
                }
                div {
                    +errorMessage
                }
            } else if (viewModel.screenState == AddNewUnitsCSVDialogViewModel.ScreenState.PendingData) {
                div {
                    +"Committing ${viewModel.pendingRequest?.unitRequests?.size ?: "0"}"
                }
                div {
                    +"Skipping ${skippedCSVRows.size} rows"
                }
                table {
                    skippedCSVRows.forEach { skippedCSVRow ->
                        tr {
                            td {
                                div {
                                    +"Unit Number: ${skippedCSVRow.unitNumber}"
                                }
                            }
                            td {
                                ol {
                                    skippedCSVRow.reasons.forEach { reason ->
                                        li {
                                            +reason
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                css {
                    textAlign = TextAlign.center
                }
                Button {
                    +"Commit"
                    variant = ButtonVariant.contained
                    onClick = {
                        viewModel.scope.launch {
                            controller.commit(propertyId)
                        }
                    }
                }
            }
        }
    }
}