package com.fireavert.styles

import emotion.css.ClassName
import react.dom.html.ReactHTML
import web.cssom.*

object DialogStyles {
    val deleteWarningBold = ClassName {
        color = NamedColor.white
        textAlign = TextAlign.center
        fontSize = 16.px
        fontWeight = integer(700)
    }
    val warningText = ClassName {
        color = NamedColor.white
        textAlign = TextAlign.center
        fontSize = 16.px
    }
    val warningBold = ClassName {
        color = NamedColor.white
        textAlign = TextAlign.center
        fontSize = 16.px
        fontWeight = integer(700)
    }
    val warningTitle = ClassName {
        color = NamedColor.white
        textAlign = TextAlign.center
        fontWeight = integer(700)
        fontSize = 16.px
        marginBottom = 15.px
    }
    val dialogTitle = ClassName {
        fontSize = 20.pt
        fontWeight = FontWeight.bold
        backgroundImage = linearGradient(180.deg, Color("#2A3042"), Color("#4C5566"))
        color = NamedColor.white
    }

    val dialogTitleWithButton = ClassName {
        fontSize = 20.pt
        fontWeight = FontWeight.bold
        color = NamedColor.white
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
    }

    val mobileDialogActionTitle = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        alignItems = AlignItems.center
        fontSize = 20.pt
        fontWeight = FontWeight.bold
        color = NamedColor.black
        backgroundColor = Color("#D9D9D9")
        padding = 15.px
    }


    fun mobileDialogActiveEventTitle(bgColor: Color) = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        justifyContent = JustifyContent.spaceBetween
        alignItems = AlignItems.center
        fontWeight = FontWeight.bold
        color = NamedColor.black
        backgroundColor = bgColor
        padding = 15.px
    }

    val mobileDialogActiveEventPropertyTitle = ClassName {
        fontSize = 14.pt
        fontWeight = FontWeight.bold
    }

    val mobileDialogActiveEventUnitTitle = ClassName {
        fontSize = 10.pt
        fontWeight = FontWeight.bold
        "& a, & a:visited" {
            color = important(NamedColor.black)
            textDecoration = important(TextDecoration.underline)
        }
    }

    val mobileDialogActiveEventTime = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        justifyContent = JustifyContent.spaceBetween
        padding = 10.px
        position = Position.relative
        fontSize = 14.px
        "::after" {
            content = string("''")
            position = Position.absolute
            left = 50.pct
            width = 75.pct
            bottom = 0.px
            height = 1.px
            backgroundColor = Color("grey")
            transform = translatex((-50).pct)
        }
    }

    val mobileDialogActiveEventButtonContainer = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.center
        padding = 10.px
    }

    val mobileDialogRebootDeviceButton = ClassName {
        color = NamedColor.white
        textDecoration = None.none
        cursor = Cursor.pointer
        visited { color = NamedColor.white }
        ReactHTML.a { color = NamedColor.white }
    }

    val mobileDialogActiveEventsActionContainer = ClassName {
        display = Display.flex
        padding = 5.px
    }

    val warningDialogContent = ClassName {
        paddingTop = important(20.px)
        textAlign = TextAlign.center
        padding = 24.px
        backgroundImage = linearGradient(180.deg, Color("#FA683F"), Color("#FBAE41"))
    }

    val largeHeading = ClassName {
        fontSize = 40.px
        fontWeight = FontWeight.bold

    }
}