package com.fireavert.components.properties

import com.fireavert.components.icons.DeleteIconButton
import com.fireavert.components.targetInputValue
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.units.presentation.AddRemoveDeviceRowController
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import mui.material.*
import mui.system.useMediaQuery
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.onChange
import web.cssom.*

external interface AddRemoveDeviceRowProps : Props {
    var type: String
    var typeError: Boolean
    var typeDisabled: Boolean
    var typeChanged: (newType: String) -> Unit
    var commVersion: Int
    var commVersionError: Boolean
    var commVersionChanged: (newVersion: Int) -> Unit
    var location: String
    var locationError: Boolean
    var locationChanged: (newLocation: String) -> Unit
    var locator: String
    var locatorError: Boolean
    var locatorChanged: (newLocator: String) -> Unit
    var qrClicked: (fieldName: String) -> Unit
    var deleteDeviceClicked: () -> Unit
    var rebootUUID: String
    var rebootUUIDError: Boolean
    var rebootUUIDChanged: (newRebootUUID: String) -> Unit
    var infoUUID: String
    var infoUUIDError: Boolean
    var infoUUIDChanged: (newInfoUUID: String) -> Unit
    var isAdmin: Boolean
}

private fun getRandomString(length: Int): String {
    val allowedChars = ('A'..'Z') + ('a'..'z') + ('0'..'9')
    return (1..length)
        .map { allowedChars.random() }
        .joinToString("")
}

val AddRemoveDeviceRow = FC<AddRemoveDeviceRowProps> { props ->
    val controller = getKoinInstance<AddRemoveDeviceRowController>()
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)

    div {
        className = ClassName {
            display = Display.flex
            flexDirection = FlexDirection.column
            gap = 15.px
            width = 100.pct

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                gap = 10.px
            }
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                width = 100.pct

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }
            if(isMobile) {
                div {
                    className = ClassName {
                        fontWeight = FontWeight.bold
                    }
                    +"----- Existing ${props.type} -----"
                }
            }

            FormControl {
                fullWidth = true
                InputLabel { id = "device-type-label"; +"Device Type" }
                Select {
                    label = ReactNode("Device Type")
                    labelId = "device-type-label"
                    value = props.type
                    error = props.typeError
                    fullWidth = true
                    disabled = props.typeDisabled
                    onChange = { event, _ -> props.typeChanged(event.target.value) }
                    MenuItem { +DeviceType.FireAvert.string; value = DeviceType.FireAvert.string }
                    MenuItem { +DeviceType.FireAvertGas.string; value = DeviceType.FireAvertGas.string }
                    MenuItem { +DeviceType.FireAvertAppliance.string; value = DeviceType.FireAvertAppliance.string }
                    MenuItem { +DeviceType.WaterSensor.string; value = DeviceType.WaterSensor.string }
                    MenuItem { +DeviceType.FlowSensor.string; value = DeviceType.FlowSensor.string }
                    MenuItem { +DeviceType.TamperSensor.string; value = DeviceType.TamperSensor.string }
                }
            }

            FormControl {
                fullWidth = true
                InputLabel { id = "comm-version-label"; +"Comm Version" }
                Select {
                    label = ReactNode("Comm Version")
                    value = props.commVersion.toString()
                    error = props.commVersionError
                    fullWidth = true
                    onChange = { event, _ -> props.commVersionChanged(event.target.value.toInt()) }
                    MenuItem { +"V1 (TTN)"; value = "1"; selected = props.commVersion == 1 }
                    MenuItem { +"V2 (AWS)"; value = "2"; selected = props.commVersion == 2 }
                    MenuItem { +"V3 (TTI)"; value = "3"; selected = props.commVersion == 3 }
                }
            }

            TextField {
                label = ReactNode("Location")
                fullWidth = true
                value = props.location
                error = props.locationError
                onChange = { event -> props.locationChanged(event.targetInputValue) }
            }

            FormControl {
                fullWidth = true
                val randomString = getRandomString(10)
                InputLabel { htmlFor = randomString; +"Device ID" }
                OutlinedInput {
                    id = randomString
                    label = ReactNode("Device ID")
                    fullWidth = true
                    value = props.locator
                    error = props.locatorError
                    onChange = { event -> props.locatorChanged(event.targetInputValue) }
                    endAdornment = InputAdornment.create {
                        IconButton {
                            edge = IconButtonEdge.end
                            onClick = { props.qrClicked("locator") }
                            img { alt = "QR Code"; src = "/static/qr.svg" }
                        }
                    }
                }
            }

            if (props.type == DeviceType.FireAvert.string || props.type == DeviceType.FireAvertGas.string ||
                props.type == DeviceType.FireAvertAppliance.string) {
                FormControl {
                    fullWidth = true
                    val rebootUUIDId = getRandomString(10)
                    InputLabel { htmlFor = rebootUUIDId; +"Reboot UUID" }
                    OutlinedInput {
                        id = rebootUUIDId
                        label = ReactNode("Reboot UUID")
                        fullWidth = true
                        value = props.rebootUUID
                        error = props.rebootUUIDError
                        disabled = !props.isAdmin
                        onChange = { event -> props.rebootUUIDChanged(event.targetInputValue) }
                        endAdornment = InputAdornment.create {
                            IconButton {
                                edge = IconButtonEdge.end
                                onClick = { props.qrClicked("rebootUUID") }
                                img { alt = "QR Code"; src = "/static/qr.svg" }
                            }
                        }
                    }
                }
            }

            FormControl {
                fullWidth = true
                val infoUUIDId = getRandomString(10)
                InputLabel { htmlFor = infoUUIDId; +"Info UUID" }
                OutlinedInput {
                    id = infoUUIDId
                    label = ReactNode("Info UUID")
                    fullWidth = true
                    value = props.infoUUID
                    error = props.infoUUIDError
                    disabled = !props.isAdmin
                    onChange = { event -> props.infoUUIDChanged(event.targetInputValue) }
                    endAdornment = InputAdornment.create {
                        IconButton {
                            edge = IconButtonEdge.end
                            onClick = { props.qrClicked("infoUUID") }
                            img { alt = "QR Code"; src = "/static/qr.svg" }
                        }
                    }
                }
            }

            DeleteIconButton {
                onClicked = { props.deleteDeviceClicked() }
            }
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                width = 100.pct

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

        }
    }
}