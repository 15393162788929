package com.fireavert.management_companies.models.request

import kotlinx.serialization.Serializable

@Serializable
data class UpdateManagementCompanyRequest(
    val managementCompanyId: Int?,
    val name: String = "",
    val selectedProperties: List<Int> = emptyList(),
)
