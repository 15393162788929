package com.fireavert.properties.logic.models.request

import com.fireavert.properties.logic.models.Mode
import com.fireavert.properties.logic.models.PropertyType
import com.fireavert.properties.logic.models.SubscriptionType
import kotlinx.serialization.Serializable

@Serializable
data class CreatePropertyRequest(
    val name: String? = null,
    val units: Int? = null,
    val address: String? = null,
    val city: String? = null,
    val state: String? = null,
    val zip: String? = null,
    val managementCompany: String? = null,
    val subscriptionType: SubscriptionType? = null,
    val mode: Mode? = null,
    val propertyType: PropertyType = PropertyType.MultiFamily,
    val timezoneId: String? = null,
)

