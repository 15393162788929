package com.fireavert.administration_page.users.logic

import com.fireavert.user.logic.models.RolesModel

data class UserTrackingRecord(
    val id: Int,
    val name: String,
    val email: String,
    val phone: String,
    val managementCompany: String,
    val roles: List<RolesModel>
)
