package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.img
import web.cssom.*

object DashboardStylesV2 {
    val actionCardTableContainer = ClassName {
        overflowY = Auto.auto
        overflowX = Overflow.hidden
        maxHeight = 700.px
        borderBottomLeftRadius = 15.px
        borderBottomRightRadius = 15.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            maxHeight = None.none
        }
    }
    val automatedActionsTakenTitle = ClassName {
        fontSize = 16.px
        color = Color("#939598")
        marginTop = 50.px
        marginBottom = 20.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 22.px
            color = Color("black")
            fontWeight = FontWeight.bold
            marginTop = 20.px
            marginBottom = 20.px
        }
    }
    val actionCardTableRow = ClassName {
        display = Display.tableRow
        textAlign = TextAlign.center
    }
    val actionCardTableRowCell = ClassName {
        display = Display.tableCell
        borderTop = Border(1.px, LineStyle.solid, Color("#b8b8b8"))
        verticalAlign = VerticalAlign.middle
        textAlign = TextAlign.left
        padding = Padding(10.px, 10.px)
        fontSize = FontSize.small
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            maxWidth = 25.pct
        }
    }
    val actionCardTableHeader = ClassName {
        display = Display.tableRow
        backgroundColor = Color("#D9D9D9")
        textAlign = TextAlign.center
        position = Position.sticky
        top = 0.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            background = NamedColor.white
            fontWeight = FontWeight.bold
            color = NamedColor.black
            fontSize = 14.px
        }
    }
    val actionCardTable = ClassName {
        display = Display.table
        width = 100.pct
        color = Color("#939598")
        backgroundColor = NamedColor.white
        borderBottomLeftRadius = 15.px
        borderBottomRightRadius = 15.px
    }
    val actionCardAndTableContainer = ClassName {
        width = 353.px
        minWidth = 353.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 33.pct
            minWidth = 33.pct
            maxWidth = 33.pct

        }
    }
    val actionCardContainer = ClassName {
        display = Display.flex
        gap = 30.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            gap = 5.px
        }
    }
    val actionCardNumber = ClassName {
        position = Position.absolute
        bottom = 0.px
        right = 0.px
        fontSize = 64.px
        fontWeight = FontWeight.bold
        lineHeight = 64.px
        marginRight = 15.px
        marginBottom = 15.px
        color = NamedColor.white
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            color = NamedColor.black
            left = 10.px
            right = null
            // top = 30.px
            fontSize = 36.px
            marginBottom = 0.px
        }
    }
    val actionCardBottomRow = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        padding = Padding(0.px, 15.px, 15.px, 15.px)
        a {
            color = NamedColor.white
        }
    }
    val actionCardTopRow = ClassName {
        display = Display.flex
        gap = 25.px
        fontSize = 30.px
        padding = Padding(15.px, 15.px, 0.px, 15.px)
        img {
            height = 50.px
            width = 55.px
            gap = 5.px

        }
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            justifyContent = JustifyContent.spaceBetween
            alignItems = AlignItems.center
            fontSize = 12.px
            padding = Padding(10.px, 5.px, 3.px, 10.px)
            fontWeight = FontWeight.bold
            position = Position.relative
            marginBottom = 5.px
            gap = 0.px
            img {
                height = 30.px
                width = 20.px

            }
            "::after" {
                content = string("''")
                position = Position.absolute
                bottom = 0.px
                left = 10.px
                width = (100.pct - 20.px)
                height = 1.px
                backgroundColor = Color("black")
            }
        }
    }
    val actionCard = ClassName {
        position = Position.relative
        display = Display.flex
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.spaceBetween
        height = 143.px
        borderRadius = 15.px
        color = NamedColor.white
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            color = NamedColor.black
            height = 100.px
            background = NamedColor.white
        }

    }
    val eventCardContainer = ClassName {
        display = Display.flex
        gap = 30.px
        marginTop = 50.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            display = Display.grid
            gridTemplateColumns = repeat(2, minmax(Auto.auto, 1.fr))
            gridAutoRows = Auto.auto
            gap = 15.px
            marginTop = 20.px
        }
    }
    val eventCard = ClassName {
        position = Position.relative
        width = 257.px
        minWidth = 257.px
        height = 130.px
        borderRadius = 15.px
        backgroundColor = NamedColor.white
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            minWidth = 45.pct
            width = 100.pct
            height = 80.px
        }
    }


    val mobileEventCardTitleSmoke = ClassName {
        height = 30.px
        background = important(Color("#FF7E7E"))
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val mobileEventCardTitleWater = ClassName {
        height = 30.px
        background = Color("#58B9FF")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val mobileEventCardTitleTamper = ClassName {
        height = 30.px
        background = Color("#FECA57")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val mobileEventCardTitleOffline = ClassName {
        height = 30.px
        background = Color("#FECA57")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val mobileEventCardTitleToiletFlow = ClassName {
        height = 30.px
        background = Color("#A6A6A6")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val eventCardImage = ClassName {
        position = Position.absolute
        top = (-10).pct
        left = 0.px
        marginLeft = 15.px
    }
    val eventCardText = ClassName {
        position = Position.absolute
        top = 0.px
        right = 0.px
        fontSize = 16.px
        color = Color("#939598")
        marginRight = 25.px
        maxWidth = 142.px
        marginTop = 5.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 14.px
            fontWeight = FontWeight.bold
            left = 0.px
            right = null
            marginLeft = 10.px
            marginRight = 0.px
            color = Color("black")
        }
    }

    fun eventCardNumber(isToilet: Boolean) = ClassName {
        position = Position.absolute
        bottom = 0.px
        right = 0.px
        fontSize = 64.px
        fontWeight = FontWeight.bold
        lineHeight = 64.px
        marginRight = 15.px
        marginBottom = 5.px
        color = if (isToilet) Color("#D9D9D9") else NamedColor.black
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = if (isToilet) 9.px else 42.px
            lineHeight = 35.px
            marginRight = 20.px
        }
    }

    fun eventCardLink(isToilet: Boolean) = ClassName {
        position = Position.absolute
        bottom = 0.px
        left = 0.px
        marginLeft = 15.px
        fontSize = 12.px
        marginBottom = 5.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            position = if (isToilet) Position.static else Position.absolute
        }
    }
    val dropdownContainer = ClassName {
        display = Display.flex
        gap = 30.px
    }

    val mobileHeaderTitle = ClassName {
        fontSize = 36.px
        fontWeight = FontWeight.bolder
        color = NamedColor.black
    }

    val mobileHeaderSpace = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        justifyContent = JustifyContent.spaceBetween
        alignContent = AlignContent.center
        alignItems = AlignItems.center
        gap = 10.px
    }

    val dialogIconContainer = ClassName {
        position = Position.fixed
        top = 10.pct
        left = 50.pct
        transform = translatex((-50).pct)
        zIndex = integer(1301)
        borderRadius = 50.pct
        padding = 10.px
    }
}