package com.fireavert.management_companies.models.response

import kotlinx.serialization.Serializable

@Serializable
data class ManagementCompanyDialogResponse(
    val id: Int = 0,
    val name: String = "",
    val selectedProperties: List<Int> = emptyList(),
) {
    fun isEmpty(): Boolean =
        name.isEmpty() && selectedProperties.isEmpty()
}
