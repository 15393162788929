package com.fireavert.styles

import emotion.css.ClassName
import web.cssom.*

object BuildingStyles {
    var buildingDialogContent = ClassName {
        minWidth = 800.px
        minHeight = 500.px
        display = Display.flex
        flexDirection = FlexDirection.column
    }

    var buildingDialogContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        gap = 24.px
        padding = 16.px
        flex = Flex(number(1.0), number(0.0), FlexBasis.content)
    }

    var buildingDialogTextFieldContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        marginBottom = 16.px
    }

    var buildingDialogAvailableUnitsContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        gap = 24.px
        flex = Flex(number(1.0), number(1.0), FlexBasis.maxContent)
        minHeight = 300.px
    }

    var buildingDialogAvailableUnitsList = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        flex = Flex(number(1.0), number(1.0), FlexBasis.maxContent)
    }

    var buildingDialogActions = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        padding = 16.px
        borderTop = Border(1.px, LineStyle.solid, Color("#e0e0e0"))
        backgroundColor = Color("#f5f5f5")
    }
}