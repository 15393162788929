package com.fireavert.reports_page.frameworks

import com.fireavert.common.Try
import com.fireavert.events.activeevents.GetActiveEventsForAllPropertiesResponse
import com.fireavert.events.activeevents.GetActiveEventsForPropertyResponse
import com.fireavert.reports_page.interface_adaptors.ReportsPageRepositoryDataSource
import com.fireavert.reports_page.logic.models.requests.HighRiskTenantRequest
import com.fireavert.reports_page.logic.models.requests.ReportsEventsRequest
import com.fireavert.reports_page.logic.models.requests.ReportsExportDialogRequest
import com.fireavert.reports_page.logic.models.requests.YearlyReportRequest
import com.fireavert.reports_page.logic.models.responses.*
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class ReportsPageRepositoryDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient,
) : ReportsPageRepositoryDataSource {
    override suspend fun getReportsSectionData(
        request: ReportsEventsRequest
    ): Try<ReportsEventResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header(HttpHeaders.Authorization, "Bearer ${request.token}")
                url {
                    takeFrom(baseUrl)
                    appendPathSegments("reports")
                    parameters.append("type", request.type)
                    parameters.append("timeZone", request.timeZone)
                    request.propertyId?.let { parameters.append("propertyId", it.toString()) }
                }
            }
            if (response.status.isSuccess()) {
                response.body()
            } else {
                throw ClientRequestException(response, "Request failed with status ${response.status}")
            }
        }
    }

    override suspend fun getYearlyEvents(request: YearlyReportRequest): Try<ReportYearlyDataResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header(HttpHeaders.Authorization, "Bearer ${request.token}")
                url {
                    takeFrom(baseUrl)
                    appendPathSegments("reports", "yearlyReport")
                    parameters.append("year", request.yearToGet.toString())
                    parameters.append("timeZone", request.timeZone)
                    request.propertyId?.let { parameters.append("propertyId", it.toString()) }
                }
            }
            if (response.status.isSuccess()) {
                response.body()
            } else {
                throw ClientRequestException(response, "Request failed with status ${response.status}")
            }
        }
    }

    override suspend fun getHighRiskTenants(
        request: HighRiskTenantRequest,
        token: String
    ): Try<HighRiskTenantsResponse> {
        return Try.fromCoCallable {
            try {
                val response = httpClient.post {
                    header(HttpHeaders.Authorization, "Bearer $token")
                    url {
                        takeFrom(baseUrl)
                        appendPathSegments("reports", "highRiskTenants")
                    }
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                if (response.status.isSuccess()) {
                    response.body<HighRiskTenantsResponse>()
                } else {
                    throw ClientRequestException(response, "Request failed with status ${response.status}")
                }
            } catch (e: Exception) {
                throw e
            }
        }
    }


    override suspend fun getAllPropertiesAverageValue(token: String): Try<Double> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header(HttpHeaders.Authorization, "Bearer $token")
                url {
                    takeFrom(baseUrl)
                    appendPathSegments("reports", "averagePropertyEvent")
                }
            }
            if (response.status.isSuccess()) {
                response.body()
            } else {
                throw ClientRequestException(response, "Request failed with status ${response.status}")
            }
        }
    }

    override suspend fun getPropertyEventsOverTime(
        propertyId: Int?,
        token: String
    ): Try<ReportsFireClaimReportResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header(HttpHeaders.Authorization, "Bearer $token")
                url {
                    takeFrom(baseUrl)
                    appendPathSegments("reports", "fireClaimReportData")
                    propertyId?.let { parameters.append("id", it.toString()) }
                }
            }
            if (response.status.isSuccess()) {
                response.body()
            } else {
                throw ClientRequestException(response, "Request failed with status ${response.status}")
            }
        }
    }

    override suspend fun exportReportEventData(request: ReportsExportDialogRequest, token: String): Try<ReportsExportDialogResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header(HttpHeaders.Authorization, "Bearer $token")
                url {
                    takeFrom(baseUrl)
                    appendPathSegments("reports", "export")
                }
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) {
                response.body()
            } else {
                throw ClientRequestException(response, "Request failed with status ${response.status}")
            }
        }
    }

    override suspend fun exportEventsHappeningNowAll(token: String): Try<GetActiveEventsForAllPropertiesResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header(HttpHeaders.Authorization, "Bearer $token")
                url("$baseUrl/activeEventsForAllProperties")
            }
            if (response.status.isSuccess()) {
                response.body()
            } else {
                throw ClientRequestException(response, "Request failed with status ${response.status}")
            }
        }
    }

    override suspend fun exportEventsHappeningNow(
        propertyId: Int?,
        token: String,
    ): Try<GetActiveEventsForPropertyResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header(HttpHeaders.Authorization, "Bearer $token")
                url("$baseUrl/activeEventsForProperty/$propertyId")
            }
            if (response.status.isSuccess()) {
                response.body()
            } else {
                throw ClientRequestException(response, "Request failed with status ${response.status}")
            }
        }
    }
}