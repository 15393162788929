package com.fireavert.integrations.yardi.common_data.interface_adaptors

import YardiSavePropertyDataRequest
import com.fireavert.common.Try
import com.fireavert.integrations.yardi.common_data.logic.YardiCommonDataClientDataSource
import com.fireavert.integrations.yardi.common_data.requests.client_requests.YardiClientTestRequest
import com.fireavert.integrations.yardi.common_data.responses.YardiCommonDataClientPingResponse
import com.fireavert.integrations.yardi.common_data.responses.YardiCommonDataClientResidentResponse
import com.fireavert.integrations.yardi.common_data.responses.YardiCommonDataClientUnitInfoResponse
import com.fireavert.integrations.yardi.common_data.responses.YardiCommonDataClientVersionResponse
import com.fireavert.integrations.yardi.shared.logic.models.YardiCredentials
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class YardiCommonDataClientDataSourceImpl(
    private var baseUrl: String,
    private var httpClient: HttpClient,
): YardiCommonDataClientDataSource {
    override suspend fun getYardiCredentialsList(token: String): Try<List<YardiCredentials>> {
        return Try.fromCoCallable {
            val request = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/yardi/commonData/credentials")
            }
            if (request.status.isSuccess()) request.body()
            else throw ClientRequestException(request, "")
        }
    }

    override suspend fun savePropertyDataBase(token: String, request: YardiSavePropertyDataRequest): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/yardi/commonData/credentials/save")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) true
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun testPing(token: String, request: YardiClientTestRequest): Try<YardiCommonDataClientPingResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/yardi/commonData/pingTest")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun testVersionNumber(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientVersionResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/versionTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }

    override suspend fun testGetResidentData(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientResidentResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/residentDataTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }

    override suspend fun testGetResidentByStatusData(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientResidentResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/residentStatusDataTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }

    override suspend fun testGetPropertyList(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientResidentResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/getPropertyListTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }

    override suspend fun testGetResidentsByUnit(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientResidentResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/getResidentsByUnitTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }

    override suspend fun testGetTenantStatus(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientResidentResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/getTenantStatusTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }

    override suspend fun testGetRateLimits(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientResidentResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/rateLimitTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }

    override suspend fun testGetUnitAttachments(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientResidentResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/unitAttachmentsTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }

    override suspend fun testGetUnitData(
        token: String,
        request: YardiClientTestRequest
    ): Try<YardiCommonDataClientUnitInfoResponse> {
        val response = httpClient.post {
            header("Authorization", "Bearer $token")
            url("$baseUrl/yardi/commonData/unitDataTest")
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.isSuccess()) return Try.Success(response.body())
        else throw ClientRequestException(response, "")
    }
}