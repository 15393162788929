package com.fireavert.administration_page.wireless_id_finder.interface_adapters

import com.fireavert.administration_page.wireless_id_finder.logic.models.PropertyOfflineStatisticsModel

data class WirelessIdSectionViewState(
    val wirelessDeviceId: String = "",
    val propertyName: String = "",
    val unitName: String = "",
    val deviceLocationLink: String = "",
    val gatewayDeviceData: Map<String, List<String>> = emptyMap(),
    val gatewayDeviceDataLoading: Boolean = false,
    val propertyOfflineStatistics: List<PropertyOfflineStatisticsModel> = emptyList(),
    val propertyStatsLoading: Boolean = false,
    val appsheetDatabaseHealthStatus: Boolean = false,
    val appsheetDatabaseHealthLoading: Boolean = false,
    val appsheetDatabaseLastChecked: String = "",
)