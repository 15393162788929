package com.fireavert.administration_page.yardi_integration.logic

import YardiSavePropertyDataRequest

class YardiCommonDataController(
    private val interactor: YardiCommonDataInteractor
) {

    suspend fun onLoad() = interactor.onLoad()
    suspend fun savePropertyData(request: YardiSavePropertyDataRequest) = interactor.savePropertyData(request)
    suspend fun testGetVersionNumber(credentialsId: Int) = interactor.testGetVersionNumber(credentialsId)
    suspend fun testPing(credentialsId: Int) = interactor.testPing(credentialsId)
    suspend fun testGetResidentData(credentialsId: Int) = interactor.testGetResidentData(credentialsId)
    suspend fun testGetResidentByStatusData(credentialsId: Int) = interactor.testGetResidentByStatusData(credentialsId)
    suspend fun testGetPropertyList(credentialsId: Int) = interactor.testGetPropertyList(credentialsId)
    suspend fun testGetResidentsByUnit(credentialsId: Int) = interactor.testGetResidentsByUnit(credentialsId)
    suspend fun testGetTenantStatus(credentialsId: Int) = interactor.testGetTenantStatus(credentialsId)
    suspend fun testGetRateLimits(credentialsId: Int) = interactor.testGetRateLimits(credentialsId)
    suspend fun testGetUnitAttachments(credentialsId: Int) = interactor.testGetUnitAttachments(credentialsId)
    suspend fun testGetUnitData(credentialsId: Int) = interactor.testGetUnitData(credentialsId)


}
