package com.fireavert.components.pages

import com.fireavert.components.common.LoggedIn
import com.fireavert.components.dialogs.property.AddNewPropertyDialog
import com.fireavert.components.dialogs.property.DeletePropertyDialog
import com.fireavert.components.nav.NavOption
import com.fireavert.components.nav.SideNavV2
import com.fireavert.components.nav.TopNavBarV2
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface PropertiesProps: Props {
    var addNewPropertyDialogIsOpen: Boolean
    var deletePropertyDialogIsOpen: Boolean
}
val PropertiesV2 = FC<PropertiesProps> { props ->
    LoggedIn {}
    if (props.addNewPropertyDialogIsOpen) {
        AddNewPropertyDialog { }
    }
    if (props.deletePropertyDialogIsOpen) {
        DeletePropertyDialog {}
    }
    ReactHTML.div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Properties
        }
        ReactHTML.div {
            className = PageStylesV2.mainContainer
            TopNavBarV2{ activeNavOption = NavOption.Properties }
            ReactHTML.div {
                className = PageStylesV2.mainContainerContent
                PropertyPortfolioV3{}
            }
        }
    }
}