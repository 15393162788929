package com.fireavert.buildings.logic.models

import com.fireavert.units.logic.models.response.UnitResponse
import kotlinx.serialization.Serializable

@Serializable
data class BuildingObject(
    val id: Int,
    val name: String,
    val units: List<UnitResponse> = emptyList()
)
