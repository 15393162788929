package com.fireavert.components.nav

import com.fireavert.styles.NavStylesV2
import emotion.react.css
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.cssom.*

val NavItemV2 = FC<NavItemProps> { props ->
    div {
        onClick = {
            props.onClick()
        }
        className = if (props.isActive) {
            NavStylesV2.navItemActive
        } else {
            NavStylesV2.navItem
        }
        div {
            css {
                width = 22.px
                height = 22.px
                margin = Margin(Auto.auto, 0.px)
                textAlign = TextAlign.center
                img {
                    margin = Auto.auto
                    display = Display.block
                }
            }
            img {
                alt = props.iconAlt
                src = if (props.isActive) {
                    props.iconActiveLocation
                } else {
                    props.iconInactiveLocation
                }
                className = NavStylesV2.navItemIcon
            }
        }
        div {
            className = NavStylesV2.navItemText
            +props.text
        }
    }
}
