package com.fireavert.administration_page.wireless_id_finder.interface_adapters

import com.fireavert.administration_page.wireless_id_finder.logic.WirelessIdRepository
import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class WirelessIdRepositoryImpl(
    private val dataSource: WirelessIdRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
): WirelessIdRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun loadWirelessIdData(deviceId: String) = withContext(ioContext) {
        callWithRefresh { dataSource.loadWirelessIdData(deviceId, preferences.token) }
    }

    override suspend fun loadGatewayDeviceData(propertyId: String) = withContext(ioContext) {
        callWithRefresh { dataSource.loadGatewayDeviceData(propertyId, preferences.token) }
    }

    override suspend fun reloadAllGatewayInfo(): Try<Boolean> = withContext(ioContext) {
        callWithRefresh { dataSource.reloadAllGatewayInfo(preferences.token) }
    }
}