    package com.fireavert.components.sort_bar

    import com.fireavert.common.TableColumn
    import com.fireavert.styles.TableStyles
    import emotion.css.cx
    import emotion.react.css
    import js.objects.jso
    import mui.material.CircularProgress
    import react.FC
    import react.Props
    import react.dom.html.ReactHTML.div
    import web.cssom.Float
    import web.cssom.TextAlign
    import web.cssom.px

    sealed class SortOrder {
        data object HighToLow : SortOrder()
        data object LowToHigh : SortOrder()
        data object Neutral : SortOrder()
    }

    sealed class Alignment {
        data object Left : Alignment()
        data object Right : Alignment()
        data object Center : Alignment()
    }

    data class Column(
        val text: String, val sortOrder: SortOrder, val alignment: Alignment, val sortEnabled: Boolean
    )


    fun mapToControlColumns(stateColumns: Array<TableColumn>): Array<Column> {
        return stateColumns.map {
            Column(
                text = it.text, sortOrder = when (it.sortOrder) {
                    TableColumn.SortOrder.HighToLow -> SortOrder.HighToLow
                    TableColumn.SortOrder.LowToHigh -> SortOrder.LowToHigh
                    TableColumn.SortOrder.Neutral -> SortOrder.Neutral
                }, alignment = when (it.alignment) {
                    TableColumn.Alignment.Center -> Alignment.Center
                    TableColumn.Alignment.Left -> Alignment.Left
                    TableColumn.Alignment.Right -> Alignment.Right
                }, sortEnabled = it.enableSort
            )
        }.toTypedArray()
    }

    fun mapToStateColumns(controlColumns: Array<Column>): Array<TableColumn> {
        return controlColumns.map {
            TableColumn(
                text = it.text, sortOrder = when (it.sortOrder) {
                    SortOrder.HighToLow -> TableColumn.SortOrder.HighToLow
                    SortOrder.LowToHigh -> TableColumn.SortOrder.LowToHigh
                    SortOrder.Neutral -> TableColumn.SortOrder.Neutral
                }, alignment = when (it.alignment) {
                    Alignment.Center -> TableColumn.Alignment.Center
                    Alignment.Left -> TableColumn.Alignment.Left
                    Alignment.Right -> TableColumn.Alignment.Right
                }, enableSort = it.sortEnabled
            )
        }.toTypedArray()
    }

    private fun sortOrderToArrowState(sortOrder: SortOrder): UpDownArrowState {
        return when (sortOrder) {
            SortOrder.HighToLow -> UpDownArrowState.Down
            SortOrder.LowToHigh -> UpDownArrowState.Up
            SortOrder.Neutral -> UpDownArrowState.Neutral
        }
    }

    private fun cycleSortOrder(sortOrder: SortOrder): SortOrder {
        return when (sortOrder) {
            SortOrder.HighToLow -> SortOrder.LowToHigh
            SortOrder.LowToHigh -> SortOrder.Neutral
            SortOrder.Neutral -> SortOrder.HighToLow
        }
    }

    private fun setColumnSortOrdersByIndexClick(props: SortBarProps, index: Int): Array<Column> {
        val copy = props.columns.copyOf()
        copy.forEachIndexed { copyIndex, column ->
            if (copyIndex == index) {
                val newSortOrder = cycleSortOrder(column.sortOrder)
                copy[copyIndex] = column.copy(sortOrder = newSortOrder)
            } else {
                copy[copyIndex] = column.copy(sortOrder = SortOrder.Neutral)
            }
        }
        return copy
    }

    external interface SortBarProps : Props {
        var columns: Array<Column>
        var columnsChanged: (columns: Array<Column>) -> Unit
        var includeLoading: Boolean
        var isLoading: Boolean
        var isNewView: Boolean
    }

    val SortBar = FC<SortBarProps> { props ->

        div {
            className = if (props.isNewView) TableStyles.tableHeaderNew else TableStyles.tableHeader
            props.columns.forEachIndexed { index, column ->
                div {
                    className = cx(TableStyles.tableHeaderCell, emotion.css.css(jso {
                        textAlign = when (column.alignment) {
                            Alignment.Center -> TextAlign.center
                            Alignment.Left -> TextAlign.left
                            Alignment.Right -> TextAlign.right
                        }
                    }))
                    +column.text

                    if (column.sortEnabled) {
                        onClick = {
                            val newColumns = setColumnSortOrdersByIndexClick(props, index = index)
                            props.columnsChanged(newColumns)
                        }
                        UpDownTableArrows {
                            arrowState = sortOrderToArrowState(column.sortOrder)
                        }
                    }
                }
            }
            if (props.includeLoading) {
                div {
                    className = TableStyles.tableHeaderCell
                    if (props.isLoading) {
                        CircularProgress {
                            size = 15.px
                            css {
                                float = Float.right
                                marginRight = 10.px
                            }
                        }
                    }
                }
            }
        }
    }