package com.fireavert.devices.logic.response

import kotlinx.serialization.Serializable

@Serializable
data class WirelessDeviceIdResponse(
    val wirelessId: String,
    val deviceLocator: String,
    var unitName: String?,
    var unitId: Int?,
    var propertyName: String?,
    var propertyId: Int?,
)
