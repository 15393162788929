package com.fireavert.properties.presentation

import com.fireavert.gateways.models.GatewayModel
import com.fireavert.properties.logic.models.*

data class PropertyFormViewState(
    val isLoading: Boolean = true,
    val propertyName: String? = null,
    val propertyNameError: Boolean = false,
    val numberOfUnits: Int? = null,
    val propertyClass: PropertyClass? = null,
    val propertyClassError: Boolean = false,
    val numberOfUnitsError: Boolean = false,
    val address: String? = null,
    val addressError: Boolean = false,
    val city: String? = null,
    val cityError: Boolean = false,
    val state: String? = null,
    val stateError: Boolean = false,
    val zip: String? = null,
    val zipError: Boolean = false,
    val managementCompany: String? = null,
    val managementCompanyError: Boolean = false,
    val subscriptionType: SubscriptionType? = null,
    val subscriptionTypeError: Boolean = false,
    val mode: Mode? = null,
    val propertyType: PropertyType = PropertyType.MultiFamily,
    val propertyTypeError: Boolean = false,
    val modeError: Boolean = false,
    val timezoneId: String? = null,
    val timezoneIdError: Boolean = false,
    val saveError: Exception? = null,
    val propertyAdminsError: Boolean = false,
    val propertyAdmins: List<PropertyAdminModel> = emptyList(),
    val unlinkedPropertyAdmins: List<PropertyAdminModel> = emptyList(),
    val errorMessage: String = "",
    val changeMeRandom: String = "",
    val fireClaimsError : Boolean = false,
    val fireClaimsOneClaimCount: Int = 0,
    val fireClaimsTwoClaimCount: Int = 0,
    val fireClaimsThreeClaimCount: Int = 0,
    val fireClaimsFourClaimCount: Int = 0,
    val fireClaimsFiveClaimCount: Int = 0,
    val fireClaimsOneCost: Double = 0.0,
    val fireClaimsTwoCost: Double = 0.0,
    val fireClaimsThreeCost: Double = 0.0,
    val fireClaimsFourCost: Double = 0.0,
    val fireClaimsFiveCost: Double = 0.0,
    val propertyGateways: List<GatewayModel> = emptyList(),
    val allGateways: List<GatewayModel> = emptyList(),
    val propertyGatewaysError: Boolean = false
)
