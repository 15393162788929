package com.fireavert.administration_page.users.interface_adapters.user_section

import com.fireavert.administration_page.users.logic.UserTrackingRecord
import com.fireavert.user.logic.models.RolesModel
import kotlinx.serialization.Serializable

@Serializable
data class UserSectionRecordDTO(
    val id: Int? = null,
    val name: String? = null,
    val email: String? = null,
    val phone: String? = null,
    val managementCompany: String? = null,
    val roles: List<RolesModel>? = null,
) {
    fun toUserTrackingRecord(): UserTrackingRecord {
        return UserTrackingRecord(
            id = id ?: 0,
            name = name ?: "",
            email = email ?: "",
            phone = phone ?: "",
            managementCompany = managementCompany ?: "",
            roles = roles ?: emptyList(),
        )
    }

}
