package com.fireavert.reboot.presentation

import com.fireavert.common.ViewModel
import com.fireavert.reboot.logic.RebootScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class RebootViewModel : RebootScreen, ViewModel() {
    private val _viewState = MutableStateFlow(RebootViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var deviceId: String
        get() = viewState.value.deviceId ?: ""
        set(value) {
            if (value == viewState.value.deviceId) {
                return
            }
            _viewState.value = _viewState.value.copy(deviceId = value)
        }
    override var success: Boolean?
        get() = viewState.value.success
        set(value) {
            _viewState.value = _viewState.value.copy(success = value, deviceError = (value == false))
        }
    override var deviceNotActive: Boolean
        get() = viewState.value.deviceNotActive
        set(value) {
            _viewState.value = _viewState.value.copy(deviceNotActive = value)
        }
    override var isOffline: Boolean
        get() = viewState.value.isOffline
        set(value) {
            _viewState.value = _viewState.value.copy(isOffline = value)
        }

}