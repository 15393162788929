package com.fireavert.components.reports

import com.fireavert.common.TableColumn
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.dashboard.DashboardDropdown
import com.fireavert.components.dialogs.ReportsExportDataDialog
import com.fireavert.components.filesaver.saveAs
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.menu.RoutePaths
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.logic.models.FireClaimData
import com.fireavert.reports_page.frameworks.EventTypes
import com.fireavert.reports_page.interface_adaptors.ReportsPageSectionController
import com.fireavert.reports_page.interface_adaptors.ReportsPageViewModel
import com.fireavert.reports_page.logic.models.HighRiskTenantModel
import com.fireavert.styles.DialogStyles.largeHeading
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.ReportPageStyles
import com.fireavert.styles.TableStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import js.objects.jso
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import mui.material.*
import mui.system.sx
import mui.system.useMediaQuery
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import react.FC
import react.ReactNode
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.router.useNavigate
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*
import kotlin.math.abs

data class SimpleMenuItem(
    override val value: String,
    override val label: String
) : SelectMenuItem

val ReportSection = FC {
    //global variables for the report section
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    var windowWidth by useState(0)

    //High Risk Client
    var mobileColumns: Array<TableColumn> by useState(arrayOf(
        TableColumn(text = "Property",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left),
        TableColumn(text = "Unit",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left),
        TableColumn(text = "Event Count",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left)
    ))

    var selectedAllProperties: Boolean by useState(false)
    var highRiskTenantsRecord: List<HighRiskTenantModel> by useState(emptyList())
    var averageEventsPerMonth: Double by useState(0.0)
    var propertyAverage: Pair<Double, Boolean> by useState(Pair(0.0, false))
    var allTimeAverageEventValue: Double by useState(0.0)

    var propertyId: Int? by useState(-1)
    var propertyMap: Map<String, Int?> by useState(mapOf("None" to -1, "All" to null))
    var loading: Boolean by useState(false)
    var loadingHighRiskTenants: Boolean by useState(false)
    var loadingYearlyEvents: Boolean by useState(false)
    var loadingAllTimeEvents: Boolean by useState(false)
    var loadingDeviceToEventRatio: Boolean by useState(false)

    var firesAverted: Int by useState(0)

    var thisYearSmokeEvents: Array<Int> by useState(emptyArray())
    var thisYearTamperEvents: Array<Int> by useState(emptyArray())
    var thisYearLeakEvents: Array<Int> by useState(emptyArray())
    var thisYearStoveShutoffEvents: Array<Int> by useState(emptyArray())
    var thisYearOfflineEvents: Array<Int> by useState(emptyArray())
    var allTimeData: Map<String, Int> by useState(emptyMap())
    var allTimeDeviceCount: Array<Int> by useState(emptyArray())
    var yearDeviceCount: Array<Int> by useState(emptyArray())
    var eventCount: Int by useState(0)
    val months = arrayOf(
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    )
    var chartName: String by useState("Smoke Events")
    val currentYear = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year
    val yearSelector = (2020..currentYear).toList().reversed().toTypedArray()
    var eventTypeSelector: String by useState("Smoke Events")
    var eventTypeSelectorRatio: String by useState("Smoke Events")
    var availableDeviceTypes: List<EventTypes> by useState(emptyList())
    var deviceToEventRatio: Array<Double> by useState(emptyArray())
    var deviceToOfflineEventRatio: Array<Double> by useState(emptyArray())
    var selectedEvents by useState(
        listOf(
            EventTypes.SMOKE.toString(),
            EventTypes.SHUTOFF.toString(),
            EventTypes.LEAK.toString(),
            EventTypes.TAMPER.toString()
        )
    )

    //Get the last 5 years in reversed order as a string for the graph
    val lastFiveYears = (currentYear - 4..currentYear).map { it.toString() }.toTypedArray()
    var lastFiveYearClaims: List<FireClaimData> by useState(emptyList())
    var yearStartedUsingFireAvert: Int by useState(0)
    var selectedYear: Int by useState(currentYear)

    var reloadJob: Job? = null

    val controller = getKoinInstance<ReportsPageSectionController>()
    val viewModel = getKoinInstance<ReportsPageViewModel>()
    val preferences = getKoinInstance<Preferences>()
    val navigate = useNavigate()
    val (openDialog, setOpenDialog) = useState(false)
    var exportError: String? by useState(null)

    val chartWidth = if (isMobile) windowWidth else (windowWidth * 0.8).toInt()
    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
            loadingHighRiskTenants = viewState.loadingHighRiskTenants
            loadingYearlyEvents = viewState.loadingYearlyEvents
            loadingAllTimeEvents = viewState.loadingAllTimeEvents
            loadingDeviceToEventRatio = viewState.loadingDeviceToEventRatio
            propertyId = viewState.propertyId
            propertyMap = viewState.propertyMap
            thisYearTamperEvents = viewState.thisYearTamperEvents
            thisYearLeakEvents = viewState.thisYearLeakEvents
            thisYearStoveShutoffEvents = viewState.thisYearStoveShutoffEvents
            thisYearSmokeEvents = viewState.thisYearSmokeEvents
            thisYearOfflineEvents = viewState.thisYearOfflineEvents
            allTimeData = viewState.allTimeData
            eventCount = viewState.eventCount
            highRiskTenantsRecord = viewState.highRiskTenants
            averageEventsPerMonth = viewState.averageEventsPerMonth
            propertyAverage = viewState.propertyAverageComparison
            allTimeAverageEventValue = viewState.allTimeAverageEventValue
            lastFiveYearClaims = viewState.lastFiveYearClaims
            yearStartedUsingFireAvert = viewState.yearStartedUsingFireAvert
            windowWidth = window.innerWidth
            allTimeDeviceCount = viewState.allTimeDeviceCount
            yearDeviceCount = viewState.yearlyDeviceCount
            deviceToEventRatio = viewState.deviceToEventRatio
            exportError = viewState.exportError
            deviceToOfflineEventRatio = viewState.deviceToOfflineEventRatio
            firesAverted = viewState.firesAvertedCount
            availableDeviceTypes = viewState.availableDeviceTypes


        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyId, eventTypeSelector ,currentYear)
        }

        onCleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }

    fun updateData(newPropertyId: Int?, eventTypeValue: String) {
        reloadJob?.cancel()
        reloadJob = viewModel.scope.launch {
            controller.updateData(newPropertyId, eventTypeValue)
            controller.loadHighRiskTenantData(newPropertyId, selectedEvents)
            controller.loadYearlyEventTrends(newPropertyId, selectedYear)
        }
    }

    fun updateYearChart(newPropertyId: Int?, yearToGet: Int) {
        reloadJob?.cancel()
        reloadJob = viewModel.scope.launch {
            controller.loadYearlyEventTrends(newPropertyId, yearToGet)
        }
    }

    fun updateHighRiskTenants(propertyId: Int?, eventTypeArray: List<String>) {
        reloadJob?.cancel()
        reloadJob = viewModel.scope.launch {
            controller.loadHighRiskTenantData(propertyId, eventTypeArray)
        }
    }

    fun updateActionsAndEvents(propertyId: Int?, eventTypeValue: String) {
        reloadJob?.cancel()
        reloadJob = viewModel.scope.launch {
            controller.loadEventsAndActions(propertyId, eventTypeValue)
        }
    }

    fun updateDeviceToEventRatio(propertyId: Int?, deviceCount: Array<Int>, eventTypeValue: String) {
        reloadJob?.cancel()
        reloadJob = viewModel.scope.launch {
            controller.loadDeviceToEventRatio(propertyId, deviceCount, eventTypeValue)
        }
    }

    //ALL THE DEVICE COUNT COMES BACK BUT ONLY IN THE MONTH THEY WERE ADDED, WE NEED TO HAVE THE NUMBER GROW AND BE SORTED EVERY MONTH

    val averageComparedToAllTime = if (allTimeAverageEventValue != 0.0) {
        ((averageEventsPerMonth - allTimeAverageEventValue) / allTimeAverageEventValue) * 100
    } else 0.0

    LoggedIn {}
    div {
        div {
            className = ReportPageStyles.buttonAndTitle
            div {
                div {
                    className = largeHeading
                    +"Reports"
                }

                div {
                    className = ReportPageStyles.dropDownContainer
                    div {
                        ariaLabel = "Dropdown Container"
                        className = ReportPageStyles.dropDownContainer
                        DashboardDropdown {
                            labelText = "Property"
                            optionMap = propertyMap
                            disabled = loading
                            //If the value is null then we have selected "All" properties
                            onChange = { newPropertyId ->
                                if (newPropertyId == null) {
                                    selectedAllProperties = true
                                    updateData(newPropertyId, chartName)
                                } else {
                                    selectedAllProperties = false
                                    updateData(newPropertyId, chartName)
                                }
                            }
                        }
                    }
                }
            }

            div {
                css {
                    alignContent = AlignContent.center
                }
                if (!isMobile) {
                    Button {
                        variant = ButtonVariant.contained
                        disabled = propertyId == -1
                        onClick = {
                            setOpenDialog(true)
                        }
                        +"Export Historical Data"
                    }
                    Button {
                        sx {
                            marginLeft = 10.px
                        }
                        variant = ButtonVariant.contained
                        disabled = propertyId == -1
                        onClick = {
                            viewModel.scope.launch {
                                val exportedData = controller.exportEventsHappeningNow(propertyId)

                                // Generate the CSV content as a string using the exported data
                                // Create a Blob from the CSV content
                                if (exportedData != null) {
                                    val blob =
                                        Blob(arrayOf(exportedData), BlobPropertyBag(type = "text/csv;charset=utf-8;"))

                                    // Use the saveAs function to prompt the user to download the file
                                    saveAs(blob, "events_happening_now.csv")
                                }
                            }
                        }
                        +"Export Events Happening Now"
                        Snackbar {
                            open = exportError != null
                            onClose = { _, _ ->
                                exportError = null
                            }
                            message = ReactNode(exportError ?: "An error occurred while exporting data")
                            autoHideDuration = 5000
                            anchorOrigin = object : SnackbarOrigin {
                                override var vertical = SnackbarOriginVertical.bottom
                                override var horizontal = SnackbarOriginHorizontal.right
                            }
                        }
                    }
                }
            }
            if (openDialog) {
                ReportsExportDataDialog {
                    propsPropertyId = propertyId
                    propsIsAdmin = preferences.isAdmin
                    propsPropertyName = propertyMap.entries.find { it.value == propertyId }?.key ?: ""
                    onClose = { setOpenDialog(false) }
                }
            }
        }
        if (propertyId != -1) {
            div {
                className = ReportPageStyles.reportPageStyle
                div {
                    className = ReportPageStyles.headingAndDropDownContainer
                    div {
                        className = ReportPageStyles.titleHeading
                        +"High-Risk Tenants"
                    }
                    div {
                        InputLabel {
                            id = "high-risk-tenants"
                            +"Event Type"
                        }
                        Select {
                            ariaLabel = "High-Risk Tenants"
                            value = selectedEvents.toTypedArray()
                            id = "high-risk-tenants"
                            label = ReactNode("High-Risk Tenants")
                            multiple = true
                            variant = SelectVariant.outlined
                            onChange = { event, _ ->
                                val selectedValues = event.target.asDynamic().value as Array<String>
                                selectedEvents = selectedValues.toList()
                                updateHighRiskTenants(propertyId, selectedValues.toList())
                            }
                            renderValue = { selected ->
                                ReactNode((selected as Array<*>).joinToString(", ") { value ->
                                    when (value) {
                                        EventTypes.SMOKE.toString() -> EventTypes.SMOKE.toString()
                                        EventTypes.SHUTOFF.toString() -> EventTypes.SHUTOFF.toString()
                                        EventTypes.TAMPER.toString() -> EventTypes.TAMPER.toString()
                                        EventTypes.LEAK.toString() -> EventTypes.LEAK.toString()
                                        else -> value.toString()
                                    }
                                })
                            }

                            MenuProps = jso {
                                PaperProps = jso {
                                    sx {
                                        "& .MuiMenuItem-root" {
                                            whiteSpace = important(WhiteSpace.normal)
                                            wordBreak = important(WordBreak.breakWord)
                                        }
                                    }
                                }
                            }
                            if (isMobile) {
                                sx {
                                    "& .MuiSelect-select.MuiSelect-outlined" {
                                        height = important(Auto.auto)
                                        minHeight = important(1.4375.em)
                                        maxHeight = important(None.none)
                                        textOverflow = important(TextOverflow.clip)
                                        whiteSpace = important(WhiteSpace.normal)
                                        overflow = important(Overflow.visible)
                                        wordBreak = important(WordBreak.breakWord)
                                        display = important(Display.block)
                                        width = important(100.pct)
                                        padding = important(8.px)
                                    }
                                    "& .MuiOutlinedInput-notchedOutline" {
                                        height = important(Auto.auto)
                                    }
                                    "& .MuiSelect-multiple" {
                                        alignItems = important(AlignItems.flexStart)
                                    }
                                    "& .MuiOutlinedInput-input" {
                                        height = important(Auto.auto)
                                        maxHeight = important(None.none)
                                        overflow = important(Overflow.visible)
                                    }
                                    "& .MuiSelect-icon" {
                                        top = important(50.pct)
                                    }
                                }
                            }

                            MenuItem {
                                value = EventTypes.SMOKE.toString()
                                Checkbox {
                                    checked = selectedEvents.contains(EventTypes.SMOKE.toString())
                                }
                                ListItemText {
                                    primary = ReactNode("Smoke Events")
                                }
                            }
                            MenuItem {
                                value = EventTypes.SHUTOFF.toString()
                                Checkbox {
                                    checked = selectedEvents.contains(EventTypes.SHUTOFF.toString())
                                }
                                ListItemText {
                                    primary = ReactNode("Stove Shutoff Events")
                                }
                            }
                            MenuItem {
                                value = EventTypes.TAMPER.toString()
                                Checkbox {
                                    checked = selectedEvents.contains(EventTypes.TAMPER.toString())
                                }
                                ListItemText {
                                    primary = ReactNode("Tamper Events")
                                }
                            }
                            MenuItem {
                                value = EventTypes.LEAK.toString()
                                Checkbox {
                                    checked = selectedEvents.contains(EventTypes.LEAK.toString())
                                }
                                ListItemText {
                                    primary = ReactNode("Leak Events")
                                }
                            }
                        }
                    }
                }
                div {
                    className = ReportPageStyles.highRiskTenantTable
                    if (!loadingHighRiskTenants) {
                        if (highRiskTenantsRecord.isEmpty()) {
                            div {
                                className = ReportPageStyles.noDataMessage
                                +"No Data Available Yet"
                            }
                        } else {
                            div {
                                className = TableStyles.table
                                SortBar {
                                    this.columns = mapToControlColumns(mobileColumns)
                                    includeLoading = false
                                    isLoading = loading
                                    columnsChanged = {
                                        mobileColumns = mapToStateColumns(it)
                                    }
                                }
                                for (highRiskTenant in highRiskTenantsRecord) {
                                    div {
                                        if (propertyId != null) {
                                            onClick = {
                                                navigate("${RoutePaths.properties}/$propertyId/units/${highRiskTenant.unitId}")
                                            }
                                        }
                                        className = ReportPageStyles.reportHighRiskTenantRow
                                        div {
                                            className = TableStyles.tableCell
                                            +highRiskTenant.propertyName
                                        }
                                        div {
                                            className = TableStyles.tableCell
                                            +highRiskTenant.unitNumber
                                        }
                                        div {
                                            className = TableStyles.tableCell
                                            +(highRiskTenant.smokeEventCount +
                                                    highRiskTenant.waterLeakEventCount +
                                                    highRiskTenant.tamperEventCount +
                                                    highRiskTenant.shutOffEventCount).toString()
                                        }
                                    }
                                }
                            }
                        }

                        div {
                            className = ReportPageStyles.highRiskInfo
                            div {
                                +"Tenants at your property have an average of: "
                                br {}
                                span {
                                    css {
                                        fontWeight = FontWeight.bold
                                    }
                                    +"$averageEventsPerMonth events per month"
                                }
                                br {}

                            }
                            div {
                                +"The average for all FireAvert properties with the same sensors: "
                                br {}
                                span {
                                    css {
                                        fontWeight = FontWeight.bold
                                    }
                                    +"$allTimeAverageEventValue events per month"
                                }
                            }

                            div {
                                +"Your properties average compared with all FireAvert Protected properties: "
                                br {}
                                span {
                                    css {
                                        fontWeight = FontWeight.bold
                                    }
                                    if (averageComparedToAllTime > 0) {
                                        css {
                                            color = Color("#CF3338")
                                        }
                                        +"${averageComparedToAllTime.toInt()}% more than average"
                                    } else {
                                        css {
                                            color = Color("#23D769")
                                        }
                                        +"${abs(averageComparedToAllTime).toInt()}% less than average"
                                    }
                                }
                                br {}
                            }
                        }
                    } else {
                        Skeleton {
                            sx {
                                borderRadius = 10.px
                            }
                            variant = SkeletonVariant.rectangular
                            width = 100.pct
                            height = 350.px
                        }
                    }
                }

                div {
                    className = ReportPageStyles.titleHeading
                    +"Potential Fires Averted"
                }
                if (!loadingYearlyEvents) {
                    div {
                        className = ReportPageStyles.costSavingsContainer
                        div {
                            className = ReportPageStyles.firesAvertedValue
                            +"$firesAverted"
                        }
                        div {
                            className = ReportPageStyles.costsSavingsText
                            +"Estimated Savings: "
                            if (lastFiveYearClaims.isEmpty() || lastFiveYearClaims.all { it.claimCount == 0 }) {
                                Button {
                                    onClick = {
                                        window.open("https://forms.gle/dWdcJbVS9sVatK8v7", "_blank")
                                    }
                                    sx {
                                        borderRadius = 10.px
                                        backgroundColor = Color("#CF3338")
                                    }
                                    variant = ButtonVariant.contained
                                    +"Fill Out Loss Report"
                                }
                            } else {
                                +"$${(lastFiveYearClaims.sumOf { it.cost })}"
                            }
                        }
                    }
                } else {
                    Skeleton {
                        sx {
                            borderRadius = 10.px
                        }
                        variant = SkeletonVariant.rectangular
                        width = 110.pct
                        height = 300.px
                    }
                }
                EventsOverTimeChart {
                    loadingProp = loading
                    lastFiveYearsProp = lastFiveYears
                    lastFiveYearClaimsProp = lastFiveYearClaims.toTypedArray()
                    yearStartedUsingFireAvertProp = yearStartedUsingFireAvert
                    chartWidthProp = chartWidth
                    propertyIdProps = propertyId
                }

                // Yearly Event Trend Chart
                ReportsGraphComponentInt {
                    loadingProp = loadingYearlyEvents
                    xAxisData = arrayOf(jsObject {
                        id = "months"
                        this.data = months
                        scaleType = "band"
                    })
                    seriesData = if (preferences.isAdmin) {
                        arrayOf(jsObject {
                            this.data = thisYearSmokeEvents
                            stack = "A"
                            label = "Smoke Events"
                            color = "#FF5E5B"
                        }, jsObject {
                            this.data = thisYearLeakEvents
                            stack = "B"
                            label = "Water Leak Events"
                            color = "#58B9FF"
                        }, jsObject {
                            this.data = thisYearTamperEvents
                            stack = "C"
                            label = "Tamper Events"
                            color = "#FECA57"
                        }, jsObject {
                            this.data = thisYearStoveShutoffEvents
                            stack = "D"
                            label = "Stove ShutOff Events"
                            color = "#262626"
                        }, jsObject {
                            this.data = thisYearOfflineEvents
                            stack = "E"
                            label = "Offline Events"
                            color = "#D3D3D3"
                        }, jsObject {
                            this.data = yearDeviceCount
                            stack = "F"
                            label = "Sensor Count"
                            color = "#5E5D5C"
                        })
                    }
                    else {
                        arrayOf(jsObject {
                            this.data = thisYearSmokeEvents
                            stack = "A"
                            label = "Smoke Events"
                            color = "#FF5E5B"
                        }, jsObject {
                            this.data = thisYearLeakEvents
                            stack = "B"
                            label = "Water Leak Events"
                            color = "#58B9FF"
                        }, jsObject {
                            this.data = thisYearTamperEvents
                            stack = "C"
                            label = "Tamper Events"
                            color = "#FECA57"
                        }, jsObject {
                            this.data = thisYearStoveShutoffEvents
                            stack = "D"
                            label = "Stove ShutOff Events"
                            color = "#262626"
                        },  jsObject {
                            this.data = yearDeviceCount
                            stack = "E"
                            label = "Sensor Count"
                            color = "#5E5D5C"
                        })
                    }
                    chartHeight = 500
                    this.chartWidth = chartWidth
                    this.isMobile = isMobile
                    menuItemList = yearSelector.map { year ->
                        SimpleMenuItem(
                            value = year.toString(),
                            label = year.toString()
                        )
                    }.toTypedArray()
                    onChangeProp = { event ->
                        val year = event.target.value as String
                        selectedYear = year.toInt()
                        updateYearChart(propertyId, year.toInt())
                    }
                    this.chartName = "Yearly Event Trends"
                    inputLabel = "Year"
                    initialSelectValue = selectedYear.toString()
                }

                // All Time Event Trend Chart
                if (selectedAllProperties) {
                    ReportsGraphComponentInt {
                        loadingProp = loadingAllTimeEvents
                        xAxisData = arrayOf(jsObject {
                            id = "months"
                            this.data = allTimeData.keys.toTypedArray()
                            scaleType = "band"
                        })
                        seriesData = arrayOf(
                            jsObject {
                                this.data = allTimeData.values.toTypedArray()
                                stack = "A"
                                label = "$chartName All Time"
                            },
                            jsObject {
                                this.data = allTimeDeviceCount
                                stack = "B"
                                label = "Sensor Count"
                                color = "#5E5D5C"
                            })
                        chartHeight = 500
                        this.chartWidth = chartWidth
                        this.isMobile = isMobile
                        menuItemList = arrayOf(
                            SimpleMenuItem("Smoke Events", "Smoke Events"),
                            SimpleMenuItem("Leak Events", "Leak Events"),
                            SimpleMenuItem("Tamper Events", "Tamper Events"),
                            SimpleMenuItem("Stove Shutoff Events", "Stove Shutoff Events")
                        )
                        onChangeProp = { event ->
                            val eventType = event.target.value as String
                            eventTypeSelector = eventType
                            chartName = eventType
                            updateActionsAndEvents(propertyId, eventType)
                        }
                        this.chartName = "All Time Events"
                        inputLabel = "Event Type"
                        initialSelectValue = eventTypeSelector
                    }
                } else {
                    ReportsGraphComponentInt {
                        loadingProp = loadingAllTimeEvents
                        xAxisData = arrayOf(jsObject {
                            id = "months"
                            this.data = allTimeData.keys.toTypedArray()
                            scaleType = "band"
                        })
                        seriesData = arrayOf(
                            jsObject {
                                this.data = allTimeData.values.toTypedArray()
                                stack = "A"
                                label = "$chartName All Time"
                            })
                        chartHeight = 500
                        this.chartWidth = chartWidth
                        this.isMobile = isMobile
                        menuItemList = arrayOf(
                            SimpleMenuItem("Smoke Events", "Smoke Events"),
                            SimpleMenuItem("Leak Events", "Leak Events"),
                            SimpleMenuItem("Tamper Events", "Tamper Events"),
                            SimpleMenuItem("Stove Shutoff Events", "Stove Shutoff Events")
                        )
                        onChangeProp = { event ->
                            val eventType = event.target.value as String
                            eventTypeSelector = eventType
                            chartName = eventType
                            updateActionsAndEvents(propertyId, eventType)
                        }
                        this.chartName = "All Time Events"
                        inputLabel = "Event Type"
                        initialSelectValue = eventTypeSelector
                    }
                }

                //Device to event ratio
                ReportsGraphComponentDouble {
                    loadingProp = loadingDeviceToEventRatio
                    xAxisData = arrayOf(jsObject {
                        id = "months"
                        this.data = allTimeData.keys.toTypedArray()
                        scaleType = "band"
                    })
                    seriesData = if (preferences.isAdmin) {
                        arrayOf(jsObject {
                            this.data = deviceToEventRatio
                            stack = "A"
                            label = "Sensor to Event Ratio"
                        }, jsObject {
                            this.data = deviceToOfflineEventRatio
                            stack = "B"
                            label = "Sensor to Offline Event Ratio"
                            color = "#5E5D5C"
                        })
                    }
                    else {
                        arrayOf(jsObject {
                            this.data = deviceToEventRatio
                            stack = "A"
                            label = "Sensor to Event Ratio"
                        })
                    }
                    chartHeight = 500
                    this.chartWidth = chartWidth
                    this.isMobile = isMobile
                    menuItemList = arrayOf(
                        SimpleMenuItem("Smoke Events", "Smoke Events"),
                        SimpleMenuItem("Leak Events", "Leak Events"),
                        SimpleMenuItem("Tamper Events", "Tamper Events"),
                    )
                    onChangeProp = { event ->
                        val eventType = event.target.value as String
                        eventTypeSelectorRatio = eventType
                        chartName = eventType
                        updateDeviceToEventRatio(propertyId, allTimeDeviceCount, eventType)
                    }
                    this.chartName = "Events per Active Sensor"
                    inputLabel = "Event Type"
                    initialSelectValue = eventTypeSelectorRatio
                    chartDescription = "Average number of events triggered per installed sensor each month, categorized by event type."
                }
            }
        }
        else {
            div {
                +"Please select a property to view reports"
            }
        }
    }
}


// Helper function to create JavaScript objects
inline fun <T> jsObject(builder: T.() -> Unit): T {
    return (js("{}") as T).apply(builder)
}