package com.fireavert.events.common.api

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.devices.interface_adapters.toDeviceEvent
import com.fireavert.events.common.logic.EventsRepository
import com.fireavert.events.logic.models.DeviceEvent
import com.fireavert.events.logic.models.DeviceEventStatus
import com.fireavert.events.logic.models.EventLinkedAction
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext
import kotlinx.datetime.Instant

class ApiEventsRepository(
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
    private val dataSource: EventsDataSource,
    private val preferences: Preferences,
) : ApiRepository(ioContext = ioContext, tokenRefreshService = tokenRefreshService),
    EventsRepository {

    override suspend fun eventHistoryForUnit(unitId: Int): Try<List<DeviceEvent>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getEventHistoryByUnit(preferences.token, unitId)
                    .map { activeEventsResponse ->
                        activeEventsResponse.events?.map { deviceEventResponse -> deviceEventResponse.toDeviceEvent() }
                            ?: emptyList()
                    }
            }
        }

    override suspend fun dismissEvent(eventId: Long): Try<Boolean> = withContext(ioContext) {
        callWithRefresh { dataSource.dismissEvent(preferences.token, eventId) }
            .map { it.success == true }
    }

    override suspend fun markAsTest(eventId: Long): Try<Boolean> = withContext(ioContext) {
        callWithRefresh { dataSource.markAsTest(preferences.token, eventId) }.map {
            it.success == true
        }
    }

    override suspend fun markAsFalseAlert(eventId: Long): Try<Boolean> = withContext(ioContext) {
        callWithRefresh {
            dataSource.markAsFalseAlarm(
                preferences.token,
                eventId
            )
        }.map { response: com.fireavert.events.markasfalsealarm.api.Response ->
            response.success == true
        }
    }

    override suspend fun deleteEventFromHistory(eventId: Long): Try<Boolean> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.deleteFromHistory(
                    preferences.token,
                    eventId
                )
            }.map { response: com.fireavert.events.deleteeventfromhistory.Response ->
                response.success == true
            }
        }

    override suspend fun deleteAllEventsFromHistoryForUnit(unitId: Int): Try<Boolean> =

        withContext(ioContext) {
            callWithRefresh { dataSource.deleteAllEventsFromHistoryForUnit(preferences.token, unitId) }
        }.map { response ->
            response.success
        }

    override suspend fun getUnclearedEvents(): Try<List<Pair<DeviceEvent, DeviceEventStatus>>> {
        TODO("Not yet implemented")
    }

    override suspend fun getEventsAndActions(
        propertyIds: List<Int>,
        startInstant: Instant,
        endInstant: Instant
    ): Try<Pair<List<DeviceEvent>, List<EventLinkedAction>>> {
        TODO("Not yet implemented")
    }

    override suspend fun getUnitIdFromEventId(eventId: Long): Try<Int> {
        TODO("Not yet implemented")
    }

    override suspend fun getAllShutOfEventsForProperty(propertyId: Int): Try<List<DeviceEvent>> {
        TODO("Not yet implemented")
    }
}