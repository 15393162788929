package com.fireavert.components.dialogs.units

import com.fireavert.common.TableColumn
import com.fireavert.components.events.EventTypeIcon
import com.fireavert.components.pages.UnitDetailsDialogProps
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.StatusLevel
import com.fireavert.devices.logic.models.UnitDetailsDeviceDataState
import com.fireavert.devices.logic.models.UnitDetailsEventHistoryDataState
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.preferences.logic.Preferences
import com.fireavert.styles.DialogStyles
import com.fireavert.styles.TableStyles
import com.fireavert.styles.UnitStyles
import com.fireavert.units.presentation.UnitDetailsController
import com.fireavert.units.presentation.UnitDetailsViewModel
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import react.router.dom.Link
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*

val UnitDetailsMobileDialog = FC<UnitDetailsDialogProps> { props ->
    val controller = getKoinInstance<UnitDetailsController>()
    val viewModel = getKoinInstance<UnitDetailsViewModel>()
    val logger = getKoinInstance<Logger>()
    val preferences = getKoinInstance<Preferences>()

    /**
     * Params
     */
    val params = useParams()
    val propertyId = params["propertyId"]?.toInt() ?: 0
    val unitId = params["unitId"]?.toInt() ?: 0


    /**
     * State
     */
    var isLoading: Boolean by useState(false)
    var unitNumber: String by useState("")
    var propertyName: String by useState("")
    var address: String by useState("")
    var city: String by useState("")
    var state: String by useState("")
    var zip: String by useState("")
    var tenantName: String by useState("")
    var tenantEmail: String by useState("")
    var tenantPhone: String by useState("")
    var totalAlarmEvents: Int by useState(0)
    var totalShutoffEvents: Int by useState(0)
    var totalLeakEvents: Int by useState(0)
    var totalTamperEvents: Int by useState(0)
    var totalFlowEvents: Int by useState(0)
    var devices: List<UnitDetailsDeviceDataState> by useState(emptyList())
    var eventHistory: List<UnitDetailsEventHistoryDataState> by useState(emptyList())
    var devicesColumns: List<TableColumn> by useState(
        listOf(
            TableColumn(
                text = "DEVICE TYPE", sortOrder = TableColumn.SortOrder.HighToLow, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "STATUS", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "ACTION",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left, 
                enableSort = false,
            )
        )
    )
    var eventHistoryColumns: List<TableColumn> by useState(
        listOf(
            TableColumn(
                text = "DATE / TIME",
                sortOrder = TableColumn.SortOrder.HighToLow,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "EVENT", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            )
        )
    )

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isLoading = viewState.isLoading
            propertyName = viewState.propertyName
            unitNumber = viewState.unitNumber
            address = viewState.address
            city = viewState.city
            state = viewState.state
            zip = viewState.zip
            tenantName = viewState.tenantName
            tenantEmail = viewState.tenantEmail
            tenantPhone = viewState.tenantPhone
            totalAlarmEvents = viewState.totalAlarmEvents
            totalShutoffEvents = viewState.totalShutoffEvents
            totalLeakEvents = viewState.totalLeakEvents
            totalFlowEvents = viewState.totalFlowEvents
            totalTamperEvents = viewState.totalTamperEvents
            if(viewState.devices.isEmpty()) {
                devices = emptyList()
            } else {
                devices = viewState.devices.map {
                    UnitDetailsDeviceDataState(
                        id = it.id,
                        type = it.type,
                        location = it.location,
                        deviceId = it.deviceId,
                        connectionStatus = it.connectionStatus,
                        connectionStatusLevel = it.connectionStatusLevel,
                        deviceStatus = it.deviceStatus,
                        deviceStatusLevel = it.deviceStatusLevel,
                        deviceIsTriggered = it.deviceIsTriggered,
                        isPoweredOn = it.powerOn,
                        installed = it.installed
                    )
                }
            }
            if (viewState.eventHistory.isEmpty()) {
                eventHistory = emptyList()
            } else {
                eventHistory = viewState.eventHistory.map {
                    UnitDetailsEventHistoryDataState(
                        eventId = it.eventId,
                        dateTime = it.dateTime,
                        timestamp = it.timestamp,
                        deviceType = it.deviceType,
                        location = it.location,
                        eventType = it.eventType,
                        alarmActive = it.alarmActive,
                        deviceTriggered = it.deviceTriggered,
                        isTest = it.isTest,
                        isFalseAlert = it.isFalseAlert
                    )
                }
            }
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyId, unitId, preferences.timeZone, preferences.isAdmin)
        }

        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = {}
        onClose = { _, _ ->
            controller.close(propertyId)
        }
        maxWidth = Breakpoint.lg
        fullWidth = true
        sx {
            "& .MuiDialog-paper" {
                height = 65.pct
                maxHeight = 65.pct
                margin = Auto.auto
                display = Display.flex
                flexDirection = FlexDirection.column
                padding = 0.px
                borderRadius = 10.px
            }
        }
        DialogTitle {
            sx {padding = 0.px}
        }
        DialogContent {
            sx {
                flex = number(1.0)
                display = Display.flex
                flexDirection = FlexDirection.column
                overflow = Overflow.scroll
                padding = 0.px
            }
            div {
                className = DialogStyles.mobileDialogActionTitle
                div {
                    div {
                        css { fontSize = 16.px }
                        +propertyName
                    }
                    div {
                        className = ClassName {
                            display = Display.flex
                            justifyContent = JustifyContent.spaceBetween
                        }
                        div {
                            +"Unit $unitNumber"
                        }
                        if (props.isUserAdmin) {
                            div {
                                Link {
                                    to = "${RoutePaths.properties}/${propertyId}/units/${unitId}/edit"
                                    css {
                                        fontSize = 12.px
                                        textDecoration = TextDecoration.underline
                                    }
                                    +"Edit Unit"
                                }
                            }
                        }
                    }
                }
            }
            if (isLoading) {
                css {
                    margin = important(0.px)
                    padding = important(10.px)
                    textAlign = TextAlign.center
                    backgroundImage = linearGradient(180.deg, Color("#FA683F"), Color("#FBAE41"))
                }
                CircularProgress {
                    color = CircularProgressColor.primary
                }
            } else {
                css {
                    margin = important(0.px)
                    padding = important(0.px)
                }
                table {
                    cellPadding = "0"
                    cellSpacing = "0"
                    className = UnitStyles.unitDetailsInfoTable
                    tr {
                        className = UnitStyles.unitInfoRow
                        td {
                            className = UnitStyles.unitInfoTitleCell
                            div {
                                className = UnitStyles.unitInfoTitle
                                +"Tenant Info"
                            }
                            div {
                                +tenantName
                            }
                            div {
                                +tenantEmail
                            }
                            div {
                                +tenantPhone
                            }
                        }
                        td {
                            className = UnitStyles.unitInfoTitleCell
                            div {
                                +"Unit Address"
                                className = UnitStyles.unitInfoTitle
                            }
                            div {
                                +propertyName
                            }
                            div {
                                +address
                            }
                            div {
                                +"$city, $state, $zip"
                            }
                        }
                    }
                }
                div {
                    className = cx(TableStyles.tableContainerMobileFixed,
                        ClassName { borderBottom = Border(3.px, LineStyle.solid, NamedColor.black) })
                    div {
                        ariaLabel = "Table Stuff"
                        className = TableStyles.table
                        SortBar {
                            columns = mapToControlColumns(devicesColumns.toTypedArray())
                            includeLoading = false
                            this.isLoading = false
                            columnsChanged = {
                                devicesColumns = mapToStateColumns(it).toList()
                            }
                        }
                        val sortedDevices = controller.sortDevicesWithColumns(devicesColumns, devices)
                        sortedDevices.forEach { device ->
                            div {
                                className = TableStyles.tableRow
                                div {
                                    className = TableStyles.tableCell
                                    +controller.deviceTypeString(device.type)
                                }
                                div {
                                    className = when (device.deviceStatusLevel) {
                                        StatusLevel.Green -> cx(TableStyles.tableCell, TableStyles.greenBold)
                                        StatusLevel.Yellow -> cx(TableStyles.tableCell, TableStyles.yellowBold)
                                        StatusLevel.Red -> cx(TableStyles.tableCell, TableStyles.redBold)
                                        StatusLevel.Grey -> cx(TableStyles.tableCell, TableStyles.greyBold)
                                        StatusLevel.None -> TableStyles.tableCell
                                    }
                                    +device.deviceStatus
                                }
                                div {
                                    className = TableStyles.tableCellCentered
                                    if (device.deviceIsTriggered && (device.type == DeviceType.FireAvert || device.type == DeviceType.FireAvertAppliance) && device.connectionStatus != "Offline") {
                                        Link {
                                            to = "${RoutePaths.properties}/$propertyId/reboot_device/${device.id}"
                                            +"Reboot"
                                            css { color = NamedColor.blue }
                                        }
                                    } else if (device.deviceIsTriggered && device.type == DeviceType.FireAvertGas) {
                                        Link {
                                            to = "${RoutePaths.properties}/$propertyId/reboot_device/${device.id}/gas"
                                            +"Reboot"
                                            css { color = NamedColor.blue }
                                        }
                                    } else if (device.type.isFireAvert) {
                                        span {
                                            className = TableStyles.disabledReboot
                                            +""
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
                div {
                    className = cx(UnitStyles.unitInfoTitle, ClassName {
                        padding = 10.px
                    })
                    +"Events"
                }
                div {
                    className = TableStyles.tableContainerMobileFixed
                    div {
                        className = TableStyles.table

                        div {
                            className = TableStyles.tableRow
                            div {
                                className = TableStyles.tableCell
                                +"Smoke"
                            }
                            div {
                                className = TableStyles.tableCell
                                +"Shutoff"
                            }
                            div {
                                className = TableStyles.tableCell
                                +"Leaks"
                            }
                            div {
                                className = TableStyles.tableCell
                                +"Tamper"
                            }
                        }

                        div {
                            className = TableStyles.tableRow
                            div {
                                className = TableStyles.tableCell
                                +totalAlarmEvents.toString()
                            }
                            div {
                                className = TableStyles.tableCell
                                +totalShutoffEvents.toString()
                            }
                            div {
                                className = TableStyles.tableCell
                                +totalLeakEvents.toString()
                            }
                            div {
                                className = TableStyles.tableCell
                                +totalTamperEvents.toString()
                            }
                        }
                    }
                }
            }
            div {
                className = TableStyles.tableContainerMobileFixed
                div {
                    className = TableStyles.table
                    SortBar {
                        columns = mapToControlColumns(eventHistoryColumns.toTypedArray())
                        includeLoading = false
                        this.isLoading = false
                        columnsChanged = {
                            eventHistoryColumns = mapToStateColumns(it).toList()
                        }
                    }
                    val sortedEvents = controller.sortEventsByColumns(
                        eventHistoryColumns, eventHistory
                    )
                    if (sortedEvents.isEmpty()) {
                        div {
                            className = cx(TableStyles.tableRow, ClassName {
                                textAlign = TextAlign.center
                                display = Display.flex
                                padding = 40.px
                                color = Color("#525F7F")
                            })
                            +"No events yet"
                        }
                    } else {
                        sortedEvents.forEachIndexed { _, event ->
                            div {
                                className = TableStyles.tableRow
                                div {
                                    className = TableStyles.tableCell
                                    +event.dateTime
                                }
                                div {
                                    className = TableStyles.tableCell
                                    EventTypeIcon {
                                        eventType = event.eventType
                                        deviceType = event.deviceType
                                        alarmActive = event.alarmActive
                                        deviceTriggered = event.deviceTriggered
                                        isMobile = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
