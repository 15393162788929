package com.fireavert.user.logic.models

import kotlinx.serialization.Serializable

@Serializable
enum class RolesModel(val displayText: String, val hierarchyLevel: Int) {
    SUPERADMIN("Superadmin", 100),
    EXECUTIVE("Executive", 90),
    ADMIN("Admin", 80),
    REGIONAL_MANAGER("Regional Manager", 70),
    PROPERTY_ADMIN("Property Admin", 60),
    SALES_REP("Sales Rep", 50),
    UI_TESTER("UI Tester", 40),
    V2_TESTER("V2 UI", 40),
    V2_UI("V2 UI", 40),
    MAINTENANCE_USER("Maintenance User", 30),
    SINGLE_FAMILY_USER("Single Family User", 20),
    TENANT("Tenant", 10),
    UNKNOWN("Unknown", 0);

    fun hasAtLeastLevelOf(otherRole: RolesModel): Boolean {
        return this.hierarchyLevel >= otherRole.hierarchyLevel
    }

    fun hasRoleLevelBelow(otherRole: RolesModel): Boolean {
        return this.hierarchyLevel < otherRole.hierarchyLevel
    }
    fun getHierarchyLevelForRole(role: RolesModel): Int {
        return role.hierarchyLevel
    }


    companion object {
        // Convert from database value (enum name) to enum
        fun fromDatabaseValue(value: String) = RolesModel.valueOf(value)

        // Convert from display text to enum if needed
        fun fromDisplayText(text: String) = RolesModel.entries.firstOrNull {
            it.displayText == text
        } ?: throw IllegalArgumentException("No status found for text: $text")

    }
}