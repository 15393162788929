package com.fireavert.components.dialogs

import com.fireavert.components.properties.PropertyForm
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.models.Mode
import com.fireavert.properties.logic.models.PropertyAdminModel
import com.fireavert.properties.logic.models.PropertyType
import com.fireavert.properties.logic.models.SubscriptionType
import com.fireavert.properties.presentation.add_new_property.AddNewPropertyController
import com.fireavert.properties.presentation.add_new_property.AddNewPropertyViewModel
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*

val AddNewPropertyDialog = FC {
    var statePropertyName: String by useState("")
    var statePropertyNameError: Boolean by useState(false)
    var stateNumberOfUnits: Int by useState(0)
    var stateNumberOfUnitsError: Boolean by useState(false)
    var stateAddress: String by useState("")
    var stateAddressError: Boolean by useState(false)
    var stateCity: String by useState("")
    var stateCityError: Boolean by useState(false)
    var stateGeographicalState: String by useState("")
    var stateGeographicalStateError: Boolean by useState(false)
    var stateManagementCompany: String by useState("")
    var stateManagementCompanyError: Boolean by useState(false)
    var stateSubscriptionType: SubscriptionType? by useState(null)
    var stateSubscriptionTypeError: Boolean by useState(false)
    var stateMode: Mode? by useState(null)
    var stateModeError: Boolean by useState(false)
    var statePropertyType: PropertyType by useState(PropertyType.MultiFamily)
    var statePropertyTypeError: Boolean by useState(false)
    var stateZip: String by useState("")
    var stateZipError: Boolean by useState(false)
    var stateTimezoneId: String by useState("")
    var stateTimezoneIdError: Boolean by useState(false)
    var mainTimeZoneList: Array<String> by useState(emptyArray())
    var statePropertyAdminModels: List<PropertyAdminModel> by useState(emptyList())
    var statePropertyAdminError: Boolean by useState(false)
    var stateFireClaimsOneCost : Double by useState(0.0)
    var stateFireClaimsTwoCost : Double by useState(0.0)
    var stateFireClaimsThreeCost : Double by useState(0.0)
    var stateFireClaimsFourCost : Double by useState(0.0)
    var stateFireClaimsFiveCost : Double by useState(0.0)
    var stateFireClaimsOneClaimCount : Int by useState(0)
    var stateFireClaimsTwoClaimCount : Int by useState(0)
    var stateFireClaimsThreeClaimCount : Int by useState(0)
    var stateFireClaimsFourClaimCount : Int by useState(0)
    var stateFireClaimsFiveClaimCount : Int by useState(0)
    var stateFireClaimsError: Boolean by useState(false)
    var stateErrorMessage: String by useState("")

    val viewModel = getKoinInstance<AddNewPropertyViewModel>()
    val controller = getKoinInstance<AddNewPropertyController>()
    val logger = getKoinInstance<Logger>()

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            logger.e("Property Admins in subscription ${viewState.propertyAdmins}")
            statePropertyName = viewState.propertyName ?: ""
            statePropertyNameError = viewState.propertyNameError
            stateNumberOfUnits = viewState.numberOfUnits ?: 0
            stateNumberOfUnitsError = viewState.numberOfUnitsError
            stateGeographicalState = viewState.state ?: ""
            stateGeographicalStateError = viewState.stateError
            stateZip = viewState.zip?.toString() ?: ""
            stateZipError = viewState.zipError
            stateAddress = viewState.address ?: ""
            stateAddressError = viewState.addressError
            stateCity = viewState.city ?: ""
            stateCityError = viewState.cityError
            stateManagementCompany = viewState.managementCompany ?: ""
            stateManagementCompanyError = viewState.managementCompanyError
            stateSubscriptionType = viewState.subscriptionType
            stateSubscriptionTypeError = viewState.subscriptionTypeError
            stateMode = viewState.mode
            stateModeError = viewState.modeError
            statePropertyType = viewState.propertyType
            statePropertyTypeError = viewState.propertyTypeError
            stateTimezoneId = viewState.timezoneId ?: ""
            stateTimezoneIdError = viewState.timezoneIdError
            mainTimeZoneList = TimeZone.availableZoneIds.filter { it.length > 3 }.toTypedArray()
            statePropertyAdminError = viewState.propertyAdminsError
            statePropertyAdminModels = viewState.propertyAdmins.toList()
            stateFireClaimsOneCost = viewState.fireClaimsOneCost
            stateFireClaimsTwoCost = viewState.fireClaimsTwoCost
            stateFireClaimsThreeCost = viewState.fireClaimsThreeCost
            stateFireClaimsFourCost = viewState.fireClaimsFourCost
            stateFireClaimsFiveCost = viewState.fireClaimsFiveCost
            stateFireClaimsOneClaimCount = viewState.fireClaimsOneClaimCount
            stateFireClaimsTwoClaimCount = viewState.fireClaimsTwoClaimCount
            stateFireClaimsThreeClaimCount = viewState.fireClaimsThreeClaimCount
            stateFireClaimsFourClaimCount = viewState.fireClaimsFourClaimCount
            stateFireClaimsFiveClaimCount = viewState.fireClaimsFiveClaimCount
            stateFireClaimsError = viewState.fireClaimsError
            stateErrorMessage = viewState.errorMessage
        }.launchIn(viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = {
            controller.clickedCancel()
        }
        onClose = { _, _ -> controller.clickedCancel() }
        maxWidth = Breakpoint.lg
        DialogTitle {
            +"Add New Property"
            css {
                backgroundImage = linearGradient(180.deg, Color("#2A3042"), Color("#4C5566"))
                color = NamedColor.white
            }
        }
        DialogContent {
            PropertyForm {
                propertyName = statePropertyName
                propertyNameError = statePropertyNameError
                propertyNameChanged = controller::propertyNameChanged

                numberOfUnits = stateNumberOfUnits
                numberOfUnitsError = stateNumberOfUnitsError
                numberOfUnitsChanged = controller::numberOfUnitsChanged

                address = stateAddress
                addressError = stateAddressError
                addressChanged = controller::addressChanged

                city = stateCity
                cityError = stateCityError
                cityChanged = controller::cityChanged

                geographicalState = stateGeographicalState
                geographicalStateError = stateGeographicalStateError
                geographicalStateChanged = controller::stateChanged

                zip = stateZip
                zipError = stateZipError
                zipChanged = controller::zipChanged

                timezoneId = stateTimezoneId
                timezoneIdError = stateTimezoneIdError
                timezoneIdChanged = controller::timezoneIdChanged
                mainTimeZonesList = mainTimeZoneList

                managementCompany = stateManagementCompany
                managementCompanyError = stateManagementCompanyError
                managementCompanyChanged = controller::managementCompanyChanged

                subscriptionType = stateSubscriptionType
                subscriptionTypeError = stateSubscriptionTypeError
                subscriptionTypeChanged = controller::subscriptionTypeChanged

                mode = stateMode
                modeError = stateModeError
                modeChanged = controller::modeChanged

                propertyType = statePropertyType
                propertyTypeError = statePropertyTypeError
                propertyTypeChanged = controller::propertyTypeChanged

                addPropertyAdminClicked = {
                    val newList = statePropertyAdminModels.toMutableList()
                    newList.add(PropertyAdminModel())
                    propertyAdminsChanged(newList)
                }

                propertyAdminsChanged = {
                    controller.propertyAdminModelsChanged(it)
                }

                resendPropertyAdminInviteClicked = {
                    /** this should never happen **/
                }

                fireClaimYearOneCost = stateFireClaimsOneCost
                fireClaimsChangedOneCost = controller::fireClaimsChangedOneCost
                fireClaimYearOneClaims = stateFireClaimsOneClaimCount
                fireClaimsChangedOneClaims = controller::fireClaimsChangedOneClaims

                fireClaimYearTwoCost = stateFireClaimsTwoCost
                fireClaimsChangedTwoCost = controller::fireClaimsChangedTwoCost
                fireClaimYearTwoClaims = stateFireClaimsTwoClaimCount
                fireClaimsChangedTwoClaims = controller::fireClaimsChangedTwoClaims

                fireClaimYearThreeCost = stateFireClaimsThreeCost
                fireClaimsChangedThreeCost = controller::fireClaimsChangedThreeCost
                fireClaimYearThreeClaims = stateFireClaimsThreeClaimCount
                fireClaimsChangedThreeClaims = controller::fireClaimsChangedThreeClaims

                fireClaimYearFourCost = stateFireClaimsFourCost
                fireClaimsChangedFourCost = controller::fireClaimsChangedFourCost
                fireClaimYearFourClaims = stateFireClaimsFourClaimCount
                fireClaimsChangedFourClaims = controller::fireClaimsChangedFourClaims

                fireClaimYearFiveCost = stateFireClaimsFiveCost
                fireClaimsChangedFiveCost = controller::fireClaimsChangedFiveCost
                fireClaimYearFiveClaims = stateFireClaimsFiveClaimCount
                fireClaimsChangedFiveClaims = controller::fireClaimsChangedFiveClaims


                propertyAdminModels = statePropertyAdminModels
                allowAddExistingPropertyAdmins = false
                errorMessage = stateErrorMessage
            }
        }
        DialogActions {
            css {
                height = 104.px
                paddingRight = 24.px
            }
            Button {
                +"Cancel"
                color = ButtonColor.inherit
                onClick = { controller.clickedCancel() }
                variant = ButtonVariant.contained
                css {
                    width = 163.px
                    marginRight = 6.px
                }
            }
            Button {
                +"Save Changes"
                color = ButtonColor.primary
                onClick = {
                    viewModel.scope.launch {
                        controller.clickedSave()
                    }
                }
                variant = ButtonVariant.contained
                css {
                    width = 163.px
                }
            }
        }
    }
}