package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import web.cssom.*

object StandardizedStyles {

    val PageTitleHeader = ClassName {
        fontWeight = integer(700)
        color = NamedColor.black
        fontSize = 3.5.rem
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        marginLeft = 25.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 36.px
            fontWeight = FontWeight.bolder
            color = NamedColor.black
            display = Display.block
        }
    }

    val PageTitleHeaderLinkContainer = ClassName {
        display = Display.flex
        alignItems = AlignItems.center
        gap = 8.px
        marginLeft = 25.px
    }


    val PageTitleHeaderLinks = ClassName {
        cursor = Cursor.pointer
        color = Color("#0088cc")

        hover {
            textDecoration = TextDecoration.underline
        }
    }

    val HeaderH2 = ClassName {
        fontSize = 1.5.rem
        marginLeft = 25.px
        fontWeight = FontWeight.bold
        color = NamedColor.black
        paddingBottom = 10.px
        paddingTop = 40.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            marginLeft = important(0.px)
            paddingBottom = 5.px
            paddingTop = 20.px
        }
    }

    val flexStackSetup = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        gap = 20.px
    }

    val flexRowSetup = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        gap = 10.px
        width = 100.pct
    }
}