package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import emotion.css.cx
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.form
import web.cssom.*

object TableStyles {
    val disabledReboot = ClassName {
        color = NamedColor.grey
        cursor = Cursor.notAllowed
    }

    val tableContainer = ClassName {
        width = 100.pct
        borderRadius = 4.px
        backgroundColor = NamedColor.white
        marginTop = 23.px
        marginBottom = 23.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            marginTop = 0.px
            marginBottom = 0.px
            maxHeight = 450.px
            overflow = Overflow.scroll
            overflowX = Overflow.hidden
            borderRadius = 10.px
        }
    }

    val tableContainerNew = ClassName {
        width = 100.pct
        backgroundColor = NamedColor.white
        marginTop = 23.px
        marginBottom = 23.px
        borderRadius = 10.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            marginTop = 0.px
            marginBottom = 0.px
            maxHeight = 450.px
            overflow = Overflow.scroll
            overflowX = Overflow.hidden
        }
    }

    val newTableView = ClassName {
        maxHeight = 250.px
        overflow = Overflow.scroll
        overflowX = Overflow.hidden
        width = 100.pct
        borderRadius = 15.px
        backgroundColor = NamedColor.white
        marginTop = 23.px
        marginBottom = 23.px
    }

    val tableContainerAccordion = ClassName {
        width = 100.pct
        borderRadius = 4.px
        backgroundColor = NamedColor.white
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            marginTop = 0.px
            marginBottom = 0.px
            maxHeight = 450.px
            overflow = Overflow.scroll
            overflowX = Overflow.hidden
            borderRadius = 10.px
        }
    }

    val tableContainerMobileFixed = ClassName {
        marginTop = 0.px
        marginBottom = 0.px
        maxHeight = 500.px
        width = 100.pct
        verticalAlign = VerticalAlign.middle
        fontWeight = FontWeight.bold
        textAlign = TextAlign.center
        margin = Margin(0.px, Auto.auto)
    }

    val scrolledTableContainer = cx(tableContainer, ClassName {
        maxHeight = 550.px
        overflow = Overflow.scroll
    })
    val tableTitle = ClassName {
        backgroundColor = NamedColor.white
        position = Position.sticky
        top = (-1).px
        zIndex = integer(100)
        fontWeight = integer(700)
        color = Color("#525F7F")
        fontSize = 15.px
        padding = Padding(24.px, 16.px)
        width = 100.pct
        display = Display.flex
        alignItems = AlignItems.center
        height = 84.px
        justifyContent = JustifyContent.spaceBetween
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 22.px
            padding = Padding(16.px, 16.px)
            color = NamedColor.black
            backgroundColor = Color("#EDEFF3")
            padding = 0.px
            height = 60.px
            marginTop = 20.px
            position = Position.relative
        }
    }

    val mobileTableTitleUnitList = ClassName {
        fontWeight = integer(700)
        fontSize = 22.px
        padding = Padding(16.px, 16.px)
        color = NamedColor.black
        backgroundColor = Color("#EDEFF3")
        padding = 0.px
        height = 36.px
        marginTop = 30.px
        marginBottom = 65.px
        position = Position.relative
    }

    val table = ClassName {
        display = Display.table
        width = 100.pct
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            tableLayout = TableLayout.fixed
        }
    }

    val tableNew = ClassName {
        display = Display.table
        width = 100.pct
        tableLayout = TableLayout.fixed
    }

    val tableHeaderNew = ClassName {
        fontWeight = integer(700)
        height = 41.px
        position = Position.sticky
        zIndex = integer(100)
        backgroundColor = NamedColor.white
        borderBottom = Border(1.px, LineStyle.solid, NamedColor.black)
        color = NamedColor.black
        fontSize = 1.5.rem
        borderRadius = 25.px
        display = Display.tableRow
        top = 0.px

        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 1.rem
        }
    }

    val tableHeader = ClassName {
        display = Display.tableHeaderGroup
        fontWeight = integer(600)
        fontSize = 11.px
        color = Color("#525F7F")
        height = 41.px
        backgroundColor = Color("#F2F4FA")
        position = Position.sticky
        top = 83.px
        zIndex = integer(100)
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            backgroundColor = NamedColor.white
            color = NamedColor.black
            fontSize = 14.px
            borderRadius = 25.px
            display = Display.tableRow
            top = 0.px
        }
    }
    val tableHeaderCell = ClassName {
        cursor = Cursor.pointer
        display = Display.tableCell
        padding = 16.px
        borderBottom = Border(1.px, LineStyle.solid, Color("#EDEFF2"))
        borderTop = Border(1.px, LineStyle.solid, Color("#EDEFF2"))
    }

    val tableRowPropertyList = ClassName {
        display = Display.tableRow
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            justifyContent = JustifyContent.spaceBetween
            display = Display.flex
            flexDirection = FlexDirection.column
            borderTop = Border(1.px, LineStyle.solid, Color("#e0e0e0"))
            padding = 15.px
            width = 100.pct
            nthOfType("1") {
                borderTop = None.none
            }
            "& a, & a:visited" {
                color = important(NamedColor.black)
            }
        }
    }

    val tableRowPropertyListNew = ClassName {
        display = Display.tableRow
        position = Position.relative
        padding = Padding(15.px, 25.px, 15.px, 25.px)
        width = 100.pct
        cursor = Cursor.pointer
        borderBottom = Border(1.px, LineStyle.solid, Color("#EDEFF2"))

        "& a, & a:visited" {
            color = important(NamedColor.black)
        }
    }

    val tableRowAdminListNew = ClassName {
        justifyContent = JustifyContent.spaceBetween
        display = Display.flex
        flexDirection = FlexDirection.column
        borderTop = Border(1.px, LineStyle.solid, Color("#f1f1f1"))
        padding = Padding(15.px, 25.px, 15.px, 25.px)
        width = 100.pct
        nthOfType("1") {
            borderTop = None.none
        }
        "& a, & a:visited" {
            color = important(NamedColor.black)
        }
    }

    val tableRow = ClassName {
        display = Display.tableRow
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            padding = 15.px
            "& a, & a:visited" {
                color = important(NamedColor.black)
            }
            borderBottom = Border(1.px, LineStyle.solid, Color("#DEE2E6"))
        }
    }

    val tableRowNew = ClassName {
        display = Display.tableRow
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            padding = 15.px
            "& a, & a:visited" {
                color = important(NamedColor.black)
            }
            borderBottom = Border(1.px, LineStyle.solid, Color("#DEE2E6"))
        }
        hover {
            cursor = Cursor.pointer
            backgroundColor = Color("#D4D7E4")
        }
    }

    fun tableRowWithConnectionStatus(connectionColor: Color) = ClassName {
        display = Display.tableRow
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            padding = 15.px
            "& a, & a:visited" {
                color = important(connectionColor)
            }
            borderBottom = Border(1.px, LineStyle.solid, Color("#DEE2E6"))
        }
    }

    val tableCell = ClassName {
        display = Display.tableCell
        padding = 16.px
        color = Color("#525F7F")
        borderBottom = Border(1.px, LineStyle.solid, Color("#DEE2E6"))
        minWidth = 185.px
        minHeight = 80.px
    }

    val tableCellNew = ClassName {
        display = Display.tableCell
        fontSize = 18.px
        padding = Padding(5.px, 0.px, 20.px, 16.px)
        color = NamedColor.black
        minWidth = 185.px
        minHeight = 80.px
        position = Position.relative
        backgroundColor = NamedColor.white
        // Add these new properties
        "&:last-child" {  // Targets the last cell in the row
            textAlign = TextAlign.right
            paddingRight = 16.px  // Match your existing padding
        }
    }

    fun tableCellAlignVar(alignment: TextAlign) = ClassName {
        display = Display.tableCell
        fontSize = 18.px
        padding = Padding(5.px, 0.px, 20.px, 16.px)
        color = Color("#525F7F")
        minWidth = 185.px
        minHeight = 80.px
        position = Position.relative
        backgroundColor = NamedColor.white
        textAlign = alignment
    }

    val tableCellUserVisit = ClassName {
        display = Display.tableCell
        padding = 10.px
        color = Color("Black")
        borderBottom = Border(1.px, LineStyle.solid, Color("#DEE2E6"))
        minWidth = 185.px
        fontSize = 14.px
    }
    val tableTopButtonContainer = ClassName {
        display = Display.flex
        gap = 10.px
        form {
            marginRight = 16.px
        }
    }
    val threeDotsButtonContainer = ClassName {
        padding = Padding(10.px, 5.px, 0.px, 5.px)
        cursor = Cursor.pointer
        float = Float.right
    }
    val threeDotsButton = ClassName {
        position = Position.relative
        cursor = Cursor.pointer
        backgroundColor = Color("#8B9BAD")
        borderRadius = 2.5.px
        fontSize = 0.px
        marginLeft = 7.px
        marginRight = 7.px
        padding = 2.px
        float = Float.right
        before {
            cursor = Cursor.pointer
            position = Position.absolute
            left = (-7).px
            top = 0.px
            content = string("''")
            backgroundColor = Color("#8B9BAD")
            borderRadius = 2.5.px
            fontSize = 0.px
            padding = 2.px
        }
        after {
            cursor = Cursor.pointer
            position = Position.absolute
            left = 7.px
            top = 0.px
            content = string("''")
            backgroundColor = Color("#8B9BAD")
            borderRadius = 2.5.px
            fontSize = 0.px
            padding = 2.px
        }
    }
    val threeDotsMenuItem = ClassName {
        cursor = Cursor.pointer
        a {
            color = Color("#525F7F")
            visited { color = Color("#525F7F") }
        }
        paddingLeft = 20.px
        paddingRight = 20.px
        paddingTop = 10.px
        paddingBottom = 10.px
    }
    val tableCellCenteredNew = cx(tableCellNew, ClassName {
        textAlign = TextAlign.center
    })
    val tableCellCentered = cx(tableCell, ClassName {
        textAlign = TextAlign.center
    })
    val greenBold = ClassName {
        color = Color("#51CF7A")
        fontWeight = integer(700)
    }
    val yellowBold = ClassName {
        color = Color("#FCC44D")
        fontWeight = integer(700)
    }
    val redBold = ClassName {
        color = Color("#FB6B5B")
        fontWeight = integer(700)
    }

    val greyBold = ClassName {
        color = Color("#8B9BAD")
        fontWeight = integer(700)
    }
    val activeIconNoMargin = ClassName {
        width = 25.px
        height = 18.px
    }

    val activeIconNoMarginOffline = ClassName {
        width = 25.px
        height = 24.px
    }
    val addNewPropertyTable = ClassName {
        width = 694.px
        marginTop = 10.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 100.pct

        }
    }
    val h2Style = ClassName {
        color = Color("#525F7F")
        marginBottom = 0.px
        marginTop = 0.px
    }

    val mobileTableRowWithArrow = ClassName {
        color = important(NamedColor.black)
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
    }

    val duelButtonContainer = ClassName {
        display = Display.flex
        form {
            marginRight = 16.px
        }
        gap = 10.px
    }

}