package com.fireavert.units.logic.models.response

import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.units.logic.models.UnitStatus
import kotlinx.serialization.Serializable

@Serializable
data class UnitResponse(
    val id: Int? = null,
    val propertyName: String? = null,
    val number: String? = null,
    val tenantName: String? = null,
    val tenantEmail: String? = null,
    val tenantPhone: String? = null,
    val propertyId: Int? = null,
    val streetAddress: String? = null,
    val city: String? = null,
    val state: String? = null,
    val zip: String? = null,
    val status: UnitStatus? = null,
    val alarmEventCount: Int? = null,
    val shutoffEventCount: Int? = null,
    val leakEventCount: Int? = null,
    val toiletFlowEventCount: Int? = null,
    val tamperEventCount: Int? = null,
    val activeAlarm: Boolean? = null,
    val activeFireAvert: Boolean? = null,
    val activeLeakSensor: Boolean? = null,
    val activeToiletFlowSensor: Boolean? = null,
    val activeTamperSensor: Boolean? = null,
    val offlineAlarm: Boolean? = null,
    val offlineFireAvert: Boolean? = null,
    val offlineLeakSensor: Boolean? = null,
    val offlineToiletFlowSensor: Boolean? = null,
    val offlineTamperSensor: Boolean? = null,
    val notifyStoveCurrent: Boolean = false,
    val deviceNotInstalled: List<DeviceType>? = null,
    val buildingId: Int? = null
)
