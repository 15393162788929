package com.fireavert.administration_page.wireless_id_finder.logic

import com.fireavert.administration_page.wireless_id_finder.logic.models.PropertyOfflineStatisticsModel
import com.fireavert.common.InMemoryCache
import com.fireavert.common.TableColumn
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import kotlinx.datetime.Clock

class WirelessIdSectionInteractor(
    private val inMemoryCache: InMemoryCache,
    private val screen: WirelessIdSectionScreen,
    private val repository: WirelessIdRepository,
    private val logger: Logger,
) {

    companion object {
        const val PROPERTY_DATA_EXPIRATION_TIME = 60 * 30
    }

    suspend fun loadWirelessIdData(deviceId: String) {
        val awsTitle = "https://us-west-2.console.aws.amazon.com/iot/home?region=us-west-2#/wireless/devices/details/"
        val deviceLocationTitle = "https://connect.fireavert.com/app/properties/"
        screen.wirelessDeviceId = ""
        if (!checkValidId(deviceId)) {
            return
        }

        try {
            val response = when (val maybe = repository.loadWirelessIdData(deviceId)) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    screen.wirelessDeviceId = "Error loading data (probably not found)"
                    return
                }
            }
            if (response.wirelessId.isEmpty()) {
                screen.wirelessDeviceId = "Device found, but no wireless Id has been set :("
                return
            }
            screen.wirelessDeviceId = awsTitle + response.wirelessId
            screen.unitName = response.unitName ?: "Unable to get unit name"
            screen.propertyName = response.propertyName ?: "Unable to get property name"
            screen.deviceLocationLink =  if (response.propertyName != null && response.unitName != null) deviceLocationTitle + response.propertyId + "/units/" + response.unitId else ""
        } catch (e: Exception) {
            screen.wirelessDeviceId = "Error loading data"
        }
    }

    suspend fun loadGatewayDeviceData(propertyId: String) {
        screen.gatewayDeviceData = emptyMap()
        screen.gatewayDeviceDataLoading = true
        try {
            val response = when (val maybe = repository.loadGatewayDeviceData(propertyId)) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    screen.gatewayDeviceDataLoading = false
                    return
                }
            }

            screen.gatewayDeviceDataLoading = false
            screen.gatewayDeviceData = response.deviceToGateway

        } catch (e: Exception) {
            logger.e("Error loading gateway device data", e)
        } finally {
            screen.gatewayDeviceDataLoading = false // Ensure loading is false even on error
        }
    }

    suspend fun reloadAllGatewayInfo() {
        try {
            when (val maybe = repository.reloadAllGatewayInfo()) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    logger.e("Error reloading all gateway info", maybe.exception)
                    return
                }
            }

        } catch (e: Exception) {
            logger.e("Error reloading all gateway info", e)
        }
    }

    // Sorts the provided list based on the column sort state
    fun sortPropertyStatistics(columns: Array<TableColumn>, statistics: List<PropertyOfflineStatisticsModel>): List<PropertyOfflineStatisticsModel> {
        val sortColumn = columns.firstOrNull { it.sortOrder != TableColumn.SortOrder.Neutral } ?: return statistics
        val columnIndex = columns.indexOf(sortColumn)
        val factor = if (sortColumn.sortOrder == TableColumn.SortOrder.LowToHigh) 1 else -1

        return when (columnIndex) {
            0 -> statistics.sortedWith { a, b -> factor * a.propertyName.compareTo(b.propertyName, ignoreCase = true) } // PROPERTY
            1 -> statistics.sortedWith { a, b -> factor * a.totalDevices.compareTo(b.totalDevices) } // TOTAL SENSORS
            2 -> statistics.sortedWith { a, b -> // ONLINE %
                val percentageComparison = a.onlinePercentage.compareTo(b.onlinePercentage)
                if (percentageComparison == 0) {
                    factor * a.onlineNumber.compareTo(b.onlineNumber)
                } else {
                    factor * percentageComparison
                }
            }
            3 -> statistics.sortedWith { a, b -> // OFFLINE %
                val percentageComparison = a.offlinePercentage.compareTo(b.offlinePercentage)
                if (percentageComparison == 0) {
                    factor * a.offlineNumber.compareTo(b.offlineNumber)
                } else {
                    factor * percentageComparison
                }
            }
            4 -> statistics.sortedWith { a, b -> // NEVER INSTALLED %
                val percentageComparison = a.neverInstalledPercentage.compareTo(b.neverInstalledPercentage)
                if (percentageComparison == 0) {
                    factor * a.neverInstalledNumber.compareTo(b.neverInstalledNumber)
                } else {
                    factor * percentageComparison
                }
            }
            5 -> statistics.sortedWith { a, b -> // OVERALL NEED FIXING %
                val aCombinedCount = a.offlineNumber + a.neverInstalledNumber
                val bCombinedCount = b.offlineNumber + b.neverInstalledNumber

                val aCombinedPercentage = if (a.totalDevices > 0) (aCombinedCount.toDouble() / a.totalDevices) * 100 else 0.0
                val bCombinedPercentage = if (b.totalDevices > 0) (bCombinedCount.toDouble() / b.totalDevices) * 100 else 0.0

                val percentageComparison = aCombinedPercentage.compareTo(bCombinedPercentage)
                if (percentageComparison == 0) {
                    factor * aCombinedCount.compareTo(bCombinedCount)
                } else {
                    factor * percentageComparison
                }
            }
            else -> statistics // Should not happen if columns match index
        }
    }


    suspend fun loadPropertyStatistics() {
        screen.propertyOfflineStatistics = emptyList() // Clear previous data
        screen.propertyStatsLoading = true
        try {
            val cached = inMemoryCache.get("propertyOfflineStatistics") as? List<PropertyOfflineStatisticsModel>
            if (cached != null) {
                screen.propertyOfflineStatistics = cached
                screen.propertyStatsLoading = false
                return
            }
            val response = when (val maybe = repository.loadPropertyStatistics()) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    logger.e("Error loading property statistics", maybe.exception)
                    // Optionally set an error state on the screen here
                    emptyList() // Return empty list on error
                }
            }
            inMemoryCache.set("propertyOfflineStatistics", response, PROPERTY_DATA_EXPIRATION_TIME)
            screen.propertyOfflineStatistics = response
        } catch (e: Exception) {
            logger.e("Error loading property statistics", e)
            screen.propertyOfflineStatistics = emptyList() // Ensure it's empty on exception
        } finally {
            screen.propertyStatsLoading = false // Ensure loading is always set to false
        }
    }

    suspend fun checkAppsheetDatabaseHealth() {
        screen.appsheetDatabaseHealthLoading = true
        try {
            val response = when (val maybe = repository.checkAppsheetDatabaseHealth()) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    logger.e("Error checking AppSheet database health", maybe.exception)
                    false // Assume false on error
                }
            }
            screen.appsheetDatabaseHealthStatus = response
            screen.appsheetDatabaseLastChecked = getCurrentTimeString()

        } catch (e: Exception) {
            logger.e("Error checking AppSheet database health", e)
            screen.appsheetDatabaseHealthStatus = false // Assume false on exception
            screen.appsheetDatabaseLastChecked = getCurrentTimeString() // Still update time
        } finally {
            screen.appsheetDatabaseHealthLoading = false // Ensure loading is false
        }
    }

    /**
     * Generates a CSV string from the provided list of property statistics.
     * The header is fixed based on the data model and common table columns.
     */
    fun generatePropertyStatisticsCsv(statistics: List<PropertyOfflineStatisticsModel>): String {
        // Define CSV header - use quotes for names that might contain commas
        val header = listOf(
            "\"Property\"",
            "\"Total Sensors\"",
            "\"Online\"", // Combine number and percentage for clarity
            "\"Offline\"",
            "\"Never Installed\"",
            "\"Overall Needing Fix\""
        ).joinToString(",")

        val rows = statistics.map { stat ->
            val combinedCount = stat.offlineNumber + stat.neverInstalledNumber
            val combinedPercentage = if (stat.totalDevices > 0) {
                (combinedCount.toDouble() / stat.totalDevices) * 100
            } else {
                0.0
            }
            // Quote the property name in case it contains a comma
            listOf(
                "\"${stat.propertyName.replace("\"", "\"\"")}\"", // Escape double quotes within the name
                stat.totalDevices.toString(),
                "\"${stat.onlineNumber} (${stat.onlinePercentage.toInt()}%)\"",
                "\"${stat.offlineNumber} (${stat.offlinePercentage.toInt()}%)\"",
                "\"${stat.neverInstalledNumber} (${stat.neverInstalledPercentage.toInt()}%)\"",
                "\"$combinedCount (${combinedPercentage.toInt()}%)\""
            ).joinToString(",")
        }
        return header + "\n" + rows.joinToString("\n")
    }

    private fun getCurrentTimeString(): String {
        // Simple time formatting, consider using kotlinx-datetime formatting for more robust needs
        return "Last checked: ${Clock.System.now().toString().replace("T", " ").substringBeforeLast('.')} UTC"
    }

    private fun checkValidId(deviceId: String): Boolean {
        val lowercaseDeviceId = deviceId.lowercase()
        // Regex patterns simplified for clarity based on examples
        val isValid = lowercaseDeviceId.matches(Regex("^(ttn-v3-integration_[a-z0-9-]{7,9}|ls-res[0-9]{8}|ws-res[0-9]{8}|[a-z0-9]{3}-[a-z0-9]{3}|eui-[a-f0-9]{16}|[a-z]{2}-[a-f0-9]{16})$"))

        if (!isValid) {
            screen.wirelessDeviceId = "Invalid ID"
        }
        return isValid
    }

}