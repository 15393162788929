package com.fireavert.properties.presentation.add_new_property

import com.fireavert.common.RandomStringGenerator
import com.fireavert.common.ViewModel
import com.fireavert.gateways.models.GatewayModel
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.add_new_property.AddNewPropertyScreen
import com.fireavert.properties.logic.models.*
import com.fireavert.properties.presentation.PropertyFormViewState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class AddNewPropertyViewModel(private val logger: Logger) : ViewModel(), AddNewPropertyScreen {
    private val _viewState = MutableStateFlow(PropertyFormViewState())
    val viewState = _viewState.asStateFlow()

    override val scope: CoroutineScope
        get() = viewModelScope

    override fun setErrorMessage(message: String) {
        _viewState.value = _viewState.value.copy(errorMessage = message)
    }

    override fun getPropertyName(): String? {
        return viewState.value.propertyName
    }

    override fun setPropertyName(propertyName: String) {
        _viewState.value = _viewState.value.copy(propertyName = propertyName)
    }

    override fun getNumberOfUnits(): Int? {
        return viewState.value.numberOfUnits
    }

    override fun setNumberOfUnits(numberOfUnits: Int) {
        _viewState.value = _viewState.value.copy(numberOfUnits = numberOfUnits)
    }

    override fun setNumberOfUnitsError(error: Boolean) {
        _viewState.value = _viewState.value.copy(numberOfUnitsError = error)
    }

    override fun getAddress(): String? {
        return viewState.value.address
    }

    override fun setAddress(address: String) {
        _viewState.value = _viewState.value.copy(address = address)
    }

    override fun getCity(): String? {
        return viewState.value.city
    }

    override fun setCity(city: String) {
        _viewState.value = _viewState.value.copy(city = city)
    }

    override fun getState(): String? {
        return viewState.value.state
    }

    override fun setState(state: String) {
        _viewState.value = _viewState.value.copy(state = state)
    }

    override fun getZip(): String? {
        return viewState.value.zip
    }

    override fun setZip(zip: String) {
        _viewState.value = _viewState.value.copy(zip = zip)
    }

    override fun setZipError(error: Boolean) {
        _viewState.value = _viewState.value.copy(zipError = error)
    }

    override fun getManagementCompany(): String? {
        return viewState.value.managementCompany
    }

    override fun setManagementCompany(managementCompany: String) {
        _viewState.value = _viewState.value.copy(managementCompany = managementCompany)
    }

    override fun setPropertyNameError(error: Boolean) {
        _viewState.value = _viewState.value.copy(propertyNameError = error)
    }

    override fun setAddressError(error: Boolean) {
        _viewState.value = _viewState.value.copy(addressError = error)
    }

    override fun setCityError(error: Boolean) {
        _viewState.value = _viewState.value.copy(cityError = error)
    }

    override fun setStateError(error: Boolean) {
        _viewState.value = _viewState.value.copy(stateError = error)
    }

    override fun setManagementCompanyError(error: Boolean) {
        _viewState.value = _viewState.value.copy(managementCompanyError = error)
    }

    override fun getTimezoneId(): String? {
        return _viewState.value.timezoneId
    }

    override fun setTimezoneId(timezoneId: String) {
        _viewState.value = _viewState.value.copy(timezoneId = timezoneId)
    }

    override fun setTimezoneIdError(error: Boolean) {
        _viewState.value = _viewState.value.copy(timezoneIdError = error)
    }

    override fun setSaveError(exception: Exception?) {
        _viewState.value = _viewState.value.copy(saveError = exception)
    }

    override fun getPropertyAdmins(): List<PropertyAdminModel> {
        return _viewState.value.propertyAdmins
    }

    override fun setPropertyAdmins(propertyAdmins: List<PropertyAdminModel>) {
        if (propertyAdmins == _viewState.value.propertyAdmins) {
            val randomString = RandomStringGenerator.generateRandomString()
            logger.e("Random String change $randomString")
            _viewState.value = _viewState.value.copy(changeMeRandom = randomString)
        } else {
            _viewState.value = _viewState.value.copy(propertyAdmins = propertyAdmins)
        }
    }

    override fun setPropertyAdminsError(error: Boolean) {
        _viewState.value = _viewState.value.copy(propertyAdminsError = error)
    }

    override fun setSubscriptionType(subscriptionType: SubscriptionType) {
        _viewState.value = _viewState.value.copy(subscriptionType = subscriptionType)
    }

    override fun setSubscriptionTypeError(error: Boolean) {
        _viewState.value = _viewState.value.copy(subscriptionTypeError = error)
    }

    override fun setMode(mode: Mode) {
        _viewState.value = _viewState.value.copy(mode = mode)
    }

    override fun setModeError(error: Boolean) {
        _viewState.value = _viewState.value.copy(modeError = error)
    }

    override fun setPropertyType(propertyType: PropertyType) {
        _viewState.value = _viewState.value.copy(propertyType = propertyType)
    }

    override fun getPropertyType(): PropertyType {
        return _viewState.value.propertyType
    }

    override fun setPropertyTypeError(error: Boolean) {
        _viewState.value = _viewState.value.copy(propertyTypeError = error)
    }

    override fun getSubscriptionType(): SubscriptionType? {
        return _viewState.value.subscriptionType
    }

    override fun getFireClaimsOneClaimCount(): Int {
        return _viewState.value.fireClaimsOneClaimCount
    }

    override fun setFireClaimsOneClaimCount(count: Int) {
        _viewState.value = _viewState.value.copy(fireClaimsOneClaimCount = count)
    }

    override fun getFireClaimsTwoClaimCount(): Int {
        return _viewState.value.fireClaimsTwoClaimCount
    }

    override fun setFireClaimsTwoClaimCount(count: Int) {
        _viewState.value = _viewState.value.copy(fireClaimsTwoClaimCount = count)
    }

    override fun getFireClaimsThreeClaimCount(): Int {
        return _viewState.value.fireClaimsThreeClaimCount
    }

    override fun setFireClaimsThreeClaimCount(count: Int) {
        _viewState.value = _viewState.value.copy(fireClaimsThreeClaimCount = count)
    }

    override fun getFireClaimsFourClaimCount(): Int {
        return _viewState.value.fireClaimsFourClaimCount
    }

    override fun setFireClaimsFourClaimCount(count: Int) {
        _viewState.value = _viewState.value.copy(fireClaimsFourClaimCount = count)
    }

    override fun getFireClaimsFiveClaimCount(): Int {
        return _viewState.value.fireClaimsFiveClaimCount
    }

    override fun setFireClaimsFiveClaimCount(count: Int) {
        _viewState.value = _viewState.value.copy(fireClaimsFiveClaimCount = count)
    }

    override fun getFireClaimsOneCost(): Double {
        return _viewState.value.fireClaimsOneCost
    }

    override fun setFireClaimsOneCost(cost: Double) {
        _viewState.value = _viewState.value.copy(fireClaimsOneCost = cost)
    }

    override fun getFireClaimsTwoCost(): Double {
        return _viewState.value.fireClaimsTwoCost
    }

    override fun setFireClaimsTwoCost(cost: Double) {
        _viewState.value = _viewState.value.copy(fireClaimsTwoCost = cost)
    }

    override fun getFireClaimsThreeCost(): Double {
        return _viewState.value.fireClaimsThreeCost
    }

    override fun setFireClaimsThreeCost(cost: Double) {
        _viewState.value = _viewState.value.copy(fireClaimsThreeCost = cost)
    }

    override fun getFireClaimsFourCost(): Double {
        return _viewState.value.fireClaimsFourCost
    }

    override fun setFireClaimsFourCost(cost: Double) {
        _viewState.value = _viewState.value.copy(fireClaimsFourCost = cost)
    }

    override fun getFireClaimsFiveCost(): Double {
        return _viewState.value.fireClaimsFiveCost
    }

    override fun setFireClaimsFiveCost(cost: Double) {
        _viewState.value = _viewState.value.copy(fireClaimsFiveCost = cost)
    }

    override fun setFireClaimError(error: Boolean) {
        _viewState.value = _viewState.value.copy(fireClaimsError = error)
    }

    override fun setGateways(gateways: List<GatewayModel>) {
        _viewState.value = _viewState.value.copy(propertyGateways = gateways)
    }

    override fun getGateways(): List<GatewayModel> {
        return _viewState.value.propertyGateways
    }

    override fun setAllGateways(gateways: List<GatewayModel>) {
        _viewState.value = _viewState.value.copy(allGateways = gateways)
    }

    override fun setGatewayError(error: Boolean) {
        _viewState.value = _viewState.value.copy(propertyGatewaysError = error)
    }

    override fun getMode(): Mode? {
        return _viewState.value.mode
    }


}