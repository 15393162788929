package com.fireavert.components.common

import com.fireavert.styles.TableStyles
import mui.material.Popover
import mui.material.PopoverReference
import react.FC
import react.Props
import react.RefCallback
import react.dom.html.ReactHTML
import react.router.dom.Link
import react.useState
import web.dom.Element

external interface ThreeDotsButtonProps : Props {
    var pathAndTextPairs: Array<Pair<String, String>>
}
val ThreeDotsButton = FC<ThreeDotsButtonProps> { props ->
    var isOpen: Boolean by useState(false)
    var savedAnchorEl: Element? by useState(null)
    ReactHTML.span {
        className = TableStyles.threeDotsButtonContainer
        onClick = {
            isOpen = true
        }
        ReactHTML.span {
            ref = RefCallback<Element> {
                if (it != null) {
                    savedAnchorEl = it
                }
            }
            className = TableStyles.threeDotsButton
        }
    }
    Popover {
        open = isOpen
        onClose = { _, _ ->
            isOpen = false
        }
        anchorReference = PopoverReference.anchorEl
        anchorEl = savedAnchorEl

        props.pathAndTextPairs.forEach {
           ReactHTML.div {
               className = TableStyles.threeDotsMenuItem
               Link {
                   to = it.first
                   +it.second
               }
           }
        }
    }
}