package com.fireavert.properties.logic.models.request

import com.fireavert.properties.logic.models.*
import kotlinx.serialization.Serializable

@Serializable
data class ModifyPropertyRequest(
    val id: Int? = null,
    val name: String? = null,
    val units: Int? = null,
    val address: String? = null,
    val city: String? = null,
    val state: String? = null,
    val zip: String? = null,
    val managementCompany: String? = null,
    val subscriptionType: SubscriptionType? = null,
    val mode: Mode? = null,
    val propertyType: PropertyType = PropertyType.MultiFamily,
    val timezoneId: String? = null,
    val propertyClass: PropertyClass? = null,
) {
    companion object {
        fun fromProperty(property: Property): ModifyPropertyRequest {
            return ModifyPropertyRequest(
                id = property.id,
                name = property.name,
                units = property.units,
                address = property.address,
                city = property.city,
                state = property.state,
                zip = property.zip,
                managementCompany = property.managementCompany,
                subscriptionType = property.subscriptionType,
                mode = property.mode,
                propertyType = property.propertyType,
                timezoneId = property.timezoneId,
                propertyClass = property.propertyClass
            )
        }
    }
}