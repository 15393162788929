package com.fireavert.components.dialogs

import com.fireavert.gateways.interface_adaptors.GatewayDialogViewModel
import com.fireavert.gateways.presentation.GatewayDialogController
import com.fireavert.styles.GatewayStyles
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import emotion.css.cx
import js.objects.jso
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.useState
import web.cssom.*
import web.html.HTMLImageElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

external interface EditGatewayDialogProps : Props {
    var propsGatewayName: String
    var propsGatewayDescription: String
    var propsGatewayId: String
    var propsGatewayLatitude: Double
    var propsGatewayLongitude: Double
    var propsController: GatewayDialogController
    var propsGatewayImages: Map<Int, String>
    var propsViewModel: GatewayDialogViewModel
    var propsPropertyId: Int
    var onClose: () -> Unit
}

val EditGatewayDialog = FC<EditGatewayDialogProps> { props ->
    var gatewayName by useState(props.propsGatewayName)
    var gatewayDescription by useState(props.propsGatewayDescription)
    var gatewayLatitude by useState(props.propsGatewayLatitude.toString())
    var gatewayLongitude by useState(props.propsGatewayLongitude.toString())
    var gatewayImages by useState(props.propsGatewayImages)

    Dialog {
        open = true
        onClose = { _, _ -> props.onClose() }
        onBackdropClick = { props.onClose() }
        fullWidth = true
        maxWidth = Breakpoint.lg
        sx {
            "& .MuiDialog-paper" {
                width = 100.pct
                maxWidth = 600.px
                height = 600.px
                margin = Auto.auto
            }
        }
        DialogTitle {
            sx {
                backgroundColor = Color("#D9D9D9")
            }
            ReactHTML.div {
                className = cx(GatewayStyles.gatewayHeaderWithButton, ClassName {
                    color = NamedColor.black
                })
                ReactHTML.div {
                    +"Edit Gateway"
                }
            }
        }

        DialogContent {
            sx {
                width = 100.pct
                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    maxWidth = 100.pct
                }
                backgroundColor = NamedColor.white
                display = Display.flex
                flexDirection = FlexDirection.column
                gap = 20.px
            }

            TextField {
                sx { marginTop = 20.px }
                label = ReactNode("Gateway Name")
                value = gatewayName
                fullWidth = true
                onChange = { event ->
                    gatewayName = (event.target as? HTMLInputElement)?.value ?: ""
                }
            }

            TextField {
                label = ReactNode("Gateway Description")
                value = gatewayDescription
                fullWidth = true
                multiline = true
                onChange = { event ->
                    gatewayDescription = (event.target as? HTMLTextAreaElement)?.value ?: ""
                }
            }

            TextField {
                label = ReactNode("Gateway Latitude")
                value = gatewayLatitude
                onChange = { event ->
                    gatewayLatitude = (event.target as? HTMLInputElement)?.value ?: ""
                }
            }

            TextField {
                label = ReactNode("Gateway Longitude")
                value = gatewayLongitude
                onChange = { event ->
                    gatewayLongitude = (event.target as? HTMLInputElement)?.value ?: ""
                }
            }

            div {
                if (gatewayImages.isNotEmpty()) {
                    gatewayImages.forEach { (index, base64Image) ->
                        div {
                            style = jso {
                                position = Position.relative
                                display = Display.inlineBlock
                            }

                            val mimeType = when {
                                base64Image.startsWith("/9j/") -> "image/jpeg"
                                base64Image.startsWith("iVBORw0KGgo") -> "image/png"
                                else -> "image/png"
                            }
                            ReactHTML.img {
                                src = try {
                                    if (base64Image.startsWith("data:image")) {
                                        base64Image
                                    } else {
                                        "data:$mimeType;base64,$base64Image"
                                    }
                                } catch (e: Exception) {
                                    "/static/mobile_text_alert_inactive.svg"
                                }
                                alt = "Gateway photo $index"
                                style = jso {
                                    width = 200.px
                                    height = 200.px
                                    objectFit = ObjectFit.cover
                                    margin = 10.px
                                }
                                onError = { event ->
                                    console.error("Failed to load image $index: ", base64Image.take(50))
                                    (event.target as? HTMLImageElement)?.let { img ->
                                        img.src = "/static/placeholder-image.png"
                                        img.alt = "Failed to load image $index"
                                    }
                                }
                            }

                            // Remove icon
                            div {
                                +"X"
                                className = GatewayStyles.gatewayRemoveImageIcon
                                onClick = {
                                    props.propsViewModel.scope.launch {
                                        gatewayImages = gatewayImages.filter { it.key != index }
                                        props.propsController.deleteGatewayImage(index, props.propsGatewayId, props.propsPropertyId)
                                    }
                                }
                            }
                        }
                    }
                } else {
                    div {
                        +"No photos available"
                    }
                }
            }

            Button {
                sx {
                    marginTop = 16.px
                    alignSelf = AlignSelf.flexEnd
                }
                variant = ButtonVariant.contained
                onClick = {
                    MainScope().launch {
                        props.propsController.updateGatewayDetails(
                            gatewayName,
                            gatewayDescription,
                            props.propsGatewayId,
                            gatewayLatitude.toDoubleOrNull() ?: props.propsGatewayLatitude,
                            gatewayLongitude.toDoubleOrNull() ?: props.propsGatewayLongitude
                        )
                    }
                    props.onClose()
                }
                +"Save"
            }
        }
    }
}