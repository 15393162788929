package com.fireavert.buildings.interface_adapters

import com.fireavert.buildings.logic.ClientBuildingRepository
import com.fireavert.buildings.logic.models.requests.SaveNewBuildingRequest
import com.fireavert.buildings.logic.models.response.BuildingsByPropertyResponse
import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ClientBuildingRepositoryImpl(
    private val dataSource: ClientBuildingRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
): ClientBuildingRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun saveNewBuilding(buildingName: String, unitIds: List<String>, propertyId: Int): Try<Boolean> = withContext(ioContext) {
            callWithRefresh { dataSource.saveNewBuilding(SaveNewBuildingRequest(buildingName, unitIds, propertyId), preferences.token) }
                .map { it }
        }

    override suspend fun getBuildingsForProperty(propertyId: Int): Try<BuildingsByPropertyResponse> = withContext(ioContext) {
        callWithRefresh { dataSource.getBuildingsForProperty(propertyId, preferences.token) }
            .map { it }

    }

}