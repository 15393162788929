import kotlinx.serialization.Serializable


@Serializable
data class YardiSavePropertyDataRequest(
    var connectionUrlKey: String = "",
    val username: String = "",
    var password: String = "",
    val databaseServer: String = "",
    val databaseName: String ="",
    val platform: String ="",
    var entityName: String = "",
    var interfaceLicense: String = "",
    val managementCompanyId: Int = 0,
    val yardiPropertyId: String = "",
)