package com.fireavert.administration_page.users.interface_adapters.user_section

import com.fireavert.administration_page.users.logic.UserTrackingRecord
import com.fireavert.administration_page.users.logic.user_section.UserSectionScreen
import com.fireavert.common.ViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class UserSectionViewModel : UserSectionScreen, ViewModel() {
    private val _viewState = MutableStateFlow(UserTrackingViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var loading: Boolean
        get() { return viewState.value.loading }
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
    override var trackingData: List<UserTrackingRecord>
        get() { return viewState.value.userTrackingRecords }
        set(value) {
            _viewState.value = _viewState.value.copy(userTrackingRecords = value.toList())
        }
}