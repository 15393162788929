package com.fireavert.components.dialogs

import com.fireavert.events.markastest.presentation.MarkAsTestDialogController
import com.fireavert.events.markastest.presentation.MarkAsTestDialogViewModel
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import react.FC
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState


val MarkAsTestDialog = FC {
    val propertyId = useParams()["propertyId"]?.toInt() ?: 0
    val eventId = useParams()["eventId"]?.toLong() ?: 0

    val controller = getKoinInstance<MarkAsTestDialogController>()
    val viewModel = getKoinInstance<MarkAsTestDialogViewModel>()

    var loading: Boolean by useState(false)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
        }.launchIn(viewModel.scope)

        controller.onLoad(eventId)
        onCleanup {
            viewStateJob.cancel()
        }
    }

    EventActionDialog {
        close = {controller.close(propertyId)}
        title = "Mark Event As Test"
        successMessage = "Event was marked as test."
        this.loading = loading
    }
}
