package com.fireavert.administration_page.main_page.logic

import com.fireavert.administration_page.main_page.interface_adaptors.AdministrationPageScreen
import com.fireavert.common.Try
import com.fireavert.properties.logic.ClientPropertyRepository

class AdministrationPageInteractor(
    private val screen: AdministrationPageScreen,
    private val adminPageRepository: AdministrationPageRepository,
    private val propertyRepository: ClientPropertyRepository,
) {

    fun searchPropertyByNames(
        propertyList: Map<String, Int>,
        searchValue: String
    ): Map<String, Int> {

        return propertyList.entries.filter { record ->
            record.key.contains(searchValue, ignoreCase = true)
        }.associate { it.toPair() }

    }
    suspend fun onLoad() {
        screen.loading = true

         when (val maybe = adminPageRepository.getUserData()) {
            is Try.Success -> {
                val result = maybe.value
                screen.totalUserCount = result.userCount
                screen.propertyAdminCount = result.propertyAdminCount
                screen.tenantCount = result.tenantCount
                screen.executiveUserCount = result.executiveUserCount
                screen.fireavertAdminCount = result.fireavertAdminCount
                screen.maintenanceUserCount = result.maintenanceUserCount
                screen.regionalManagerCount = result.regionalManagerCount
            }
            is Try.Error -> {
                screen.error = "Error getting the user counts, please try again later"
            }
        }

        when (val maybe = propertyRepository.getProperties()) {
            is Try.Success -> {
                screen.propertyList = maybe.value.associate { it.name to it.id }
            }
            is Try.Error -> {
                screen.error = "Error getting the property list, please try again later"
            }
        }

        screen.loading = false

    }
}