package com.fireavert.administration_page.users.logic

import com.fireavert.administration_page.users.logic.user_section.UserSectionScreen
import com.fireavert.common.TableColumn
import com.fireavert.common.Try
import com.fireavert.logging.Logger

class UserSectionInteractor(
    private val screen: UserSectionScreen,
    private val userSectionRepository: UserSectionRepository,
    private val logger: Logger
) {
    fun searchAndSortTrackingRecordsByColumn(
        columns: Array<TableColumn>,
        trackingRecords: List<UserTrackingRecord>,
        searchValue: String
    ): List<UserTrackingRecord> {

        //Search for the records that match the search value first
        val filteredRecords = searchUsers(searchValue, trackingRecords)
        val sortColumn =
            columns.firstOrNull() { it.sortOrder != TableColumn.SortOrder.Neutral } ?: return filteredRecords
        return when (sortColumn.text) {
            "Name" -> {
                TableColumn.sortList(true, sortColumn, filteredRecords) { it.name }
            }

            "Email" -> {
                TableColumn.sortList(true, sortColumn, filteredRecords) { it.email }
            }

            "Company" -> {
                TableColumn.sortList(true, sortColumn, filteredRecords) { it.phone }
            }
            else -> filteredRecords
        }.filter { true }
    }

    private fun searchUsers(searchValue: String, trackingRecords: List<UserTrackingRecord>): List<UserTrackingRecord> {
        return trackingRecords.filter { record ->
            record.name.contains(searchValue, ignoreCase = true) ||
                    record.email.contains(searchValue, ignoreCase = true) ||
                    record.phone.contains(searchValue, ignoreCase = true) ||
                    record.managementCompany.contains(searchValue, ignoreCase = true)
        }.toList()
    }

    suspend fun onLoad() {
        screen.loading = true
        val userTrackingData = when (val maybe = userSectionRepository.getUserTrackingData()) {
            is Try.Error -> {
                logger.e("Failed to get user tracking data. " + maybe.exception.message)
                screen.loading = false
                null
            }

            is Try.Success -> maybe.value
        } ?: return

        screen.trackingData = userTrackingData
        screen.loading = false
    }
}