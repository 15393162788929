package com.fireavert.components.administration

import com.fireavert.styles.AdministrationPageStyle
import mui.material.Skeleton
import mui.material.SkeletonVariant
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.router.dom.Link

external interface AdminUseBoxesProps : Props {
    var boxTitle: String
    var boxValue: Int
    var link: String
    var loading: Boolean
}

val AdminUserBoxes = FC<AdminUseBoxesProps> { props ->

    if (props.loading) {
        div {
            className = AdministrationPageStyle.admimUserBoxes
            Skeleton {
                variant = SkeletonVariant.rounded
                width = "100%"
                height = "100%"
            }
        }
    }
    else {
        Link {
            to = props.link
            div {
                className = AdministrationPageStyle.admimUserBoxes
                div {
                    className = AdministrationPageStyle.adminUserBoxTitle
                    +props.boxTitle
                }
                div {
                    className = AdministrationPageStyle.adminUserBoxValue
                    +props.boxValue.toString()
                }
            }
        }
    }
}