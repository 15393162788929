package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import react.dom.html.ReactHTML.img
import web.cssom.*

object HelpStyles {

    val helpSectionTitle = ClassName {
        fontWeight = integer(700)
        color = NamedColor.black
        fontSize = 2.5.rem
        paddingBottom = 15.px
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 36.px
            fontWeight = FontWeight.bolder
            color = NamedColor.black
            display = Display.block
        }
    }
    val helpEventCardImage = ClassName {
        left = 0.px
        height = 40.px
    }

    val feedbackButton = ClassName {
        position = Position.fixed
        bottom = 20.px
        right = 20.px
        padding = Padding(10.px, 20.px)
        zIndex = integer(1000)
    }


    val eventTypeHelpCard = ClassName {
        padding = Padding(0.px, 15.px, 15.px, 15.px)
        margin = 20.px
        display = Display.flex
        width = 25.pct
        borderRadius = 15.px
        flexDirection = FlexDirection.column

        boxShadow = BoxShadow(
            offsetX = 0.px,
            offsetY = 0.px,
            blurRadius = 10.px,
            color = Color("rgba(0, 0, 0, 0.1)")
        )
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 100.pct
            margin = Margin(50.px, 0.px, 0.px, 0.px)
            backgroundColor = NamedColor.white
        }
    }

    val actionHelpCard = ClassName {
        padding = Padding(0.px, 15.px, 15.px, 15.px)
        margin = 20.px
        display = Display.flex
        width = 33.pct
        flexDirection = FlexDirection.column

        boxShadow = BoxShadow(
            offsetX = 0.px,
            offsetY = 0.px,
            blurRadius = 10.px,
            color = Color("rgba(0, 0, 0, 0.1)")
        )
        borderRadius = 15.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 100.pct
            margin = Margin(20.px, 0.px, 0.px, 0.px)
            backgroundColor = NamedColor.white

        }
    }

    val eventIconAndQuote = ClassName {
        display = Display.flex
        paddingTop = 10.px
        flexDirection = FlexDirection.row
        alignItems = AlignItems.center
    }

    val eventActions = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.spaceBetween
        padding = 10.px
        fontWeight = FontWeight.bold
        fontSize = 20.px
    }


    val helpActionTitle = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        textAlign = TextAlign.left
        color = Color("black")
        padding = 2.rem
        borderRadius = 8.px
        fontSize = 2.0.rem
        fontWeight = FontWeight.bold
        background = Color("white")
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.columnReverse
            justifyContent = JustifyContent.left
            fontSize = 1.5.rem
            padding = 0.px
            marginTop = 15.px
            backgroundColor = Color("#EDEFF3")
        }
    }

    val helpSection = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
    }

    val contactInfoSection = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        gap = 20.px
        backgroundColor = NamedColor.white
        justifyContent = JustifyContent.spaceAround
        marginBottom = 20.px
        borderRadius = 10.px
        padding = 20.px
        fontSize = 24.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.column
            fontSize = 18.px
        }
    }

    val helpSectionBox = ClassName {
        backgroundColor = Color("white")
        marginBottom = 20.px
        borderRadius = 10.px
        backgroundSize = 100.pct
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            backgroundColor = Color("#EDEFF3")
            margin = 0.px
        }
    }

    val helpContainerEvents = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        color = Color("black")
        backgroundPositionX = 5.px
        backgroundPositionY = 5.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.column
        }
    }

    val helpContainerActions = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        color = Color("black")
        backgroundColor = Color("white")
        margin = 20.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.column
            margin = 0.px
            backgroundColor = Color("#EDEFF3")
        }
    }

    fun actionTitle(titleColor: Color): ClassName {
        return ClassName {
            fontSize = 1.5.rem
            fontSize = 1.5.rem
            marginTop = (-1).rem
            marginLeft = (-15).px
            marginRight = (-15).px
            paddingLeft = 15.px
            color = Color("white")
            fontWeight = FontWeight.bold
            background = titleColor
            borderTopLeftRadius = 15.px
            borderTopRightRadius = 15.px
            borderBottomLeftRadius = 0.px
            borderBottomRightRadius = 0.px
            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                fontSize = 1.2.rem
            }

        }
    }

    val icon = ClassName {
        justifyContent = JustifyContent.center
        img {
            height = 4.rem
            marginTop = 15.px
        }
    }

    val lineSeparator = ClassName {
        color = Color("#E4E8EF")
        fontSize = 31.px
        letterSpacing = 25.px
        fontWeight = FontWeight.bold
        justifyContent = JustifyContent.center
        display = Display.flex
    }

    val lineSeparatorAction = ClassName {
        color = Color("#E4E8EF")
        fontSize = 31.px
        letterSpacing = 25.px
        fontWeight = FontWeight.bold
        justifyContent = JustifyContent.left
        display = Display.flex
    }

    val eventTitle = ClassName {
        fontSize = 1.5.rem
        fontWeight = FontWeight.bold
        padding = 5.px
        justifyContent = JustifyContent.center
    }

    fun eventTypeQuote(color1: Color): ClassName {
        return ClassName {
            color = color1
            padding = 10.px
            fontStyle = FontStyle.italic
        }
    }

    val helpIcon = ClassName {
        position = important(Position.absolute)
        top = (-5).px
        left = 89.pct
        img {
            height = 15.px
        }
    }
}
