package com.fireavert.units.logic

import com.fireavert.common.TableColumn
import com.fireavert.common.TableColumn.SortOrder.Neutral
import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.devices.logic.models.*
import com.fireavert.devices.logic.models.DeviceType.*
import com.fireavert.devices.logic.models.FireAvertStatus.*
import com.fireavert.devices.logic.models.StatusLevel.*
import com.fireavert.devices.logic.requests.DevicePoweredOnRequest
import com.fireavert.events.common.logic.EventsRepository
import com.fireavert.events.logic.InstantTimeStringFormatter
import com.fireavert.events.logic.models.EventType
import com.fireavert.events.logic.models.EventType.DeviceTriggered
import com.fireavert.events.logic.models.EventType.SmokeAlarmActive
import com.fireavert.events.logic.models.EventTypeIconOption
import com.fireavert.logging.Logger
import com.fireavert.units.logic.UnitsNavigator.Destination.PropertyDetails
import com.fireavert.units.presentation.UnitDetailsDeviceData
import com.fireavert.units.presentation.UnitDetailsEventHistoryData

/**
 *
 */
class ClientUnitDetails(
    private val screen: UnitDetailsScreen,
    private val navigator: UnitsNavigator,
    private val unitsRepository: UnitsRepository,
    private val deviceRepository: DeviceRepository,
    private val eventsRepository: EventsRepository,
    private val logger: Logger
) : UnitDetails {
    override suspend fun onLoad(propertyId: Int, unitId: Int, timeZone: String, isAdmin: Boolean) {
        val unit = when (val maybeUnit = unitsRepository.getUnitById(unitId)) {
            is Success -> maybeUnit.value
            is Error -> {
                /// TODO put an error message somewhere
                logger.d("Failed to get unit for id $unitId")
                return
            }
        }

        screen.unitNumber = unit.number
        screen.propertyName = unit.propertyName
        screen.address = unit.streetAddress
        screen.city = unit.city
        screen.state = unit.state
        screen.zip = unit.zip
        screen.tenantName = unit.tenantName
        screen.tenantEmail = unit.tenantEmail
        screen.tenantPhone = unit.tenantPhone

        val devices = when (val maybeDevices = deviceRepository.getDevicesForUnitId(unitId)) {
            is Error -> {
                /// TODO put an error message somewhere
                logger.d("Failed to get devices for unit id $unitId")
                return
            }
            is Success -> maybeDevices.value
        }

        val devicesPowered = when (val maybePower = deviceRepository.getDevicesPowerStatusForUnitId(
            DevicePoweredOnRequest(devices.map { it.id })
        )) {
            is Error -> {
                logger.d("Failed to get devices power status for unit id $unitId")
                return
            }
            is Success -> maybePower.value
        }

        val alarmOnDeviceMap = mutableMapOf<Int, Boolean>()

        screen.devices = devices.map {
            alarmOnDeviceMap[it.id] = it.alarmIsOn
            val connectionStatusPair = connectionStatusFromDeviceStatus(it.status, it.installed)
            val deviceStatusPair = deviceStatusFromDeviceTypeAndStatus(it.type, it.status, it.installed)
            UnitDetailsDeviceData(
                id = it.id,
                type = it.type,
                location = it.location,
                deviceId = it.deviceLocator,
                connectionStatus = connectionStatusPair.first,
                connectionStatusLevel = connectionStatusPair.second,
                deviceStatus = deviceStatusPair.first,
                deviceStatusLevel = deviceStatusPair.second,
                deviceIsTriggered = it.deviceIsTriggered,
                powerOn = devicesPowered[it.id] ?: PowerStatus.UNKNOWN,
                installed = it.installed,

                )
        }

        val events =
            when (val maybeEvents = eventsRepository.eventHistoryForUnit(unitId = unitId)) {
                is Error -> {
                    /// TODO put an error message somewhere
                    logger.d("Failed to get devices for unit id $unitId.  ${maybeEvents.exception.message ?: "No Message"}")
                    return
                }
                is Success -> maybeEvents.value
            }

        var alarmEvents = 0
        var shutoffEvents = 0
        var leakEvents = 0
        var flowEvents = 0
        var tamperEvents = 0

        val eventHistoryData = mutableListOf<UnitDetailsEventHistoryData>()
        for (event in events) {
            if (!event.isTest && !event.isFalseAlert) {
                when (event.deviceType) {
                    FireAvert, FireAvertGas, FireAvertAppliance -> {
                        when (event.type) {
                            SmokeAlarmActive -> {
                                alarmEvents += 1
                            }
                            DeviceTriggered -> {
                                shutoffEvents += 1
                            }
                            else -> {} /// do nothing
                        }
                    }
                    WaterSensor -> {
                        if (event.type == DeviceTriggered) {
                            leakEvents += 1
                        }
                    }
                    FlowSensor -> {
                        if (event.type == DeviceTriggered) {
                            flowEvents += 1
                        }
                    }
                    TamperSensor -> {
                        if (event.type == DeviceTriggered) {
                            tamperEvents += 1
                        }
                    }
                }
            }
            val device = devices.first { it.id == event.deviceId }
            eventHistoryData.add(
                UnitDetailsEventHistoryData(
                    eventId = event.id,
                    dateTime = InstantTimeStringFormatter.formatB(
                        instant = event.timestamp,
                        zoneId = timeZone
                    ),
                    timestamp = event.timestamp.epochSeconds,
                    deviceType = event.deviceType,
                    location = device.location,
                    eventType = event.type,
                    alarmActive = device.alarmIsOn,
                    deviceTriggered = device.deviceIsTriggered,
                    isTest = event.isTest,
                    isFalseAlert = event.isFalseAlert
                )
            )
        }
        screen.totalAlarmEvents = alarmEvents
        screen.totalShutoffEvents = shutoffEvents
        screen.totalLeakEvents = leakEvents
        screen.totalFlowEvents = flowEvents
        screen.totalTamperEvents = tamperEvents

        screen.eventHistory = if (isAdmin) eventHistoryData else eventHistoryData.filter { it.eventType != EventType.DeviceOffline }
    }

    private fun deviceStatusFromDeviceTypeAndStatus(
        type: DeviceType,
        status: FireAvertStatus,
        installed: Boolean
    ): Pair<String, StatusLevel> {
        if (!installed) {
            return "Never Installed" to Grey
        }
        return when (type) {
            FireAvert, FireAvertGas, FireAvertAppliance -> when (status) {
                Active -> "Active" to Red
                Listening -> "Listening" to Green
                Offline -> "Offline" to Yellow
                NotFound -> "Not Found" to Red
                Rebooting -> "Rebooting" to Yellow
                Error -> "Error" to Red
            }
            WaterSensor -> when (status) {
                Active -> "Wet" to Red
                Listening -> "Dry" to Green
                Offline -> "Offline" to Yellow
                NotFound -> "Not Found" to Red
                Rebooting -> "Rebooting" to Yellow
                Error -> "Error" to Red
            }
            FlowSensor -> when (status) {
                Active -> "Running" to Red
                Listening -> "Normal Flow" to Green
                Offline -> "Offline" to Yellow
                NotFound -> "Not Found" to Red
                Rebooting -> "Rebooting" to Yellow
                Error -> "Error" to Red
            }
            TamperSensor -> when (status) {
                Active -> "Open" to Red
                Listening -> "Closed" to Green
                Offline -> "Offline" to Yellow
                NotFound -> "Not Found" to Red
                Rebooting -> "Rebooting" to Yellow
                Error -> "Error" to Red
            }
        }
    }

    private fun connectionStatusFromDeviceStatus(status: FireAvertStatus, isInstalled: Boolean): Pair<String, StatusLevel> {
        if (!isInstalled) {
            return "Never Installed" to Grey
        }
        return when (status) {
            Active -> "Online" to Green
            Listening -> "Online" to Green
            Offline -> "Offline" to Yellow
            NotFound -> "Error" to Red
            Rebooting -> "Rebooting" to Yellow
            Error -> "Error" to Red
        }
    }

    override fun close(propertyId: Int) {
        navigator.navigate(PropertyDetails(propertyId))
    }

    override suspend fun deleteEventHistoryItem(eventHistoryItem: UnitDetailsEventHistoryDataState) {
        screen.isLoading = true
        screen.removeEventHistoryItem(eventHistoryItem)
        val result =
            when (val maybeResult =
                eventsRepository.deleteEventFromHistory(eventHistoryItem.eventId)) {
                is Error -> {
                    //TODO show error on screen somewhere
                    logger.e("Failed to dismiss event ${eventHistoryItem.eventId}. ${maybeResult.exception.message}")
                    screen.isLoading = false
                    return
                }
                is Success -> {
                    maybeResult.value
                }
            }

        if (!result) {
            //TODO show error on screen somewhere
            logger.e("Failed to dismiss event at index ${eventHistoryItem.eventId}. No error message.")
        }

        when (eventHistoryItem.deviceType) {
            FireAvert, FireAvertGas, FireAvertAppliance -> {
                if (eventHistoryItem.eventType == SmokeAlarmActive) {
                    screen.totalAlarmEvents -= 1
                } else if (eventHistoryItem.eventType == DeviceTriggered) {
                    screen.totalShutoffEvents -= 1
                }
            }
            WaterSensor -> if (eventHistoryItem.eventType == DeviceTriggered) {
                screen.totalLeakEvents -= 1
            }
            FlowSensor -> if (eventHistoryItem.eventType == DeviceTriggered) {
                screen.totalFlowEvents -= 1
            }
            TamperSensor -> if (eventHistoryItem.eventType == DeviceTriggered) {
                screen.totalTamperEvents -= 1
            }
        }

        screen.isLoading = false
        return
    }

    override suspend fun clearEventHistoryForUnit(unitId: Int) {
        screen.isLoading = true
        val result =
            when (val maybeResult = eventsRepository.deleteAllEventsFromHistoryForUnit(unitId)) {
                is Error -> {
                    logger.e("Failed to clear event history! ${maybeResult.exception}")
                    screen.isLoading = false
                    return
                }
                is Success -> maybeResult.value
            }
        if (!result) {
            logger.e("Result when clearing was false!")
            screen.isLoading = false
            return
        }
        screen.eventHistory = emptyList()
        screen.totalAlarmEvents = 0
        screen.totalShutoffEvents = 0
        screen.totalLeakEvents = 0
        screen.totalFlowEvents = 0
        screen.totalTamperEvents = 0
        screen.isLoading = false
    }

    override fun sortDevicesWithColumns(
        devicesColumns: List<TableColumn>,
        devices: List<UnitDetailsDeviceDataState>
    ): List<UnitDetailsDeviceDataState> {
        val sortColumn = devicesColumns.firstOrNull { it.sortOrder != Neutral } ?: return devices
        return when (sortColumn.text) {
            "DEVICE TYPE" -> {
                TableColumn.sortList(true, sortColumn, devices) { it.type }
            }
            "LOCATION" -> {
                TableColumn.sortList(true, sortColumn, devices) { it.location }
            }
            "DEVICE ID" -> {
                TableColumn.sortList(true, sortColumn, devices) { it.deviceId }
            }
            "CONNECTION" -> {
                TableColumn.sortList(true, sortColumn, devices) { it.connectionStatus }
            }
            "STATUS" -> {
                TableColumn.sortList(true, sortColumn, devices) { it.deviceStatus }
            }
            else -> {
                devices
            }
        }
    }

    override fun sortEventsByColumns(
        eventHistoryColumns: List<TableColumn>,
        eventHistory: List<UnitDetailsEventHistoryDataState>
    ): List<UnitDetailsEventHistoryDataState> {
        val sortColumn =
            eventHistoryColumns.firstOrNull { it.sortOrder != Neutral } ?: return eventHistory
        return when (sortColumn.text) {
            "DATE / TIME" -> {
                TableColumn.sortList(false, sortColumn, eventHistory) { it.timestamp }
            }
            "DEVICE TYPE" -> {
                TableColumn.sortList(true, sortColumn, eventHistory) { it.deviceType }
            }
            "LOCATION" -> {
                TableColumn.sortList(true, sortColumn, eventHistory) { it.location }
            }
            "EVENT" -> {
                TableColumn.sortList(true, sortColumn, eventHistory) {
                    EventTypeIconOption.from(it.deviceType, it.eventType)
                }
            }
            else -> {
                eventHistory
            }
        }
    }

    override fun generateUnitHistoryCSV(): String {
        val history = screen.eventHistory

        val csvContent = buildString {
            appendLine("Unit Name, Device Type, Event Type, Time, Location, Event Marked as Test")
            history.forEach { event ->
                if (event.eventType != EventType.DeviceOffline) {
                    appendLine(
                        "${screen.unitNumber}, ${event.deviceType}, ${convertEventTypeToString(event.eventType, event.deviceType)}, ${event.dateTime}, ${event.location}, ${event.isTest}"
                    )
                }
            }
        }
        return csvContent
    }

    private fun convertEventTypeToString(eventType: EventType, deviceType: DeviceType): String {
        if (eventType == DeviceTriggered) {
            return when (deviceType) {
                FireAvert, FireAvertGas, FireAvertAppliance -> "Stove Shutoff"
                WaterSensor -> "Water Leak"
                FlowSensor -> "Flow"
                TamperSensor -> "Tamper Detected"
            }
        }
        return eventType.toString()
    }
}
