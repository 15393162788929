package com.fireavert.management_companies.logic

import com.fireavert.common.InMemoryCache
import com.fireavert.common.TableColumn
import com.fireavert.common.TableColumn.SortOrder.Neutral
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.api.toProperty
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.properties.logic.models.Property

class ManagementCompanyInteractor(
    private val screen: ManagementCompanyScreen,
    private val pageScreen: ManagementCompanyPageScreen,
    private val repository: ManagementCompanyRepository,
    private val propertyRepository: ClientPropertyRepository,
    private val inMemoryCache: InMemoryCache,
    private val preferences: Preferences,
) {
    suspend fun onLoadNew(){
        screen.loading = true
        val allProperties = when (val maybeProperty = propertyRepository.getProperties()) {
            is Try.Success -> maybeProperty.value
            is Try.Error -> {
                emptyList()
            }
        }

        val response = when (val maybe = repository.getManagementCompaniesWithProperties()) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                return
            }
        }

        screen.name = response.name
        screen.associatedProperties = response.selectedProperties
        screen.properties = allProperties
        screen.loading = false

    }
    suspend fun onLoadEdit(managementCompanyId: Int) {
        screen.loading = true
        val allProperties = when (val maybeProperty = propertyRepository.getProperties()) {
            is Try.Success -> maybeProperty.value
            is Try.Error -> {
                emptyList()
            }
        }

        val response = when (val maybe = repository.getManagementCompanyById(managementCompanyId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                return
            }
        }

        screen.name = response.name
        screen.associatedProperties = response.selectedProperties
        screen.properties = allProperties
        screen.loading = false
    }

    suspend fun onSaveNew(name: String, propertyIdList: List<Int>): Boolean {
        screen.loading = true
        when (val maybe = repository.saveManagementCompany(name, propertyIdList)) {
            is Try.Success -> {
                inMemoryCache.delete("${preferences.userId}_ApiPropertyRepositorg::getPropertiesWithManagementCompany")
                screen.loading = false
                return maybe.value
            }
            is Try.Error -> {
                screen.error = "Failed to save management company (name probably already exists)"
                screen.loading = false
                return false
            }
        }
    }

    suspend fun onSaveEdit(managementCompanyId: Int,  name: String, propertyIdList: List<Int>): Boolean {
        screen.loading = true
        val response = repository.updateManagementCompany(managementCompanyId, name, propertyIdList)
        when (val maybe = response) {
            is Try.Success -> {
                inMemoryCache.delete("${preferences.userId}_ApiPropertyRepositorg::getPropertiesWithManagementCompany")
                screen.loading = false
                return maybe.value
            }
            is Try.Error -> {
                screen.error = "Failed to save management company"
                screen.loading = false
                return false
            }
        }
    }

    suspend fun managementCompanyPageOnLoad() {
        pageScreen.loading = true
        val managementCompanyResponse = when (val maybe = propertyRepository.getPropertiesWithManagementCompany()) {
            is Try.Success -> {
                maybe.value
            }
            is Try.Error -> {
                pageScreen.loading = false
                null
            }
        }
        val mapToProperty = managementCompanyResponse?.mapValues { it.value.map { it.toProperty() } }
        pageScreen.managementCompaniesWithProperties = mapToProperty ?: emptyMap()
        pageScreen.loading = false
    }

     fun searchAndsortPropertiesByColumnMap(columns: Array<TableColumn>, properties: Map<String, List<Property>>, searchValue: String): Map<String, List<Property>> {
        val filteredProperties = searchPropertiesMap(searchValue, properties)
        val sortColumn = columns.firstOrNull { it.sortOrder != Neutral } ?: return filteredProperties
        return when (sortColumn.text) {
            "Management Company" -> {
                TableColumn.sortMap(true, sortColumn, filteredProperties) { mapEntry ->
                    mapEntry.key
                }
            }
            "Properties" -> {
                TableColumn.sortMap(true, sortColumn, filteredProperties) { mapEntry ->
                    mapEntry.value.size
                }
            }

            else -> filteredProperties
        }
    }

     fun searchAndsortPropertiesByColumn(columns: Array<TableColumn>, properties: Array<Property>, searchValue: String): Array<Property> {
        val filteredProperties = searchProperties(searchValue, properties)
        val sortColumn = columns.firstOrNull { it.sortOrder != Neutral } ?: return filteredProperties
        return when (sortColumn.text) {
            "NAME" -> {
                TableColumn.sortList(true,sortColumn, filteredProperties.toList()) { it.name }.toTypedArray()
            }
            "ADDRESS" -> {
                TableColumn.sortList(true,sortColumn, filteredProperties.toList()) { it.address }.toTypedArray()
            }
            "Property" -> {
                TableColumn.sortList(true,sortColumn, filteredProperties.toList()) { it.name }.toTypedArray()
            }
            else -> {
                filteredProperties
            }
        }
    }

     fun filterSubPropertiesBySearchValue(
        searchValue: String,
        properties: Array<Property>
    ): Array<Property> {
        return searchProperties(searchValue, properties)
    }

    private fun searchProperties(searchValue: String, properties: Array<Property>): Array<Property> {
        return properties.filter { property ->
            property.name.contains(searchValue, ignoreCase = true) ||
                    property.address.contains(searchValue, ignoreCase = true) ||
                    property.subscriptionType.value.contains(searchValue, ignoreCase = true)
        }.toTypedArray()
    }

    private fun searchPropertiesMap(searchValue: String, properties: Map<String, List<Property>>): Map<String, List<Property>> {
        return properties.filter { property ->
            property.key.contains(searchValue, ignoreCase = true) || property.value.any { it.name.contains(searchValue, ignoreCase = true) }
        }
    }
}