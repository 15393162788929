package com.fireavert.user.api

import com.fireavert.user.logic.Role
import com.fireavert.user.logic.models.RolesModel
import com.fireavert.user.logic.models.responses.RoleResponse

object RoleMapper {
    fun mapFromRoleResponse(roleResponse: RoleResponse) : Role {
        return Role(
            id = roleResponse.id ?: 0,
            name = roleResponse.name ?: RolesModel.UNKNOWN,
            permissions = roleResponse.permissions?.map { it.toPermission() } ?: emptyList(),
            pageVersions = roleResponse.pageVersions
        )
    }
}

fun RoleResponse.toRole() : Role = RoleMapper.mapFromRoleResponse(this)