package com.fireavert.buildings.logic

import com.fireavert.common.Try
import com.fireavert.units.logic.models.UnitModel

class AddNewBuildingInteractor(
    private val clientBuildingRepository: ClientBuildingRepository,
    private val screen: AddNewBuildingScreen,
) {
    suspend fun saveNewBuilding(buildingName: String, unitIds: List<String>, propertyId: Int, allUnits: List<UnitModel>): Boolean {
        screen.loading = true
        val result = when (val maybe = clientBuildingRepository.saveNewBuilding(buildingName, unitIds, propertyId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.error = "Error saving building"
                screen.loading = false
                return false
            }
        }
        //Refresh the building list
        getBuildingsForProperty(propertyId, allUnits)
        screen.loading = false
        return result
    }

    suspend fun getBuildingsForProperty(propertyId: Int, allUnits: List<UnitModel>) {
        val response = when (val maybe = clientBuildingRepository.getBuildingsForProperty(propertyId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.error = maybe.exception.toString()
                return
            }
        }
        // Create a set of unit IDs to remove
        val unitIdsToRemove = response.buildingModels
            .filter { it.name != "Unassigned" }
            .flatMap { it.units }
            .map { it.id }
            .toSet()

        // Create new list excluding units whose IDs are in the remove set
        val remainingUnits = allUnits.filter { unit ->
            unit.id !in unitIdsToRemove
        }
        screen.availableUnitList = remainingUnits
        screen.buildings = response.buildingModels
    }
}