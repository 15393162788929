package com.fireavert.administration_page.users.interface_adapters.user_info

import com.fireavert.administration_page.users.logic.user_info.UserInfoScreen
import com.fireavert.common.ViewModel
import com.fireavert.properties.logic.models.UserLinkedToProperty
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class UserInfoViewModel : UserInfoScreen, ViewModel() {
    private val _viewState = MutableStateFlow(UserInfoViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var loading: Boolean
        get() { return viewState.value.loading }
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
    override var name: String
        get() = viewState.value.name
        set(value) {
            _viewState.value = _viewState.value.copy(name = value)
        }
    override var email: String
        get() = viewState.value.email
        set(value) {
            _viewState.value = _viewState.value.copy(email = value)
        }
    override var phone: String
        get() = viewState.value.phone
        set(value) {
            _viewState.value = _viewState.value.copy(phone = value)
        }
    override var role: List<String>
        get() = viewState.value.role
        set(value) {
            _viewState.value = _viewState.value.copy(role = value)
        }
    override var propertyNotificationInfo: List<UserLinkedToProperty>
        get() = viewState.value.propertyNotificationInfo
        set(value) {
            _viewState.value = _viewState.value.copy(propertyNotificationInfo = value)
        }
    override var loginHistory: Map<String, String>
        get() = viewState.value.loginHistory
        set(value) {
            _viewState.value = _viewState.value.copy(loginHistory = value)
        }
    override var monthlyLoginCount: Int
        get() = viewState.value.monthlyLoginCount
        set(value) {
            _viewState.value = _viewState.value.copy(monthlyLoginCount = value)
        }
    override var weeklyLoginCount: Int
        get() = viewState.value.weeklyLoginCount
        set(value) {
            _viewState.value = _viewState.value.copy(weeklyLoginCount = value)
        }


}