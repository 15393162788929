package com.fireavert.properties.api

import com.fireavert.common.ApiRepository
import com.fireavert.common.InMemoryCache
import com.fireavert.common.Try
import com.fireavert.gateways.models.GatewayModel
import com.fireavert.gateways.models.request.PropertyGatewayChangeRequest
import com.fireavert.logging.Logger
import com.fireavert.management_companies.models.ManagementCompany
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.properties.logic.models.FireClaimData
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.logic.models.PropertyAdminModel
import com.fireavert.properties.logic.models.request.*
import com.fireavert.properties.logic.models.response.PropertyResponse
import com.fireavert.reports_page.frameworks.EventTypes
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ApiPropertyRepository(
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
    private val dataSource: PropertyDataSource,
    private val preferences: Preferences,
    private val inMemoryCache: InMemoryCache,
    private val logger: Logger,
) : ApiRepository(ioContext = ioContext, tokenRefreshService = tokenRefreshService),
    ClientPropertyRepository {
    companion object {
        const val getPropertyByIdAsyncTTL = 60 * 10
        fun getPropertyByIdAsyncCacheKeyForId(propertyId: Int): String {
            return "ApiPropertyRepository::getPropertyByIdAsync-$propertyId"
        }
    }

    override suspend fun createProperty(request: CreatePropertyRequest): Try<Property> =
        withContext(ioContext) {
            inMemoryCache.delete("${preferences.userId}_ApiPropertyRepository::getProperties")
            callWithRefresh {
                dataSource.createProperty(
                    preferences.token,
                    request
                )
            }.map { it.toProperty() }
        }

    override suspend fun getProperties(idList: List<Int>): Try<List<Property>> =
        withContext(ioContext) {
            val userSpecificKey = "${preferences.userId}_ApiPropertyRepository::getProperties"
            val cached = inMemoryCache.get(userSpecificKey) as? List<Property>
            if (cached != null) {
                return@withContext Try.Success(cached)
            }
            callWithRefresh { dataSource.getProperties(preferences.token) }
                .map { response ->
                    val properties = response.toPropertyList()
                    inMemoryCache.set(userSpecificKey, properties, getPropertyByIdAsyncTTL)
                    properties
                }
        }

    override suspend fun getPropertiesWithManagementCompany(idList: List<Int>): Try<Map<String, List<PropertyResponse>>> =
        withContext(ioContext) {
            val userSpecificKey = "${preferences.userId}_ApiPropertyRepositorg::getPropertiesWithManagementCompany"
            val cached = inMemoryCache.get(userSpecificKey) as? Map<String, List<PropertyResponse>>
            if (cached != null) {
                return@withContext Try.Success(cached)
            }
            callWithRefresh { dataSource.getPropertiesWithManagementCompany(preferences.token) }
                .map { response ->
                    val managementCompanyResponse = response.managementCompanyWithPropertiesMap ?: emptyMap()
                    inMemoryCache.set(userSpecificKey, managementCompanyResponse, getPropertyByIdAsyncTTL)
                    managementCompanyResponse
                }
        }

    override fun getPropertyByIdSync(id: Int): Try<Property> {
        TODO("Not yet implemented")
    }

    override suspend fun getPropertyByIdAsync(id: Int): Try<Property> = withContext(ioContext) {
        val cached = inMemoryCache.get(getPropertyByIdAsyncCacheKeyForId(id)) as? Property
        if (cached != null) {
            return@withContext Try.Success(cached)
        }
        callWithRefresh { dataSource.getPropertyById(preferences.token, id) }
            .map {
                val property = it.toProperty()
                inMemoryCache.set(
                    getPropertyByIdAsyncCacheKeyForId(id),
                    property,
                    getPropertyByIdAsyncTTL
                )
                property
            }
    }

    override suspend fun modifyProperty(
        property: Property
    ): Try<Property> = withContext(ioContext) {
        val request = ModifyPropertyRequest.fromProperty(property)
        inMemoryCache.delete(getPropertyByIdAsyncCacheKeyForId(property.id))
        callWithRefresh {
            dataSource.modifyProperty(
                preferences.token,
                property.id,
                request
            )
        }.map { it.toProperty() }
    }

    override suspend fun deleteProperty(propertyId: Int): Try<Boolean> = withContext(ioContext) {
        inMemoryCache.delete(getPropertyByIdAsyncCacheKeyForId(propertyId))
        callWithRefresh {
            dataSource.deleteProperty(
                preferences.token,
                propertyId
            )
        }.map { it.success ?: false }
    }

    override suspend fun getPropertyAdmins(propertyId: Int): Try<List<PropertyAdminModel>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getPropertyAdmins(
                    preferences.token,
                    propertyId
                )
            }.map { it.propertyAdmins.toPropertyAdminModels() }
        }

    override suspend fun deletePropertyAdmin(
        propertyId: Int,
        propertyAdminUserId: Int
    ): Try<Boolean> = withContext(ioContext) {
        callWithRefresh {
            dataSource.deletePropertyAdmin(
                preferences.token,
                propertyId,
                propertyAdminUserId
            )
        }.map {
            it.success ?: false
        }
    }

    override suspend fun createPropertyAdmin(
        propertyId: Int,
        propertyAdminModel: PropertyAdminModel
    ): Try<Boolean> =
        withContext(ioContext) {
            callWithRefresh {
                val propertyAdminRequest = CreatePropertyAdminRequest(
                    firstName = propertyAdminModel.firstName,
                    lastName = propertyAdminModel.lastName,
                    phone = propertyAdminModel.phone,
                    email = propertyAdminModel.email,
                    stoveTextNotifications = propertyAdminModel.stoveTextNotifications,
                    leakTextNotifications = propertyAdminModel.leakTextNotifications,
                    tamperTextNotifications = propertyAdminModel.tamperTextNotifications,
                    stoveEmailNotifications = propertyAdminModel.stoveEmailNotifications,
                    leakEmailNotifications = propertyAdminModel.leakEmailNotifications,
                    tamperEmailNotifications = propertyAdminModel.tamperEmailNotifications,
                    smokeAlarmEmailNotifications = propertyAdminModel.smokeAlarmEmailNotifications,
                    smokeAlarmTextNotifications = propertyAdminModel.smokeAlarmTextNotifications,
                    stoveOfflineEmailNotifications = propertyAdminModel.stoveOfflineEmailNotifications,
                    stoveOfflineTextNotifications = propertyAdminModel.stoveOfflineTextNotifications,
                    tamperOfflineEmailNotifications = propertyAdminModel.tamperOfflineEmailNotifications,
                    tamperOfflineTextNotifications = propertyAdminModel.tamperOfflineTextNotifications,
                    leakOfflineEmailNotifications = propertyAdminModel.leakOfflineEmailNotifications,
                    leakOfflineTextNotifications = propertyAdminModel.leakOfflineTextNotifications,
                    dailyReports = propertyAdminModel.dailyReports,
                    weeklyReports = propertyAdminModel.weeklyReports,
                    monthlyReports = propertyAdminModel.monthlyReports,
                    quarterlyReports = propertyAdminModel.quarterlyReports,
                    yearlyReports = propertyAdminModel.yearlyReports,
                )
                dataSource.createPropertyAdmin(
                    token = preferences.token,
                    propertyId = propertyId,
                    propertyAdminRequest = propertyAdminRequest
                ).map { it.success }
            }
        }

    override suspend fun modifyPropertyAdmin(
        propertyId: Int,
        propertyAdminModel: PropertyAdminModel
    ): Try<Boolean> = withContext(ioContext) {
        callWithRefresh {
            val request = ModifyPropertyAdminRequest(
                userId = propertyAdminModel.id.toInt(),
                propertyId = propertyId,
                firstName = propertyAdminModel.firstName,
                lastName = propertyAdminModel.lastName,
                phone = propertyAdminModel.phone,
                email = propertyAdminModel.email,
                stoveTextNotifications = propertyAdminModel.stoveTextNotifications,
                leakTextNotifications = propertyAdminModel.leakTextNotifications,
                tamperTextNotifications = propertyAdminModel.tamperTextNotifications,
                stoveEmailNotifications = propertyAdminModel.stoveEmailNotifications,
                leakEmailNotifications = propertyAdminModel.leakEmailNotifications,
                tamperEmailNotifications = propertyAdminModel.tamperEmailNotifications,
                smokeAlarmEmailNotifications = propertyAdminModel.smokeAlarmEmailNotifications,
                smokeAlarmTextNotifications = propertyAdminModel.smokeAlarmTextNotifications,
                stoveOfflineEmailNotifications = propertyAdminModel.stoveOfflineEmailNotifications,
                stoveOfflineTextNotifications = propertyAdminModel.stoveOfflineTextNotifications,
                tamperOfflineEmailNotifications = propertyAdminModel.tamperOfflineEmailNotifications,
                tamperOfflineTextNotifications = propertyAdminModel.tamperOfflineTextNotifications,
                leakOfflineEmailNotifications = propertyAdminModel.leakOfflineEmailNotifications,
                leakOfflineTextNotifications = propertyAdminModel.leakOfflineTextNotifications,
                dailyReports = propertyAdminModel.dailyReports,
                weeklyReports = propertyAdminModel.weeklyReports,
                monthlyReports = propertyAdminModel.monthlyReports,
                quarterlyReports = propertyAdminModel.quarterlyReports,
                yearlyReports = propertyAdminModel.yearlyReports
            )
            dataSource.modifyPropertyAdmin(
                token = preferences.token,
                propertyId = propertyId,
                request = request
            ).map { it.success ?: false }
        }
    }

    override suspend fun getUnlinkedPropertyAdmins(propertyId: Int): Try<List<PropertyAdminModel>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getUnlinkedPropertyAdmins(
                    preferences.token,
                    propertyId
                )
            }.map { it.propertyAdmins.toPropertyAdminModels() }
        }

    override suspend fun linkPropertyAdmin(
        propertyId: Int,
        propertyAdminId: Int,
    ): Try<Boolean> = withContext(ioContext) {
        callWithRefresh {
            val request = LinkPropertyAdminRequest(
                propertyAdminId = propertyAdminId
            )
            dataSource.linkPropertyAdmin(preferences.token, propertyId, request)
        }.map { it.success ?: false }
    }

    override suspend fun resendPropertyAdminInvite(id: String): Try<Boolean> = withContext(ioContext) {
        callWithRefresh {
            dataSource.resendPropertyAdminInvite(preferences.token, id)
        }.map { it.success ?: false }
    }

    override suspend fun getFireClaims(propertyId: Int): Try<List<FireClaimData>> =
        callWithRefresh {
            dataSource.getFireClaims(preferences.token, propertyId)
        }.map { it }

    override suspend fun modifyFireClaims(fireClaimRequest: PropertyFireClaimRequest): Try<Boolean> =
        callWithRefresh { dataSource.modifyFireClaims(preferences.token, fireClaimRequest) }.map { it.success ?: false }

    override suspend fun getPropertyGateways(propertyId: Int): Try<List<GatewayModel>> {
        return callWithRefresh {
            dataSource.getPropertyGateways(preferences.token, propertyId)
        }.map { it } }

    override suspend fun modifyGatewayChanges(propertyGatewayChangeRequest: PropertyGatewayChangeRequest): Try<Boolean> {
        return callWithRefresh {
            dataSource.modifyGatewayChanges(preferences.token, propertyGatewayChangeRequest)
        }.map { it }
    }

    override suspend fun updatePropertyMode(request:PropertyModeChangeRequest): Try<Boolean> {
        return callWithRefresh {
            dataSource.turnOnTestMode(preferences.token, request)
        }.map { it }
    }

    override suspend fun archiveProperty(request: ArchivePropertyRequest): Try<Boolean> {
        return callWithRefresh {
            dataSource.archiveProperty(preferences.token, request)
        }.map { it }
    }

    override suspend fun getSensorTypesForProperty(propertyId: Int?): Try<List<EventTypes>> {
        return callWithRefresh {
            dataSource.getSensorTypesForProperty(preferences.token, propertyId)
        }.map { it.sensorTypes }
    }

    override suspend fun getManagementCompanies(): Try<List<ManagementCompany>> {
        return callWithRefresh {
            dataSource.getManagementCompanies(preferences.token)
        }.map { it.managementCompanies}
    }
}