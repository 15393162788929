package com.fireavert.administration_page.wireless_id_finder.logic.models

import kotlinx.serialization.Serializable

@Serializable
data class PropertyOfflineStatisticsModel(
    val propertyId: Int,
    val propertyName: String,
    val totalDevices: Int,
    val onlineNumber: Int,
    val onlinePercentage: Double,
    val offlineNumber: Int,
    val offlinePercentage: Double,
    val neverInstalledNumber: Int,
    val neverInstalledPercentage: Double,
)
