package com.fireavert.gateways

import com.fireavert.common.Try
import com.fireavert.gateways.interface_adaptors.GatewayPropertyRepository
import com.fireavert.gateways.logic.GatewayDialogScreen
import com.fireavert.gateways.logic.GatewayPropertyScreen
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

class GatewayDialogInteractor(
    private val repository: GatewayPropertyRepository,
    private val screen: GatewayDialogScreen,
    private val gatewayPropertyScreen: GatewayPropertyScreen,
) {

    suspend fun onLoad(gatewayId: String, propertyId: Int) {
        screen.loading = true
        screen.imageUploading = false
        when (val maybe = repository.getGatewayImages(gatewayId, propertyId)) {
            is Try.Success -> {
                screen.gatewayImages = maybe.value
            }
            is Try.Error -> {
                screen.loading = false
                return
            }
        }
        screen.loading = false
    }

    @ExperimentalEncodingApi
    fun Base64.encodeToString(source: ByteArray, startIndex: Int = 0, endIndex: Int = source.size): String {
        val encodedBytes = encodeToByteArray(source, startIndex, endIndex)
        return encodedBytes.decodeToString()
    }

    @OptIn(ExperimentalEncodingApi::class)
    suspend fun uploadGatewayImage(file: ByteArray, fileSize: Double, fileType: String, fileName: String,
                                   gatewayId: String, propertyId: Int) {
        screen.imageUploading = true
        val maxFileSize = 10 * 1024 * 1024 // 10MB
        val allowedImageTypes = listOf("image/jpeg", "image/png")

        if (fileSize > maxFileSize) {
            screen.imageUploadError = "File size is too large. Please upload a file less than 10MB."
            screen.imageUploading = false
            return
        }

        if (fileType !in allowedImageTypes) {
            screen.imageUploadError = "Invalid file type. Please upload a JPEG or PNG file."
            screen.imageUploading = false
            return
        }

        // Convert ByteArray to base64 string
        val base64Image = withContext(Dispatchers.Default) {
            Base64.encodeToString(file)
        }

        when (val maybe = repository.uploadGatewayImage(base64Image, fileSize, fileType, fileName, gatewayId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.imageUploadError = "There was an error uploading the image. Please try again."
                screen.imageUploading = false
                return
            }
        }

        screen.imageUploading = false
        //Refresh the page with the new image
        onLoad(gatewayId, propertyId)
    }


    suspend fun updateGatewayDetails(gatewayName: String, gatewayDescription: String, gatewayId: String,
                                     gatewayLatitude: Double, gatewayLongitude: Double) {
        when (val maybe = repository.updateGatewayDetails(gatewayName, gatewayDescription, gatewayId, gatewayLatitude, gatewayLongitude)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                return
            }
        }
        val updatedGateways = gatewayPropertyScreen.associatedGateways.map { gateway ->
            if (gateway.gatewayId == gatewayId) {
                gateway.copy(name = gatewayName, description = gatewayDescription)
            } else {
                gateway
            }
        }
        gatewayPropertyScreen.associatedGateways = updatedGateways
        screen.loading = false
    }

    suspend fun deleteGatewayImage(imageId: Int, gatewayId: String, propertyId: Int) {
        when (val maybe = repository.deleteGatewayImage(imageId, gatewayId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                return
            }
        }

        //Refresh the page with the new image
        onLoad(gatewayId, propertyId)
    }
}