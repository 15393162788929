package com.fireavert.reports_page.interface_adaptors

import com.fireavert.common.ViewModel
import com.fireavert.reports_page.logic.ReportsExportDialogScreen
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class ReportsExportDialogViewModel : ViewModel(), ReportsExportDialogScreen {
    val scope = viewModelScope
    private val _viewState = MutableStateFlow(ReportsExportDialogViewState())
    val viewState = _viewState.asStateFlow()
    override var isExportLoading: Boolean
        get() = viewState.value.isExportLoading
        set(value) {_viewState.value = viewState.value.copy(isExportLoading = value)}
    override var error: String
        get() = viewState.value.error
        set(value) {_viewState.value = viewState.value.copy(error = value)}

}