package com.fireavert.management_companies.presentation

import com.fireavert.properties.logic.models.Property

data class ManagementCompanyViewState(
    val loading: Boolean = false,
    val name: String = "",
    val properties: List<Property> = emptyList(),
    val associatedProperties: List<Int> = emptyList(),
    val error: String = "",
)