package com.fireavert.properties.logic.models

import kotlinx.serialization.Serializable

@Serializable
enum class PropertyClass {
    A,
    B,
    C,
    D,
    UNKNOWN
}