package com.fireavert.properties.presentation.property_details

import com.fireavert.properties.logic.models.Mode

data class PropertyDetailsViewState(
    val propertyName: String = "",
    val propertyMode: Mode? = null,
    val propertyTotalUnits: Int = 0,
    val isAdmin: Boolean = false,
    val loading: Boolean = false,
    val testModeError: String = "",
    val isTestModeDialogOpen: Boolean = false
)