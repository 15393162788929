package com.fireavert.components.administration

import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdSectionController
import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdSectionViewModel
import com.fireavert.administration_page.wireless_id_finder.logic.models.PropertyOfflineStatisticsModel
import com.fireavert.common.TableColumn
import com.fireavert.components.filesaver.saveAs
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.menu.RoutePaths
import com.fireavert.styles.SuperAdminToolsStyles
import com.fireavert.styles.TableStyles
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.material.Size
import mui.material.styles.TypographyVariant
import org.w3c.files.Blob
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.cssom.*
import web.html.HTMLInputElement

// WirelessDeviceAdminPage FC remains the same as in your original code

val SuperAdminToolsSection = FC {
    val controller = getKoinInstance<WirelessIdSectionController>()
    val viewModel = getKoinInstance<WirelessIdSectionViewModel>()

    // --- State Variables ---
    var wirelessDeviceId: String by useState("")
    var deviceLocationLink: String by useState("")
    var unitName: String by useState("")
    var propertyName: String by useState("")
    val (searchValue, setSearchValue) = useState("") // For Device ID Lookup
    val (searchDeviceValue, setDeviceValue) = useState("") // For Gateway Device Data
    var gatewayDeviceInformation by useState(emptyMap<String, List<String>>())
    var gatewayDeviceInfoLoading by useState(false)
    var gatewayReloadDisabled by useState(false)

    var listPropertyStatistics by useState(emptyList<PropertyOfflineStatisticsModel>())
    var propertyStatsLoading by useState(false)
    val (propertySearchValue, setPropertySearchValue) = useState("") // <-- State for Property Statistics search

    var appsheetDatabaseHealthStatus by useState(false)
    var appsheetDatabaseHealthLoading by useState(false)
    var appsheetDatabaseLastChecked by useState("")

    // --- Effects ---
    useEffectOnce {
        // Subscribe to ViewModel updates
        viewModel.viewState.onEach { viewState ->
            wirelessDeviceId = viewState.wirelessDeviceId
            deviceLocationLink = viewState.deviceLocationLink
            unitName = viewState.unitName
            propertyName = viewState.propertyName
            gatewayDeviceInformation = viewState.gatewayDeviceData
            appsheetDatabaseHealthStatus = viewState.appsheetDatabaseHealthStatus
            appsheetDatabaseHealthLoading = viewState.appsheetDatabaseHealthLoading
            appsheetDatabaseLastChecked = viewState.appsheetDatabaseLastChecked
            gatewayDeviceInfoLoading = viewState.gatewayDeviceDataLoading
            listPropertyStatistics = viewState.propertyOfflineStatistics
            propertyStatsLoading = viewState.propertyStatsLoading
            // Reset reload button state when data updates might indicate completion
            gatewayReloadDisabled = viewState.gatewayDeviceDataLoading // Example: disable while loading
        }.launchIn(viewModel.scope)
    }

    // --- Table Columns State ---
    var propertyStatisticsColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(text = "PROPERTY", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left),
            TableColumn(text = "TOTAL SENSORS", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Center),
            TableColumn(text = "ONLINE", /* Updated Header */ sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Center),
            TableColumn(text = "OFFLINE", /* Updated Header */ sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Center),
            TableColumn(text = "NEVER INSTALLED", /* Updated Header */ sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Center),
            TableColumn(text = "OVERALL NEED FIXING", /* Updated Header */ sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Center)
        )
    )

    // --- Event Handlers ---
    fun searchForId(value: String) {
        viewModel.scope.launch {
            controller.loadWirelessIdData(value)
        }
    }

    fun getGatewayDeviceData(value: String) {
        viewModel.scope.launch {
            controller.loadGatewayDeviceData(value)
        }
    }

    fun reloadAllGatewayInfo() {
        // Disable button immediately for visual feedback
        gatewayReloadDisabled = true
        viewModel.scope.launch {
            controller.reloadAllGatewayInfo()
            // Consider adding feedback or re-enabling after completion,
            // maybe via ViewModel state update if the operation is long.
            // For simplicity, it stays disabled until next data load for now.
        }
    }

    fun checkAppsheetDatabaseHealth() {
        viewModel.scope.launch {
            controller.checkAppsheetDatabaseHealth()
        }
    }

    fun loadPropertyStatistics() {
        // Clear search when loading fresh data
        setPropertySearchValue("")
        viewModel.scope.launch {
            controller.loadPropertyStatistics()
        }
    }

    // --- Render Logic ---
    section {
        // --- First Row of Cards ---
        div {
            className = SuperAdminToolsStyles.cardGrid
            // Device ID Lookup Card (Paper)...
            Paper {
                className = SuperAdminToolsStyles.dashboardCard
                elevation = 0
                // ... content from your original code ...
                div {
                    className = SuperAdminToolsStyles.cardHeader
                    Typography {
                        className = SuperAdminToolsStyles.cardTitle
                        +"Wireless Device ID Lookup"
                    }
                }
                div {
                    className = SuperAdminToolsStyles.cardContent
                    div {
                        className = SuperAdminToolsStyles.inputGroup
                        TextField { /* ... */
                            value = searchValue // Don't force lowercase here, do it in searchForId if needed
                            onChange = { event -> setSearchValue((event.target as HTMLInputElement).value) }
                        }
                        Button {  onClick = { searchForId(searchValue.lowercase()) }; +"Search" } // Force lowercase on search
                    }
                    if (wirelessDeviceId.isNotEmpty() || deviceLocationLink.isNotEmpty() || propertyName.isNotEmpty()) {
                        +"${wirelessDeviceId} - $propertyName"
                        +"Location: $deviceLocationLink"
                        +"Unit Name: $unitName"

                    }
                }
            } // End Device ID Lookup Card

            // AppSheet Database Health Check Card (Paper)...
            Paper {
                className = SuperAdminToolsStyles.dashboardCard
                elevation = 0
                // ... content from your original code ...
                div { className = SuperAdminToolsStyles.cardHeader; Typography { +"Appsheet Database Health"} }
                div { className = SuperAdminToolsStyles.cardContent
                    Button {
                        onClick = { checkAppsheetDatabaseHealth() }
                        disabled = appsheetDatabaseHealthLoading
                        +"Check Health"
                    }
                    if (appsheetDatabaseLastChecked.isNotEmpty()) {
                        div {
                            if (appsheetDatabaseHealthStatus) {
                                +"Appsheet Database is Healthy"
                            } else {
                                +"Appsheet Database is Unhealthy"
                            }
                        }
                        +"Last Checked: $appsheetDatabaseLastChecked"
                    }
                }
            } // End AppSheet Health Card
        } // End First Row

        // --- Second row - Gateway Device Data ---
        div {
            className = SuperAdminToolsStyles.cardGrid
            // Gateway Device Data Card (Paper)...
            Paper {
                className = SuperAdminToolsStyles.dashboardCard
                elevation = 0
                div { className = SuperAdminToolsStyles.cardHeader
                    Typography { +"Gateway Device Data" }
                    Button {
                        onClick = { reloadAllGatewayInfo() }
                        disabled = gatewayReloadDisabled || gatewayDeviceInfoLoading // Disable if reloading or loading specific
                        +"Reload All Gateway Info"
                    }
                }
                div { className = SuperAdminToolsStyles.cardContent
                    div { className = SuperAdminToolsStyles.inputGroup
                        TextField { /* ... */
                            value = searchDeviceValue
                            onChange = { event -> setDeviceValue((event.target as HTMLInputElement).value) }
                        }
                        Button {
                            onClick = { getGatewayDeviceData(searchDeviceValue) }
                            disabled = gatewayDeviceInfoLoading
                            /* ... button content with loading ... */
                        }
                    }
                    if (gatewayDeviceInfoLoading && gatewayDeviceInformation.isEmpty()) { // Show loading only when fetching and no data yet
                        div { className = SuperAdminToolsStyles.loadingContainer; CircularProgress {} }
                    } else if (gatewayDeviceInformation.isNotEmpty()) {
                        // ... data list rendering ...
                    } else { // Show empty state if not loading and no data
                        div { className = SuperAdminToolsStyles.emptyStateContainer
                            Typography { +"No gateway data available. Enter a Property ID and click 'Get Data'." }
                        }
                    }
                }
            } // End Gateway Device Data Card
        } // End Second Row

        // --- Full-width card - Property Wireless Statistics ---
        div {
            className = SuperAdminToolsStyles.tableGrid // Ensures it takes full width if styled correctly

            Paper {
                className = cx(SuperAdminToolsStyles.dashboardCard, SuperAdminToolsStyles.fullWidthCard)
                elevation = 0

                div { // Card Header
                    className = SuperAdminToolsStyles.cardHeader
                    Typography {
                        className = SuperAdminToolsStyles.cardTitle
                        +"Property Wireless Statistics"
                    }
                    // Header actions could go here if needed
                }

                div { // Card Content for Table
                    className = SuperAdminToolsStyles.tableCardContent

                    // --- Controls: Scan Button, Search, Export ---
                    div {
                        // Use flexbox for layout
                        css {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            alignItems = AlignItems.center
                            gap = 16.px // Spacing between controls
                            marginBottom = 20.px // Space below controls
                            flexWrap = FlexWrap.wrap // Allow controls to wrap on smaller screens
                        }

                        // Scan Button
                        Button {
                            variant = ButtonVariant.contained
                            color = ButtonColor.primary
                            disabled = propertyStatsLoading
                            onClick = { loadPropertyStatistics() } // Clears search inside

                            div { // Button content with loading indicator
                                className = SuperAdminToolsStyles.buttonWithIcon
                                if (propertyStatsLoading && listPropertyStatistics.isEmpty()) { // Show spinner only on initial load
                                    CircularProgress { size = 20.px; color = CircularProgressColor.inherit; className = SuperAdminToolsStyles.infoIcon }
                                    +"Scanning..."
                                } else {
                                    +"Start Property Scan"
                                }
                            }
                        }

                        // Search Bar for Properties
                        TextField {
                            variant = FormControlVariant.outlined
                            size = Size.small
                            placeholder = "Search Properties..."
                            value = propertySearchValue
                            onChange = { event ->
                                setPropertySearchValue((event.target as HTMLInputElement).value)
                            }
                            // Optional styling: make it take available space or fixed width
                            css {
                                minWidth = 250.px // Example width
                                flexGrow = number(1.0) // Allow it to grow if space allows
                                maxWidth = 400.px // Max width
                            }
                        }

                        // Export CSV Button
                        Button {
                            variant = ButtonVariant.outlined
                            color = ButtonColor.primary
                            // Disable if no data OR if currently loading the initial list
                            disabled = listPropertyStatistics.isEmpty() || (propertyStatsLoading && listPropertyStatistics.isEmpty())
                            onClick = {
                                // 1. Filter data based on current search value
                                val filteredData = listPropertyStatistics.filter {
                                    it.propertyName.contains(propertySearchValue, ignoreCase = true)
                                }
                                // 2. Sort the filtered data based on current column sort state
                                val sortedData = controller.sortPropertyStatistics(propertyStatisticsColumns, filteredData)

                                // 3. Generate CSV content from the *sorted and filtered* data
                                val csvContent = controller.generatePropertyStatisticsCsv(sortedData)

                                // 4. Create a Blob
                                val blob = Blob(arrayOf(csvContent), org.w3c.files.BlobPropertyBag(type = "text/csv;charset=utf-8;"))

                                // 5. Trigger download
                                saveAs(blob, "property_wireless_statistics.csv")
                            }
                            +"Export CSV"
                        }
                    }

                    if (propertyStatsLoading && listPropertyStatistics.isEmpty()) {
                        div {
                            className = SuperAdminToolsStyles.loadingContainer
                            CircularProgress { color = CircularProgressColor.primary; size = 60.px }
                            Typography { variant = TypographyVariant.body1; +"Scanning properties, please wait..." }
                        }
                    }
                    val filteredStatistics = listPropertyStatistics.filter {
                        it.propertyName.contains(propertySearchValue, ignoreCase = true)
                    }
                    val sortedAndFilteredStatistics = controller.sortPropertyStatistics(
                        propertyStatisticsColumns,
                        filteredStatistics
                    )

                    // Render table container only if not initial loading OR if there's data (even if loading update)
                    if (!(propertyStatsLoading && listPropertyStatistics.isEmpty())) {
                        div { // Table Wrapper with Scroll
                            className = cx(TableStyles.scrolledTableContainer, SuperAdminToolsStyles.tableWrapper)
                            div { // Actual Table Structure
                                className = TableStyles.table

                                // Sort Bar
                                SortBar {
                                    columns = mapToControlColumns(propertyStatisticsColumns)
                                    includeLoading = true // Show loading state in sort bar during updates
                                    isLoading = propertyStatsLoading // Pass loading state
                                    columnsChanged = { newColumns ->
                                        propertyStatisticsColumns = mapToStateColumns(newColumns)
                                        // Sorting is now handled reactively based on state change
                                    }
                                }

                                // --- Table Rows ---
                                if (sortedAndFilteredStatistics.isNotEmpty()) {
                                    sortedAndFilteredStatistics.forEach { stat ->
                                        div { // Table Row
                                            className = cx(
                                                TableStyles.tableRow,
                                                // Apply conditional class for specific properties
                                                if (stat.propertyId in listOf(182, 141, 130, 209)) ClassName { backgroundColor = Color("lightgray") } else null
                                            )

                                            react.router.dom.Link {
                                                to = "${RoutePaths.properties}/${stat.propertyId}"
                                                div { className = TableStyles.tableCell; +stat.propertyName }
                                            } // Property Name
                                            div { className = TableStyles.tableCell; +"${stat.totalDevices}" } // Total
                                            div { className = TableStyles.tableCell; +"${stat.onlineNumber} (${stat.onlinePercentage.toInt()}%)" } // Online
                                            div { className = TableStyles.tableCell; +"${stat.offlineNumber} (${stat.offlinePercentage.toInt()}%)" } // Offline
                                            div { className = TableStyles.tableCell; +"${stat.neverInstalledNumber} (${stat.neverInstalledPercentage.toInt()}%)" } // Never Installed

                                            // Overall Need Fixing
                                            div {
                                                className = TableStyles.tableCell
                                                val combinedCount = stat.offlineNumber + stat.neverInstalledNumber
                                                val combinedPercentage = if (stat.totalDevices > 0) {
                                                    (combinedCount.toDouble() / stat.totalDevices) * 100
                                                } else { 0.0 }
                                                +"$combinedCount (${combinedPercentage.toInt()}%)"
                                            }
                                        } // End Table Row
                                    }
                                } else {
                                    // --- No Data / No Results Message ---
                                    div {
                                        className = TableStyles.tableRow // Use table row for structure
                                        div {
                                            className = TableStyles.tableCell // Use table cell
                                            css { textAlign = TextAlign.center; padding = 32.px; color = Color("#666") }

                                            +if (propertySearchValue.isNotBlank()) {
                                                "No properties match your search criteria \"$propertySearchValue\"."
                                            } else if (listPropertyStatistics.isEmpty() && !propertyStatsLoading) {
                                                "No data available. Click 'Start Property Scan' to retrieve statistics."
                                            } else {
                                                // This case might occur briefly during loading or if filtering clears everything
                                                "No statistics to display."
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } // End Property Statistics Paper
        } // End Full-width div
    } // End Section
}