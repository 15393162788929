package com.fireavert.components.properties

import com.fireavert.logging.Logger
import com.fireavert.properties.logic.models.PropertyAdminModel
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.TableStyles
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import mui.material.*
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import web.cssom.*

external interface AddRemovePropertyAdminContainerProps : Props {
    var propertyAdmins: List<PropertyAdminModel>
    var propertyAdminsChanged: (List<PropertyAdminModel>) -> Unit

    var unLinkedPropertyAdmins: List<PropertyAdminModel>
    var linkPropertyAdminClicked: (PropertyAdminModel) -> Unit

    var addPropertyAdminClicked: () -> Unit
    var removePropertyAdminFromServerClicked: (PropertyAdminModel) -> Unit
    var allowAddExisting: Boolean

    var resendInviteClicked: (PropertyAdminModel) -> Unit
}

val AddRemovePropertyAdminContainer = FC<AddRemovePropertyAdminContainerProps> { props ->
    val logger = getKoinInstance<Logger>()
    var selectedUnlinkedPropertyAdmin: PropertyAdminModel? by useState()

    useEffectOnce {
        selectedUnlinkedPropertyAdmin = null
    }

    fun getPropertyAdminUIModelById(id: String): PropertyAdminModel? {
        return props.propertyAdmins.find { id == it.id }
    }

    fun replaceAdminUIModel(model: PropertyAdminModel): List<PropertyAdminModel> {
        val mutableList = props.propertyAdmins.toMutableList()
        val indexToReplace = mutableList.indexOfFirst {
            it.id == model.id
        }
        mutableList[indexToReplace] = model
        return mutableList
    }

    fun handleFirstNameChange(id: String, firstName: String, error: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.firstName = firstName
        model.firstNameError = error
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleLastNameChange(id: String, lastName: String, error: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.lastName = lastName
        model.lastNameError = error
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handlePhoneChange(id: String, phone: String, error: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.phone = phone
        model.phoneError = error
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleEmailChange(id: String, email: String, error: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.email = email
        model.emailError = error
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleStoveTextNotificationsChange(id: String, stoveTextNotifications: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.stoveTextNotifications = stoveTextNotifications
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleStoveEmailNotificationsChange(id: String, stoveEmailNotifications: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.stoveEmailNotifications = stoveEmailNotifications
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleLeakTextNotificationsChange(id: String, leakTextNotifications: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.leakTextNotifications = leakTextNotifications
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleLeakEmailNotificationsChange(id: String, leakEmailNotifications: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.leakEmailNotifications = leakEmailNotifications
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleTamperTextNotificationsChange(id: String, tamperTextNotifications: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.tamperTextNotifications = tamperTextNotifications
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleTamperEmailNotificationsChange(id: String, tamperEmailNotifications: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.tamperEmailNotifications = tamperEmailNotifications
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleSmokeEmailNotificationsChange(id: String, smokeEmailNotifications: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.smokeAlarmEmailNotifications = smokeEmailNotifications
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleSmokeTextNotificationsChange(id: String, smokeTextNotifications: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.smokeAlarmTextNotifications = smokeTextNotifications
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleDailyReportsChange(id: String, dailyReports: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.dailyReports = dailyReports
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleMonthlyReportsChange(id: String, monthlyReports: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.monthlyReports = monthlyReports
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleQuarterlyReportsChange(id: String, quarterlyReports: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.quarterlyReports = quarterlyReports
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleWeeklyReportsChange(id: String, weeklyReports: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.weeklyReports = weeklyReports
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleYearlyReportsChange(id: String, yearlyReports: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.yearlyReports = yearlyReports
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleStoveOfflineEmailNotificationsChange(id: String, offline: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.stoveOfflineEmailNotifications = offline
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleStoveOfflineTextNotificationsChange(id: String, offline: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.stoveOfflineTextNotifications = offline
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleLeakOfflineEmailNotificationsChange(id: String, offline: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.leakOfflineEmailNotifications = offline
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleLeakOfflineTextNotificationsChange(id: String, offline: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.leakOfflineTextNotifications = offline
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleTamperOfflineEmailNotificationsChange(id: String, offline: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.tamperOfflineEmailNotifications = offline
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun handleTamperOfflineTextNotificationsChange(id: String, offline: Boolean) {
        val model = getPropertyAdminUIModelById(id) ?: return
        model.tamperOfflineTextNotifications = offline
        props.propertyAdminsChanged(replaceAdminUIModel(model))
    }

    fun removeClicked(id: String) {
        val model = getPropertyAdminUIModelById(id) ?: return
        if (model.existsOnServer) {
            props.removePropertyAdminFromServerClicked(model)
        } else {
            val mutableList = props.propertyAdmins.toMutableList()
            mutableList.removeAll { it.id == model.id }
            props.propertyAdminsChanged(mutableList)
        }
    }

    fun resendInviteClicked(id: String) {
        val model = getPropertyAdminUIModelById(id) ?: return
        props.resendInviteClicked(model)
    }

    div {
        className = ClassName {
            width = 100.pct
            maxWidth = 694.px
            margin = Auto.auto
            padding = 10.px
            boxSizing = BoxSizing.borderBox

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
                padding = 5.px
            }
        }

        // Property Admins Header
        h2 {
            className = TableStyles.h2Style
            +"Property Admins"
        }

        // Add Existing Property Admin (if allowed)
        if (props.allowAddExisting) {
            div {
                className = ClassName {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                    gap = 10.px
                    marginBottom = 15.px

                    media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                        flexDirection = FlexDirection.column
                        alignItems = AlignItems.stretch
                    }
                }

                FormControl {
                    fullWidth = true
                    InputLabel {
                        id = "existing-user-label"
                        +"Select Existing User"
                    }
                    Select {
                        label = ReactNode("Select Existing User")
                        labelId = "existing-user-label"
                        value = selectedUnlinkedPropertyAdmin?.id ?: ""
                        fullWidth = true
                        onChange = { event, _ ->
                            selectedUnlinkedPropertyAdmin =
                                props.unLinkedPropertyAdmins.firstOrNull { it.id == event.target.value }
                        }

                        for (unlinkedPropertyAdmin in props.unLinkedPropertyAdmins.filter {
                            !props.propertyAdmins.contains(it)
                        }) {
                            MenuItem {
                                +"${unlinkedPropertyAdmin.firstName} ${unlinkedPropertyAdmin.lastName} ${unlinkedPropertyAdmin.email}"
                                value = unlinkedPropertyAdmin.id
                            }
                        }
                    }
                }

                Button {
                    +"Add Existing Property Admin"
                    color = ButtonColor.primary
                    variant = ButtonVariant.contained
                    disabled = selectedUnlinkedPropertyAdmin == null
                    onClick = {
                        val propertyAdminToLink = selectedUnlinkedPropertyAdmin
                        if (propertyAdminToLink != null) {
                            props.linkPropertyAdminClicked(propertyAdminToLink)
                        }
                    }
                    fullWidth = true
                }
            }
        }

        props.propertyAdmins.forEach { propertyAdminUIModel ->
            div {
                className = ClassName {
                    marginBottom = 15.px
                }
                AddRemovePropertyAdminRow {
                    id = propertyAdminUIModel.id
                    existsOnServer = propertyAdminUIModel.existsOnServer
                    firstName = propertyAdminUIModel.firstName
                    firstNameError = propertyAdminUIModel.firstNameError
                    firstNameChanged = ::handleFirstNameChange
                    lastName = propertyAdminUIModel.lastName
                    lastNameError = propertyAdminUIModel.lastNameError
                    lastNameChanged = ::handleLastNameChange
                    phone = propertyAdminUIModel.phone
                    phoneError = propertyAdminUIModel.phoneError
                    phoneChanged = ::handlePhoneChange
                    email = propertyAdminUIModel.email
                    emailError = propertyAdminUIModel.emailError
                    emailChanged = ::handleEmailChange
                    stoveShutoffTextNotifications = propertyAdminUIModel.stoveTextNotifications
                    leakTextNotifications = propertyAdminUIModel.leakTextNotifications
                    tamperTextNotifications = propertyAdminUIModel.tamperTextNotifications
                    smokeAlarmTextNotifications = propertyAdminUIModel.smokeAlarmTextNotifications
                    smokeAlarmEmailNotifications = propertyAdminUIModel.smokeAlarmEmailNotifications
                    stoveShutoffEmailNotifications = propertyAdminUIModel.stoveEmailNotifications
                    leakEmailNotifications = propertyAdminUIModel.leakEmailNotifications
                    tamperEmailNotifications = propertyAdminUIModel.tamperEmailNotifications
                    stoveShutoffTextNotificationsChanged = ::handleStoveTextNotificationsChange
                    leakTextNotificationsChanged = ::handleLeakTextNotificationsChange
                    tamperTextNotificationsChanged = ::handleTamperTextNotificationsChange
                    smokeAlarmTextNotificationsChanged = ::handleSmokeTextNotificationsChange
                    smokeAlarmEmailNotificationsChanged = ::handleSmokeEmailNotificationsChange
                    stoveShutoffEmailNotificationsChanged = ::handleStoveEmailNotificationsChange
                    leakEmailNotificationsChanged = ::handleLeakEmailNotificationsChange
                    tamperEmailNotificationsChanged = ::handleTamperEmailNotificationsChange
                    stoveOfflineEmailNotifications = propertyAdminUIModel.stoveOfflineEmailNotifications
                    stoveOfflineEmailNotificationsChanged = ::handleStoveOfflineEmailNotificationsChange
                    stoveOfflineTextNotifications = propertyAdminUIModel.stoveOfflineTextNotifications
                    stoveOfflineTextNotificationsChanged = ::handleStoveOfflineTextNotificationsChange
                    leakOfflineEmailNotifications = propertyAdminUIModel.leakOfflineEmailNotifications
                    leakOfflineEmailNotificationsChanged = ::handleLeakOfflineEmailNotificationsChange
                    leakOfflineTextNotifications = propertyAdminUIModel.leakOfflineTextNotifications
                    leakOfflineTextNotificationsChanged = ::handleLeakOfflineTextNotificationsChange
                    tamperOfflineEmailNotifications = propertyAdminUIModel.tamperOfflineEmailNotifications
                    tamperOfflineEmailNotificationsChanged = ::handleTamperOfflineEmailNotificationsChange
                    tamperOfflineTextNotifications = propertyAdminUIModel.tamperOfflineTextNotifications
                    tamperOfflineTextNotificationsChanged = ::handleTamperOfflineTextNotificationsChange
                    dailyReports = propertyAdminUIModel.dailyReports
                    dailyReportsChanged = ::handleDailyReportsChange
                    weeklyReports = propertyAdminUIModel.weeklyReports
                    weeklyReportsChanged = ::handleWeeklyReportsChange
                    monthlyReports = propertyAdminUIModel.monthlyReports
                    monthlyReportsChanged = ::handleMonthlyReportsChange
                    quarterlyReports = propertyAdminUIModel.quarterlyReports
                    quarterlyReportsChanged = ::handleQuarterlyReportsChange
                    yearlyReports = propertyAdminUIModel.yearlyReports
                    yearlyReportsChanged = ::handleYearlyReportsChange
                    removeClicked = ::removeClicked
                    isEmailReadOnly = propertyAdminUIModel.existsOnServer
                    resendInviteClicked = ::resendInviteClicked
                }
            }
        }

        div {
            className = ClassName {
                textAlign = TextAlign.center
                marginTop = 20.px
            }
        }
    }
}