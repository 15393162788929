import com.fireavert.ConsoleKoinLogger
import com.fireavert.KoinModules
import com.fireavert.app.App
import com.fireavert.preferences.logic.Preferences
import com.fireavert.utilities.getKoinInstance
import com.russhwolf.settings.JsSettings
import com.russhwolf.settings.Settings
import csstype.sx
import kotlinx.browser.window
import kotlinx.datetime.TimeZone
import org.koin.core.context.startKoin
import react.create
import react.dom.client.createRoot
import web.cssom.pct
import web.dom.appendText
import web.dom.document
import web.html.HTML.div

@JsModule("@js-joda/timezone")
@JsNonModule
external object JsJodaTimeZoneModule

private val jsJodaTz = JsJodaTimeZoneModule

// Define public routes that don't require authentication or cache checking
private val publicRoutes = listOf(
    "/app/reboot_uuid",
    "/app/reset_password",
    "/app/device_info",
    "/app/reboot"
)

private fun isPublicRoute(path: String): Boolean {
    return publicRoutes.any { route ->
        path.startsWith(route)
    }
}

fun main() {
    //Initialize Google Analytics
    GA4Manager.initialize()
    // Initialize settings
    val settings: Settings = JsSettings()

    try {
        // Skip cache version check for public routes
        if (!isPublicRoute(window.location.pathname)) {
            // Check version and clear if needed
            val currentVersion = "2" // Increment this for breaking changes
            val storedVersion = settings.getString("SETTINGS_VERSION", "1")

            if (storedVersion != currentVersion) {
                settings.clear()
                settings.putString("SETTINGS_VERSION", currentVersion)
                // Only redirect to login if not on a public route
                window.location.href = "/"
                return
            }
        }

        startKoin {
            logger(ConsoleKoinLogger())
            modules(KoinModules.all)
        }

        val preferences = getKoinInstance<Preferences>()
        preferences.timeZone = TimeZone.currentSystemDefault().id

        val root = document.createElement(div)
            .apply {
                sx { height = 100.pct }
                id = "root"
            }
            .also { document.body.appendChild(it) }

        val styleElement = document.createElement("style")
        styleElement.appendText("""
            a, a:visited {
                color: #3580E1;
                text-decoration: none;
            }
            html, body, #root {
                overflow: hidden;
                position: relative;
                height: 100%;
            }
            .MuiCircularProgress-colorPrimary {
                color: #FFFFFF !important;
            }
        """.trimIndent()
        )
        document.head.appendChild(styleElement)
        createRoot(root).render(
            App.create()
        )
    } catch (e: Exception) {
        // Only redirect to login if not on a public route
        console.error("Critical initialization error:", e)
        if (!isPublicRoute(window.location.pathname)) {
            window.location.href = "/"
        }
        return
    }
}