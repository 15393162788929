package com.fireavert.administration_page.users.interface_adapters.add_user_dialog

import com.fireavert.administration_page.users.logic.add_user_dialog.AddUserInteractor

class AddUserController(
    private val interactor: AddUserInteractor,
) {
    suspend fun onLoadAddUser() = interactor.onLoadNewUser()
    suspend fun onLoadEditUser(userId: Int) = interactor.onLoadEditUser(userId)
    suspend fun onNewUserSave(): Boolean = interactor.onNewUserSave()
    suspend fun onEditUserSave(userId: Int): Boolean = interactor.onEditUserSave(userId)

}