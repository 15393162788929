package com.fireavert.styles

import emotion.css.ClassName
import web.cssom.*

object LoadingCircleStyle {

    val loadingCircleAdminPage = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.center
        alignItems = AlignItems.center
        height = 90.vh
    }

    val loadingCircleClassic = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.center
        alignItems = AlignItems.center
    }
}