package com.fireavert.administration_page.users.frameworks

import com.fireavert.administration_page.users.interface_adapters.user_section.UserSectionRecordDTO
import com.fireavert.administration_page.users.interface_adapters.user_section.UserSectionRepositoryDataSource
import com.fireavert.common.Try
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class UserSectionRepositoryDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient,
) : UserSectionRepositoryDataSource {
    override suspend fun getUserSectionData(token: String): Try<List<UserSectionRecordDTO>> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/userTrackingRecords")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}