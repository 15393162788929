import kotlinx.browser.document
import kotlinx.browser.window

object GA4Manager {
    private const val MEASUREMENT_ID = "G-E7KKWQHY5D"
    private var initialized = false

    fun initialize() {
        try {
            // Existing initialization code
            val gaScript = document.createElement("script")
            gaScript.setAttribute("async", "true")
            gaScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=$MEASUREMENT_ID")
            document.head?.appendChild(gaScript)

            val initScript = document.createElement("script")
            initScript.textContent = """
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '$MEASUREMENT_ID', { 'send_page_view': false });
            """.trimIndent()
            document.head?.appendChild(initScript)

            initialized = true
        } catch (e: Exception) {
            console.error("Failed to initialize G:", e)
        }
    }

    private fun checkGtag(): Boolean {
        return initialized && js("typeof window.gtag") != "undefined"
    }

    fun logEvent(eventName: String, params: Map<String, Any> = emptyMap()) {
        if (!checkGtag()) return
        try {
            window.asDynamic().gtag("event", eventName, params.toJsObject())
        } catch (e: Exception) {
            console.error("Failed to log event:", e)
        }
    }

    fun setUserId(userId: Int) {
        if (!checkGtag()) return
        try {
            // Create an empty JS object and add properties to it
            val config = js("{}")
            config["fa_user_id"] = userId.toString()

            // Pass the object to gtag
            window.asDynamic().gtag("config", MEASUREMENT_ID, config)
        } catch (e: Exception) {
        }
    }

    fun logPageView(pagePath: String, pageTitle: String? = null) {
        try {
            val params = mutableMapOf<String, Any>(
                "page_location" to window.location.href,
                "page_path" to pagePath
            )

            if (pageTitle != null) {
                params["page_title"] = pageTitle
            }

            window.asDynamic().gtag("event", "page_view", params.toJsObject())
        } catch (e: Exception) {
        }
    }

    fun setUserRoles(roles: List<String>) {
        if (!checkGtag()) return
        try {
            // Join roles into a comma-separated string
            val rolesString = roles.joinToString(",")

            // Create an empty JS object for user properties
            val userProperties = js("{}")
            userProperties["user_role"] = rolesString

            // Pass to gtag
            window.asDynamic().gtag("set", "user_role", userProperties)
        } catch (e: Exception) {
        }
    }

    private fun Map<String, Any>.toJsObject(): dynamic {
        val obj = js("{}")
        forEach { (key, value) ->
            obj[key] = value
        }
        return obj
    }
}