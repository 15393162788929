package com.fireavert.components.dialogs.property

import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.properties.PropertyForm
import com.fireavert.properties.logic.models.*
import com.fireavert.properties.presentation.edit_property.EditPropertyController
import com.fireavert.properties.presentation.edit_property.EditPropertyViewModel
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.user.logic.Permission
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import mui.system.useMediaQuery
import react.FC
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*

val EditPropertyDialog = FC {
    PermissionRequired {
        permission = Permission.EditProperty
        val propertyId = useParams()["propertyId"]?.toInt() ?: 0

        val controller = getKoinInstance<EditPropertyController>()
        val viewModel = getKoinInstance<EditPropertyViewModel>()
        var isLoading: Boolean by useState(false)
        var statePropertyName: String by useState("")
        var statePropertyNameError: Boolean by useState(false)
        var stateNumberOfUnits: Int by useState(0)
        var stateNumberOfUnitsError: Boolean by useState(false)
        var statePropertyClass: PropertyClass? by useState(null)
        var statePropertyClassError: Boolean by useState(false)
        var stateAddress: String by useState("")
        var stateAddressError: Boolean by useState(false)
        var stateCity: String by useState("")
        var stateCityError: Boolean by useState(false)
        var stateGeographicalState: String by useState("")
        var stateGeographicalStateError: Boolean by useState(false)
        var stateManagementCompany: String by useState("")
        var stateManagementCompanyError: Boolean by useState(false)
        var managementCompanyList: Map<String, Int> by useState(emptyMap())
        var stateSubscriptionType: SubscriptionType? by useState(null)
        var stateSubscriptionTypeError: Boolean by useState(false)
        var stateMode: Mode? by useState(null)
        var stateModeError: Boolean by useState(false)
        var statePropertyType: PropertyType by useState(PropertyType.MultiFamily)
        var statePropertyTypeError: Boolean by useState(false)
        var stateZip: String by useState("")
        var stateZipError: Boolean by useState(false)
        var stateTimezoneId: String by useState("")
        var stateTimezoneIdError: Boolean by useState(false)
        var mainTimeZoneList: Array<String> by useState(emptyArray())
        var statePropertyAdminModels: List<PropertyAdminModel> by useState(emptyList())
        var statePropertyAdminError: Boolean by useState(false)
        var stateUnlinkedPropertyAdminModels: List<PropertyAdminModel> by useState(emptyList())
        var stateErrorMessage: String by useState("")
        var stateFireClaimsOneCost: Double by useState(0.0)
        var stateFireClaimsTwoCost: Double by useState(0.0)
        var stateFireClaimsThreeCost: Double by useState(0.0)
        var stateFireClaimsFourCost: Double by useState(0.0)
        var stateFireClaimsFiveCost: Double by useState(0.0)
        var stateFireClaimsOneClaimCount: Int by useState(0)
        var stateFireClaimsTwoClaimCount: Int by useState(0)
        var stateFireClaimsThreeClaimCount: Int by useState(0)
        var stateFireClaimsFourClaimCount: Int by useState(0)
        var stateFireClaimsFiveClaimCount: Int by useState(0)
        var stateFireClaimsError: Boolean by useState(false)
        val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)

        useEffectOnceWithCleanup {
            val viewStateJob = viewModel.viewState.onEach { viewState ->
                statePropertyName = viewState.propertyName ?: ""
                statePropertyNameError = viewState.propertyNameError
                stateNumberOfUnits = viewState.numberOfUnits ?: 0
                statePropertyClass = viewState.propertyClass ?: PropertyClass.UNKNOWN
                statePropertyClassError = viewState.propertyClassError
                stateNumberOfUnitsError = viewState.numberOfUnitsError
                stateGeographicalState = viewState.state ?: ""
                stateGeographicalStateError = viewState.stateError
                stateZip = viewState.zip ?: ""
                stateZipError = viewState.zipError
                stateAddress = viewState.address ?: ""
                stateAddressError = viewState.addressError
                stateCity = viewState.city ?: ""
                stateCityError = viewState.cityError
                stateManagementCompany = viewState.managementCompany ?: ""
                stateManagementCompanyError = viewState.managementCompanyError
                managementCompanyList = viewState.managementCompanyList
                stateSubscriptionType = viewState.subscriptionType
                stateSubscriptionTypeError = viewState.subscriptionTypeError
                stateMode = viewState.mode
                stateModeError = viewState.modeError
                statePropertyType = viewState.propertyType
                statePropertyTypeError = viewState.propertyTypeError
                stateTimezoneId = viewState.timezoneId ?: ""
                stateTimezoneIdError = viewState.timezoneIdError
                mainTimeZoneList = TimeZone.availableZoneIds.filter { it.length > 3 }.toTypedArray()
                statePropertyAdminError = viewState.propertyAdminsError
                statePropertyAdminModels = viewState.propertyAdmins.toList()
                stateUnlinkedPropertyAdminModels = viewState.unlinkedPropertyAdmins.toList()
                stateErrorMessage = viewState.errorMessage
                stateFireClaimsOneCost = viewState.fireClaimsOneCost
                stateFireClaimsTwoCost = viewState.fireClaimsTwoCost
                stateFireClaimsThreeCost = viewState.fireClaimsThreeCost
                stateFireClaimsFourCost = viewState.fireClaimsFourCost
                stateFireClaimsFiveCost = viewState.fireClaimsFiveCost
                stateFireClaimsOneClaimCount = viewState.fireClaimsOneClaimCount
                stateFireClaimsTwoClaimCount = viewState.fireClaimsTwoClaimCount
                stateFireClaimsThreeClaimCount = viewState.fireClaimsThreeClaimCount
                stateFireClaimsFourClaimCount = viewState.fireClaimsFourClaimCount
                stateFireClaimsFiveClaimCount = viewState.fireClaimsFiveClaimCount
                stateFireClaimsError = viewState.fireClaimsError


            }.launchIn(viewModel.scope)

            val onLoadJob = viewModel.scope.launch {
                controller.onLoad(propertyId)
            }

            onCleanup {
                viewStateJob.cancel()
                onLoadJob.cancel()
            }
        }
        if (isMobile) {
            Dialog {
                open = true
                fullWidth = true
                onBackdropClick = { controller.clickedCancel(propertyId) }
                onClose = { _, _ -> controller.clickedCancel(propertyId) }
                maxWidth = Breakpoint.lg
                sx {
                    "& .MuiDialog-paper" {
                        height = 65.pct
                        maxHeight = 65.pct
                        margin = Auto.auto
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        padding = 0.px
                        borderRadius = 10.px
                    }
                }
                DialogTitle {
                    sx { padding = 0.px }
                }
                DialogContent {
                    sx {
                        flex = number(1.0)
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        overflow = Overflow.scroll
                        padding = 0.px
                    }
                    if (isLoading) {
                        CircularProgress {}
                    } else {
                        PropertyForm {
                            propertyName = statePropertyName
                            propertyNameError = statePropertyNameError
                            propertyNameChanged = { statePropertyName = it }

                            numberOfUnits = stateNumberOfUnits
                            numberOfUnitsError = stateNumberOfUnitsError
                            numberOfUnitsChanged = controller::numberOfUnitsChanged

                            propertyClass = statePropertyClass
                            propertyClassError = statePropertyClassError
                            propertyClassChanged = controller::propertyClassChanged

                            address = stateAddress
                            addressError = stateAddressError
                            addressChanged = controller::addressChanged

                            city = stateCity
                            cityError = stateCityError
                            cityChanged = controller::cityChanged

                            geographicalState = stateGeographicalState
                            geographicalStateError = stateGeographicalStateError
                            geographicalStateChanged = controller::stateChanged

                            zip = stateZip
                            zipError = stateZipError
                            zipChanged = controller::zipChanged

                            timezoneId = stateTimezoneId
                            timezoneIdError = stateTimezoneIdError
                            timezoneIdChanged = controller::timezoneIdChanged
                            mainTimeZonesList = mainTimeZoneList

                            managementCompany = stateManagementCompany
                            managementCompanyError = stateManagementCompanyError
                            managementCompanyChanged = controller::managementCompanyChanged

                            subscriptionType = stateSubscriptionType
                            subscriptionTypeError = stateSubscriptionTypeError
                            subscriptionTypeChanged = controller::subscriptionTypeChanged

                            mode = stateMode
                            modeError = stateModeError
                            modeChanged = controller::modeChanged

                            propertyType = statePropertyType
                            propertyTypeError = statePropertyTypeError
                            propertyTypeChanged = controller::propertyTypeChanged

                            fireClaimYearOneCost = stateFireClaimsOneCost
                            fireClaimsChangedOneCost = controller::fireClaimsChangedOneCost
                            fireClaimYearOneClaims = stateFireClaimsOneClaimCount
                            fireClaimsChangedOneClaims = controller::fireClaimsChangedOneClaims

                            fireClaimYearTwoCost = stateFireClaimsTwoCost
                            fireClaimsChangedTwoCost = controller::fireClaimsChangedTwoCost
                            fireClaimYearTwoClaims = stateFireClaimsTwoClaimCount
                            fireClaimsChangedTwoClaims = controller::fireClaimsChangedTwoClaims

                            fireClaimYearThreeCost = stateFireClaimsThreeCost
                            fireClaimsChangedThreeCost = controller::fireClaimsChangedThreeCost
                            fireClaimYearThreeClaims = stateFireClaimsThreeClaimCount
                            fireClaimsChangedThreeClaims = controller::fireClaimsChangedThreeClaims

                            fireClaimYearFourCost = stateFireClaimsFourCost
                            fireClaimsChangedFourCost = controller::fireClaimsChangedFourCost
                            fireClaimYearFourClaims = stateFireClaimsFourClaimCount
                            fireClaimsChangedFourClaims = controller::fireClaimsChangedFourClaims

                            fireClaimYearFiveCost = stateFireClaimsFiveCost
                            fireClaimsChangedFiveCost = controller::fireClaimsChangedFiveCost
                            fireClaimYearFiveClaims = stateFireClaimsFiveClaimCount
                            fireClaimsChangedFiveClaims = controller::fireClaimsChangedFiveClaims

                            addPropertyAdminClicked = {
                                val newList = statePropertyAdminModels.toMutableList()
                                newList.add(PropertyAdminModel())
                                propertyAdminsChanged(newList)
                            }

                            propertyAdminsChanged = {
                                controller.propertyAdminModelsChanged(it)
                            }

                            resendPropertyAdminInviteClicked = {
                                controller.resendPropertyAdminInvite(it)
                            }

                            linkPropertyAdminClicked = {
                                controller.linkPropertyAdmin(propertyId, it)
                            }

                            unlinkedPropertyAdminModels = stateUnlinkedPropertyAdminModels
                            propertyAdminModels = statePropertyAdminModels
                            removePropertyAdminFromServerClicked = {
                                controller.removePropertyAdminModelFromServerClicked(propertyId, it)
                            }
                            allowAddExistingPropertyAdmins = true
                            errorMessage = stateErrorMessage
                        }
                    }
                }
                DialogActions {
                    css {
                        height = 104.px
                        paddingRight = 24.px
                    }
                    Button {
                        +"Cancel"
                        color = ButtonColor.inherit
                        disabled = isLoading
                        onClick = {
                            controller.clickedCancel(propertyId)
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 163.px
                            marginRight = 6.px
                        }
                    }
                    Button {
                        +"Save Changes"
                        color = ButtonColor.primary
                        disabled = isLoading
                        onClick = {
                            viewModel.scope.launch {
                                controller.propertyAdminModelsChanged(statePropertyAdminModels.toList())
                                controller.clickedSave(propertyId)
                            }
                        }
                        variant = ButtonVariant.contained
                        css {
                            163.px
                        }
                    }
                }
            }
        } else {
            Dialog {
                open = true
                onBackdropClick = { controller.clickedCancel(propertyId) }
                onClose = { _, _ -> controller.clickedCancel(propertyId) }
                maxWidth = Breakpoint.lg
                DialogTitle {
                    +"Edit Property"
                    css {
                        backgroundImage = linearGradient(180.deg, Color("#2A3042"), Color("#4C5566"))
                        color = NamedColor.white
                        position = Position.relative
                    }
                }
                DialogContent {
                    sx {
                        padding = 0.px
                    }
                    if (isLoading) {
                        CircularProgress {}
                    } else {
                        PropertyForm {
                            propertyName = statePropertyName
                            propertyNameError = statePropertyNameError
                            propertyNameChanged = controller::propertyNameChanged
                            propPropertyId = propertyId

                            numberOfUnits = stateNumberOfUnits
                            numberOfUnitsError = stateNumberOfUnitsError
                            numberOfUnitsChanged = controller::numberOfUnitsChanged

                            propertyClass = statePropertyClass
                            propertyClassError = statePropertyClassError
                            propertyClassChanged = controller::propertyClassChanged

                            address = stateAddress
                            addressError = stateAddressError
                            addressChanged = controller::addressChanged

                            city = stateCity
                            cityError = stateCityError
                            cityChanged = controller::cityChanged

                            geographicalState = stateGeographicalState
                            geographicalStateError = stateGeographicalStateError
                            geographicalStateChanged = controller::stateChanged

                            zip = stateZip
                            zipError = stateZipError
                            zipChanged = controller::zipChanged

                            timezoneId = stateTimezoneId
                            timezoneIdError = stateTimezoneIdError
                            timezoneIdChanged = controller::timezoneIdChanged
                            mainTimeZonesList = mainTimeZoneList

                            this.managementCompanyList = managementCompanyList
                            managementCompany = stateManagementCompany
                            managementCompanyError = stateManagementCompanyError
                            managementCompanyChanged = controller::managementCompanyChanged

                            subscriptionType = stateSubscriptionType
                            subscriptionTypeError = stateSubscriptionTypeError
                            subscriptionTypeChanged = controller::subscriptionTypeChanged

                            mode = stateMode
                            modeError = stateModeError
                            modeChanged = controller::modeChanged

                            propertyType = statePropertyType
                            propertyTypeError = statePropertyTypeError
                            propertyTypeChanged = controller::propertyTypeChanged

                            fireClaimYearOneCost = stateFireClaimsOneCost
                            fireClaimsChangedOneCost = controller::fireClaimsChangedOneCost
                            fireClaimYearOneClaims = stateFireClaimsOneClaimCount
                            fireClaimsChangedOneClaims = controller::fireClaimsChangedOneClaims

                            fireClaimYearTwoCost = stateFireClaimsTwoCost
                            fireClaimsChangedTwoCost = controller::fireClaimsChangedTwoCost
                            fireClaimYearTwoClaims = stateFireClaimsTwoClaimCount
                            fireClaimsChangedTwoClaims = controller::fireClaimsChangedTwoClaims

                            fireClaimYearThreeCost = stateFireClaimsThreeCost
                            fireClaimsChangedThreeCost = controller::fireClaimsChangedThreeCost
                            fireClaimYearThreeClaims = stateFireClaimsThreeClaimCount
                            fireClaimsChangedThreeClaims = controller::fireClaimsChangedThreeClaims

                            fireClaimYearFourCost = stateFireClaimsFourCost
                            fireClaimsChangedFourCost = controller::fireClaimsChangedFourCost
                            fireClaimYearFourClaims = stateFireClaimsFourClaimCount
                            fireClaimsChangedFourClaims = controller::fireClaimsChangedFourClaims

                            fireClaimYearFiveCost = stateFireClaimsFiveCost
                            fireClaimsChangedFiveCost = controller::fireClaimsChangedFiveCost
                            fireClaimYearFiveClaims = stateFireClaimsFiveClaimCount
                            fireClaimsChangedFiveClaims = controller::fireClaimsChangedFiveClaims

                            addPropertyAdminClicked = {
                                val newList = statePropertyAdminModels.toMutableList()
                                newList.add(PropertyAdminModel())
                                propertyAdminsChanged(newList)
                            }

                            propertyAdminsChanged = {
                                controller.propertyAdminModelsChanged(it)
                            }

                            resendPropertyAdminInviteClicked = {
                                controller.resendPropertyAdminInvite(it)
                            }

                            linkPropertyAdminClicked = {
                                controller.linkPropertyAdmin(propertyId, it)
                            }

                            unlinkedPropertyAdminModels = stateUnlinkedPropertyAdminModels
                            propertyAdminModels = statePropertyAdminModels
                            removePropertyAdminFromServerClicked = {
                                controller.removePropertyAdminModelFromServerClicked(propertyId, it)
                            }
                            allowAddExistingPropertyAdmins = true
                            errorMessage = stateErrorMessage
                        }
                    }
                }
                DialogActions {
                    css {
                        height = 104.px
                        paddingRight = 24.px
                    }
                    Button {
                        +"Cancel"
                        color = ButtonColor.inherit
                        disabled = isLoading
                        onClick = {
                            controller.clickedCancel(propertyId)
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 163.px
                            marginRight = 6.px
                        }
                    }
                    Button {
                        +"Save Changes"
                        color = ButtonColor.primary
                        disabled = isLoading
                        onClick = {
                            viewModel.scope.launch {
                                controller.propertyAdminModelsChanged(statePropertyAdminModels.toList())
                                controller.clickedSave(propertyId)
                            }
                        }
                        variant = ButtonVariant.contained
                        css {
                            163.px
                        }
                    }
                }
            }
        }
    }
}