package com.fireavert.components.properties

import com.fireavert.logging.Logger
import com.fireavert.styles.NotificationCheckboxStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.NamedColor
import web.cssom.px

external interface NotificationsCheckboxTableProps: Props {
    var id: String
    var stoveShutoffTextNotifications: Boolean
    var stoveShutoffTextNotificationsChanged: (String, Boolean) -> Unit
    var stoveShutoffEmailNotifications: Boolean
    var stoveShutoffEmailNotificationsChanged: (String, Boolean) -> Unit
    var smokeAlarmTextNotifications: Boolean
    var smokeAlarmTextNotificationsChanged: (String, Boolean) -> Unit
    var smokeAlarmEmailNotifications: Boolean
    var smokeAlarmEmailNotificationsChanged: (String, Boolean) -> Unit
    var leakTextNotifications: Boolean
    var leakTextNotificationsChanged: (String, Boolean) -> Unit
    var leakEmailNotifications: Boolean
    var leakEmailNotificationsChanged: (String, Boolean) -> Unit
    var tamperTextNotifications: Boolean
    var tamperTextNotificationsChanged: (String, Boolean) -> Unit
    var tamperEmailNotifications: Boolean
    var tamperEmailNotificationsChanged: (String, Boolean) -> Unit
    var stoveOfflineEmailNotifications: Boolean
    var stoveOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var stoveOfflineTextNotifications: Boolean
    var stoveOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var leakOfflineEmailNotifications: Boolean
    var leakOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var leakOfflineTextNotifications: Boolean
    var leakOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var tamperOfflineEmailNotifications: Boolean
    var tamperOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var tamperOfflineTextNotifications: Boolean
    var tamperOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var dailyReports: Boolean
    var dailyReportsChanged: (String, Boolean) -> Unit
    var weeklyReports: Boolean
    var weeklyReportsChanged: (String, Boolean) -> Unit
    var monthlyReports: Boolean
    var monthlyReportsChanged: (String, Boolean) -> Unit
    var quarterlyReports: Boolean
    var quarterlyReportsChanged: (String, Boolean) -> Unit
    var yearlyReports: Boolean
    var yearlyReportsChanged: (String, Boolean) -> Unit
}

// Define data classes to represent different types of notifications
data class NotificationSection(
    val title: String,
    val items: List<NotificationItem>
)

data class NotificationItem(
    val text: String,
    val checked: Boolean,
    val onChange: (String, Boolean) -> Unit
)

val NotificationsCheckboxTable = FC<NotificationsCheckboxTableProps> { props ->
    val logger = getKoinInstance<Logger>()

    // Helper function to create notification items
    fun createNotificationItem(
        text: String,
        checked: Boolean,
        onChange: (String, Boolean) -> Unit
    ) = NotificationItem(
        text = text,
        checked = checked,
        onChange = onChange
    )

    // Group notifications into sections
    val sections = listOf(
        NotificationSection(
            "Text Notifications",
            listOf(
                createNotificationItem(
                    "Stove Shutoff",
                    props.stoveShutoffTextNotifications
                ) { id, value -> props.stoveShutoffTextNotificationsChanged(id, value) },
                createNotificationItem(
                    "Smoke Alarms",
                    props.smokeAlarmTextNotifications
                ) { id, value -> props.smokeAlarmTextNotificationsChanged(id, value) },
                createNotificationItem(
                    "Leak",
                    props.leakTextNotifications
                ) { id, value -> props.leakTextNotificationsChanged(id, value) },
                createNotificationItem(
                    "Tamper",
                    props.tamperTextNotifications
                ) { id, value -> props.tamperTextNotificationsChanged(id, value) }
            )
        ),
        NotificationSection(
            "Email Notifications",
            listOf(
                createNotificationItem(
                    "Stove Shutoff",
                    props.stoveShutoffEmailNotifications
                ) { id, value -> props.stoveShutoffEmailNotificationsChanged(id, value) },
                createNotificationItem(
                    "Smoke Alarms",
                    props.smokeAlarmEmailNotifications
                ) { id, value -> props.smokeAlarmEmailNotificationsChanged(id, value) },
                createNotificationItem(
                    "Leak",
                    props.leakEmailNotifications
                ) { id, value -> props.leakEmailNotificationsChanged(id, value) },
                createNotificationItem(
                    "Tamper",
                    props.tamperEmailNotifications
                ) { id, value -> props.tamperEmailNotificationsChanged(id, value) }
            )
        ),
        NotificationSection(
            "Report Subscriptions",
            listOf(
                createNotificationItem(
                    "Daily",
                    props.dailyReports
                ) { id, value -> props.dailyReportsChanged(id, value) },
                createNotificationItem(
                    "Weekly",
                    props.weeklyReports
                ) { id, value -> props.weeklyReportsChanged(id, value) },
                createNotificationItem(
                    "Monthly",
                    props.monthlyReports
                ) { id, value -> props.monthlyReportsChanged(id, value) },
                createNotificationItem(
                    "Quarterly",
                    props.quarterlyReports
                ) { id, value -> props.quarterlyReportsChanged(id, value) },
                createNotificationItem(
                    "Yearly",
                    props.yearlyReports
                ) { id, value -> props.yearlyReportsChanged(id, value) }
            )
        )
    )
    Paper {
        sx {
            padding = 24.px
            backgroundColor = NamedColor.white
        }

        Typography {
            variant = TypographyVariant.h6
            sx {
                marginBottom = 24.px
                color = NamedColor.black
                fontWeight = FontWeight.bold
            }
            +"Notification Preferences"
        }

        div {
            css {
                display = Display.flex
            }
            sections.forEach { section ->
                Card {
                    className = NotificationCheckboxStyles.modernSectionStyles

                    // Section Header
                    div {
                        className = NotificationCheckboxStyles.modernSectionHeaderStyles
                        Typography {
                            variant = TypographyVariant.body1
                            sx {
                                fontWeight = FontWeight.bold
                                color = NamedColor.black
                            }
                            +section.title
                        }
                    }

                    Divider {}

                    // Section Content
                    FormGroup {
                        className = NotificationCheckboxStyles.modernSectionContentStyles
                        section.items.forEach { item ->
                            ModernCheckBoxCell {
                                checked = item.checked
                                text = item.text
                                onChange = { value -> item.onChange(props.id, value) }
                            }
                        }
                    }
                }
            }
        }
    }
}