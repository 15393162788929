package com.fireavert.properties.logic

import com.fireavert.events.logic.EmailValidator
import com.fireavert.events.logic.SMSValidator

object PropertyValidator {
    fun validate(propertyScreen: PropertyScreen): Boolean {
        propertyScreen.setErrorMessage("")
        return checkProperty(propertyScreen) &&
            checkNumberOfUnits(propertyScreen) &&
            checkAddress(propertyScreen) &&
            checkCity(propertyScreen) &&
            checkState(propertyScreen) &&
            checkZip(propertyScreen) &&
            checkManagementCompany(propertyScreen) &&
            checkSubscriptionType(propertyScreen) &&
            checkMode(propertyScreen) &&
            checkTimezoneId(propertyScreen) &&
            checkPropertyAdminFields(propertyScreen)
    }

    private fun checkSubscriptionType(screen: PropertyScreen): Boolean {
        val subscriptionType = screen.getSubscriptionType()
        return if (subscriptionType == null) {
            screen.setErrorMessage("Missing Subscription Type")
            screen.setSubscriptionTypeError(true)
            false
        } else {
            true
        }
    }

    private fun checkMode(screen: PropertyScreen): Boolean {
        val mode = screen.getMode()
        return if (mode == null) {
            screen.setErrorMessage("Missing Mode")
            screen.setModeError(true)
            false
        } else {
            true
        }
    }

    private fun checkPropertyAdminFields(screen: PropertyScreen): Boolean {
        fun findDuplicateStrings(strings: List<String>): Set<String> {
            val seen: MutableSet<String> = mutableSetOf()
            return strings.filter { !seen.add(it) }.toSet()
        }

        val propertyAdmins = screen.getPropertyAdmins()

        val duplicateEmails = findDuplicateStrings(propertyAdmins.map { it.email })
        propertyAdmins.forEach { if (duplicateEmails.contains(it.email)) it.emailError = true }
        var foundError = duplicateEmails.isNotEmpty()
        val errorMessages = mutableListOf<String>()

        propertyAdmins.forEach { propertyAdmin ->
            if (propertyAdmin.firstName.isBlank()) {
                propertyAdmin.firstNameError = true
                foundError = true
                errorMessages += "Blank Property Admin First Name"
            }
            if (propertyAdmin.lastName.isBlank()) {
                propertyAdmin.lastNameError = true
                foundError = true
                errorMessages += "Blank Property Admin Last Name"
            }
            if (propertyAdmin.email.isBlank() || !EmailValidator.isEmailValid(propertyAdmin.email)) {
                propertyAdmin.emailError = true
                foundError = true
                errorMessages += "Invalid or blank Property Admin Email"
            }
            if (propertyAdmin.phone.isNotBlank() && !SMSValidator.isSMSValid(propertyAdmin.phone)) {
                propertyAdmin.phoneError = true
                foundError = true
                errorMessages += "Invalid or blank Property Admin Phone"
            }
        }
        screen.setPropertyAdmins(propertyAdmins)
        screen.setPropertyAdminsError(foundError)
        screen.setErrorMessage(errorMessages.joinToString(", "))

        return !foundError
    }

    private fun checkTimezoneId(screen: PropertyScreen): Boolean {
        val timezoneId = screen.getTimezoneId()
        if (timezoneId.isNullOrBlank()) {
            screen.setTimezoneIdError(true)
            screen.setErrorMessage("Missing Timezone ID")
            return false
        }
        return true
    }

    private fun checkManagementCompany(screen: PropertyScreen): Boolean {
        val managementCompany = screen.getManagementCompany()
        if (managementCompany.isNullOrBlank()) {
            screen.setManagementCompanyError(true)
            screen.setErrorMessage("Missing Management Company")
            return false
        }
        return true
    }

    private fun checkZip(screen: PropertyScreen): Boolean {
        val zip = screen.getZip()
        if (zip == null || zip.length != 5) {
            screen.setZipError(true)
            screen.setErrorMessage("Missing Zip")
            return false
        }
        return true
    }

    private fun checkState(screen: PropertyScreen): Boolean {
        val state = screen.getState()
        if (state.isNullOrBlank()) {
            screen.setStateError(true)
            screen.setErrorMessage("Missing State")
            return false
        }
        return true
    }

    private fun checkCity(screen: PropertyScreen): Boolean {
        val city = screen.getCity()
        if (city.isNullOrBlank()) {
            screen.setCityError(true)
            screen.setErrorMessage("Missing City")
            return false
        }
        return true
    }

    private fun checkAddress(screen: PropertyScreen): Boolean {
        val address = screen.getAddress()
        if (address.isNullOrBlank()) {
            screen.setAddressError(true)
            screen.setErrorMessage("Missing Address")
            return false
        }
        return true
    }

    private fun checkNumberOfUnits(screen: PropertyScreen): Boolean {
        val numberOfUnits = screen.getNumberOfUnits()
        if (numberOfUnits == null || numberOfUnits == 0) {
            screen.setNumberOfUnitsError(true)
            screen.setErrorMessage("Missing number of units or set to 0")
            return false
        }
        return true
    }

    private fun checkProperty(screen: PropertyScreen): Boolean {
        val propertyName = screen.getPropertyName()
        if (propertyName.isNullOrBlank()) {
            screen.setPropertyNameError(true)
            screen.setErrorMessage("Missing property name")
            return false
        }
        return true
    }

}