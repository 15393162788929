package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import react.dom.html.ReactHTML.div
import web.cssom.*

object SearchbarStyles {
    val searchBar = ClassName {
        fontSize = 16.px
        marginRight = Auto.auto
        marginLeft = 20.px
    }

    val searchBarNew = ClassName {
        fontSize = 16.px
        marginRight = Auto.auto
        marginLeft = 10.px
        padding = important(10.px)
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            marginLeft = 0.px
            marginRight = 0.px
            paddingBottom = 20.px
            marginTop = 15.px
            marginBottom = 15.px
        }
    }

    fun searchBarInput(widthSize: Int): ClassName =
        ClassName {
            div {
                borderRadius = important(30.px)
                border = Border(2.px, LineStyle.solid, NamedColor.black)
            }
            width = important(widthSize.rem)
            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                width = important(100.pct)
            }
        }

    fun searchBarInputNoButton(widthSize: Int): ClassName =
        ClassName {
            div {
                borderRadius = important(30.px)
            }
            marginRight = important(Auto.auto)
            marginLeft = important( 30.px)
            width = important(widthSize.rem)
        }

    var searchBarAndMobileTitle = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        alignItems = AlignItems.center
        marginTop = 20.px
    }

    var titleWithSearchbarNew = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        alignItems = AlignItems.center
        marginTop = 15.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.column
        }
    }

}