package com.fireavert.menu

import GA4Manager
import com.fireavert.administration_page.shared.presentation.AdminRouter
import com.fireavert.auth.interface_adapters.AuthRouter
import com.fireavert.devices.interface_adapters.DevicesRouter
import com.fireavert.events.presentation.EventsRouter
import com.fireavert.menu.paths.AdministrationPagePaths
import com.fireavert.menu.presentation.MenuRouter
import com.fireavert.properties.presentation.PropertyRouter
import com.fireavert.reboot.presentation.RebootRouter
import com.fireavert.units.presentation.UnitsRouter
import kotlinx.browser.window
import remix.run.router.Router


class NavRouter :
    AuthRouter,
    MenuRouter,
    PropertyRouter,
    DevicesRouter,
    EventsRouter,
    RebootRouter,
    AdminRouter,
    UnitsRouter {

    var router: Router? = null
    var ga4Tracker = GA4Manager

    /***********************************************************************************************
     * Reboot
     **********************************************************************************************/
    override fun routeToSuccess() {
        router?.navigate("${RoutePaths.reboot}_success")
        ga4Tracker.logPageView("${RoutePaths.reboot}_success", "Reboot Success")
    }

    override fun routeToNotFoundOrNotPro() {
        router?.navigate("${RoutePaths.reboot}_not_found")
        ga4Tracker.logPageView("${RoutePaths.reboot}_not_found", "Reboot Not Found")
    }

    /***********************************************************************************************
     * Dashboard
     **********************************************************************************************/
    override fun routeToDashboard() {
        print("Route To Dashboard")
        router?.navigate(RoutePaths.dashboard)
        ga4Tracker.logPageView(RoutePaths.dashboard, "Dashboard")
    }

    /***********************************************************************************************
     * Dashboard
     **********************************************************************************************/
    override fun routeToAdministration() {
        router?.navigate(RoutePaths.administration)
        ga4Tracker.logPageView(RoutePaths.administration, "Administration")
    }

    override fun routeToReports() {
        router?.navigate(RoutePaths.reports)
        ga4Tracker.logPageView(RoutePaths.reports, "Reports")
    }

    override fun routeToHelp() {
        router?.navigate(RoutePaths.help)
        ga4Tracker.logPageView(RoutePaths.help, "Help")
    }

    override fun routeToFeedback() {
        window.location.href = "https://forms.gle/jTiz351BbcGXGZ7TA"
        ga4Tracker.logPageView("Feedback", "Feedback")
    }

    /***********************************************************************************************
     * Login
     **********************************************************************************************/
    override fun routeToLogin() {
        print("Route To Login")
        router?.navigate(RoutePaths.root)
        ga4Tracker.logPageView(RoutePaths.root, "Login")
    }

    /***********************************************************************************************
     * Password Reset Sent
    **********************************************************************************************/
    override fun routeToResetPasswordSent() {
        router?.navigate(RoutePaths.resetPasswordSent)
        ga4Tracker.logPageView(RoutePaths.resetPasswordSent, "Reset Password Sent")
    }

    /***********************************************************************************************
     * Device
     **********************************************************************************************/

    /***********************************************************************************************
     * Property
     **********************************************************************************************/
    override fun routeToProperties() {
        router?.navigate(RoutePaths.properties)
        ga4Tracker.logPageView(RoutePaths.properties, "Properties")
    }

    override fun routeToProperty(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId", "Property")
    }

    override fun routeToAddNewPropertyDialog() {
        router?.navigate(RoutePaths.addNewProperty)
        ga4Tracker.logPageView(RoutePaths.addNewProperty, "Add New Property")
    }

    override fun routeToRemovePropertyAdmin(propertyId: Int, propertyAdminUserId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/removePropertyAdmin/$propertyAdminUserId")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId/removePropertyAdmin/$propertyAdminUserId", "Remove Property Admin")
    }

    override fun routeToEditProperty(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/edit")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId/edit", "Edit Property")
    }

    override fun routeToEditManagementCompany(managementCompanyId: Int) {
        router?.navigate("${RoutePaths.properties}/$managementCompanyId/editManagementCompany")
        ga4Tracker.logPageView("${RoutePaths.properties}/$managementCompanyId/editManagementCompany", "Edit Management Company")
    }

    override fun routeToNewManagementCompany() {
        router?.navigate("${RoutePaths.properties}/newManagementCompany")
        ga4Tracker.logPageView("${RoutePaths.properties}/newManagementCompany", "New Management Company")
    }

    /***********************************************************************************************
     * Reload
     **********************************************************************************************/
    override fun reload() {
        router?.navigate(0)
    }

    /***********************************************************************************************
     * Device
     **********************************************************************************************/
    override fun routeToAddNewDeviceDialog(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/add_new_device")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId/add_new_device", "Add New Device")
    }

    override fun routeToDeviceDetails(propertyId: Int, deviceId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/devices/$deviceId")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId/devices/$deviceId", "Device Details")
    }

    /***********************************************************************************************
     * Unit
     **********************************************************************************************/
    override fun routeToUnit(propertyId: Int, unitId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/units/$unitId")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId/units/$unitId", "Unit")
    }

    override fun routeToAddNewUnitCSVDialog(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/add_new_units_csv")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId/add_new_units_csv", "Add New Units CSV")
    }

    override fun routeToAddNewUnitDialog(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/add_new_unit")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId/add_new_unit", "Add New Unit")
    }

    override fun routeToConvertToCommV2Dialog(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/convert_to_comm_v2")
        ga4Tracker.logPageView("${RoutePaths.properties}/$propertyId/convert_to_comm_v2", "Convert to Comm V2")
    }

    /***********************************************************************************************
     * Admin Pages
     **********************************************************************************************/

    override fun routeToUserPage(userId: Int) {
        router?.navigate("${AdministrationPagePaths.userPageWithId}/$userId")
        ga4Tracker.logPageView("${AdministrationPagePaths.userPageWithId}/$userId}", "User Page")
    }
}