package com.fireavert.management_companies.presentation

import com.fireavert.common.TableColumn
import com.fireavert.management_companies.logic.ManagementCompanyInteractor
import com.fireavert.properties.logic.models.Property

class ManagementCompanyController(private val interactor: ManagementCompanyInteractor) {
    suspend fun onLoadNew() = interactor.onLoadNew()
    suspend fun onLoadEdit(managementCompanyId: Int) = interactor.onLoadEdit(managementCompanyId)
    suspend fun onSaveNew(name: String, propertyIdList: List<Int>): Boolean { return interactor.onSaveNew(name, propertyIdList) }
    suspend fun onSaveEdit(managementCompanyId: Int, name: String, propertyIdList: List<Int>) = interactor.onSaveEdit(managementCompanyId, name, propertyIdList)
    suspend fun managementCompanyPageOnLoad() = interactor.managementCompanyPageOnLoad()
    fun sortPropertiesByColumnMap(columns: Array<TableColumn>, properties: Map<String, List<Property>>, searchValue: String): Map<String, List<Property>> =
        interactor.searchAndsortPropertiesByColumnMap(columns, properties, searchValue)
    fun sortPropertiesByColumn(columns: Array<TableColumn>, properties: Array<Property>, searchValue: String): Array<Property> =
        interactor.searchAndsortPropertiesByColumn(columns, properties, searchValue)
    fun filterSubPropertiesBySearchValue(searchValue: String, properties: Array<Property>): Array<Property> =
        interactor.filterSubPropertiesBySearchValue(searchValue, properties)

}