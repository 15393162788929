package com.fireavert.components.reports

import com.fireavert.components.charts.LineChartDouble
import com.fireavert.components.charts.LineChartInt
import com.fireavert.components.charts.Series
import com.fireavert.components.charts.XAxis
import com.fireavert.styles.ReportPageStyles
import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.pct
import web.cssom.px

interface SelectMenuItem {
    val value: String
    val label: String
}

external interface ReportsGraphComponentProps<T> : Props {
    var loadingProp: Boolean
    var xAxisData: Array<XAxis>
    var seriesData: Array<Series<T>>
    var chartHeight: Int
    var chartWidth: Int
    var isMobile: Boolean
    var menuItemList: Array<SelectMenuItem>
    var onChangeProp: (dynamic) -> Unit
    var chartName: String
    var inputLabel: String
    var initialSelectValue: String
    var chartDescription: String
}

// Create specific components for Int and Double
val ReportsGraphComponentInt = FC<ReportsGraphComponentProps<Int>> { props ->
    ReactHTML.div {
        className = ReportPageStyles.headingAndDropDownContainer
        ReactHTML.div {
            className = ReportPageStyles.titleHeading
            +props.chartName
        }

        InputLabel {
            id = "actions-${props.inputLabel}"
            +props.inputLabel
        }
        Select {
            value = props.initialSelectValue
            id = "actions-${props.inputLabel}"
            variant = SelectVariant.outlined
            onChange = { event, _ ->
                props.onChangeProp(event)
            }
            for (item in props.menuItemList) {
                MenuItem {
                    value = item.value
                    +item.label
                }
            }
        }
    }
    ReactHTML.div{
        +props.chartDescription
    }
    ReactHTML.div {
        className = ReportPageStyles.chartContainer
        if (!props.loadingProp) {
            LineChartInt {
                xAxis = props.xAxisData
                series = props.seriesData
                width = props.chartWidth
                height = props.chartHeight
                grid = jsObject {
                    vertical = true
                    horizontal = true
                }
            }
        } else {
            Skeleton {
                sx {
                    borderRadius = 10.px
                }
                variant = SkeletonVariant.rectangular
                width = 100.pct
                height = if (props.isMobile) 300.px else 500.px
            }
        }
    }
}

val ReportsGraphComponentDouble = FC<ReportsGraphComponentProps<Double>> { props ->
    ReactHTML.div {
        className = ReportPageStyles.headingAndDropDownContainer
        ReactHTML.div {
            className = ReportPageStyles.titleHeading
            +props.chartName
        }

        InputLabel {
            id = "actions-${props.inputLabel}"
            +props.inputLabel
        }
        Select {
            value = props.initialSelectValue
            id = "actions-${props.inputLabel}"
            variant = SelectVariant.outlined
            onChange = { event, _ ->
                props.onChangeProp(event)
            }
            for (item in props.menuItemList) {
                MenuItem {
                    value = item.value
                    +item.label
                }
            }
        }
    }
    ReactHTML.div{
        +props.chartDescription
    }
    ReactHTML.div {
        className = ReportPageStyles.chartContainer
        if (!props.loadingProp) {
            LineChartDouble {
                xAxis = props.xAxisData
                series = props.seriesData
                width = props.chartWidth
                height = props.chartHeight
                grid = jsObject {
                    vertical = true
                    horizontal = true
                }
            }
        } else {
            Skeleton {
                sx {
                    borderRadius = 10.px
                }
                variant = SkeletonVariant.rectangular
                width = 100.pct
                height = if (props.isMobile) 300.px else 500.px
            }
        }
    }
}