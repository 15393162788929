package com.fireavert.components.dialogs.units

import com.fireavert.components.common.WarningIcon
import com.fireavert.logging.Logger
import com.fireavert.properties.presentation.comm_v2_conversion_dialog.CommV2ConversionDialogController
import com.fireavert.properties.presentation.comm_v2_conversion_dialog.CommV2ConversionDialogViewModel
import com.fireavert.styles.DialogStyles
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*

val CommV2DialogTitle = FC {
    DialogTitle {
        css {
            backgroundImage = linearGradient(180.deg, Color("#2A3042"), Color("#4C5566"))
            color = NamedColor.white
        }
        +"Comm V2 Conversion"
    }
}

val ConvertToV2Dialog = FC {
    val propertyId = useParams()["propertyId"]?.toInt() ?: 0
    val controller = getKoinInstance<CommV2ConversionDialogController>()
    val viewModel = getKoinInstance<CommV2ConversionDialogViewModel>()
    val logger = getKoinInstance<Logger>()

    var isLoading: Boolean by useState(false)
    var success: Boolean by useState(false)
    var isConfirmed: Boolean by useState(false)
    var errorMessages: List<String> by useState(emptyList())

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isLoading = viewState.isLoading
            success = viewState.success
            isConfirmed = viewState.isConfirmed
            errorMessages = viewState.errorMessages
        }.launchIn(viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = { controller.close(propertyId) }
        onClose = { _, _ -> controller.close(propertyId) }
        maxWidth = Breakpoint.lg
        if (!isConfirmed) {
            DialogContent {
                className = DialogStyles.warningDialogContent
                WarningIcon {}
                div {
                    className = DialogStyles.warningTitle
                    +"WARNING"
                }
                div {
                    className = cx(DialogStyles.warningText, ClassName {
                        marginBottom = 20.px
                    })
                    +"Are you sure you want to update this property to comm v2?"
                }
                DialogActions {
                    css {
                        height = 94.px
                        width = 500.px
                        padding = 24.px
                    }
                    Button {
                        +"Cancel"
                        color = ButtonColor.inherit
                        onClick = {
                            controller.close(propertyId)
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            marginRight = 6.px
                            backgroundColor = NamedColor.white
                        }
                    }
                    Button {
                        +"Yes"
                        color = ButtonColor.inherit
                        onClick = {
                            isConfirmed = true
                            viewModel.scope.launch {
                                controller.convertPropertyToCommV2(propertyId)
                            }
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            color = NamedColor.white
                        }
                    }
                }
            }
        } else if (isLoading) {
            CommV2DialogTitle {}
            DialogContent {
                className = DialogStyles.warningDialogContent
                CircularProgress {}

            }
        } else {
            CommV2DialogTitle {}
            DialogContent {
                className = DialogStyles.warningDialogContent
                if (success) {
                    div {
                        className = DialogStyles.warningText
                        +"Property Devices Have Been Converted to Comm V2"
                    }
                } else {
                    div {
                        className = DialogStyles.warningText
                        +"Error(s) encountered when converting to Comm V2"
                        br {}
                        errorMessages.forEach {
                            +it
                            br {}
                        }
                    }
                }
                DialogActions {
                    disableSpacing = true
                    css {
                        height = 80.px
                        justifyContent = important(JustifyContent.center)
                    }
                    Button {
                        +"OK"
                        color = ButtonColor.inherit
                        onClick = { controller.close(propertyId) }
                        variant = ButtonVariant.contained
                        css {
                            width = 163.px
                            marginRight = 6.px
                        }
                    }
                }
            }
        }
    }
}