package com.fireavert.properties.logic.models.response

import com.fireavert.management_companies.models.ManagementCompany
import kotlinx.serialization.Serializable


@Serializable
data class GetManagementCompaniesResponse(
    val managementCompanies: List<ManagementCompany>
)
