package com.fireavert.integrations.yardi.shared.logic.models

import kotlinx.serialization.Serializable

@Serializable
data class YardiCredentials(
    var id: Int,
    var connectionUrl: String,
    val username: String,
    var password: String,
    val serverName: String,
    val database: String,
    val platform: String,
    var interfaceEntity: String,
    var interfaceLicense: String,
    val yardiPropertyId: String,
)
