package com.fireavert.reboot.logic

import com.fireavert.common.Try
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.logging.Logger
import com.fireavert.reboot.logic.RebootNavigator.Destination

class RebootImpl(
    private val screen: RebootScreen,
    private val devicesRepository: DeviceRepository,
    private val rebootNavigator: RebootNavigator,
    private val logger: Logger
) : Reboot {
    override suspend fun onLoad(deviceId: String?) {

        // If we don't have a device ID yet, just initialize the screen
        // but don't exit early
        if (deviceId.isNullOrBlank()) {
            screen.deviceId = ""
            return
        }

        // Now check offline status since we have a device ID
        val isOffline = when (val maybe = devicesRepository.getDeviceConnectionStatus(deviceLocator = deviceId)) {
            is Try.Success -> {
                maybe.value
            }

            is Try.Error -> {
                false
            }
        }
        // Always update the screen's offline status
        screen.isOffline = isOffline

        // Check if device is active while we're at it
        val isActive = when (val maybe = devicesRepository.isDeviceActive(deviceLocator = deviceId)) {
            is Try.Success -> {
                maybe.value
            }
            is Try.Error -> {
                false
            }
        }
        screen.deviceNotActive = !isActive
    }

    override suspend fun submit(deviceId: String?) {
        if (deviceId.isNullOrBlank() || screen.deviceNotActive) {
            rebootNavigator.navigate(Destination.NotFoundOrNotPro)
        }
        when (devicesRepository.rebootDevice(deviceLocator = deviceId ?: "")) {
            is Try.Success -> {
                screen.success = true
                rebootNavigator.navigate(Destination.Success)
            }

            is Try.Error -> {
                screen.success = false
            }
        }
    }

    override suspend fun textChangedUUID(text: String) {
        screen.success = null
        if (text.isBlank()) {
            rebootNavigator.navigate(Destination.NotFoundOrNotPro)
        }
        val locator = when (val maybe = devicesRepository.getDeviceLocatorForUUID(text)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                logger.e("Failed to get device ID for uuid: $text")
                rebootNavigator.navigate(Destination.NotFoundOrNotPro)
                return
            }
        }
        val isActive = when (val maybe = devicesRepository.isDeviceActive(deviceLocator = locator)) {
            is Try.Success -> {
                maybe.value
            }

            is Try.Error -> {
                false
            }
        }
        screen.deviceNotActive = isActive.not()
        screen.deviceId = locator.removePrefix("ttn-v3-integration_")
    }

    override fun noIdFound() {
        rebootNavigator.navigate(Destination.NotFoundOrNotPro)
    }

    override fun textChanged(text: String) {
        if (text.isBlank()) {
            rebootNavigator.navigate(Destination.NotFoundOrNotPro)
        }
        screen.success = null
        val newText = text.replace(Regex("[\\W\\s._\\-]+"), "").take(6)
        if (newText == screen.deviceId) {
            return
        }

        val chunk: MutableList<String> = mutableListOf()
        for (i in newText.indices step 3) {
            val length = if (i + 3 >= newText.length) newText.length else i + 3
            val toAdd = newText.substring(i, length)
            chunk.add(toAdd)
        }
        val output = chunk.joinToString(separator = "-")
        screen.deviceId = output

    }
}