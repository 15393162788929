package com.fireavert.reports_page.logic.models

import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.events.logic.models.EventType
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ReportsExportDataModel(
    val timestamp: Instant,
    val unitName: String,
    val type: EventType,
    val isTest: Boolean,
    val deviceType: DeviceType,
    val propertyName: String,
)