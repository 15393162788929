package com.fireavert.devices.external

import com.fireavert.common.Try
import com.fireavert.devices.interface_adapters.DeviceDataSource
import com.fireavert.devices.logic.requests.DeviceCreationRequest
import com.fireavert.devices.logic.requests.DevicePoweredOnRequest
import com.fireavert.devices.logic.requests.ModifyDeviceRequest
import com.fireavert.devices.logic.response.*
import com.fireavert.info.logic.DeviceInfo
import com.fireavert.logging.Logger
import com.fireavert.reboot.logic.RebootDeviceRequest
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class ClientDeviceDataSource(
    private val httpClient: HttpClient,
    private val baseUrl: String,
    private val logger: Logger
) : DeviceDataSource {
    override suspend fun getDevicesForUnitId(token: String, unitId: Int): Try<GetDevicesByUnitIdResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/$unitId/devices")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getDevicesForPropertyId(token: String, propertyId: Int): Try<GetDevicesByPropertyIdResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/devices")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun createDevice(
        token: String,
        request: DeviceCreationRequest
    ): Try<CreateDeviceResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/${request.unitId}/devices")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getDevice(token: String, deviceId: Int): Try<DeviceResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/$deviceId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getDeviceEvents(token: String, deviceId: Int): Try<GetDeviceEventsResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/$deviceId/events")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getActiveDeviceEvents(token: String, deviceId: Int): Try<GetDeviceEventsResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/$deviceId/activeEvents")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun modifyDevice(
        token: String,
        request: ModifyDeviceRequest
    ): Try<ModifyDeviceResponse> {
        return Try.fromCoCallable {
            val response = httpClient.put {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/${request.id}")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun deleteDevice(token: String, deviceId: Int): Try<DeleteDeviceResponse> {
        return Try.fromCoCallable {
            val response = httpClient.delete {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/$deviceId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun rebootDevice(token: String, deviceId: Int): Try<RebootDeviceResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/$deviceId/reboot")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun rebootDevice(
        request: RebootDeviceRequest
    ): Try<RebootDeviceResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                url("$baseUrl/devices/reboot")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getDeviceLocatorForUUID(
        token: String,
        uuid: String
    ): Try<GetDeviceForUUIDResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                url("$baseUrl/devices/locator_for_uuid/$uuid")
                header("Authorization", "Bearer $token")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getDeviceInfoForUUID(token: String, uuid: String): Try<DeviceInfo> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                url("$baseUrl/devices/info_for_uuid/$uuid")
                header("Authorization", "Bearer $token")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getAllDeviceRebootIds(token: String): Try<List<String>> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                url("$baseUrl/devices/rebootUUIDs")
                header("Authorization", "Bearer $token")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getAllDeviceInfoIds(token: String): Try<List<String>> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                url("$baseUrl/devices/infoUUIDs")
                header("Authorization", "Bearer $token")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun isDeviceActive(deviceLocator: String): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                url("$baseUrl/devices/$deviceLocator/isActive")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getDevicesPowerStatusForUnitId(
        token: String,
        request: DevicePoweredOnRequest
    ): Try<DevicePowerOnResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/powerStatus")
                contentType(ContentType.Application.Json)
                setBody(request)
        }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getDeviceConnectionStatus(token: String, deviceLocator: String): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                url("$baseUrl/devices/$deviceLocator/connectionStatus")
                header("Authorization", "Bearer $token")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}