package com.fireavert.router

import com.fireavert.components.auth.Auth
import com.fireavert.components.dashboard.DashboardV2
import com.fireavert.components.error.ErrorPage
import com.fireavert.components.pages.*
import com.fireavert.components.redirect.Redirect
import com.fireavert.components.reset_password.ResetPasswordPage
import com.fireavert.components.reset_password.ResetPasswordSentPage
import com.fireavert.components.unauthenticated.InvitePage
import com.fireavert.menu.RoutePaths
import js.objects.jso
import kotlinx.browser.window
import react.create
import react.router.dom.createBrowserRouter
import remix.run.router.Router

val Router: Router = createBrowserRouter(
    routes =
            PropertiesRoutes +
            AdminRoutes +
            arrayOf(
                jso {
                    path = RoutePaths.invite
                    element = InvitePage.create {
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = RoutePaths.resetPasswordSent
                    element = ResetPasswordSentPage.create {
                        onSubmitCalled = {
                            window.location.href = RoutePaths.appPrefix
                        }
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = RoutePaths.resetPassword
                    element = ResetPasswordPage.create {
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = "${RoutePaths.reboot}_uuid"
                    this.element = RebootPage.create {
                        uuid = true
                    }
                },
                jso {
                    path = RoutePaths.reboot
                    element = RebootPage.create {
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = RoutePaths.infoPage
                    element = InfoPage.create {
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = RoutePaths.help
                    element = HelpPageV2.create {
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = RoutePaths.reports
                    element = ReportsPageV2.create {
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = RoutePaths.unsubscribe
                    element = UnsubscribePage.create {
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    /** Dashboard Route **/
                    path = RoutePaths.dashboard
                    element = DashboardV2.create {
                    }
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = RoutePaths.appPrefix
                    element = Redirect.create {
                        location = RoutePaths.dashboard
                    }
                },
                jso {
                    /** Root Route **/
                    path = RoutePaths.root
                    element = Auth.create {
                    }
                    ErrorBoundary = ErrorPage
                },
            ),
)