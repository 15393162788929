package com.fireavert.buildings.interface_adapters

import com.fireavert.buildings.logic.models.BuildingObject
import com.fireavert.units.logic.models.UnitModel

data class AddNewBuildingViewState(
    var loading: Boolean = false,
    var buildings: List<BuildingObject> = emptyList(),
    var error: String = "",
    var availableUnitList: List<UnitModel> = emptyList()

)
