package com.fireavert.components.dialogs

import com.fireavert.components.properties.UnitForm
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.logging.Logger
import com.fireavert.styles.DialogStyles
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.units.presentation.AddNewUnitController
import com.fireavert.units.presentation.AddOrEditUnitViewModel
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import mui.system.useMediaQuery
import org.koin.core.qualifier.named
import react.FC
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.px


val AddUnitDialog = FC {
    val propertyId = useParams()["propertyId"]?.toInt() ?: 0

    var isLoading: Boolean by useState(false)
    var number: String by useState("")
    var numberError: Boolean by useState(false)

    var addressError: Boolean by useState(false)
    var address: String by useState("")

    var cityError: Boolean by useState(false)
    var city: String by useState("")

    var stateError: Boolean by useState(false)
    var state: String by useState("")

    var zipError: Boolean by useState(false)
    var zip: String by useState("")

    var tenantName: String by useState("")
    var tenantNameError: Boolean by useState(false)

    var phone: String by useState("")
    var phoneError: Boolean by useState(false)

    var email: String by useState("")
    var emailError: Boolean by useState(false)

    var stateDeviceCreationData: Array<DeviceData> by useState(emptyArray())
    var saveDisabled: Boolean by useState(false)
    var stateSaveError: String? by useState(null)

    var notifyStoveCurrent: Boolean by useState(false)

    val controller = getKoinInstance<AddNewUnitController>()
    val viewModel = getKoinInstance<AddOrEditUnitViewModel>(qualifier = named("add"))
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    val logger = getKoinInstance<Logger>()

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            number = viewState.number ?: ""
            numberError = viewState.numberError

            address = viewState.streetAddress ?: ""
            addressError = viewState.streetError
            city = viewState.city ?: ""
            cityError = viewState.cityError

            state = viewState.state ?: ""
            stateError = viewState.stateError

            zip = viewState.zip ?: ""
            zipError = viewState.zipError

            tenantName = viewState.tenantName ?: ""
            tenantNameError = viewState.tenantNameError

            phone = viewState.phone ?: ""
            phoneError = viewState.phoneError

            email = viewState.email ?: ""
            emailError = viewState.emailError

            notifyStoveCurrent = viewState.notifyStoveCurrent

            stateDeviceCreationData = viewState.deviceDataStateList.map {
                DeviceData(
                    type = it.type ?: "",
                    typeError = it.typeError,
                    location = it.location ?: "",
                    locationError = it.locationError,
                    locator = it.locator ?: "",
                    locatorError = it.locatorError,
                    commVersion = it.commVersion ?: 1,
                    commVersionError = it.commVersionError,
                    infoUUID = it.infoUUID,
                    rebootUUID = it.rebootUUID
                )
            }.toTypedArray()
            stateSaveError = viewState.saveError
            saveDisabled = viewState.saveDisabled
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyId)
        }
        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = { controller.clickedCancel(propertyId) }
        onClose = { _, _ -> controller.clickedCancel(propertyId) }
        maxWidth = Breakpoint.lg
        DialogTitle {
            className = DialogStyles.dialogTitle
            +"Add New Unit"
        }
        DialogContent {
            if (isLoading) {
                CircularProgress {}
            } else {
                UnitForm {
                    this.number = number
                    this.numberError = numberError
                    numberChanged = controller::numberChanged

                    this.address = address
                    this.addressError = addressError
                    addressChanged = controller::addressChanged

                    this.city = city
                    this.cityError = cityError
                    cityChanged = controller::cityChanged

                    this.state = state
                    this.stateError = stateError
                    stateChanged = controller::stateChanged

                    this.zip = zip
                    this.zipError = zipError
                    zipChanged = controller::zipChanged

                    this.tenantName = tenantName
                    this.tenantNameError = tenantNameError
                    tenantNameChanged = controller::tenantNameChanged

                    this.phone = phone
                    this.phoneError = phoneError
                    phoneChanged = controller::phoneChanged


                    this.email = email
                    this.emailError = emailError
                    emailChanged = {
                        controller.emailChanged(it)
                    }

                    this.notifyStoveCurrent = notifyStoveCurrent
                    this.notifyStoveCurrentChanged = {
                        controller.notifyStoveCurrentChanged(it)
                    }

                    deviceCreationData = stateDeviceCreationData
                    saveError = stateSaveError

                    clickedAddNewDevice = controller::clickedAddNewDevice
                    deleteDeviceAtIndex = controller::deleteDeviceAtIndex

                    deviceAtIndexChanged = { index, newDeviceCreationData ->
                        controller.deviceAtIndexChanged(index, stateDeviceCreationData[index], newDeviceCreationData)
                        val newList = stateDeviceCreationData.toMutableList()
                        newList[index] = newDeviceCreationData
                        stateDeviceCreationData = newList.toTypedArray()
                    }
                }
            }
            DialogActions {
                css {
                    height = 104.px
                    paddingRight = 24.px
                }
                Button {
                    +"Cancel"
                    color = ButtonColor.inherit
                    onClick = {
                        controller.clickedCancel(propertyId)
                    }
                    variant = ButtonVariant.contained
                    css {
                        width = 163.px
                        marginRight = 6.px
                    }
                }
                Button {
                    +"Save Changes"
                    color = ButtonColor.primary
                    disabled = saveDisabled && !isLoading
                    onClick = {
                        viewModel.scope.launch {
                            controller.clickedSave(propertyId)
                        }
                    }
                    variant = ButtonVariant.contained
                    css {
                        width = 163.px
                    }
                }
            }
        }
    }
}