package com.fireavert.styles

import web.cssom.*

object SuperAdminToolsStyles {
    val dashboardContainer = emotion.css.ClassName {
        padding = 24.px
        backgroundColor = Color("#f7f9fc")
        minHeight = 100.vh
    }

    val pageTitle = emotion.css.ClassName {
        fontWeight = integer(600)
        color = Color("#1a202c")
        marginBottom = 4.px
    }

    val pageSubtitle = emotion.css.ClassName {
        color = Color("#4a5568")
        marginTop = 4.px
        marginBottom = 24.px
    }

    val pageHeaderWrapper = emotion.css.ClassName {
        marginBottom = 24.px
    }

    val cardGrid = emotion.css.ClassName {
        display = Display.grid
        gridTemplateColumns = repeat(4, 1.fr.unsafeCast<GridTemplateTracks>())
        gap = 24.px
        marginBottom = 24.px
    }

    val dashboardCard = emotion.css.ClassName {
        borderRadius = 12.px
        overflow = Overflow.hidden
        boxShadow = BoxShadow(0.px, 4.px, 12.px, rgb(0, 0, 0, 0.05))
        transition = Transition(PropertyName.transform, 0.2.s, TransitionTimingFunction.ease)
        hover {
            transform = scale(1.005)
            boxShadow = BoxShadow(0.px, 6.px, 16.px, rgb(0, 0, 0, 0.08))
        }
    }

    val cardHeader = emotion.css.ClassName {
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        alignItems = AlignItems.center
        padding = Padding(16.px, 20.px)
        borderBottom = Border(1.px, LineStyle.solid, Color("#edf2f7"))
    }

    val cardTitle = emotion.css.ClassName {
        fontSize = 18.px
        fontWeight = integer(600)
        color = Color("#2d3748")
        margin = 0.px
    }

    val cardContent = emotion.css.ClassName {
        padding = 20.px
    }

    val inputGroup = emotion.css.ClassName {
        display = Display.flex
        gap = 12.px
        alignItems = AlignItems.center
        marginBottom = 16.px
    }

    val searchField = emotion.css.ClassName {
        flex = Flex(number(1.0), number(1.0))
    }

    val resultContainer = emotion.css.ClassName {
        backgroundColor = Color("#f8fafc")
        borderRadius = 8.px
        padding = 16.px
        marginTop = 16.px
    }

    val resultItem = emotion.css.ClassName {
        display = Display.flex
        marginBottom = 8.px
    }

    val resultLabel = emotion.css.ClassName {
        fontWeight = integer(500)
        marginRight = 8.px
        color = Color("#4a5568")
    }

    val resultValue = emotion.css.ClassName {
        color = Color("#2d3748")
    }

    val gatewayItem = emotion.css.ClassName {
        backgroundColor = Color("white")
        borderRadius = 8.px
        padding = 16.px
        marginBottom = 12.px
        border = Border(1.px, LineStyle.solid, Color("#edf2f7"))
    }

    val buttonWithIcon = emotion.css.ClassName {
        display = Display.flex
        alignItems = AlignItems.center
        gap = 8.px
    }

    val statusContainer = emotion.css.ClassName {
        display = Display.flex
        alignItems = AlignItems.center
        gap = 8.px
        padding = Padding(12.px, 16.px)
        borderRadius = 8.px
        marginTop = 16.px
    }

    val statusGood = emotion.css.ClassName {
        backgroundColor = Color("#e6fffa")
        color = Color("#047857")
    }

    val statusBad = emotion.css.ClassName {
        backgroundColor = Color("#fee2e2")
        color = Color("#b91c1c")
    }

    val fullWidthCard = emotion.css.ClassName {
        gridColumn = "1 / -1".unsafeCast<GridColumn>()
    }

    val tableCardContent = emotion.css.ClassName {
        padding = 20.px
        maxWidth = 100.pct
        overflow = Overflow.hidden
    }

    val dataListContainer = emotion.css.ClassName {
        marginTop = 16.px
        maxHeight = 300.px
        overflowY = Auto.auto
    }

    val emptyStateContainer = emotion.css.ClassName {
        marginTop = 16.px
        textAlign = TextAlign.center
        padding = Padding(40.px, 20.px)
        color = Color("#718096")
        backgroundColor = Color("#f8fafc")
        borderRadius = 8.px
    }

    val loadingContainer = emotion.css.ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        alignItems = AlignItems.center
        justifyContent = JustifyContent.center
        padding = Padding(60.px, 0.px)
    }

    val loadingText = emotion.css.ClassName {
        marginTop = 16.px
        color = Color("#718096")
    }

    val scanButtonContainer = emotion.css.ClassName {
        marginBottom = 20.px
    }

    val linkStyle = emotion.css.ClassName {
        color = Color("#3182ce")
        textDecoration = None.none
        hover {
            textDecoration = TextDecoration.underline
        }
    }

    val subtitleWithWeight = emotion.css.ClassName {
        fontWeight = integer(500)
        marginBottom = 4.px
    }

    val secondaryText = emotion.css.ClassName {
        color = Color("#4a5568")
        wordBreak = WordBreak.breakWord
        lineHeight = number(1.6)
    }

    val infoIcon = emotion.css.ClassName {
        marginRight = 8.px
        color = Color("white")
    }

    val chipLabel = emotion.css.ClassName {
        marginBottom = 8.px
    }

    val tableGrid = emotion.css.ClassName {
        display = Display.grid
        gridTemplateColumns = "1fr".unsafeCast<GridTemplateColumns>() // Full width
        gap = 24.px
        marginBottom = 24.px
    }

    val tableWrapper = emotion.css.ClassName {
        width = 100.pct
        overflowX = Auto.auto
    }

    val italicText = emotion.css.ClassName {
        marginTop = 8.px
        fontStyle = FontStyle.italic
    }
}
