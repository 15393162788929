package com.fireavert.components.pages.administration

import com.fireavert.components.administration.ManagementCompanySection
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.dialogs.AddNewManagementCompanyDialog
import com.fireavert.components.nav.NavOption
import com.fireavert.components.nav.SideNavV2
import com.fireavert.components.nav.TopNavBarV2
import com.fireavert.menu.RoutePaths
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import com.fireavert.user.logic.Permission
import react.FC
import react.dom.html.ReactHTML
import react.router.useNavigate

external interface AdminManagementCompanyPageProps : react.Props{
    var isEditDialog: Boolean
    var isNewDialog: Boolean
}

val AdminManagementCompanyPage = FC<AdminManagementCompanyPageProps> { props ->
    val navigate = useNavigate()
    LoggedIn {}
    if (props.isEditDialog) {
        AddNewManagementCompanyDialog {
            closeDialog = {
                navigate(RoutePaths.administration)
            }
        }
    }
    if (props.isNewDialog) {
        AddNewManagementCompanyDialog {
            closeDialog = {
                navigate(RoutePaths.administration)
            }
        }
    }
    ReactHTML.div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Administration
        }
        PermissionRequired {
            permission = Permission.ViewSuperAdminTools
            ReactHTML.div {
                className = PageStylesV2.mainContainer
                TopNavBarV2 { activeNavOption = NavOption.Administration }
                ReactHTML.div {
                    className = PageStylesV2.mainContainerContent
                    ManagementCompanySection {}
                }
            }
        }
    }
}