package com.fireavert.components.dialogs

import com.fireavert.gateways.interface_adaptors.GatewayDialogViewModel
import com.fireavert.gateways.presentation.GatewayDialogController
import com.fireavert.properties.presentation.GatewayPropertyController
import com.fireavert.styles.GatewayStyles
import com.fireavert.styles.LoadingCircleStyle
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.PageStylesV2
import com.fireavert.units.logic.models.UnitStatus
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import js.objects.jso
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.internal.JSJoda.DateTimeFormatter
import kotlinx.datetime.internal.JSJoda.Instant
import kotlinx.datetime.internal.JSJoda.ZoneId
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import org.w3c.files.Blob
import org.w3c.files.FileReader
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.strong
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*
import web.file.File
import web.html.HTMLImageElement
import web.html.HTMLInputElement
import web.html.InputType
import kotlin.js.Promise

external interface GatewayDialogProps : Props {
    var propsPropertyName: String
    var propsGatewayName: String
    var propsGatewayDescription: String
    var propsGatewayId: String
    var propsGatewayLastUplink: Long
    var propsGatewaysStatus: UnitStatus
    var propsIsAdmin: Boolean
    var propsImageUploadError: String
    var propsController: GatewayPropertyController
    var propsPropertyId: Int
    var propsGatewayLatitude: Double
    var propsGatewayLongitude: Double
    var onClose: () -> Unit
}

fun readFileAsBytes(file: File): Promise<ByteArray> {
    return Promise { resolve, reject ->
        val reader = FileReader()
        reader.onload = { event ->
            val arrayBuffer = event.target.asDynamic().result
            val uint8Array = js("new Uint8Array(arrayBuffer)")
            val byteArray = uint8Array.unsafeCast<ByteArray>()
            resolve(byteArray)
        }
        reader.onerror = { reject(Throwable(reader.error.toString())) }
        reader.readAsArrayBuffer(file.unsafeCast<Blob>())
    }
}

fun formatTimestamp(timestamp: Long, pattern: String = "yyyy-MM-dd HH:mm:ss"): String {
    val instant = Instant.ofEpochMilli(timestamp.toDouble())
    val localDateTime = instant.atZone(ZoneId.systemDefault()).toLocalDateTime()
    val formatter = DateTimeFormatter.ofPattern(pattern)
    return localDateTime.format(formatter)
}

val GatewayDialog = FC<GatewayDialogProps> { props ->
    val dialogController = getKoinInstance<GatewayDialogController>()
    val viewModel = getKoinInstance<GatewayDialogViewModel>()
    var selectedFile by useState<File?>(null)
    val (openDialog, setOpenDialog) = useState(false)
    var loading by useState(false)
    var gatewayPhotos by useState(emptyMap<Int, String>())
    var imageError by useState("")
    var imageUploading by useState(false)

    useEffectOnceWithCleanup  {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
            gatewayPhotos = viewState.gatewayImages
            imageError = viewState.imageUploadError
            imageUploading = viewState.imageUploading

        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            dialogController.onLoad(props.propsGatewayId, props.propsPropertyId)
        }

        onCleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }


    val handleFileChange = { event: ChangeEvent<HTMLInputElement> ->
        val files = event.target.files
        if (files != null && files.length > 0) {
            selectedFile = files[0].unsafeCast<File>()
        }
    }

    val handleUpload = {
        selectedFile?.let { file ->
            imageUploading = true
            viewModel.scope.launch {
                try {
                    val fileBytes = readFileAsBytes(file).await()
                    dialogController.uploadGatewayImage(
                        fileBytes,
                        file.size,
                        file.type,
                        file.name,
                        props.propsGatewayId,
                        props.propsPropertyId
                    )
                } catch (e: Exception) {
                    console.error("Error reading file: ", e)
                    // Handle the error appropriately
                }
            }
        }
    }


    Dialog {
        open = true
        onClose = { _, _ -> props.onClose() }
        onBackdropClick = { props.onClose() }
        maxWidth = Breakpoint.lg
        DialogTitle {
            sx {
                backgroundImage = linearGradient(180.deg, Color("#2A3042"), Color("#4C5566"))
            }
            ReactHTML.div {
                className = GatewayStyles.gatewayHeaderWithButton
                ReactHTML.div {
                    +props.propsGatewayName
                }
                ReactHTML.div {
                    if (props.propsIsAdmin) {
                        Button {
                            className = PageStylesV2.genericButton
                            color = ButtonColor.primary
                            variant = ButtonVariant.contained
                            onClick =  { setOpenDialog(true) }
                            +"Edit Gateway"
                        }
                    }
                }
            }
        }
        DialogContent {
            sx {
                padding = 0.px
                width = 100.pct
                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    maxWidth = 100.pct
                }
                backgroundColor = Color("#D9D9D9")
            }
            div {
                className = GatewayStyles.gatewayDialogBody
                div {
                    div {
                        strong { +"Gateway Name: " }
                        +props.propsGatewayName
                        br {}

                        strong { +"Status: " }
                        +props.propsGatewaysStatus.toString()
                        br {}

                        strong { +"Last Uplink: " }
                        +formatTimestamp(props.propsGatewayLastUplink)
                        br {}
                    }
                }

                div {
                    img {
                        height = 75.0
                        width = 50.0
                        src = "/static/gateway_icon.svg"
                        alt = "Gateway"
                    }
                }
            }

            div {
                className = GatewayStyles.gatewayDescription
                +"Gateway Description: ${props.propsGatewayDescription}"
            }

            div {
                className = GatewayStyles.gatewayHeaderWithButton
                div {
                    className = cx(GatewayStyles.gatewayTitle, ClassName {
                        color = NamedColor.black
                    })
                    +"Photos of Location"
                }
                if (props.propsIsAdmin) {
                    div {
                        ReactHTML.input {
                            type = InputType.file
                            style = jso {
                                display = None.none
                            }
                            id = "file-upload"
                            accept = "image/*"
                            onChange = handleFileChange
                        }

                        ReactHTML.label {
                            htmlFor = "file-upload"
                            Button {
                                variant = ButtonVariant.contained
                                className = PageStylesV2.genericButton
                                component = ReactHTML.span
                                disabled = imageUploading || gatewayPhotos.size >= 3
                                + if (gatewayPhotos.size >= 3) "Photo Limit Reached" else "Choose File"
                            }
                        }
                        Button {
                            variant = ButtonVariant.contained
                            className = PageStylesV2.genericButton
                            onClick = { handleUpload() }
                            disabled = selectedFile == null || imageUploading || gatewayPhotos.size >= 3
                            +if (imageUploading) "Uploading..." else "Upload File"
                        }
                    }
                }
            }

            div {
                className = GatewayStyles.gatewayPhotoContainer

                if (imageError.isNotEmpty()) {
                    div {
                        +"Error: $imageError"
                    }
                }
                if (!loading) {
                    if (gatewayPhotos.isNotEmpty()) {
                        gatewayPhotos.forEach { (index, base64Image) ->
                            div {
                                key = index.toString()
                                val mimeType = when {
                                    base64Image.startsWith("/9j/") -> "image/jpeg"
                                    base64Image.startsWith("iVBORw0KGgo") -> "image/png"
                                    else -> "image/png"
                                }
                                img {
                                    src = try {
                                        if (base64Image.startsWith("data:image")) {
                                            base64Image
                                        } else {
                                            "data:$mimeType;base64,$base64Image"
                                        }
                                    } catch (e: Exception) {
                                        "/static/mobile_text_alert_inactive.svg"
                                    }
                                    alt = "Gateway photo ${index + 1}"
                                    style = jso {
                                        width = 200.px
                                        height = 200.px
                                        objectFit = ObjectFit.cover
                                        margin = 10.px
                                    }
                                    onError = { event ->
                                        console.error("Failed to load image ${index + 1}: ", base64Image.take(50))
                                        (event.target as? HTMLImageElement)?.let { img ->
                                            img.src =
                                                "/static/placeholder-image.png" // Replace with your placeholder image path
                                            img.alt = "Failed to load image ${index + 1}"
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        div {
                            +"No photos available"
                        }
                    }

                } else {
                    div {
                        className = LoadingCircleStyle.loadingCircleClassic
                        ReactHTML.span {
                            CircularProgress {
                                color = CircularProgressColor.info
                                size = 50.px
                            }
                        }
                    }
                }
            }

        }
        if (openDialog) {
            EditGatewayDialog {
                propsGatewayName = props.propsGatewayName
                propsGatewayDescription = props.propsGatewayDescription
                propsGatewayId = props.propsGatewayId
                propsGatewayLatitude = props.propsGatewayLatitude
                propsGatewayLongitude = props.propsGatewayLongitude
                propsController = dialogController
                propsGatewayImages = gatewayPhotos
                propsViewModel = viewModel
                propsPropertyId = props.propsPropertyId
                onClose = { setOpenDialog(false) }
            }
        }
    }
}