package com.fireavert.app

import com.fireavert.logging.Logger
import com.fireavert.menu.NavRouter
import com.fireavert.router.Router
import com.fireavert.theme.ThemeModule
import com.fireavert.utilities.getKoinInstance
import react.FC
import react.Props
import react.router.RouterProvider


val App = FC<Props> {
    val logger = getKoinInstance<Logger>()

    ThemeModule {
        RouterProvider {
            val navRouter = getKoinInstance<NavRouter>()
            navRouter.router = Router
            router = Router
        }
    }
}