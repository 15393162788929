package com.fireavert.administration_page.wireless_id_finder.interface_adapters

import com.fireavert.administration_page.wireless_id_finder.logic.WirelessIdSectionInteractor

class WirelessIdSectionController(
    private val interactor: WirelessIdSectionInteractor,
) {
    suspend fun loadWirelessIdData(deviceId: String) = interactor.loadWirelessIdData(deviceId)
    suspend fun loadGatewayDeviceData(propertyId: String) = interactor.loadGatewayDeviceData(propertyId)
    suspend fun reloadAllGatewayInfo() = interactor.reloadAllGatewayInfo()
}