package com.fireavert.components.common.shared

import com.fireavert.components.auth.PermissionRequired
import com.fireavert.styles.StandardizedStyles
import com.fireavert.styles.UserPagesStyles
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import web.cssom.px
import web.html.HTMLDivElement
import web.window.WindowTarget

external interface PageHeaderProps : Props {
    var pageTitle: String
    var deleteMode: Boolean
    var linkCalls: List<HeaderLink>
}

external interface HeaderLink {
    var text: String
    var onClick: (MouseEvent<HTMLDivElement, *>) -> Unit
    var isActive: Boolean
    var permissionNeeded: String
}

val PageHeader = FC<PageHeaderProps> { props ->
    div {
        div {
            className = StandardizedStyles.PageTitleHeader
            +props.pageTitle
        }
        //For each of the links we want to set up a click event
        div {
            className = StandardizedStyles.PageTitleHeaderLinkContainer
            props.linkCalls.forEachIndexed { index, link ->
                PermissionRequired {
                    permission = link.permissionNeeded
                    fallback = null
                    div {
                        className = StandardizedStyles.PageTitleHeaderLinks
                        onClick = { event ->
                            link.onClick.invoke(event)
                        }
                        +link.text
                    }
                    if (index < props.linkCalls.size - 1) {
                        div {
                            +"|"
                        }
                    }
                }
            }
        }
    }
}


external interface PageHeaderUserInfoProps : Props {
    var pageTitle: String
    var role: List<String>
    var email: String
    var phone: String
    var deleteMode: Boolean
    var linkCalls: List<HeaderLink>
    var permissionNeeded: String
}

val PageHeaderUserInfo = FC<PageHeaderUserInfoProps> { props ->
    div {
        div {
            className = cx(StandardizedStyles.flexStackSetup, ClassName {
                marginLeft = 25.px
                gap = 0.px
            })
            div {
                className = cx(StandardizedStyles.PageTitleHeader, ClassName {
                    marginLeft = 0.px
                })
                +props.pageTitle
            }

            div {
                className = UserPagesStyles.userRoleStyle
                props.role.forEachIndexed { index, role ->
                    div {
                        +if (index < props.role.size - 1) {
                            "$role, "
                        } else {
                            role
                        }
                    }
                }
            }

            div {
                className = UserPagesStyles.userContactInfo
                div {
                    css { marginBottom = 5.px }
                    a {
                        href = "mailto:" + props.email
                        target = WindowTarget._blank
                        +props.email
                    }
                }
                div {
                    a {
                        href = "tel:" + props.phone
                        target = WindowTarget._blank
                        +props.phone
                    }
                }
            }
        }

        PermissionRequired {
            permission = props.permissionNeeded
            //For each of the links we want to set up a click event
            div {
                className = StandardizedStyles.PageTitleHeaderLinkContainer
                props.linkCalls.forEachIndexed { index, link ->
                    div {
                        className = StandardizedStyles.PageTitleHeaderLinks
                        onClick = { event ->
                            link.onClick.invoke(event)
                        }
                        +link.text
                    }
                    if (index < props.linkCalls.size - 1) {
                        div {
                            +"|"
                        }
                    }
                }
            }
        }
    }
}