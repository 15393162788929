package com.fireavert.user.logic.models.responses

import kotlinx.serialization.Serializable

@Serializable
data class UserAuthResponse(
    val id: Int? = 0,
    val token: String? = "",
    val refresh: String? = "",
    val roles: List<RoleResponse>? = emptyList()
)
