package com.fireavert.google_analytics

import GA4Manager
import com.fireavert.preferences.logic.AnalyticsService

class GA4AnalyticsService : AnalyticsService {
    override fun setUserId(userId: Int) {
        try {
            GA4Manager.setUserId(userId)
        } catch (e: Exception) {
            console.error("Failed to set GA4 user ID:", e)
        }
    }

    override fun setUserRoles(roles: List<String>) {
        try {
            GA4Manager.setUserRoles(roles)
        } catch (e: Exception) {
            console.error("Failed to set GA4 user roles:", e)
        }
    }

    override fun logEvent(eventName: String, params: Map<String, Any>) {
        try {
            GA4Manager.logEvent(eventName, params)
        } catch (e: Exception) {
            console.error("Failed to log GA4 event:", e)
        }
    }
}