package com.fireavert.components.nav

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.NavStyles
import com.fireavert.styles.NavStylesV2
import com.fireavert.topnav.presentation.TopNavController
import com.fireavert.topnav.presentation.TopNavViewModel
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.base.ClickAwayListener
import mui.material.*
import mui.system.useMediaQuery
import react.*
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.dom.Element

external interface MobileTopNavBarProps : Props {
    var firstNameProp: String
    var lastNameProp: String
    var rolesProp: String
    var initialsProp: String
    var drawerOpenProp: Boolean
    var toggleDrawer: () -> Unit
    var controllerProp: TopNavController
    var activeNav: NavOption
}

external interface DesktopTopNavBarProps : Props {
    var firstNameProp: String
    var lastNameProp: String
    var rolesProp: String
    var popoverOpenProp: Boolean
    var setPopoverOpen: (Boolean) -> Unit
    var savedAnchorElProp: Element?
    var setSavedAnchorEl: (Element?) -> Unit
    var controllerProp: TopNavController
}

external interface TopNavBarV2Props : Props {
    var activeNavOption: NavOption
}

val TopNavBarV2 = FC<TopNavBarV2Props> { props ->
    val controller = getKoinInstance<TopNavController>()
    val viewModel = getKoinInstance<TopNavViewModel>()
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    var firstName by useState("")
    var lastName by useState("")
    var roles by useState("")
    var popoverOpen by useState(false)
    var drawerOpen by useState(false)
    var savedAnchorEl: Element? by useState(null)
    var initials by useState("")

    var viewStateJob: Job?

    useEffectWithCleanup {
        viewStateJob = viewModel.viewState.onEach { viewState ->
            firstName = viewState.firstName
            lastName = viewState.lastName
            roles = viewState.roles
            initials = "${firstName.first()}${lastName.first()}"
        }.launchIn(viewModel.scope)

        controller.onLoad()

        onCleanup {
            viewStateJob?.cancel()
        }
    }

    fun toggleDrawer() {
        drawerOpen = !drawerOpen
    }

    if (isMobile) {
        MobileTopNavBar {
            firstNameProp = firstName
            lastNameProp = lastName
            rolesProp = roles
            initialsProp = initials
            drawerOpenProp = drawerOpen
            toggleDrawer = ::toggleDrawer
            activeNav = props.activeNavOption
            controllerProp = controller
        }

    } else {
        DesktopTopNavBar {
            firstNameProp = firstName
            lastNameProp = lastName
            rolesProp = roles
            popoverOpenProp = popoverOpen
            setPopoverOpen = { popoverOpen = it }
            savedAnchorElProp = savedAnchorEl
            setSavedAnchorEl = { savedAnchorEl = it }
            controllerProp = controller
        }
    }
}

val MobileTopNavBar = FC<MobileTopNavBarProps> { props ->
    var popoverOpen by useState(false)
    var savedAnchorEl: Element? by useState(null)
    div {
        ariaLabel = "TopNav"
        className = NavStylesV2.topNav

        div {
            ClickAwayListener {
                onClickAway = { if (props.drawerOpenProp) props.toggleDrawer() }

                Fragment {
                    div {
                        className = NavStylesV2.notificationBell
                        IconButton {
                            onClick = { props.toggleDrawer() }
                            img {
                                src = "/static/hamburger_menu.svg"
                                alt = "Menu"
                            }
                            color = IconButtonColor.primary
                        }
                    }

                    Drawer {
                        anchor = DrawerAnchor.left
                        open = props.drawerOpenProp
                        onClose = { _, _ -> props.toggleDrawer() }

                        MobileNavV2 { activeNavOption = props.activeNav }
                    }
                }
            }
        }
        div {
            className = NavStylesV2.mobileLogo
            a {
                href = "/app/dashboard"
                img {
                    src = "/static/white_logo.svg"
                }
            }
        }
        div {
            ariaLabel = "User Menu"
            onClick = {
                popoverOpen = true
            }
            className = NavStylesV2.userMenu
            div {
                className = NavStyles.userMenuName
                +props.initialsProp
            }
            div {
                ref = RefCallback {
                    savedAnchorEl = it
                }
                className = NavStyles.userMenuArrow
            }
        }
        Popover {
            open = popoverOpen
            onClose = { _, _ ->
                popoverOpen = false
            }
            anchorReference = PopoverReference.anchorEl
            anchorEl = savedAnchorEl

            Paper {
                MenuItem {
                    onClick = {
                        props.controllerProp.clickedLogout()
                        popoverOpen = false
                    }
                    +"Logout"
                }
            }
        }
    }
}

val DesktopTopNavBar = FC<DesktopTopNavBarProps> { props ->
    div {
        ariaLabel = "TopNav"
        className = NavStylesV2.topNav

        div {
            ariaLabel = "User Menu"
            onClick = {
                props.setPopoverOpen(true)
            }
            className = NavStylesV2.userMenu
            div {
                className = NavStyles.userMenuName
                +"${props.firstNameProp} ${props.lastNameProp} ${props.rolesProp}"
            }
            div {
                ref = RefCallback {
                    props.setSavedAnchorEl(it)
                }
                className = NavStyles.userMenuArrow
            }
        }
        Popover {
            open = props.popoverOpenProp
            onClose = { _, _ ->
                props.setPopoverOpen(false)
            }
            anchorReference = PopoverReference.anchorEl
            anchorEl = props.savedAnchorElProp

            Paper {
                MenuItem {
                    onClick = {
                        props.controllerProp.clickedLogout()
                        props.setPopoverOpen(false)
                    }
                    +"Logout"
                }
            }
        }
    }
}
