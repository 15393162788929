package com.fireavert.components.pages
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.dialogs.DismissEventDialog
import com.fireavert.components.dialogs.MarkAsFalseAlarmDialog
import com.fireavert.components.dialogs.MarkAsTestDialog
import com.fireavert.components.dialogs.RebootDeviceDialog
import com.fireavert.components.dialogs.property.ArchivePropertyDialog
import com.fireavert.components.dialogs.property.DeletePropertyAdminDialog
import com.fireavert.components.dialogs.property.EditPropertyDialog
import com.fireavert.components.dialogs.property.PropertyTestModeDialog
import com.fireavert.components.dialogs.units.*
import com.fireavert.components.help.HelpDialog
import com.fireavert.components.nav.NavOption
import com.fireavert.components.nav.SideNavV2
import com.fireavert.components.nav.TopNavBarV2
import com.fireavert.components.properties.DamageEventsHappeningNow
import com.fireavert.components.properties.GatewayDetails
import com.fireavert.components.properties.ListUnitsAndDevices
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.logic.models.Mode
import com.fireavert.properties.presentation.property_details.PropertyDetailsController
import com.fireavert.properties.presentation.property_details.PropertyDetailsViewModel
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import com.fireavert.styles.PropertyStyles
import com.fireavert.user.logic.Permission
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Tooltip
import mui.system.useMediaQuery
import react.*
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.router.dom.Link
import react.router.dom.useSearchParams
import react.router.useParams
import web.cssom.Color
import web.cssom.NamedColor
import web.cssom.important
import web.cssom.px
import web.dom.Element
import web.html.HTMLElement

external interface UnitDetailsDialogProps: Props {
    var isUserAdmin: Boolean
}

const val SESSION_STORAGE_SCROLL_KEY = "SESSION_STORAGE_SCROLL_KEY"
external interface PropertyDetailsProps : Props {
    var unitDetailsDialogIsOpen: Boolean
    var addNewUnitDialogIsOpen: Boolean
    var editPropertyDialogIsOpen: Boolean
    var editUnitDialogIsOpen: Boolean
    var deleteUnitDialogIsOpen: Boolean
    var dismissEventDialogIsOpen: Boolean
    var markAsTestDialogIsOpen: Boolean
    var markAsFalseAlarmDialogIsOpen: Boolean
    var rebootDeviceDialogIsOpen: Boolean
    var deletePropertyAdminDialogIsOpen: Boolean
    var rebootIsGas: Boolean
    var addNewUnitCSVDialogIsOpen: Boolean
    var convertToV2DialogIsOpen: Boolean
}


val PropertyDetailsV2 = FC<PropertyDetailsProps> { props ->
    val propertyIdParam = useParams()["propertyId"]?.toInt() ?: 0
    val queryStringParam = useSearchParams().component1().toString()
    var propertyName: String by useState("")
    var propertyMode: Mode? by useState(null)
    var propertyTotalUnitsState: Int by useState(0)
    val mainContentDivRef = useRef<Element>()
    var isAdmin: Boolean by useState(false)
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    val (openTestModeDialog, setTestModeOpenDialog) = useState(false)
    val (openArchivePropertyDialog, setArchivePropertyDialog) = useState(false)
    var testModeTime: Int by useState(0)
    var showBadPasswordAlert: Boolean by useState(false)


    val viewModel = getKoinInstance<PropertyDetailsViewModel>()
    val controller = getKoinInstance<PropertyDetailsController>()
    val preferences = getKoinInstance<Preferences>()
    val logger = getKoinInstance<Logger>()

    fun saveScroll() {
        val element = mainContentDivRef.current as? HTMLElement
        element?.scrollTop?.let { scrollPosition ->
            sessionStorage.setItem(SESSION_STORAGE_SCROLL_KEY, scrollPosition.toString())
        }
    }

    fun restoreScroll() {
        window.requestAnimationFrame {
            val scrollRestore = sessionStorage.getItem(SESSION_STORAGE_SCROLL_KEY)?.toDouble() ?: 0.0
            val element = mainContentDivRef.current as? HTMLElement
            element?.scrollTo(0.0, scrollRestore)
        }
    }

    // Keep track of whether initial data has been loaded
    var dataLoaded by useState(false)
    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            propertyName = viewState.propertyName
            propertyTotalUnitsState = viewState.propertyTotalUnits
            dataLoaded = viewState.propertyName.isNotEmpty()
            propertyMode = viewState.propertyMode
            isAdmin = viewState.isAdmin
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyIdParam, preferences.userId)
        }

        restoreScroll()
        onCleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }

    useEffect(props.unitDetailsDialogIsOpen) {
        if (!props.unitDetailsDialogIsOpen) {
            restoreScroll()
        }
    }

    LoggedIn {}
    if (props.editPropertyDialogIsOpen) {
        EditPropertyDialog {}
    } else if (props.deletePropertyAdminDialogIsOpen) {
        DeletePropertyAdminDialog {}
    } else if (props.unitDetailsDialogIsOpen) {
        if (isMobile) {
            UnitDetailsMobileDialog {
                isUserAdmin = isAdmin
            }
        }
        else {
            UnitDetailsDialog {
                isUserAdmin = isAdmin
            }
        }
    } else if (props.addNewUnitDialogIsOpen) {
        AddUnitDialog {}
    } else if (props.editUnitDialogIsOpen) {
        EditUnitDialog {}
    } else if (props.addNewUnitCSVDialogIsOpen) {
        AddNewUnitsCSVDialog {}
    } else if (props.convertToV2DialogIsOpen) {
        ConvertToV2Dialog {}
    } else if (props.deleteUnitDialogIsOpen) {
        DeleteUnitDialog {}
    } else if (props.dismissEventDialogIsOpen) {
        DismissEventDialog {}
    } else if (props.markAsTestDialogIsOpen) {
        MarkAsTestDialog {}
    } else if (props.markAsFalseAlarmDialogIsOpen) {
        MarkAsFalseAlarmDialog {}
    } else if (props.rebootDeviceDialogIsOpen) {
        RebootDeviceDialog {
            isGas = props.rebootIsGas
        }
    }
    div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Properties
        }
        div {
            className = cx(PageStylesV2.mainContainer, ClassName {
                backgroundColor = when (propertyMode) {
                    Mode.Active -> Color("#EDEFF3")
                    Mode.Test -> Color("#FF7E6B")
                    Mode.Install -> Color("#A9F0D1")
                    Mode.Archive -> Color("#e85a33")
                    else -> Color("#EDEFF3")
                }
            })

            onScroll = { _ ->
                saveScroll()
            }
            ref = mainContentDivRef
            TopNavBarV2 { activeNavOption = NavOption.Properties }
            div {
                className = PageStylesV2.mainContainerContent
                if (isMobile) {
                    div {
                        a {
                            href = RoutePaths.properties
                            +"< Properties"
                        }
                    }
                }

                div {
                    className = PageStyles.sectionTitleWithRightHandButton
                    div {
                        +propertyName
                        div {
                            when (propertyMode) {
                                Mode.Active -> {
                                    className = PageStyles.test(NamedColor.green)
                                    Tooltip {
                                        title = HelpDialog.toolTipText(propertyMode.toString())
                                        +"Protected"
                                    }
                                }

                                Mode.Test -> {
                                    className = PageStyles.test(NamedColor.black)
                                    Tooltip {
                                        title = HelpDialog.toolTipText(propertyMode.toString())
                                        +"Test Mode"
                                    }
                                }

                                Mode.Install -> {
                                    className = PageStyles.test(NamedColor.black)
                                    Tooltip {
                                        title = HelpDialog.toolTipText(propertyMode.toString())
                                        +"Install Mode"
                                    }
                                }

                                Mode.Mute -> {
                                    className = PageStyles.test(NamedColor.black)
                                    Tooltip {
                                        title = HelpDialog.toolTipText(propertyMode.toString())
                                        +"Mute Mode"
                                    }
                                }

                                Mode.Archive -> {
                                    className = PageStyles.test(NamedColor.black)
                                    Tooltip {
                                        title = HelpDialog.toolTipText(propertyMode.toString())
                                        +"Archived"
                                    }
                                }

                                null -> {
                                    className = PageStyles.test(NamedColor.black)
                                    Tooltip {
                                        title = HelpDialog.toolTipText(propertyMode.toString())
                                        +"Loading..."
                                    }
                                }
                            }
                        }
                    }

                    if (openTestModeDialog) {
                        PropertyTestModeDialog {
                            closeCallback = {
                                setTestModeOpenDialog(false)
                                window.location.reload()
                            }
                            this.viewModel = viewModel
                            this.controller = controller
                            propertyId = propertyIdParam
                        }
                    }

                    if (openArchivePropertyDialog) {
                        ArchivePropertyDialog {
                            message = "Are you sure you want to archive this property? No new data or notifications will work!"
                            error = if (showBadPasswordAlert) "Incorrect password, please try again." else ""
                            closeCallback = {
                                setArchivePropertyDialog(false)
                                window.location.reload()
                            }
                            archiveCallback = {
                                viewModel.scope.launch {
                                    val success = controller.archiveProperty(propertyIdParam, it)
                                    if (success) {
                                        showBadPasswordAlert = false
                                        setArchivePropertyDialog(false)
                                        window.location.reload()
                                    }
                                    else {
                                        showBadPasswordAlert = true
                                    }
                                }
                            }
                        }
                    }

                    div {
                        if (!isMobile) {
                            PermissionRequired {
                                permission = Permission.EditProperty
                                fallback = null
                                Link {
                                    to = "${RoutePaths.properties}/${propertyIdParam}/edit"
                                    Button {
                                        className = PageStylesV2.genericButton
                                        color = ButtonColor.primary
                                        variant = ButtonVariant.contained
                                        +"Edit Property"
                                    }
                                }
                            }
                        }
                        if (propertyMode != Mode.Test) {
                            Button {
                                variant = ButtonVariant.contained
                                onClick = {
                                    setTestModeOpenDialog(true)
                                }
                                +"Turn on Test Mode"
                            }
                        } else if (isAdmin and isMobile) {
                            div {
                                css {
                                    fontSize = 16.px
                                }
                                Link {
                                    to = "${RoutePaths.properties}/${propertyIdParam}/add_new_unit"
                                    a {
                                        +"Add Unit"
                                    }
                                }
                                +"  |  "
                                Link {
                                    to = "${RoutePaths.properties}/${propertyIdParam}/edit"
                                    a {
                                        +"Edit Property"
                                    }
                                }
                            }
                        }
                    }
                }
                DamageEventsHappeningNow {
                    propertyId = if (dataLoaded) propertyIdParam else 0
                    mobileVersion = isMobile
                }
                GatewayDetails {
                    propsIsAdmin = isAdmin
                    propsIsMobile = isMobile
                    propertyId = if (dataLoaded) propertyIdParam else 0
                    propsPropertyName = propertyName
                }

                ListUnitsAndDevices {
                    propertyId = if (dataLoaded) propertyIdParam else 0
                    propertyTotalUnitCount = propertyTotalUnitsState
                    queryString = queryStringParam
                    isMobileVersion = isMobile
                }
            }

            if (isAdmin && propertyMode != Mode.Archive) {
                div {
                    className = PropertyStyles.propertyArchiveButton
                    Button {
                        onClick = {
                            setArchivePropertyDialog(true)
                        }
                        variant = ButtonVariant.contained
                        css { backgroundColor = important(NamedColor.red) }
                        +"Archive Property"
                    }
                }
            }
        }
    }
}