package com.fireavert.components.pages.administration

import com.fireavert.auth.interface_adapters.PageAuthenticationController
import com.fireavert.components.administration.UserInfoSection
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.nav.NavOption
import com.fireavert.components.nav.SideNavV2
import com.fireavert.components.nav.TopNavBarV2
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import com.fireavert.utilities.getKoinInstance
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import react.FC
import react.dom.html.ReactHTML.div
import react.useEffectOnceWithCleanup
import react.useState


val AdminUserInfoPage = FC {
    LoggedIn {}

    var hasAccess by useState(false)
    var isLoading by useState(true)

    div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Administration
        }

        val controller = getKoinInstance<PageAuthenticationController>()

        useEffectOnceWithCleanup {
            val scope = MainScope()

            val path = window.location.pathname
            val pageName = path.split("/").last()

            scope.launch {
                try {
                    val result = controller.validatePageAccess(pageName)
                    hasAccess = result
                    isLoading = false
                } catch (_: Exception) {
                    hasAccess = false
                    isLoading = false
                }
            }

            onCleanup {
                scope.cancel()
            }
        }

        when {
            isLoading -> {
                // Optional: Show loading state
                div {
                    +"Loading..."
                }
            }
            !hasAccess -> {
                div {
                    +"You do not have permission to view this page."
                }
            }
            else -> {
                div {
                    className = PageStylesV2.mainContainer
                    TopNavBarV2 { activeNavOption = NavOption.Administration }
                    div {
                        className = PageStylesV2.mainContainerContent
                        UserInfoSection {}
                    }
                }
            }
        }
    }
}