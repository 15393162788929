package com.fireavert.administration_page.main_page

import com.fireavert.administration_page.main_page.logic.AdminPageRepositoryDataSource
import com.fireavert.administration_page.main_page.logic.models.response.AdminGetUserCountResponse
import com.fireavert.common.Try
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

class AdminPageRepositoryDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient
) : AdminPageRepositoryDataSource {
    override suspend fun getUserData(token: String): Try<AdminGetUserCountResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/admin/userCount")
            }
            if (response.status.isSuccess()) response.body() else {
                throw Exception("Failed to get user data")
            }
        }
    }

}
