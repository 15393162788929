package com.fireavert.gateways.models.request

import kotlinx.serialization.Serializable

@Serializable
data class GatewayDetailChangeRequest(
    val gatewayName: String,
    val gatewayDescription: String,
    val gatewayId: String,
    val gatewayLatitude: Double,
    val gatewayLongitude: Double
)
