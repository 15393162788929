package com.fireavert.components.administration

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import mui.material.Skeleton
import mui.material.SkeletonVariant
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.router.dom.Link
import web.cssom.*

object AdminToolBoxesStyles {
    val toolBox = ClassName {
        display = Display.flex
        alignItems = AlignItems.flexStart
        justifyContent = JustifyContent.spaceBetween
        padding = Padding(20.px, 24.px)
        backgroundColor = NamedColor.white
        borderRadius = 12.px
        cursor = Cursor.pointer
        minWidth = 290.px
        minHeight = 100.px

        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            padding = Padding(16.px, 20.px)
            minWidth = 100.pct
        }
        hover {
            backgroundColor = Color("#D4D7E4")
        }
    }

    val contentContainer = ClassName {
        display = Display.flex
        alignItems = AlignItems.flexStart
        gap = 16.px
        flexDirection = FlexDirection.column
    }

    val iconWrapper = ClassName {
        display = Display.flex
        alignItems = AlignItems.center
        justifyContent = JustifyContent.center
        width = 60.px
        height = 60.px

        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 32.px
            height = 32.px
        }
    }

    val icon = ClassName {
        width = 100.pct
        height = 100.pct
        objectFit = ObjectFit.contain
    }

    val title = ClassName {
        fontSize = 16.px
        fontWeight = FontWeight.bold
        color = Color("#2D3748")  // Dark gray for text

        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 14.px
        }
    }

    val arrowIcon = ClassName {
        width = 24.px
        height = 24.px


        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 20.px
            height = 20.px
        }
    }
}

external interface AdminToolBoxesProps : Props {
    var boxTitle: String
    var boxIcon: String
    var link: String
    var loading: Boolean?
}

val AdminToolBoxes = FC<AdminToolBoxesProps> { props ->
    val isLoading = props.loading ?: false
    
    if (isLoading) {
        div {
            className = AdminToolBoxesStyles.toolBox
            Skeleton {
                variant = SkeletonVariant.rounded
                width = "100%"
                height = "100%"
            }
        }
    } else {
        Link {
            to = props.link
            div {
                className = AdminToolBoxesStyles.toolBox
    
                div {
                    className = AdminToolBoxesStyles.contentContainer
    
                    div {
                        className = AdminToolBoxesStyles.iconWrapper
                        img {
                            className = AdminToolBoxesStyles.icon
                            src = props.boxIcon
                            alt = props.boxTitle
                        }
                    }
    
                    div {
                        className = AdminToolBoxesStyles.title
                        +props.boxTitle
                    }
                }
    
                // Right arrow icon
                img {
                    className = AdminToolBoxesStyles.arrowIcon
                    src = "/static/mobile_arrow_black.svg"  // Assuming this path exists
                    alt = "Navigate to ${props.boxTitle}"
                }
            }
        }
    }
}