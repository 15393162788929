package com.fireavert.buildings.frameworks

import com.fireavert.buildings.interface_adapters.ClientBuildingRepositoryDataSource
import com.fireavert.buildings.logic.models.requests.SaveNewBuildingRequest
import com.fireavert.buildings.logic.models.response.BuildingsByPropertyResponse
import com.fireavert.common.Try
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class ClientBuildingRepositoryDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient,
) : ClientBuildingRepositoryDataSource {

    override suspend fun saveNewBuilding(request: SaveNewBuildingRequest, token: String): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                setBody(request)
                contentType(ContentType.Application.Json)
                url("$baseUrl/properties/building/new")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getBuildingsForProperty(propertyId: Int, token: String): Try<BuildingsByPropertyResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                contentType(ContentType.Application.Json)
                url("$baseUrl/properties/$propertyId/buildings")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}