package com.fireavert.components.pages.administration

import UserDialogMode
import com.fireavert.components.administration.UserSection
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.dialogs.AddNewUserDialog
import com.fireavert.components.nav.NavOption
import com.fireavert.components.nav.SideNavV2
import com.fireavert.components.nav.TopNavBarV2
import com.fireavert.menu.paths.AdministrationPagePaths
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import com.fireavert.user.logic.Permission
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.useNavigate

external interface AdminUserPageProps : Props {
    var addNewUserDialogIsOpen: Boolean
    var editUserDialogIsOpen: Boolean
}
val AdminUserPage = FC<AdminUserPageProps> { props ->
    val navigate = useNavigate()
    LoggedIn {}
    if (props.addNewUserDialogIsOpen) {
        AddNewUserDialog {
            mode = UserDialogMode.CREATE
            closeDialog = { props.addNewUserDialogIsOpen = false
            navigate("${AdministrationPagePaths.userPage}/all")
            }
        }
    }
    else if (props.editUserDialogIsOpen) {
        AddNewUserDialog {
            mode = UserDialogMode.EDIT
            closeDialog = { props.editUserDialogIsOpen = false
            navigate("${AdministrationPagePaths.userPage}/all")
            }
        }
    }
    ReactHTML.div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Administration
        }
        PermissionRequired {
            permission = Permission.ViewUserList
            ReactHTML.div {
                className = PageStylesV2.mainContainer
                TopNavBarV2 { activeNavOption = NavOption.Administration }
                ReactHTML.div {
                    className = PageStylesV2.mainContainerContent
                    UserSection {
                        isUserDialogOpen = false
                    }
                }
            }
        }
    }
}