package com.fireavert.reports_page.logic

import com.fireavert.common.Try
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.events.logic.models.EventType
import com.fireavert.logging.Logger
import kotlinx.datetime.Clock

class ReportsExportDialogInteractor(
    private val screen: ReportsExportDialogScreen,
    private val reportsPageRepository: ReportsPageRepository,
    private val logger: Logger,
) {
    suspend fun exportReportEventData(startDate: Any, endDate: Any, propertyId: Int?, includeOffline: Boolean, isEasySelection: Boolean): String? {
        screen.isExportLoading = true
        val finalStartDate: Long
        val finalEndDate: Long
        if (isEasySelection) {
            val currentTime = Clock.System.now().epochSeconds
            val secondsPerDay = 24 * 60 * 60
            finalStartDate = currentTime - (startDate as Long * secondsPerDay)
            finalEndDate = currentTime
        }
        else {
            //For the date picker we need to convert the date to seconds
            finalStartDate = startDate as Long / 1000
            finalEndDate = endDate as Long / 1000
        }
        val exportData = when (val maybe = reportsPageRepository.exportReportEventData(finalStartDate, finalEndDate, propertyId, includeOffline)) {
            is Try.Error -> {
                logger.e("Failed to export report event data ${maybe.exception}")
                screen.isExportLoading = false
                screen.error = maybe.exception.message ?: ""
                return null
            }
            is Try.Success -> {
                maybe.value
            }
        }

        //Build the CSV content
        val csvContent = buildString {
            appendLine("Property Name, Unit Number, Device Type, Event Type, Time, Event Marked as Test")
            exportData.propertyEventMap.forEach { eventData ->
                eventData.value.forEach { unitData ->
                    appendLine(
                        "${unitData.propertyName}, ${unitData.unitName}, ${unitData.deviceType}, ${convertEventTypeToString(unitData.type, unitData.deviceType)}, ${unitData.timestamp}, ${unitData.isTest}"
                    )
                }
            }
        }

        screen.isExportLoading = false
        return csvContent
    }


    private fun convertEventTypeToString(eventType: EventType, deviceType: DeviceType): String {
        if (eventType == EventType.DeviceTriggered) {
            return when (deviceType) {
                DeviceType.FireAvert -> "Stove Shut Off"
                DeviceType.WaterSensor -> "Water Leak Detected"
                DeviceType.TamperSensor -> "Tamper Detected"
                DeviceType.FireAvertGas -> "Stove Shut Off"
                DeviceType.FireAvertAppliance -> "Stove Shut Off"
                DeviceType.FlowSensor -> "Water Leak Detected"
            }
        }
        return eventType.toString()
    }
}