package com.fireavert.management_companies.interface_adaptors

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.management_companies.framework.ManagementCompanyDataSource
import com.fireavert.management_companies.logic.ManagementCompanyRepository
import com.fireavert.management_companies.models.ManagementCompany
import com.fireavert.management_companies.models.request.UpdateManagementCompanyRequest
import com.fireavert.management_companies.models.response.ManagementCompanyDialogResponse
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ManagementCompanyRepositoryImpl(
    private val dataSource: ManagementCompanyDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
): ManagementCompanyRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun getManagementCompaniesWithProperties(): Try<ManagementCompanyDialogResponse> = withContext(ioContext) {
        callWithRefresh { dataSource.getManagementCompanyDialogData(preferences.token, null ) }
            .map { it }
    }

    override suspend fun getManagementCompanyById(id: Int): Try<ManagementCompanyDialogResponse> = withContext(ioContext) {
        callWithRefresh { dataSource.getManagementCompanyDialogData(preferences.token, id) }
            .map { it }
    }

    override suspend fun saveManagementCompany(name: String, propertyIdList: List<Int>): Try<Boolean> {
        return callWithRefresh { dataSource.saveManagementCompany(preferences.token, UpdateManagementCompanyRequest(null, name = name, selectedProperties = propertyIdList)) }
            .map { it }
    }

    override suspend fun updateManagementCompany(
        managementCompanyId: Int,
        name: String,
        propertyIdList: List<Int>
    ): Try<Boolean> {
        return callWithRefresh { dataSource.saveManagementCompany(preferences.token, UpdateManagementCompanyRequest(managementCompanyId, name, propertyIdList)) }
            .map { it }
    }

    override suspend fun getAllManagementCompanies(): Try<List<ManagementCompany>> {
        return callWithRefresh { dataSource.getAllManagementCompanies(preferences.token) }
            .map { it.managementCompanies }
    }
}