package com.fireavert.administration_page.users.interface_adapters.user_section

import com.fireavert.administration_page.users.logic.UserSectionRepository
import com.fireavert.administration_page.users.logic.UserTrackingRecord
import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class UserSectionRepositoryImpl(
    private val dataSource: UserSectionRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
) : UserSectionRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun getUserTrackingData(): Try<List<UserTrackingRecord>> = withContext(ioContext) {
        callWithRefresh { dataSource.getUserSectionData(preferences.token) }
            .map { dtoList ->
                dtoList.map { dto ->
                    dto.toUserTrackingRecord()
                }
            }
    }
}