package com.fireavert.components.pages

import com.fireavert.common.TableColumn
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.shared.HeaderLink
import com.fireavert.components.common.shared.PageHeader
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.presentation.property_portfolio.PropertyPortfolioController
import com.fireavert.properties.presentation.property_portfolio.PropertyPortfolioViewModel
import com.fireavert.styles.SearchbarStyles
import com.fireavert.styles.StandardizedStyles
import com.fireavert.styles.TableStyles
import com.fireavert.user.logic.Permission
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.material.TextField
import mui.system.sx
import react.FC
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.router.useNavigate
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*
import web.html.HTMLDivElement
import web.html.HTMLInputElement

val PropertyPortfolioV3 = FC {
    val logger = getKoinInstance<Logger>()
    val viewModel = getKoinInstance<PropertyPortfolioViewModel>()
    val controller = getKoinInstance<PropertyPortfolioController>()

    var properties: Array<Property> by useState(emptyArray())
    var managementCompaniesWithProperties: Map<String, List<Property>> by useState(emptyMap())
    var loading: Boolean by useState(false)
    var showCreateButton: Boolean by useState(false)
    var showEditProperty: Boolean by useState(false)
    var showDeleteProperty: Boolean by useState(false)
    val navigate = useNavigate()
    var expandedRows: Set<String> by useState(emptySet()) // Track expanded rows by management company
    val (searchValue, setSearchValue) = useState("")

    var stateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "Management Company",
                sortOrder = TableColumn.SortOrder.HighToLow,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "Properties",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            ),
        )
    )

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            properties = viewState.properties.toTypedArray()
            managementCompaniesWithProperties = viewState.managementCompaniesWithProperties
            loading = viewState.loading
            showCreateButton = viewState.showCreateButton
            showEditProperty = viewState.showEditProperty
            showDeleteProperty = viewState.showDeleteProperty
            
            // Auto-expand management companies when search is active and companies list changes
            if (searchValue.isNotEmpty() && managementCompaniesWithProperties.isNotEmpty()) {
                expandedRows = managementCompaniesWithProperties.keys.toSet()
            }
        }.launchIn(viewModel.scope)

        controller.onLoad()

        onCleanup {
            viewStateJob.cancel()
        }
    }

    fun toggleRowExpansion(managementCompany: String) {
        expandedRows = if (expandedRows.contains(managementCompany)) {
            expandedRows - managementCompany
        } else {
            expandedRows + managementCompany
        }
    }

    PageHeader {
        pageTitle = if (managementCompaniesWithProperties.size == 1) "Portfolio - ${managementCompaniesWithProperties.keys.first()}" else "Portfolio"
        deleteMode = false
        linkCalls = listOf(
            object : HeaderLink {
                override var text = "Add Management Company"
                override var permissionNeeded = Permission.CreateManagementCompany
                override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    navigate("${RoutePaths.administration}/companies/new")
                }
                override var isActive = true
            },
            object : HeaderLink {
                override var text = "Add Property"
                override var permissionNeeded = Permission.CreateProperty
                override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    controller.clickedAddNewProperty()
                }
                override var isActive = true
            }
        )
    }

    div {
        className = SearchbarStyles.searchBarNew
        TextField {
            className = SearchbarStyles.searchBarInput(30)
            placeholder = "Search properties..."
            value = searchValue
            onChange = { event ->
                val target = event.target as? HTMLInputElement
                val newSearchValue = target?.value ?: ""
                setSearchValue(newSearchValue)
                
                // Auto-expand all management companies when search is active
                if (newSearchValue.isNotEmpty()) {
                    expandedRows = managementCompaniesWithProperties.keys.toSet()
                } else {
                    // Collapse all rows when search is cleared
                    expandedRows = emptySet()
                }
            }
        }
    }

    div {
        className = TableStyles.tableContainerNew
        div {
            className = TableStyles.table
            SortBar {
                columns = mapToControlColumns(stateColumns)
                includeLoading = true
                isNewView = true
                isLoading = loading
                columnsChanged = { stateColumns = mapToStateColumns(it) }
            }

            if (!loading) {
                val sortedProperties = controller.sortPropertiesByColumnMap(
                    stateColumns,
                    managementCompaniesWithProperties,
                    searchValue
                )

                sortedProperties.forEach { (managementCompany, properties) ->
                    div {
                        className = TableStyles.tableRowPropertyListNew
                        onClick = { toggleRowExpansion(managementCompany) }

                        div {
                            className = cx(TableStyles.tableCellNew, ClassName {
                                fontWeight = integer(700)
                                borderBottom = Border(2.px, LineStyle.solid, Color("#E0E0E0"))
                            })
                            div {
                                +managementCompany
                            }
                        }
                        div {
                            css { paddingLeft = 20.px }
                            className = cx(TableStyles.tableCellNew, ClassName {
                                borderBottom = Border(2.px, LineStyle.solid, Color("#E0E0E0"))
                            })
                            +properties.size.toString()
                        }
                        div {
                            className = cx(TableStyles.tableCellNew, ClassName {
                                borderBottom = Border(2.px, LineStyle.solid, Color("#E0E0E0"))
                            })
                            div {
                                className = TableStyles.threeDotsButtonContainer

                                img {
                                    src = if (expandedRows.contains(managementCompany)) {
                                        "/static/accordion_arrow_black_up.svg"
                                    } else {
                                        "/static/accordion_arrow_black.svg"
                                    }
                                    alt = "Expand"
                                }
                            }
                        }
                    }

                    if (expandedRows.contains(managementCompany)) {
                        val updatedProperties = controller.filterSubPropertiesBySearchValue(searchValue, properties.toTypedArray())
                        updatedProperties.forEach { property ->
                            div {
                                onClick = {
                                    navigate("${RoutePaths.properties}/${property.id}")
                                }
                                className = TableStyles.tableRowPropertyListNew
                                div {
                                    className = cx(TableStyles.tableCell, ClassName {
                                        paddingLeft = important(5.pct)
                                        color = NamedColor.black
                                        borderBottom = Border(1.px, LineStyle.solid, Color("#E0E0E0"))
                                    })
                                    div {
                                        className = StandardizedStyles.flexRowSetup
                                        div {
                                            css {zIndex = integer(99)}
                                            onClick = {
                                                it.stopPropagation()
                                                navigate("${RoutePaths.properties}/${property.id}/edit")
                                            }
                                            PermissionRequired {
                                                permission = Permission.EditProperty
                                                img {
                                                    height = 20.0
                                                    width = 20.0
                                                    src = "/static/edit_icon.svg"
                                                    alt = "edit"
                                                }
                                            }
                                        }
                                        div {
                                            +property.name
                                        }
                                    }
                                }
                                div {
                                    className = TableStyles.tableCell
                                    +""
                                }
                                div {
                                    className = TableStyles.tableCell
                                    div {
                                        css {
                                            display = Display.inlineFlex  // Add this
                                            marginLeft = Auto.auto        // Push to right
                                        }
                                        img {
                                            src = "/static/mobile_arrow_black.svg"
                                            alt = "Arrow"
                                            css {
                                                width = 15.px  // Ensure explicit size
                                                height = 15.px
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                Skeleton {
                    sx { borderRadius = 10.px }
                    variant = SkeletonVariant.rectangular
                    width = 100.pct
                    height = 100.pct
                }
            }
        }
    }
}