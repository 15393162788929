package com.fireavert.buildings.presentation

import com.fireavert.buildings.logic.AddNewBuildingInteractor
import com.fireavert.units.logic.models.UnitModel

class AddNewBuildingController(
    private val interactor: AddNewBuildingInteractor
) {
    suspend fun saveNewBuilding(buildingName: String, unitIds: List<String>, propertyId: Int, allUnits: List<UnitModel>): Boolean {
        return interactor.saveNewBuilding(buildingName, unitIds, propertyId, allUnits)
    }
    suspend fun getBuildingsForProperty(propertyId: Int, allUnits: List<UnitModel>) {
        interactor.getBuildingsForProperty(propertyId, allUnits)
    }
}