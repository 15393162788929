package com.fireavert.components.dialogs

import com.fireavert.components.common.WarningIcon
import com.fireavert.logging.Logger
import com.fireavert.properties.presentation.delete_property_dialog.DeletePropertyDialogViewModel
import com.fireavert.styles.DialogStyles
import com.fireavert.units.presentation.DeleteUnitDialogController
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.dom.html.ReactHTML.div
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.NamedColor
import web.cssom.px

val DeleteUnitDialog = FC {
    val propertyId = useParams()["propertyId"]?.toInt() ?: 0
    val unitId = useParams()["unitId"]?.toInt() ?: 0

    val controller = getKoinInstance<DeleteUnitDialogController>()
    val viewModel = getKoinInstance<DeletePropertyDialogViewModel>()
    val logger = getKoinInstance<Logger>()

    var isDeleting: Boolean by useState(false)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isDeleting = viewState.isDeleting
        }.launchIn(viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = {
            controller.clickedCancel(propertyId)
        }
        onClose = { _, _ ->
            controller.clickedCancel(propertyId)
        }
        maxWidth = Breakpoint.lg

        if (isDeleting) {
            DialogContent {
                className = DialogStyles.warningDialogContent
                CircularProgress {}
            }
        } else {
            DialogContent {
                className = DialogStyles.warningDialogContent
                WarningIcon {}
                div {
                    className = DialogStyles.warningTitle
                    +"Warning"
                }
                div {
                    className = DialogStyles.warningText
                    +"You are about to delete a unit!"
                }
                div {
                    className = DialogStyles.deleteWarningBold
                    +"Are you sure you want to proceed?"
                }
                DialogActions {
                    css {
                        height = 94.px
                        width = 500.px
                        padding = 24.px
                    }
                    Button {
                        +"No, keep unit"
                        color = ButtonColor.inherit
                        onClick = {controller.clickedCancel(propertyId)}
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            marginRight = 6.px
                            backgroundColor = NamedColor.white
                        }
                    }
                    Button {
                        +"Yes, proceed to delete"
                        color = ButtonColor.inherit
                        onClick = {
                            viewModel.scope.launch {
                                controller.clickedDeleteUnit(unitId, propertyId)
                            }
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            backgroundColor = NamedColor.white
                        }
                    }
                }
            }
        }
    }


}