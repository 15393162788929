package com.fireavert.components.auth

import com.fireavert.auth.interface_adapters.AuthController
import com.fireavert.auth.interface_adapters.AuthViewModel
import com.fireavert.components.common.NotLoggedIn
import com.fireavert.styles.AuthStyles
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.*
import react.FC
import react.ReactNode
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.useEffectOnceWithCleanup
import react.useState
import web.html.ButtonType
import web.html.HTMLInputElement
import web.html.InputType

val Auth = FC {
    NotLoggedIn {}

    val controller = getKoinInstance<AuthController>()
    val viewModel = getKoinInstance<AuthViewModel>()

    var viewStateJob: Job?

    var errorState: Throwable? by useState(null)

    useEffectOnceWithCleanup {
        viewStateJob = viewModel.authViewState.onEach { viewState ->
            if (viewState.errorState.error) {
                errorState = Exception(viewState.errorState.message)
            }
        }.launchIn(viewModel.scope)

        controller.didLoad()

        onCleanup {
            viewStateJob?.cancel()
        }
    }


    val submitFunction = {
        controller.clickedSignIn(
            email = viewModel.authViewState.value.email, password = viewModel.authViewState.value.password
        )
    }


    form {
        className = AuthStyles.loginForm
        noValidate = true
        onSubmit = {
            it.preventDefault()
            submitFunction()
        }

        div {
            className = AuthStyles.welcomeTitle
            +"Welcome!"
        }

        div {
            className = AuthStyles.formBody
            img {
                alt = "FireAvert Logo"
                src = "/static/logo.svg"
                className = AuthStyles.logo
            }
            div {
                className = AuthStyles.controlFields

                TextField {
                    variant = FormControlVariant.outlined
                    margin = FormControlMargin.normal
                    required = false
                    fullWidth = true
                    id = "email"
                    label = ReactNode("Email")
                    name = "email"
                    autoComplete = "email"
                    autoFocus = true
                    error = viewModel.authViewState.value.errorState.error
                    onChange = { controller.emailChanged((it.target as? HTMLInputElement)?.value ?: "") }
                    className = AuthStyles.textField
                }

                TextField {
                    variant = FormControlVariant.outlined
                    margin = FormControlMargin.normal
                    required = false
                    fullWidth = true
                    id = "password"
                    label = ReactNode("Password")
                    name = "password"
                    autoComplete = "current-password"
                    type = InputType.password
                    error = viewModel.authViewState.value.errorState.error
                    onChange = { controller.passwordChanged((it.target as? HTMLInputElement)?.value ?: "") }
                    className = AuthStyles.textField
                }

                div {
                    className = AuthStyles.linkWrappingDiv
                    a {
                        className = AuthStyles.forgotPasswordLink
                        href = "/app/reset_password"
                        +"Forgot Password"
                    }
                }

                Button {
                    variant = ButtonVariant.contained
                    color = ButtonColor.primary
                    fullWidth = true
                    type = ButtonType.submit
                    className = AuthStyles.loginButton
                    +"Login"
                }
            }
        }
    }
}