package com.fireavert.components.dialogs.units

import com.fireavert.components.common.WarningIcon
import com.fireavert.properties.presentation.delete_property_dialog.DeletePropertyDialogViewModel
import com.fireavert.styles.DialogStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.Props
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.NamedColor
import web.cssom.px

external interface DeleteUnitHistoryDialogProps : Props {
    var unitName: String
    var unitId: Int
    var onClose: () -> Unit
    var onClearEventHistory: (Int) -> Unit
}

val DeleteUnitHistoryDialog = FC<DeleteUnitHistoryDialogProps> { props ->

    val viewModel = getKoinInstance<DeletePropertyDialogViewModel>()

    var isDeleting: Boolean by useState(false)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isDeleting = viewState.isDeleting
        }.launchIn(viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = {
            props.onClose()
        }
        onClose = { _, _ ->
            props.onClose()
        }
        maxWidth = Breakpoint.lg

        if (isDeleting) {
            DialogContent {
                className = DialogStyles.warningDialogContent
                CircularProgress {}
            }
        } else {
            DialogContent {
                className = DialogStyles.warningDialogContent
                WarningIcon {}
                div {
                    className = DialogStyles.warningTitle
                    +"Warning"
                }
                div {
                    className = DialogStyles.warningText
                    +"You are about to delete the history for unit ${props.unitName}!"
                }
                div {
                    className = DialogStyles.deleteWarningBold
                    +"This data will be permanently removed from the database."
                    br{}
                    + "Are you sure you want to proceed?"
                }
                DialogActions {
                    css {
                        height = 94.px
                        width = 500.px
                        padding = 24.px
                    }
                    Button {
                        +"No, keep history"
                        color = ButtonColor.inherit
                        onClick = {props.onClose()}
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            marginRight = 6.px
                            backgroundColor = NamedColor.white
                        }
                    }
                    Button {
                        +"Yes, proceed to delete"
                        color = ButtonColor.inherit
                        onClick = {
                            props.onClearEventHistory(props.unitId)
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            backgroundColor = NamedColor.white
                        }
                    }
                }
            }
        }
    }


}