package com.fireavert.devices.logic.models

data class UnitDetailsDeviceDataState(
    val id: Int,
    val type: DeviceType,
    val location: String,
    val deviceId: String,
    val connectionStatus: String,
    val connectionStatusLevel: StatusLevel,
    val deviceStatus: String,
    val deviceStatusLevel: StatusLevel,
    val deviceIsTriggered: Boolean,
    val isPoweredOn: PowerStatus,
    val installed: Boolean,
)