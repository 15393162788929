package com.fireavert.properties.logic.models.response

import com.fireavert.properties.logic.models.Mode
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.logic.models.PropertyType
import com.fireavert.properties.logic.models.SubscriptionType
import kotlinx.serialization.Serializable

@Serializable
data class PropertyResponse(
    val id: Int? = null,
    val name: String? = null,
    val address: String? = null,
    val units: Int? = null,
    val city: String? = null,
    val state: String? = null,
    val zip: String? = null,
    val managementCompany: String? = null,
    val subscriptionType: SubscriptionType? = null,
    val mode: Mode? = null,
    val propertyType: PropertyType = PropertyType.MultiFamily,
    val timezoneId: String? = null,
) {
    companion object {
        fun fromProperty(property: Property): PropertyResponse {
            return PropertyResponse(
                id = property.id,
                name = property.name,
                units = property.units,
                address = property.address,
                city = property.city,
                state = property.state,
                zip = property.zip,
                managementCompany = property.managementCompany,
                subscriptionType = property.subscriptionType,
                mode = property.mode,
                propertyType = property.propertyType,
                timezoneId = property.timezoneId,
            )
        }
    }
}
