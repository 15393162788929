package com.fireavert.components.administration

import com.fireavert.administration_page.users.interface_adapters.user_section.UserSectionController
import com.fireavert.administration_page.users.interface_adapters.user_section.UserSectionViewModel
import com.fireavert.administration_page.users.logic.UserTrackingRecord
import com.fireavert.common.TableColumn
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.shared.*
import com.fireavert.components.dialogs.UserMobileDialog
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.menu.paths.AdministrationPagePaths
import com.fireavert.styles.AdministrationPageStyle
import com.fireavert.styles.LoadingCircleStyle
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.SearchbarStyles
import com.fireavert.styles.TableStyles
import com.fireavert.user.logic.Permission
import com.fireavert.user.logic.models.RolesModel
import com.fireavert.utilities.getKoinInstance
import js.objects.jso
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.sx
import mui.system.useMediaQuery
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.dom.onChange
import react.router.useLocation
import react.router.useNavigate
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.NamedColor
import web.cssom.px
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface UserSectionProps : Props {
    var isUserDialogOpen: Boolean
}

val UserSection = FC<UserSectionProps> { props ->
    val controller = getKoinInstance<UserSectionController>()
    val viewModel = getKoinInstance<UserSectionViewModel>()
    var isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    val navigate = useNavigate()
    val logger = getKoinInstance<Logger>()

    val location = useLocation()
    val pathSegments = location.pathname.split("/")
    val roleFromUrl = if (pathSegments.size > 4) pathSegments[4] else "all"

    // Map URL role type to EventTypes
    val initialRoleList = when (roleFromUrl) {
        "executive" -> listOf(RolesModel.EXECUTIVE.displayText)
        "property" -> listOf(RolesModel.PROPERTY_ADMIN.displayText)
        "maintenance" -> listOf(RolesModel.MAINTENANCE_USER.displayText)
        "tenant" -> listOf(RolesModel.TENANT.displayText)
        else -> emptyList()
    }

    // Initialize selectedUserList with the URL role
    var selectedUserList by useState(initialRoleList)

    val eventItems = listOf(
        jso<DropDownItem> {
            value = RolesModel.TENANT.displayText
            label = "Tenants"
        },
        jso<DropDownItem> {
            value = RolesModel.MAINTENANCE_USER.displayText
            label = "Maintenance User"
        },
        jso<DropDownItem> {
            value = RolesModel.PROPERTY_ADMIN.displayText
            label = "Property Admins"
        },
        jso<DropDownItem> {
            value = RolesModel.EXECUTIVE.displayText
            label = "Executive"
        }
    )

    val (searchValue, setSearchValue) = useState("")

    if (props.isUserDialogOpen) {
        UserMobileDialog {}
    }

    Breadcrumbs {
        ariaLabel = "breadcrumb"

        Link {
            underline = LinkUnderline.hover
            color = "inherit"
            href = RoutePaths.administration

            +"Administration"
        }
        Typography {
           sx {
                color = NamedColor.black
           }
            +"Users"
        }
    }

    PageHeader {
        pageTitle = "Users"
        deleteMode = false
        linkCalls = listOf(
            object : HeaderLink {
                override var text = "Add User"
                override var permissionNeeded = Permission.CreateUsers
                override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    navigate(AdministrationPagePaths.newUser)
                }
                override var isActive = true
            }
        )
    }

    div {
        className = AdministrationPageStyle.twoBoxSpace
        div {
            className = SearchbarStyles.searchBarNew
            TextField {
                sx {
                    height = 25.px
                    marginBottom = 25.px
                }
                className = SearchbarStyles.searchBarInput(20)
                placeholder = "Search..."
                value = searchValue
                onChange = { event ->
                    val target = event.target as? HTMLInputElement
                    setSearchValue(target?.value ?: "")
                }
            }
        }

        PermissionRequired {
            permission = Permission.ViewSuperAdmins
            div {
                DropDownSelector {
                    id = "user-type-selector"
                    label = "User Type Filter"
                    items = eventItems
                    isFullWidth = false
                    selectedValues = selectedUserList
                    onSelectionChange = { newSelection ->
                        selectedUserList = newSelection
                    }
                    this.isMobile = isMobile
                }
            }
        }
    }

    var desktopColumns: Array<TableColumn> by useState(arrayOf(
        TableColumn(
            text = "Name",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
        TableColumn(
            text = "Email",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
        TableColumn(
            text = "Company",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
    ))

    var mobileColumns: Array<TableColumn> by useState(arrayOf(
        TableColumn(
            text = "NAME",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
        TableColumn(
            text = "MANAGEMENT COMPANY",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        )
    ))

    var loading: Boolean by useState(false)
    var trackingRecords: Array<UserTrackingRecord> by useState(emptyArray())

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
            trackingRecords = viewState.userTrackingRecords.toTypedArray()
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad()
        }
        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }
    div {
        className = TableStyles.tableContainerNew
        if (loading) {
            div {
                className = LoadingCircleStyle.loadingCircleAdminPage
                span {
                    CircularProgress {
                        color = CircularProgressColor.info
                        size = 150.px
                    }
                }
            }
        } else {
            div {
                className = TableStyles.tableNew
                SortBar {
                    this.columns = if (isMobile) mapToControlColumns(mobileColumns) else mapToControlColumns(desktopColumns)
                    includeLoading = false
                    isLoading = loading
                    isNewView = true
                    columnsChanged = {
                        desktopColumns = mapToStateColumns(it)
                    }
                }

                val userTrackingRecords =
                    controller.sortTrackingRecordsByColumn(
                        desktopColumns,
                        trackingRecords.toList(),
                        searchValue,
                        selectedUserList
                    )
                for (userTrackingRecord in userTrackingRecords) {
                    div {
                        className = TableStyles.tableRowNew
                        onClick = {
                            navigate(RoutePaths.administration + "/user/${userTrackingRecord.id}")
                        }
                        div {
                            className = TableStyles.tableCell
                            +userTrackingRecord.name
                        }
                        if (!isMobile) {
                            div {
                                className = TableStyles.tableCell
                                +userTrackingRecord.email
                            }
                        }
                        div {
                            className = TableStyles.tableCell
                            +userTrackingRecord.managementCompany
                        }
                        div {
                            className = TableStyles.tableCellCentered
                            TableArrow {}
                        }
                    }
                }
            }
        }
    }
}

