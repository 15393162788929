package com.fireavert.user.logic

import com.fireavert.user.logic.models.RolesModel

data class Role(
    val id: Int,
    val name: RolesModel,
    val permissions: List<Permission>,
    val pageVersions: Map<PageName,Int>
) {
    // Check if this role has a specific permission
    fun hasPermission(permissionKey: String): Boolean {
        return permissions.any { it.key == permissionKey }
    }

    // Check if this role has at least the level of another role
    fun hasAtLeastLevelOf(otherRoleName: RolesModel): Boolean {
        return name.hasAtLeastLevelOf(otherRoleName)
    }

    fun hasRoleLevelBelow(otherRoleName: RolesModel): Boolean {
        return name.hasRoleLevelBelow(otherRoleName)
    }
}