package com.fireavert.styles

import emotion.css.ClassName
import react.dom.html.ReactHTML.a
import web.cssom.*

object UserPagesStyles {

    val userLoginCountBoxes = ClassName {
        padding = 20.px
        backgroundColor = NamedColor.white
        borderRadius = 25.px
        width = 100.pct
        display = Display.flex
        flexDirection = FlexDirection.row
    }

    val userRoleStyle = ClassName {
        fontSize = 22.px
        color = NamedColor.black
        fontWeight = integer(600)
        marginBottom = 10.px
        marginTop = (-10).px
    }

    val userContactInfo = ClassName {
        marginBottom = 10.px
        a {
            color = Color("#939598")
            textDecoration = TextDecoration.underline
        }
        fontSize = 15.px
    }

    val userAccordionTitle = ClassName {
        fontSize = 20.px
        color = NamedColor.black
        fontWeight = integer(600)
        marginBottom = 10.px
        marginTop = 10.px
    }

    val userNotificationManagerMobile = ClassName {
        media(MediaQuery("(max-width: 640px)")) {
            display = Display.grid
            gridTemplateColumns = repeat(
                AutoRepeat.autoFit,
                minmax("250px".unsafeCast<GridAutoTracks>(), "1fr".unsafeCast<GridAutoTracks>())
            )
            gap = 16.px
        }
    }

    val userNotificationManagerSection = ClassName {
        marginTop = 24.px
        padding = 16.px
        backgroundColor = NamedColor.white
        borderRadius = 8.px
        boxShadow = BoxShadow(0.px, 2.px, 4.px, Color("rgba(0, 0, 0, 0.1)"))
    }

    val userNotificationManagerHeader = ClassName {
        fontSize = 18.px
        fontWeight = integer(500)
        marginBottom = 16.px
        color = Color("#525F7F")
    }
}