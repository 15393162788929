package com.fireavert.components.administration

import com.fireavert.properties.logic.models.UserLinkedToProperty
import com.fireavert.styles.StandardizedStyles
import com.fireavert.styles.UserPagesStyles
import emotion.css.ClassName
import emotion.css.cx
import mui.material.*
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import web.cssom.*

external interface UserNotificationManagerProps: Props {
    var propertyNotificationList: List<UserLinkedToProperty>
    var onEdit: (List<UserLinkedToProperty>) -> Unit
    var notificationError: String
}

val UserNotificationManager = FC<UserNotificationManagerProps> { props ->
    var accordionStates: Map<Int, Boolean> by useState(emptyMap())
    var editMode: Boolean by useState(true)

    var notificationSettings by useState<Map<Int, UserLinkedToProperty>>(
        props.propertyNotificationList.associateBy { it.propertyId }
    )

    useEffect(props.propertyNotificationList) {
        notificationSettings = props.propertyNotificationList.associateBy { it.propertyId }
    }

    fun updateNotification(propertyId: Int, updateFn: (UserLinkedToProperty) -> UserLinkedToProperty) {
        notificationSettings = notificationSettings.mapValues { (id, property) ->
            if (id == propertyId) updateFn(property) else property
        }
    }

    div {
        className = ClassName {
            width = 100.pct
            margin = Margin(0.px, Auto.auto)
        }

        // Header with Title and Edit Button
        div {
            className = ClassName {
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                marginBottom = 24.px

                media(MediaQuery("(max-width: 640px)")) {
                    flexDirection = FlexDirection.column
                    gap = 16.px
                }
            }

            div {
                className = StandardizedStyles.HeaderH2
                +"Manage Alerts"
            }

            Button {
                sx {
                    backgroundColor = if (editMode) NamedColor.black else null
                    color = if (editMode) NamedColor.white else null
                }
                variant = ButtonVariant.contained
                onClick = {
                    if (!editMode) {
                        props.onEdit(notificationSettings.values.toList())
                    }
                    editMode = !editMode
                }
                +(if (editMode) "Edit Notifications" else "Save")
            }
        }

        // Error Message
        if (props.notificationError.isNotEmpty()) {
            div {
                className = ClassName {
                    color = NamedColor.red
                    fontSize = 16.px
                    marginTop = 16.px
                }
                +props.notificationError
            }
        }

        // Properties List
        notificationSettings.values.forEach { property ->
            Accordion {
                sx {
                    marginTop = 16.px
                    borderRadius = 8.px
                    boxShadow = BoxShadow(0.px, 2.px, 4.px, Color("rgba(0, 0, 0, 0.1)"))

                    "&.MuiAccordion-root:last-of-type" {
                        borderRadius = 8.px
                    }
                    "&" {
                        paddingTop = 10.px
                        paddingBottom = 10.px
                        borderRadius = important(8.px)
                    }
                }
                expanded = accordionStates[property.propertyId] == true
                onChange = { _, _ ->
                    accordionStates = accordionStates.toMutableMap().apply {
                        put(property.propertyId, !(accordionStates[property.propertyId] ?: false))
                    }
                }

                AccordionSummary {
                    div {
                        className = ClassName {
                            fontSize = 18.px
                            fontWeight = integer(600)
                        }
                        +property.propertyName
                    }
                }

                AccordionDetails {
                    sx {
                        padding = 24.px
                        "&.MuiAccordionDetails-root" {
                            borderTop = Border(1.px, LineStyle.solid, Color("#edeff2"))
                        }
                    }

                    // Text Alerts Section
                    div {
                        className = UserPagesStyles.userNotificationManagerSection
                        h3 {
                            className = UserPagesStyles.userNotificationManagerHeader
                            +"Text Alerts"
                        }
                        div {
                            className = UserPagesStyles.userNotificationManagerMobile

                            // Stove
                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.stoveTextNotifications
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { stoveTextNotifications = !stoveTextNotifications }
                                        }
                                    }
                                }
                                label = ReactNode("Stove")
                            }

                            // Smoke
                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.smokeAlarmTextNotifications
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { smokeAlarmTextNotifications = !smokeAlarmTextNotifications }
                                        }
                                    }
                                }
                                label = ReactNode("Smoke")
                            }

                            // Leak
                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.leakTextNotifications
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { leakTextNotifications = !leakTextNotifications }
                                        }
                                    }
                                }
                                label = ReactNode("Leak")
                            }

                            // Tamper
                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.tamperTextNotifications
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { tamperTextNotifications = !tamperTextNotifications }
                                        }
                                    }
                                }
                                label = ReactNode("Tamper")
                            }
                        }
                    }

                    // Email Alerts Section
                    div {
                        className = UserPagesStyles.userNotificationManagerSection
                        h3 {
                            className = UserPagesStyles.userNotificationManagerHeader
                            +"Email Alerts"
                        }
                        div {
                            className = UserPagesStyles.userNotificationManagerMobile

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.stoveEmailNotifications
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { stoveEmailNotifications = !stoveEmailNotifications }
                                        }
                                    }
                                }
                                label = ReactNode("Stove")
                            }

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.smokeAlarmEmailNotifications
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { smokeAlarmEmailNotifications = !smokeAlarmEmailNotifications }
                                        }
                                    }
                                }
                                label = ReactNode("Smoke")
                            }

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.leakEmailNotifications
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { leakEmailNotifications = !leakEmailNotifications }
                                        }
                                    }
                                }
                                label = ReactNode("Leak")
                            }

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.tamperEmailNotifications
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { tamperEmailNotifications = !tamperEmailNotifications }
                                        }
                                    }
                                }
                                label = ReactNode("Tamper")
                            }
                        }
                    }

                    // Email Reports Section
                    div {
                        className = cx(UserPagesStyles.userNotificationManagerSection, ClassName {
                            borderTop = Border(1.px, LineStyle.solid, Color("#edeff2"))
                            marginTop = 24.px
                            paddingTop = 24.px
                        })

                        h3 {
                            className = UserPagesStyles.userNotificationManagerHeader
                            +"Email Reports"
                        }

                        div {
                            className = UserPagesStyles.userNotificationManagerMobile

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.dailyReports
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { dailyReports = !dailyReports }
                                        }
                                    }
                                }
                                label = ReactNode("Daily")
                            }

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.weeklyReports
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { weeklyReports = !weeklyReports }
                                        }
                                    }
                                }
                                label = ReactNode("Weekly")
                            }

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.monthlyReports
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { monthlyReports = !monthlyReports }
                                        }
                                    }
                                }
                                label = ReactNode("Monthly")
                            }

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.quarterlyReports
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { quarterlyReports = !quarterlyReports }
                                        }
                                    }
                                }
                                label = ReactNode("Quarterly")
                            }

                            FormControlLabel {
                                control = Checkbox.create {
                                    color = CheckboxColor.info
                                    checked = property.yearlyReports
                                    disabled = editMode
                                    onChange = { _, _ ->
                                        updateNotification(property.propertyId) { p ->
                                            p.copy().apply { yearlyReports = !yearlyReports }
                                        }
                                    }
                                }
                                label = ReactNode("Yearly")
                            }
                        }
                    }
                }
            }
        }
    }
}