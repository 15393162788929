package com.fireavert.properties.presentation

import com.fireavert.gateways.GatewayPropertyInteractor
import com.fireavert.gateways.models.GatewayModel

class GatewayPropertyController(
    private val interactor: GatewayPropertyInteractor
) {
    suspend fun onLoad(propertyId: Int) { interactor.onLoad(propertyId) }
    suspend fun refreshGatewayStatus(propertyId: Int) { interactor.refreshGatewayStatus(propertyId) }
    suspend fun saveAssociatedGateways(newGateways: List<GatewayModel>, propertyId: Int) {
        interactor.saveAssociatedGateways(newGateways, propertyId)
    }
    suspend fun loadGatewayDeviceData(propertyId: String) = interactor.loadGatewayDeviceData(propertyId)

}