package com.fireavert

import com.fireavert.administration_page.main_page.AdminPageRepositoryDataSourceImpl
import com.fireavert.administration_page.main_page.interface_adaptors.AdministrationPageRepositoryImpl
import com.fireavert.administration_page.main_page.interface_adaptors.AdministrationPageScreen
import com.fireavert.administration_page.main_page.interface_adaptors.AdministrationPageViewModel
import com.fireavert.administration_page.main_page.logic.AdminPageRepositoryDataSource
import com.fireavert.administration_page.main_page.logic.AdministrationPageController
import com.fireavert.administration_page.main_page.logic.AdministrationPageInteractor
import com.fireavert.administration_page.main_page.logic.AdministrationPageRepository
import com.fireavert.administration_page.shared.interface_adaptors.RoutedAdminNavigator
import com.fireavert.administration_page.shared.logic.AdminNavigator
import com.fireavert.administration_page.shared.presentation.AdminRouter
import com.fireavert.administration_page.users.frameworks.UserDialogRepositoryDataSourceImpl
import com.fireavert.administration_page.users.frameworks.UserInfoRepositoryDataSourceImpl
import com.fireavert.administration_page.users.frameworks.UserSectionRepositoryDataSourceImpl
import com.fireavert.administration_page.users.interface_adapters.add_user_dialog.AddUserController
import com.fireavert.administration_page.users.interface_adapters.add_user_dialog.AddUserViewModel
import com.fireavert.administration_page.users.interface_adapters.add_user_dialog.UserDialogRepositoryImpl
import com.fireavert.administration_page.users.interface_adapters.user_info.*
import com.fireavert.administration_page.users.interface_adapters.user_section.UserSectionController
import com.fireavert.administration_page.users.interface_adapters.user_section.UserSectionRepositoryDataSource
import com.fireavert.administration_page.users.interface_adapters.user_section.UserSectionRepositoryImpl
import com.fireavert.administration_page.users.interface_adapters.user_section.UserSectionViewModel
import com.fireavert.administration_page.users.logic.UserSectionInteractor
import com.fireavert.administration_page.users.logic.UserSectionRepository
import com.fireavert.administration_page.users.logic.add_user_dialog.AddUserDialogScreen
import com.fireavert.administration_page.users.logic.add_user_dialog.AddUserInteractor
import com.fireavert.administration_page.users.logic.add_user_dialog.UserDialogRepository
import com.fireavert.administration_page.users.logic.user_info.UserInfoInteractor
import com.fireavert.administration_page.users.logic.user_info.UserInfoRepository
import com.fireavert.administration_page.users.logic.user_info.UserInfoScreen
import com.fireavert.administration_page.users.logic.user_section.UserSectionScreen
import com.fireavert.administration_page.wireless_id_finder.frameworks.WirelessIdRepositoryDataSourceImpl
import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdRepositoryDataSource
import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdRepositoryImpl
import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdSectionController
import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdSectionViewModel
import com.fireavert.administration_page.wireless_id_finder.logic.WirelessIdRepository
import com.fireavert.administration_page.wireless_id_finder.logic.WirelessIdSectionInteractor
import com.fireavert.administration_page.wireless_id_finder.logic.WirelessIdSectionScreen
import com.fireavert.app.external.ClientAnalyticsDataSourceImpl
import com.fireavert.app.interface_adapters.AppController
import com.fireavert.app.interface_adapters.AppViewModel
import com.fireavert.app.interface_adapters.ClientAnalyticsDataSource
import com.fireavert.app.interface_adapters.ClientAnalyticsRepositoryImpl
import com.fireavert.app.logic.AnalyticsUseCase
import com.fireavert.app.logic.ClientAnalyticsRepository
import com.fireavert.auth.external.HttpPasswordSenderSource
import com.fireavert.auth.interface_adapters.*
import com.fireavert.auth.logic.*
import com.fireavert.buildings.frameworks.ClientBuildingRepositoryDataSourceImpl
import com.fireavert.buildings.interface_adapters.AddNewBuildingViewModel
import com.fireavert.buildings.interface_adapters.ClientBuildingRepositoryDataSource
import com.fireavert.buildings.interface_adapters.ClientBuildingRepositoryImpl
import com.fireavert.buildings.logic.AddNewBuildingInteractor
import com.fireavert.buildings.logic.AddNewBuildingScreen
import com.fireavert.buildings.logic.ClientBuildingRepository
import com.fireavert.buildings.presentation.AddNewBuildingController
import com.fireavert.common.InMemoryCache
import com.fireavert.dashboard.adapters.ApiDashboardRepository
import com.fireavert.dashboard.adapters.DashboardController
import com.fireavert.dashboard.adapters.DashboardRepositoryDataSource
import com.fireavert.dashboard.adapters.DashboardViewModel
import com.fireavert.dashboard.external.ClientDashboardRepositoryDataSource
import com.fireavert.dashboard.logic.DashboardInteractor
import com.fireavert.dashboard.logic.DashboardRepository
import com.fireavert.dashboard.logic.DashboardScreen
import com.fireavert.devices.external.ClientDeviceDataSource
import com.fireavert.devices.interface_adapters.ApiDeviceRepository
import com.fireavert.devices.interface_adapters.DeviceDataSource
import com.fireavert.devices.interface_adapters.DevicesRouter
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.events.activedeviceevents.interface_adapters.ActiveDeviceEventsController
import com.fireavert.events.activedeviceevents.interface_adapters.ActiveDeviceEventsViewModel
import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEvents
import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEventsScreen
import com.fireavert.events.activedeviceevents.logic.ClientActiveDeviceEvents
import com.fireavert.events.activeevents.api.ActiveEventsDataSource
import com.fireavert.events.activeevents.api.ApiActiveEventsRepository
import com.fireavert.events.activeevents.data.ClientActiveEventsDataSource
import com.fireavert.events.activeevents.logic.*
import com.fireavert.events.activeevents.presentation.ActiveEventsController
import com.fireavert.events.activeevents.presentation.ActiveEventsV2Controller
import com.fireavert.events.activeevents.presentation.ActiveEventsV2ViewModel
import com.fireavert.events.activeevents.presentation.ActiveEventsViewModel
import com.fireavert.events.common.api.ApiEventsRepository
import com.fireavert.events.common.api.EventsDataSource
import com.fireavert.events.common.data.ClientEventsDataSource
import com.fireavert.events.common.logic.EventsNavigator
import com.fireavert.events.common.logic.EventsRepository
import com.fireavert.events.dismissevent.logic.ClientDismissEvent
import com.fireavert.events.dismissevent.logic.DismissEvent
import com.fireavert.events.dismissevent.logic.DismissEventDialogScreen
import com.fireavert.events.dismissevent.presentation.DismissEventDialogController
import com.fireavert.events.dismissevent.presentation.DismissEventDialogViewModel
import com.fireavert.events.markastest.logic.ClientMarkAsTest
import com.fireavert.events.markastest.logic.MarkAsTest
import com.fireavert.events.markastest.logic.MarkAsTestDialogScreen
import com.fireavert.events.markastest.presentation.MarkAsTestDialogController
import com.fireavert.events.markastest.presentation.MarkAsTestDialogViewModel
import com.fireavert.events.presentation.EventsRouter
import com.fireavert.events.presentation.RoutedEventsNavigator
import com.fireavert.events.rebootdevice.logic.ClientRebootDevice
import com.fireavert.events.rebootdevice.logic.RebootDevice
import com.fireavert.events.rebootdevice.logic.RebootDeviceDialogScreen
import com.fireavert.events.rebootdevice.presentation.RebootDeviceDialogController
import com.fireavert.events.rebootdevice.presentation.RebootDeviceDialogViewModel
import com.fireavert.gateways.GatewayDialogInteractor
import com.fireavert.gateways.GatewayPropertyInteractor
import com.fireavert.gateways.frameworks.GatewayPropertyDataSource
import com.fireavert.gateways.frameworks.GatewayPropertyDataSourceImpl
import com.fireavert.gateways.interface_adaptors.GatewayDialogViewModel
import com.fireavert.gateways.interface_adaptors.GatewayPropertyRepository
import com.fireavert.gateways.interface_adaptors.GatewayPropertyRepositoryImpl
import com.fireavert.gateways.interface_adaptors.GatewayPropertyViewModel
import com.fireavert.gateways.logic.GatewayDialogScreen
import com.fireavert.gateways.logic.GatewayPropertyScreen
import com.fireavert.gateways.presentation.GatewayDialogController
import com.fireavert.info.logic.InfoPage
import com.fireavert.info.logic.InfoPageImpl
import com.fireavert.info.logic.InfoPageScreen
import com.fireavert.info.presentation.InfoPageController
import com.fireavert.info.presentation.InfoPageViewModel
import com.fireavert.invite.interface_adapters.InviteController
import com.fireavert.invite.interface_adapters.InviteViewModel
import com.fireavert.invite.logic.Invite
import com.fireavert.invite.logic.InviteScreen
import com.fireavert.jobs.external.CommonClientJobDataSource
import com.fireavert.jobs.interface_adapters.ClientJobDataSource
import com.fireavert.jobs.interface_adapters.CommonClientJobRepository
import com.fireavert.jobs.logic.ClientJobRepository
import com.fireavert.logging.Logger
import com.fireavert.logging.NapierLogger
import com.fireavert.management_companies.framework.ManagementCompanyDataSource
import com.fireavert.management_companies.framework.ManagementCompanyDataSourceImpl
import com.fireavert.management_companies.interface_adaptors.ManagementCompanyRepositoryImpl
import com.fireavert.management_companies.logic.ManagementCompanyInteractor
import com.fireavert.management_companies.logic.ManagementCompanyRepository
import com.fireavert.management_companies.logic.ManagementCompanyScreen
import com.fireavert.management_companies.presentation.ManagementCompaniesViewModel
import com.fireavert.management_companies.presentation.ManagementCompanyController
import com.fireavert.menu.NavRouter
import com.fireavert.menu.api.ApiMenuNotificationRepository
import com.fireavert.menu.api.MenuDataSource
import com.fireavert.menu.data.RemoteMenuDataSource
import com.fireavert.menu.logic.MenuInteractor
import com.fireavert.menu.logic.MenuNavigator
import com.fireavert.menu.logic.MenuNotificationRepository
import com.fireavert.menu.logic.MenuScreen
import com.fireavert.menu.presentation.MenuController
import com.fireavert.menu.presentation.MenuRouter
import com.fireavert.menu.presentation.MenuViewModel
import com.fireavert.menu.presentation.RoutedMenuNavigator
import com.fireavert.preferences.logic.Preferences
import com.fireavert.preferences.repository.KeyValuePreferences
import com.fireavert.properties.api.ApiPropertyRepository
import com.fireavert.properties.api.PropertyDataSource
import com.fireavert.properties.data.ClientPropertyDataSource
import com.fireavert.properties.logic.AddRemovePropertyAdminValidator
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.properties.logic.PropertyNavigator
import com.fireavert.properties.logic.add_new_property.AddNewProperty
import com.fireavert.properties.logic.add_new_property.AddNewPropertyScreen
import com.fireavert.properties.logic.add_new_property.ClientAddNewProperty
import com.fireavert.properties.logic.comm_v3_conversion_dialog.CommV2Conversion
import com.fireavert.properties.logic.comm_v3_conversion_dialog.CommV2ConversionScreen
import com.fireavert.properties.logic.delete_property.ClientDeleteProperty
import com.fireavert.properties.logic.delete_property.DeleteProperty
import com.fireavert.properties.logic.delete_property.DeletePropertyDialogScreen
import com.fireavert.properties.logic.edit_property.ClientEditProperty
import com.fireavert.properties.logic.edit_property.EditProperty
import com.fireavert.properties.logic.edit_property.EditPropertyScreen
import com.fireavert.properties.logic.property_admin.ClientDeletePropertyAdmin
import com.fireavert.properties.logic.property_admin.DeletePropertyAdmin
import com.fireavert.properties.logic.property_admin.DeletePropertyAdminDialogScreen
import com.fireavert.properties.logic.property_details.ClientPropertyDetails
import com.fireavert.properties.logic.property_details.PropertyDetails
import com.fireavert.properties.logic.property_details.PropertyDetailsScreen
import com.fireavert.properties.logic.property_portfolio.ClientPropertyPortfolio
import com.fireavert.properties.logic.property_portfolio.PropertyPortfolio
import com.fireavert.properties.logic.property_portfolio.PropertyPortfolioScreen
import com.fireavert.properties.presentation.AddRemovePropertyAdminRowController
import com.fireavert.properties.presentation.GatewayPropertyController
import com.fireavert.properties.presentation.PropertyRouter
import com.fireavert.properties.presentation.RoutedPropertyNavigator
import com.fireavert.properties.presentation.add_new_property.AddNewPropertyController
import com.fireavert.properties.presentation.add_new_property.AddNewPropertyViewModel
import com.fireavert.properties.presentation.comm_v2_conversion_dialog.CommV2ConversionDialogController
import com.fireavert.properties.presentation.comm_v2_conversion_dialog.CommV2ConversionDialogViewModel
import com.fireavert.properties.presentation.delete_property_admin_dialog.DeletePropertyAdminDialogController
import com.fireavert.properties.presentation.delete_property_admin_dialog.DeletePropertyAdminDialogViewModel
import com.fireavert.properties.presentation.delete_property_dialog.DeletePropertyDialogController
import com.fireavert.properties.presentation.delete_property_dialog.DeletePropertyDialogViewModel
import com.fireavert.properties.presentation.edit_property.EditPropertyController
import com.fireavert.properties.presentation.edit_property.EditPropertyViewModel
import com.fireavert.properties.presentation.property_details.PropertyDetailsController
import com.fireavert.properties.presentation.property_details.PropertyDetailsViewModel
import com.fireavert.properties.presentation.property_portfolio.PropertyPortfolioController
import com.fireavert.properties.presentation.property_portfolio.PropertyPortfolioViewModel
import com.fireavert.reboot.logic.Reboot
import com.fireavert.reboot.logic.RebootImpl
import com.fireavert.reboot.logic.RebootNavigator
import com.fireavert.reboot.logic.RebootScreen
import com.fireavert.reboot.presentation.RebootPageController
import com.fireavert.reboot.presentation.RebootRouter
import com.fireavert.reboot.presentation.RebootViewModel
import com.fireavert.reboot.presentation.RoutedRebootNavigator
import com.fireavert.reports_page.frameworks.ReportsPageRepositoryDataSourceImpl
import com.fireavert.reports_page.interface_adaptors.*
import com.fireavert.reports_page.logic.*
import com.fireavert.topnav.logic.ClientTopNav
import com.fireavert.topnav.logic.TopNav
import com.fireavert.topnav.logic.TopNavScreen
import com.fireavert.topnav.presentation.TopNavController
import com.fireavert.topnav.presentation.TopNavViewModel
import com.fireavert.units.api.ApiUnitsRepository
import com.fireavert.units.api.UnitsDataSource
import com.fireavert.units.data.ClientUnitsDataSource
import com.fireavert.units.logic.*
import com.fireavert.units.presentation.*
import com.fireavert.unsubscribe.adapters.ClientUnsubscribeSetter
import com.fireavert.unsubscribe.adapters.UnsubscribeController
import com.fireavert.unsubscribe.adapters.UnsubscribeSetterDataSource
import com.fireavert.unsubscribe.adapters.UnsubscribeViewModel
import com.fireavert.unsubscribe.data.APIUnsubscribeDataSource
import com.fireavert.unsubscribe.logic.UnsubscribeSetter
import com.fireavert.user.api.ClientUserRepository
import com.fireavert.user.api.UserDataCache
import com.fireavert.user.api.UserDataSource
import com.fireavert.user.api.UserPreferencesTokenRefreshService
import com.fireavert.user.data.ClientUserDataSource
import com.fireavert.user.data.InMemoryUserDataCache
import com.fireavert.user.logic.TokenRefreshService
import com.fireavert.user.logic.UserRepository
import com.russhwolf.settings.JsSettings
import com.russhwolf.settings.Settings
import io.github.aakira.napier.DebugAntilog
import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.serialization.json.Json
import org.koin.core.qualifier.named
import org.koin.dsl.bind
import org.koin.dsl.binds
import org.koin.dsl.module

val host = window.location.host
val protocol = window.location.protocol

object KoinModules {

    private val apiBaseUrl = "$protocol//$host/api"
    private val logging = module {
        single { NapierLogger(DebugAntilog()) } bind Logger::class
    }
    private val infoPageModule = module {
        single {
            InfoPageViewModel()
        } bind InfoPageScreen::class

        factory {
            InfoPageImpl(deviceRepository = get(), screen = get(), logger = get())
        } bind InfoPage::class

        factory {
            InfoPageController(interactor = get())
        }
    }
    private val rebootModule = module {
        single {
            RebootViewModel()
        } bind RebootScreen::class

        single {
            RoutedRebootNavigator(
                rebootRouter = get()
            )
        } bind RebootNavigator::class

        factory {
            RebootImpl(
                screen = get(),
                devicesRepository = get(),
                logger = get(),
                rebootNavigator = get()
            )
        } bind Reboot::class

        factory {
            RebootPageController(reboot = get())
        }
    }
    private val unitsModule = module {

        single {
            UnitDetailsViewModel()
        } bind UnitDetailsScreen::class
        factory {
            ClientDeleteUnit(
                unitsNavigator = get(),
                screen = get(),
                unitsRepository = get(),
                logger = get()
            )
        } bind DeleteUnit::class
        single {
            DeleteUnitDialogViewModel()
        } bind DeleteUnitDialogScreen::class
        factory {
            DeleteUnitDialogController(
                interactor = get()
            )
        }
        factory {
            ClientUnitDetails(
                screen = get(),
                navigator = get(),
                unitsRepository = get(),
                deviceRepository = get(),
                eventsRepository = get(),
                logger = get()
            )
        } bind UnitDetails::class
        factory {
            UnitDetailsController(
                interactor = get(),
                screen = get()
            )
        }
        single(named("add")) {
            AddOrEditUnitViewModel()
        } bind AddOrEditUnitScreen::class
        single(named("edit")) {
            AddOrEditUnitViewModel()
        } bind AddOrEditUnitScreen::class

        factory {
            ClientAddNewUnit(
                unitsNavigator = get(),
                screen = get(qualifier = named("add")),
                propertyRepository = get(),
                unitsRepository = get(),
                deviceRepository = get(),
                logger = get()
            )
        } bind AddNewUnit::class

        factory {
            ClientEditUnit(
                unitsNavigator = get(),
                screen = get(qualifier = named("edit")),
                unitsRepository = get(),
                deviceRepository = get(),
                logger = get()
            )
        } bind EditUnit::class

        factory(named("addDevice")) {
            AddEditDeviceImpl(screen = get(qualifier = named("add")))
        } bind AddEditDevice::class
        factory(named("editDevice")) {
            AddEditDeviceImpl(screen = get(qualifier = named("edit")))
        } bind AddEditDevice::class

        factory {
            EditUnitDialogController(editUnitInteractor = get(), addEditDevice = get(qualifier = named("editDevice")))
        }

        factory {
            AddNewUnitController(addNewUnit = get(), addEditDevice = get(qualifier = named("addDevice")))
        }

        single {
            AddNewUnitsCSVDialogViewModel()
        } bind AddNewUnitCSVScreen::class

        factory {
            ClientAddNewUnitCSV(
                screen = get(),
                unitsRepository = get(),
                deviceRepository = get(),
                unitsNavigator = get(),
                jobsRepository = get(),
                logger = get()
            )
        } bind AddNewUnitCSV::class

        factory {
            AddNewUnitsCSVDialogController(addNewUnitCSV = get())
        }
        factory {
            UnitsSectionController(
                interactor = get()
            )
        }
        factory {
            ClientUnitsInteractor(
                screen = get(),
                preferences = get(),
                unitsRepository = get(),
                unitsNavigator = get(),
                activeEventsRepository = get(),
                logger = get()
            )
        } bind UnitsInteractor::class

        single {
            RoutedUnitsNavigator(unitsRouter = get())
        } bind UnitsNavigator::class

        single {
            UnitsSectionViewModel()
        } bind UnitsSectionScreen::class

        single {
            ApiUnitsRepository(
                dataSource = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get(),
                preferences = get(),
                logger = get()
            )
        } bind UnitsRepository::class

        single {
            ClientUnitsDataSource(
                httpClient = get(), baseUrl = apiBaseUrl
            )
        } bind UnitsDataSource::class
    }
    private val eventsModule = module {

        single {
            ActiveEventsV2ViewModel()
        } bind ActiveEventsV2Screen::class

        factory {
            ActiveEventsV2Interactor(
                activeEventsV2Screen = get(),
                activeEventsRepository = get(),
                logger = get()
            )
        }
        factory { ActiveEventsV2Controller(
            interactor = get()
        )}
        factory { RebootDeviceDialogController(interactor = get()) }
        factory {
            ClientRebootDevice(
                deviceRepository = get(),
                screen = get(),
                eventsNavigator = get(),
                logger = get()
            )
        } bind RebootDevice::class
        single {
            RebootDeviceDialogViewModel()
        } bind RebootDeviceDialogScreen::class

        factory { MarkAsTestDialogController(interactor = get()) }
        factory {
            ClientMarkAsTest(
                eventsRepository = get(), screen = get(), eventsNavigator = get()
            )
        } bind MarkAsTest::class
        single {
            MarkAsTestDialogViewModel()
        } bind MarkAsTestDialogScreen::class

        factory {
            com.fireavert.events.markasfalsealarm.presentation.MarkAsFalseAlarmController(
                onLoadUseCase = get(),
                navigator = get(),
                screen = get(),
                ioContext = Dispatchers.Default
            )
        }
        /**
         * Mark As False Alarm
         */
        factory {
            com.fireavert.events.markasfalsealarm.logic.OnLoadUseCaseImpl(eventsRepository = get())
        } bind com.fireavert.events.markasfalsealarm.logic.OnLoadUseCase::class
        single {
            com.fireavert.events.markasfalsealarm.presentation.MarkAsFalseAlarmViewModel()
        } bind com.fireavert.events.markasfalsealarm.logic.Screen::class


        factory { DismissEventDialogController(interactor = get()) }
        factory {
            ClientDismissEvent(
                eventsRepository = get(), screen = get(), eventsNavigator = get()
            )
        } bind DismissEvent::class
        single {
            ApiEventsRepository(
                dataSource = get(),
                tokenRefreshService = get(),
                preferences = get(),
                ioContext = Dispatchers.Default
            )
        } bind EventsRepository::class
        single {
            ClientEventsDataSource(httpClient = get(), baseUrl = apiBaseUrl, logger = get())
        } bind EventsDataSource::class
        single {
            DismissEventDialogViewModel()
        } bind DismissEventDialogScreen::class
        single { RoutedEventsNavigator(eventsRouter = get()) } bind EventsNavigator::class

        factory { ActiveEventsController(interactor = get()) }
        factory {
            ClientActiveEvents(
                screen = get(),
                activeEventsRepository = get(),
                logger = get(),
            )
        } bind ActiveEvents::class

        single {
            ClientActiveEventsDataSource(
                httpClient = get(),
                baseUrl = apiBaseUrl
            )
        } bind ActiveEventsDataSource::class

        single {
            ApiActiveEventsRepository(
                ioContext = Dispatchers.Default,
                tokenRefreshService = get(),
                preferences = get(),
                activeEventsDataSource = get()
            )
        } bind ActiveEventsRepository::class

        single { ActiveEventsViewModel() } bind ActiveEventsScreen::class

        factory {
            ActiveDeviceEventsController(
                interactor = get()
            )
        }
        factory {
            ClientActiveDeviceEvents(
                deviceRepository = get(),
                unitsRepository = get(),
                activeEventsRepository = get(),
                screen = get(),
                logger = get()
            )
        } bind ActiveDeviceEvents::class
        single { ActiveDeviceEventsViewModel() } bind ActiveDeviceEventsScreen::class
    }
    private val devicesModule = module {
        single {
            ClientDeviceDataSource(
                httpClient = get(), baseUrl = apiBaseUrl, logger = get()
            )
        } bind DeviceDataSource::class
        single {
            ApiDeviceRepository(
                dataSource = get(),
                tokenRefreshService = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                logger = get()
            )
        } bind DeviceRepository::class
    }
    private val propertyModule = module {

        single {
            CommV2ConversionDialogViewModel()
        } bind CommV2ConversionScreen::class

        factory {
            CommV2Conversion(propertyNavigator = get(),
                deviceRepository = get(),
                screen = get(),
                logger = get()
            )
        }
        factory {
            CommV2ConversionDialogController(useCase = get())
        }
        factory {
            DeletePropertyDialogController(interactor = get())
        }
        factory {
            ClientDeleteProperty(
                propertyNavigator = get(),
                screen = get(),
                propertyRepository = get(),
                logger = get()
            )
        } bind DeleteProperty::class
        single {
            DeletePropertyDialogViewModel()
        } bind DeletePropertyDialogScreen::class

        single { EditPropertyViewModel() } bind EditPropertyScreen::class
        factory {
            ClientEditProperty(
                screen = get(),
                propertyNavigator = get(),
                propertyRepository = get(),
                logger = get(),
            )
        } bind EditProperty::class
        factory {
            EditPropertyController(editProperty = get())
        }

        single { PropertyDetailsViewModel() } bind PropertyDetailsScreen::class
        factory {
            ClientPropertyDetails(
                propertyRepository = get(),
                screen = get(),
                clientUserRepository = get(),
                logger = get(),
            )
        } bind PropertyDetails::class
        factory { PropertyDetailsController(propertyDetails = get()) }

        single {
            ClientPropertyDataSource(
                httpClient = get(),
                baseUrl = apiBaseUrl
            )
        } bind PropertyDataSource::class
        single {
            ApiPropertyRepository(
                ioContext = Dispatchers.Default,
                tokenRefreshService = get(),
                dataSource = get(),
                preferences = get(),
                inMemoryCache = get(),
                logger = get()
            )
        } bind ClientPropertyRepository::class
        single { RoutedPropertyNavigator(get()) } bind PropertyNavigator::class
        single { PropertyPortfolioViewModel() } bind PropertyPortfolioScreen::class
        factory {
            ClientPropertyPortfolio(
                propertyRepository = get(), screen = get(), logger = get(), preferences = get()
            )
        } bind PropertyPortfolio::class
        factory {
            PropertyPortfolioController(
                propertyNavigator = get(),
                propertyPortfolio = get()
            )
        }
        factory { AddNewPropertyController(addNewProperty = get()) }
        factory {
            ClientAddNewProperty(
                screen = get(),
                propertyNavigator = get(),
                propertyRepository = get(),
                logger = get()
            )
        } bind AddNewProperty::class
        single { AddNewPropertyViewModel(
            logger = get()
        ) } bind AddNewPropertyScreen::class

        factory {
            GatewayPropertyDataSourceImpl(httpClient = get(), baseUrl = apiBaseUrl)
        } bind GatewayPropertyDataSource::class

        factory {
            GatewayPropertyRepositoryImpl(
                dataSource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind GatewayPropertyRepository::class

        factory {
            GatewayPropertyInteractor(repository = get(), screen = get())
        }

        factory {
            GatewayDialogInteractor(repository = get(), screen = get(), gatewayPropertyScreen = get())
        }

        factory {
            GatewayPropertyController(interactor = get())
        }

        factory {
            GatewayDialogController(interactor = get())
        }

        single { GatewayPropertyViewModel() } bind GatewayPropertyScreen::class

        single {GatewayDialogViewModel() } bind GatewayDialogScreen::class
    }



    private val httpClientModule = module {
        single {
            HttpClient(Js) {
                install(ContentNegotiation) {
                    json(Json {
                        prettyPrint = true
                        isLenient = true
                    })
                }
            }
        }
    }

    private val userModule = module {
        single {
            ClientUserDataSource(
                httpClient = get(), baseUrl = apiBaseUrl, logger = get()
            )
        } bind UserDataSource::class
        single { InMemoryUserDataCache() } bind UserDataCache::class
        single {
            ClientUserRepository(
                dataCache = get(), dataSource = get(), Dispatchers.Default, preferences = get()
            )
        } bind UserRepository::class
        factory {
            GetUser(
                userRepository = get(),
                preferences = get(),
                tokenRefreshService = get()
            )
        }
    }

    private val preferencesModule = module {
        single { KeyValuePreferences(settings = get(), logger = get()) } bind Preferences::class
        single { JsSettings() } bind Settings::class
    }

    private val authModule = module {
        single { AuthViewModel() } bind AuthScreen::class
        single { RoutedAuthNavigator(authRouter = get()) } bind AuthNavigator::class
        single { NavRouter() } binds arrayOf(
            AuthRouter::class,
            MenuRouter::class,
            PropertyRouter::class,
            DevicesRouter::class,
            EventsRouter::class,
            UnitsRouter::class,
            AdminRouter::class,
            RebootRouter::class,
        )
        single {
            UserPreferencesTokenRefreshService(
                userRepository = get(),
                preferences = get(),
                logger = get()
            )
        } bind TokenRefreshService::class

        factory {
            ClientUserAuthenticator(
                userRepository = get(),
                authScreen = get(),
                authNavigator = get(),
                preferences = get(),
                logger = get()
            )
        } bind UserAuthenticator::class

        factory {
            ClientPageAuthenticator(
                userRepository = get(),
                preferences = get(),
            )
        } bind PageAuthenticator::class
        factory { AuthController(get()) }
        factory { PageAuthenticationController(get()) }
    }

    private val unsubscribeModule = module {
        single {
            APIUnsubscribeDataSource(httpClient = get(), logger = get(), baseUrl = apiBaseUrl)
        } bind UnsubscribeSetterDataSource::class
        single {
            ClientUnsubscribeSetter(ioContext = Dispatchers.Default, dataSource = get())
        } bind UnsubscribeSetter::class
        factory {
            UnsubscribeController(unsubscribeSetter = get())
        }
        single {
            UnsubscribeViewModel()
        }
    }
    private val inMemoryCacheModule = module {
        single {
            InMemoryCache()
        }
    }

    private val addRemovePropertyAdminRowModule = module {
        factory {
            AddRemovePropertyAdminRowController(
                validator = get()
            )
        }
        factory {
            AddRemovePropertyAdminValidator()
        }
    }

    private val addRemoveDeviceContainerModule = module {
        factory {
            AddRemoveDevicesContainerController(preferences = get())
        }
        factory {
            AddRemoveDeviceRowController()
        }
    }

    private val deletePropertyAdminModule = module {
        factory {
            ClientDeletePropertyAdmin(
                propertyRepository = get(),
                screen = get(),
                logger = get(),
            )
        } bind DeletePropertyAdmin::class

        factory {
            DeletePropertyAdminDialogController(
                propertyNavigator = get(),
                deletePropertyAdmin = get()
            )
        }

        single {
            DeletePropertyAdminDialogViewModel()
        } bind DeletePropertyAdminDialogScreen::class
    }

    private val navigationModule = module {
        single { TopNavViewModel() } bind TopNavScreen::class
        factory {
            ClientTopNav(
                screen = get(),
                preferences = get(),
                authNavigator = get(),
                adminNavigator = get(),
                getUser = get(),
                logger = get()
            )
        } bind TopNav::class
        factory { TopNavController(topNav = get(), userAuthenticator = get()) }
        single { RoutedMenuNavigator(router = get()) } bind MenuNavigator::class
        single { MenuViewModel() } bind MenuScreen::class
        factory {
            MenuInteractor(
                logger = get(),
                screen = get(),
                preferences = get(),
                menuNotificationRepository = get()
            )
        }
        factory { MenuController(navigator = get(), interactor = get()) }
        single {
            ApiMenuNotificationRepository(
                dataSource = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get(),
                preferences = get()
            )
        } bind MenuNotificationRepository::class
        single {
            RemoteMenuDataSource(httpClient = get(), baseUrl = apiBaseUrl, logger = get())
        } bind MenuDataSource::class
    }

    private val inviteModule = module {
        single {
            InviteViewModel()
        } bind InviteScreen::class
        factory {
            InviteController(invite = get())
        }
        factory {
            Invite(screen = get(), authenticator = get(), userRepository = get(), logger = get())
        }
    }

    private val jobsModule = module {
        single {
            CommonClientJobDataSource(
                httpClient = get(),
                baseUrl = apiBaseUrl
            )
        } bind ClientJobDataSource::class

        single {
            CommonClientJobRepository(
                ioContext = Dispatchers.Default,
                tokenRefreshService = get(),
                jobDataSource = get(),
                preferences = get()
            )
        } bind ClientJobRepository::class
    }

    private val resetPasswordModule = module {
        single {
            ResetPasswordViewModel()
        } bind ResetPasswordScreen::class
        single {
            HttpPasswordSenderSource(baseUrl = apiBaseUrl, httpClient = get())
        } bind ResetPasswordSenderSource::class
        single {
            ResetPasswordSenderImpl(
                resetPasswordSenderSource = get(),
                ioContext = Dispatchers.Default,
                logger = get(),
            )
        } bind ResetPasswordSender::class
        factory {
            ResetPasswordUseCase(screen = get(), authNavigator = get(), resetPasswordSender = get())
        }
        factory {
            ResetPasswordController(useCase = get())
        }
    }

    private val scrollPageManagerModule = module {
        single {
            window
        }
        single(qualifier = named("screenScrollData")) {
            mutableMapOf<String, Pair<Double, Double>>()
        }
    }

    private val reportsPageModule = module {
        single {
            ReportsPageRepositoryDataSourceImpl(
                baseUrl = apiBaseUrl,
                httpClient = get()
            )
        } bind ReportsPageRepositoryDataSource::class

        single {
            ReportsPageSectionRepositoryImpl(
                dataSource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind ReportsPageRepository::class

        single {
            ReportsPageViewModel()
        } bind ReportsPageScreen::class

        factory {
            ReportsPagesSectionInteractor(
                screen = get(),
                propertyRepository = get(),
                reportsPageRepository = get(),
                logger = get()
            )
        }

        factory {
            ReportsPageSectionController(
                interactor = get()
            )
        }

        factory {
            ReportsExportDialogController(
                interactor = get()
            )
        }

        factory {
            ReportsExportDialogInteractor(
                screen = get(),
                reportsPageRepository = get(),
                logger = get()
            )
        }

        single {
            ReportsExportDialogViewModel()
        } bind ReportsExportDialogScreen::class

    }

    private val managementCompanyModule = module {
        single {
            ManagementCompanyDataSourceImpl(
                baseUrl = apiBaseUrl,
                httpClient = get()
            )
        } bind ManagementCompanyDataSource::class

        single {
            ManagementCompanyRepositoryImpl(
                dataSource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind ManagementCompanyRepository::class


        factory { ManagementCompanyController(interactor = get()) }

        single { ManagementCompaniesViewModel() } bind ManagementCompanyScreen::class

        factory { ManagementCompanyInteractor(screen = get(), repository = get(), propertyRepository = get()) }

        factory { ManagementCompanyController(interactor = get()) }
    }

    private val buildingModule = module {
        single {
            ClientBuildingRepositoryDataSourceImpl(baseUrl = apiBaseUrl, httpClient = get())
        } bind ClientBuildingRepositoryDataSource::class

        single {
            ClientBuildingRepositoryImpl(
                dataSource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind ClientBuildingRepository::class

        factory {
            AddNewBuildingInteractor(
                clientBuildingRepository = get(),
                screen = get(),
            )
        }

        factory { AddNewBuildingController(interactor = get()) }

        single {
            AddNewBuildingViewModel()
        } bind AddNewBuildingScreen::class
    }

    private val userTrackingModule = module {

        single {
            UserSectionRepositoryDataSourceImpl(baseUrl = apiBaseUrl, httpClient = get())
        } bind UserSectionRepositoryDataSource::class

        single {
            UserSectionRepositoryImpl(
                dataSource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind UserSectionRepository::class

        single {
            UserSectionViewModel()
        } bind UserSectionScreen::class

        factory {
            UserSectionInteractor(
                screen = get(),
                userSectionRepository = get(),
                logger = get()
            )
        }
        factory { UserSectionController(interactor = get()) }

    }

    private val administrationPageModule = module {

        factory { AdministrationPageController(interactor = get()) }

        single {
            RoutedAdminNavigator(adminRouter = get())
        } bind AdminNavigator::class

        single {
            AdministrationPageViewModel()
        } bind AdministrationPageScreen::class

        single {AdminPageRepositoryDataSourceImpl(
            baseUrl = apiBaseUrl, httpClient = get())
        } bind AdminPageRepositoryDataSource::class

        single {
            AdministrationPageRepositoryImpl(
                datasource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind AdministrationPageRepository::class
        factory {
            AdministrationPageInteractor(
                screen = get(),
                adminPageRepository = get()
            )
        }
    }

    private val userAdminModule = module {

        single {
            UserInfoRepositoryDataSourceImpl(baseUrl = apiBaseUrl, httpClient = get())
        } bind UserInfoRepositoryDataSource::class

        single {
            UserInfoRepositoryImpl(
                dataSource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind UserInfoRepository::class

        single {
            UserInfoViewModel()
        } bind UserInfoScreen::class

        factory {
            UserInfoInteractor(
                screen = get(),
                userInfoRepository = get(),
                logger = get()
            )
        }

        factory { UserInfoController(interactor = get()) }

        single {
            UserDialogRepositoryDataSourceImpl(baseUrl = apiBaseUrl, httpClient = get())
        } bind UserDialogRepositoryDataSource::class

        single {
            UserDialogRepositoryImpl(
                dataSource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind UserDialogRepository::class

        single {
            AddUserViewModel()
        } bind AddUserDialogScreen::class

        factory {
            AddUserController(
                interactor = get()
            )
        }

        factory {
            AddUserInteractor(
                screen = get(),
                userDialogRepository = get(),
                userInfoRepository = get(),
                propertyRepository = get(),
                logger = get()
            )
        }
    }

    private val wirelessIdModule = module {
        single {
            WirelessIdRepositoryDataSourceImpl(
                baseUrl = apiBaseUrl,
                httpClient = get()
            )
        } bind WirelessIdRepositoryDataSource::class

        single {
            WirelessIdRepositoryImpl(
                dataSource = get(),
                preferences = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind WirelessIdRepository::class

        single {
            WirelessIdSectionViewModel()
        } bind WirelessIdSectionScreen::class

        factory {
            WirelessIdSectionInteractor(
                screen = get(),
                repository = get(),
                logger = get()
            )
        }
        factory { WirelessIdSectionController(interactor = get()) }
    }

    private val applicationModule = module {
        single {
            ClientAnalyticsDataSourceImpl(
                baseUrl = apiBaseUrl,
                httpClient = get()
            )
        } bind ClientAnalyticsDataSource::class
        single {
            ClientAnalyticsRepositoryImpl(
                clientAnalyticsDataSource = get(),
                preferences = get(),
                logger = get(),
                ioContext = Dispatchers.Default,
                tokenRefreshService = get()
            )
        } bind ClientAnalyticsRepository::class

        single {
            AppViewModel()
        }
        factory { AnalyticsUseCase(clientAnalyticsRepository = get()) }
        factory {
            AppController(analyticsUseCase = get())
        }
    }

    private val dashboardModule = module {
        single {
            ClientDashboardRepositoryDataSource(
                baseUrl = apiBaseUrl,
                httpClient = get(),
                logger = get()
            )
        } bind DashboardRepositoryDataSource::class

        single {
            ApiDashboardRepository(
                ioContext = Dispatchers.Default,
                tokenRefreshService = get(),
                preferences = get(),
                dataSource = get(),
                logger = get()
            )
        } bind DashboardRepository::class

        single {
            DashboardViewModel()
        } bind DashboardScreen::class

        factory {
            DashboardInteractor(
                screen = get(),
                propertyRepository = get(),
                dashboardRepository = get(),
                logger = get()
            )
        }
        factory {
            DashboardController(
                interactor = get()
            )
        }
    }

    val all = listOf(
        inMemoryCacheModule,
        logging,
        httpClientModule,
        userModule,
        preferencesModule,
        authModule,
        navigationModule,
        propertyModule,
        devicesModule,
        eventsModule,
        unitsModule,
        rebootModule,
        infoPageModule,
        unsubscribeModule,
        addRemovePropertyAdminRowModule,
        addRemoveDeviceContainerModule,
        deletePropertyAdminModule,
        inviteModule,
        jobsModule,
        resetPasswordModule,
        scrollPageManagerModule,
        userTrackingModule,
        applicationModule,
        dashboardModule,
        reportsPageModule,
        wirelessIdModule,
        buildingModule,
        administrationPageModule,
        userAdminModule,
        managementCompanyModule,
    )
}