package com.fireavert.components.dialogs

import com.fireavert.components.common.WarningIcon
import com.fireavert.components.targetInputValue
import com.fireavert.styles.DialogStyles
import emotion.react.css
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.useState
import web.cssom.JustifyContent
import web.cssom.NamedColor
import web.cssom.PropertyName.Companion.backgroundColor
import web.cssom.PropertyName.Companion.padding
import web.cssom.important
import web.cssom.px
import web.html.InputType

typealias ArchivePropertyDialogCloseCallback = () -> Unit
typealias ArchivePropertyDialogRemoveCallback = (String) -> Unit

external interface ArchivePropertyDialogProps : Props {
    var message: String
    var error: String
    var closeCallback: ArchivePropertyDialogCloseCallback
    var archiveCallback: ArchivePropertyDialogRemoveCallback
}

val ArchivePropertyDialog = FC<ArchivePropertyDialogProps> { props ->
    var (password, setPassword) = useState("")
    Dialog {
        open = true
        onBackdropClick = { props.closeCallback() }
        onClose = { _, _ -> props.closeCallback() }
        maxWidth = Breakpoint.md
        DialogContent {
            className = DialogStyles.warningDialogContent

            WarningIcon {}
            div {
                className = DialogStyles.warningText
                +"ARCHIVE PROPERTY?"
            }
            div {
                className = DialogStyles.warningText
                +props.message
            }

            TextField {
                label = ReactNode("Password")
                value = password
                type = InputType.password
                onChange = { event ->
                    password = event.targetInputValue
                    setPassword(event.targetInputValue)
                }
            }
            div {
                className = DialogStyles.warningText
                +props.error
            }
            DialogActions {
                css {
                    height = 94.px
                    width = 500.px
                    padding = 24.px
                    justifyContent = important(JustifyContent.center)
                }
                Button {
                    +"Archive Property"
                    color = ButtonColor.inherit
                    onClick = {
                        props.archiveCallback(password)
                    }
                    variant = ButtonVariant.contained
                    css {
                        width = 400.px
                    }
                }
                Button {
                    +"Cancel"
                    color = ButtonColor.inherit
                    onClick = {
                        props.closeCallback()
                    }
                    variant = ButtonVariant.contained
                    css {
                        width = 400.px
                    }
                }
            }
        }
    }
}