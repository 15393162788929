package com.fireavert.components.common.shared

import com.fireavert.components.list.SearchableCheckboxList
import com.fireavert.components.list.SearchableCheckboxListProps
import com.fireavert.components.properties.NotificationsCheckboxTable
import com.fireavert.components.targetInputValue
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.presentation.AddRemovePropertyAdminRowController
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.StandardizedStyles
import com.fireavert.user.logic.models.RolesModel
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.react.css
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.*
import web.html.HTMLInputElement

external interface AddUserProps : Props {
    var id: String
    var firstName: String
    var firstNameError: Boolean
    var firstNameChanged: (String, Boolean) -> Unit
    var lastName: String
    var lastNameError: Boolean
    var lastNameChanged: (String, Boolean) -> Unit
    var phone: String
    var phoneError: Boolean
    var phoneChanged: (String, Boolean) -> Unit
    var email: String
    var emailError: Boolean
    var emailChanged: (String, Boolean) -> Unit
    var stoveShutoffTextNotifications: Boolean
    var stoveShutoffTextNotificationsChanged: (String, Boolean) -> Unit
    var stoveShutoffEmailNotifications: Boolean
    var stoveShutoffEmailNotificationsChanged: (String, Boolean) -> Unit
    var smokeAlarmTextNotifications: Boolean
    var smokeAlarmTextNotificationsChanged: (String, Boolean) -> Unit
    var smokeAlarmEmailNotifications: Boolean
    var smokeAlarmEmailNotificationsChanged: (String, Boolean) -> Unit
    var leakTextNotifications: Boolean
    var leakTextNotificationsChanged: (String, Boolean) -> Unit
    var leakEmailNotifications: Boolean
    var leakEmailNotificationsChanged: (String, Boolean) -> Unit
    var tamperTextNotifications: Boolean
    var tamperTextNotificationsChanged: (String, Boolean) -> Unit
    var tamperEmailNotifications: Boolean
    var tamperEmailNotificationsChanged: (String, Boolean) -> Unit
    var dailyReports: Boolean
    var dailyReportsChanged: (String, Boolean) -> Unit
    var weeklyReports: Boolean
    var weeklyReportsChanged: (String, Boolean) -> Unit
    var monthlyReports: Boolean
    var monthlyReportsChanged: (String, Boolean) -> Unit
    var quarterlyReports: Boolean
    var quarterlyReportsChanged: (String, Boolean) -> Unit
    var yearlyReports: Boolean
    var yearlyReportsChanged: (String, Boolean) -> Unit
    var propertyList: List<com.fireavert.properties.logic.models.Property>
    var roleList: List<RolesModel>
    var associatedRoles: List<RolesModel>
    var roleListChanged: (List<RolesModel>) -> Unit
    var associatedPropertiesChanged: (List<Int>) -> Unit
    var isEditMode: Boolean
    var associatedProperties: List<Int>
}

val AddUser = FC<AddUserProps> { props ->
    val controller = getKoinInstance<AddRemovePropertyAdminRowController>()
    var deletedProperties by useState(emptyList<Property>())
    var selectedProperties by useState(props.propertyList.filter { props.associatedProperties.contains(it.id) }.toSet())
    var (searchValue, setSearchValue) = useState("")
    val logger = getKoinInstance<Logger>()

    useEffect(props.associatedProperties) {
        // Find matching properties
        val matchingProperties = props.propertyList.filter { property ->
            props.associatedProperties.contains(property.id)
        }.toSet()
        // Update selected properties
        selectedProperties = matchingProperties
    }

    div {
        className = ClassName {
            width = 100.pct
            maxWidth = 100.pct
            margin = Auto.auto
            padding = 10.px
            boxSizing = BoxSizing.borderBox

            ".MuiOutlinedInput-notchedOutline" {
                borderRadius = important(15.px)
                border = important(Border(1.px, LineStyle.solid, NamedColor.black))
            }

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
                padding = 5.px
            }
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 10.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

            div {
                css {fontWeight = FontWeight.bold}
                +"First Name"
                TextField {
                    value = props.firstName
                    required = true
                    fullWidth = true
                    error = props.firstNameError
                    onChange = {
                        val value = it.targetInputValue
                        props.firstNameChanged(
                            value,
                            !controller.checkName(value)
                        )
                    }
                }
            }

            div {
                css {fontWeight = FontWeight.bold}
                +"Last Name"
                TextField {
                    value = props.lastName
                    required = true
                    fullWidth = true
                    error = props.lastNameError
                    onChange = {
                        val value = it.targetInputValue
                        props.lastNameChanged(
                            value,
                            !controller.checkName(value)
                        )
                    }
                }
            }
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 10.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }


            div {
                css {fontWeight = FontWeight.bold; width = 100.pct}
                +"Phone Number"
                TextField {
                    value = props.phone
                    required = true
                    fullWidth = true
                    error = props.phoneError
                    onChange = {
                        val value = it.targetInputValue
                        props.phoneChanged(
                            value,
                            !controller.checkPhone(value)
                        )
                    }
                }
            }

            div {
                css {fontWeight = FontWeight.bold; width = 100.pct}
                +"Email"
                TextField {
                    value = props.email
                    required = true
                    fullWidth = true
                    error = props.emailError
                    onChange = {
                        val value = it.targetInputValue
                        props.emailChanged(
                            value,
                            !controller.checkEmail(value)
                        )
                    }
                }
            }
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 10.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }
            div {
                css { fontWeight = FontWeight.bold; width = 100.pct }
                +"Role"
                DropDownSelector {
                    id = "user-role-selector"
                    label = ""
                    isFullWidth = true
                    items = props.roleList.map { role ->
                        DropDownItemImpl(
                            value = role.name,
                            label = role.displayText
                        )
                    }
                    selectedValues = props.associatedRoles.map { it.name }
                    onSelectionChange = { newSelection ->
                        props.roleListChanged(newSelection.map { roleName ->
                            // Find and return the actual role object that matches the selected name
                            props.roleList.find { it.name == roleName }!!
                        })
                    }
                    this.isMobile = isMobile
                }
            }
        }



        div {
            className = StandardizedStyles.HeaderH2
            +"Select Properties"
        }
        div {
            css {
                marginBottom = 16.px
                maxHeight = 150.px
                overflowY = Auto.auto
            }
            selectedProperties.forEach { property ->
                div {
                    css {
                        padding = 4.px
                        borderRadius = 4.px
                        backgroundColor = Color("#f5f5f5")
                        marginBottom = 4.px
                    }
                    +property.name
                }
            }
        }

        TextField {
            value = searchValue
            onChange = { event ->
                val target = event.target as? HTMLInputElement
                setSearchValue(target?.value ?: "")
            }
            placeholder = "Search properties..."
            fullWidth = true
            sx {
                marginBottom = 2.px
            }
        }

        SearchableCheckboxList<SearchableCheckboxListProps<com.fireavert.properties.logic.models.Property>> {
            items = props.propertyList
            selectedItems = selectedProperties.toList()
            associatedItems = selectedProperties.toList()
            deletedItems = deletedProperties
            this.searchValue = searchValue
            getSearchString = { property ->
                "${property.name} ${property.managementCompany}"
            }
            getPrimaryText = { property -> property.name }
            getSecondaryText = { property -> property.managementCompany }
            onSelectionChange = { newSelected, newDeleted ->
                selectedProperties = newSelected.toSet()
                deletedProperties = newDeleted
                props.associatedPropertiesChanged(newSelected.map { it.id })
            }
            paperHeight = 400
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.start
                marginTop = 30.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                    maxWidth = 100.pct
                }
            }

            div {
                className = ClassName {
                    flexGrow = number(1.0)
                }

                if (!props.isEditMode) {
                    NotificationsCheckboxTable {
                        id = props.id
                        stoveShutoffTextNotifications = props.stoveShutoffTextNotifications
                        stoveShutoffTextNotificationsChanged = props.stoveShutoffTextNotificationsChanged
                        stoveShutoffEmailNotifications = props.stoveShutoffEmailNotifications
                        stoveShutoffEmailNotificationsChanged = props.stoveShutoffEmailNotificationsChanged
                        smokeAlarmTextNotifications = props.smokeAlarmTextNotifications
                        smokeAlarmTextNotificationsChanged = props.smokeAlarmTextNotificationsChanged
                        smokeAlarmEmailNotifications = props.smokeAlarmEmailNotifications
                        smokeAlarmEmailNotificationsChanged = props.smokeAlarmEmailNotificationsChanged
                        leakTextNotifications = props.leakTextNotifications
                        leakTextNotificationsChanged = props.leakTextNotificationsChanged
                        leakEmailNotifications = props.leakEmailNotifications
                        leakEmailNotificationsChanged = props.leakEmailNotificationsChanged
                        tamperTextNotifications = props.tamperTextNotifications
                        tamperTextNotificationsChanged = props.tamperTextNotificationsChanged
                        tamperEmailNotifications = props.tamperEmailNotifications
                        tamperEmailNotificationsChanged = props.tamperEmailNotificationsChanged
                        dailyReports = props.dailyReports
                        dailyReportsChanged = props.dailyReportsChanged
                        weeklyReports = props.weeklyReports
                        weeklyReportsChanged = props.weeklyReportsChanged
                        monthlyReports = props.monthlyReports
                        monthlyReportsChanged = props.monthlyReportsChanged
                        quarterlyReports = props.quarterlyReports
                        quarterlyReportsChanged = props.quarterlyReportsChanged
                        yearlyReports = props.yearlyReports
                        yearlyReportsChanged = props.yearlyReportsChanged
                    }
                }
            }

            div {
                className = ClassName {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.flexEnd
                    gap = 10.px

                    media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceBetween
                        width = 100.pct
                    }
                }
            }
        }
    }
}
