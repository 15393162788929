package com.fireavert.reports_page.interface_adaptors

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.events.activeevents.GetActiveEventsForAllPropertiesResponse
import com.fireavert.events.activeevents.GetActiveEventsForPropertyResponse
import com.fireavert.preferences.logic.Preferences
import com.fireavert.reports_page.logic.ReportsPageRepository
import com.fireavert.reports_page.logic.models.requests.*
import com.fireavert.reports_page.logic.models.responses.*
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ReportsPageSectionRepositoryImpl(
    private val dataSource: ReportsPageRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
): ReportsPageRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun getEventsAndActions(
        propertyId: Int?,
        eventTypeValue: String
    ): Try<ReportsEventResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getReportsSectionData(
                    ReportsEventsRequest(
                        eventTypeValue, propertyId, preferences.token, preferences.timeZone
                    )
                )
            }.map { it }
        }

    override suspend fun getYearlyEvents(propertyId: Int?, yearToGet: Int): Try<ReportYearlyDataResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getYearlyEvents(
                    YearlyReportRequest(
                        propertyId,
                        preferences.token,
                        yearToGet,
                        preferences.timeZone
                    )
                )
            }
        }

    override suspend fun getHighRiskTenants(
        propertyId: Int?,
        eventTypeList: List<String>
    ): Try<HighRiskTenantsResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getHighRiskTenants(HighRiskTenantRequest(propertyId, eventTypeList), preferences.token)
            }
                .map { response ->
                    HighRiskTenantsResponse(
                        highRiskTenants = response.highRiskTenants,
                        averageEventsPerMonth = response.averageEventsPerMonth
                    )
                }
        }

    override suspend fun getAllPropertiesAverageValue(): Try<Double> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getAllPropertiesAverageValue(preferences.token)
            }
                .map { response -> response }

        }

    override suspend fun getPropertyEventsOverTime(propertyId: Int?): Try<ReportsFireClaimReportResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getPropertyEventsOverTime(propertyId, preferences.token)
            }
        }

    override suspend fun exportReportEventData(
        startDate: Long,
        endDate: Long,
        propertyId: Int?,
        includeOffline: Boolean,
    ): Try<ReportsExportDialogResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.exportReportEventData(
                    ReportsExportDialogRequest(startDate, endDate, propertyId, includeOffline),
                    preferences.token
                )
            }
        }

    override suspend fun exportEventsHappeningNowAll(): Try<GetActiveEventsForAllPropertiesResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.exportEventsHappeningNowAll(preferences.token)
            }
        }

    override suspend fun exportEventsHappeningNow(propertyId: Int?): Try<GetActiveEventsForPropertyResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.exportEventsHappeningNow(propertyId, preferences.token)
            }
        }

    override suspend fun getDeviceToEventRatio(
        propertyId: Int?,
        eventDeviceType: String
    ): Try<DeviceToEventRatioResponse> {
        return withContext(ioContext) {
            callWithRefresh {
                dataSource.getDeviceToEventRatio(
                    DeviceToEventRatioRequest(propertyId, eventDeviceType),
                    preferences.token)
            }
        }
    }

    override suspend fun getFiresAvertedCount(propertyId: Int?): Try<Int> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getFiresAvertedCount(propertyId, preferences.token)
            }
    }
}
