package com.fireavert.administration_page.wireless_id_finder.interface_adapters

import com.fireavert.administration_page.wireless_id_finder.logic.WirelessIdSectionScreen
import com.fireavert.administration_page.wireless_id_finder.logic.models.PropertyOfflineStatisticsModel
import com.fireavert.common.ViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class WirelessIdSectionViewModel : WirelessIdSectionScreen, ViewModel() {
    private val _viewState = MutableStateFlow(WirelessIdSectionViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope

    override var wirelessDeviceId: String
        get() { return viewState.value.wirelessDeviceId }
        set(value) {
            _viewState.value = _viewState.value.copy(wirelessDeviceId = value)
        }
    override var propertyName: String
        get() = viewState.value.propertyName
        set(value) { _viewState.value = _viewState.value.copy(propertyName = value) }
    override var unitName: String
        get() = viewState.value.unitName
        set(value) { _viewState.value = _viewState.value.copy(unitName = value) }
    override var deviceLocationLink: String
        get() = viewState.value.deviceLocationLink
        set(value) { _viewState.value = _viewState.value.copy(deviceLocationLink = value) }
    override var gatewayDeviceData: Map<String, List<String>>
        get() = viewState.value.gatewayDeviceData
        set(value) { _viewState.value = _viewState.value.copy(gatewayDeviceData = value) }
    override var gatewayDeviceDataLoading: Boolean
        get() = viewState.value.gatewayDeviceDataLoading
        set(value) { _viewState.value = _viewState.value.copy(gatewayDeviceDataLoading = value) }
    override var propertyOfflineStatistics: List<PropertyOfflineStatisticsModel>
        get() = viewState.value.propertyOfflineStatistics
        set(value) { _viewState.value = _viewState.value.copy(propertyOfflineStatistics = value) }
    override var propertyStatsLoading: Boolean
        get() = viewState.value.propertyStatsLoading
        set(value) { _viewState.value = _viewState.value.copy(propertyStatsLoading = value) }
    override var appsheetDatabaseHealthStatus: Boolean
        get() = viewState.value.appsheetDatabaseHealthStatus
        set(value) { _viewState.value = _viewState.value.copy(appsheetDatabaseHealthStatus = value) }
    override var appsheetDatabaseHealthLoading: Boolean
        get() = viewState.value.appsheetDatabaseHealthLoading
        set(value) { _viewState.value = _viewState.value.copy(appsheetDatabaseHealthLoading = value) }
    override var appsheetDatabaseLastChecked: String
        get() = viewState.value.appsheetDatabaseLastChecked
        set(value) { _viewState.value = _viewState.value.copy(appsheetDatabaseLastChecked = value) }
}