package com.fireavert.reports_page.interface_adaptors

import com.fireavert.reports_page.logic.ReportsExportDialogInteractor

class ReportsExportDialogController(
    private val interactor: ReportsExportDialogInteractor
) {
    suspend fun exportReportEventData(
        startDate: Any,
        endDate: Any,
        propertyId: Int?,
        includeOffline: Boolean,
        isEasySelection: Boolean,
    ): String? {
        return interactor.exportReportEventData(
            startDate,
            endDate,
            propertyId,
            includeOffline,
            isEasySelection
        )
    }
}
