package com.fireavert.styles

import emotion.css.ClassName
import web.cssom.*

object HeadingStyles {

    val titleHeadingGreyBackground = ClassName {
        fontSize = 20.px
        padding = Padding(16.px, 16.px)
        color = NamedColor.black
        backgroundColor = Color("#EDEFF3")
        padding = 0.px
        height = 36.px
        marginTop = 20.px
        position = Position.relative
        fontWeight = FontWeight.bold
    }

    val titleHeadingWhiteBackground = ClassName {
        padding = Padding(16.px, 16.px)
        backgroundColor = Color("#FFF")
        padding = 0.px
        height = 18.px
        marginTop = 10.px
        marginBottom = 10.px
        position = Position.relative
        fontWeight = FontWeight.bold
        fontWeight = integer(700)
        color = Color("#525F7F")
        fontSize = 15.px
    }
}