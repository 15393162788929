package com.fireavert.integrations.yardi.common_data.interface_adaptors

import YardiSavePropertyDataRequest
import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.integrations.yardi.common_data.logic.YardiCommonDataClientDataSource
import com.fireavert.integrations.yardi.common_data.logic.YardiCommonDataClientRepository
import com.fireavert.integrations.yardi.common_data.requests.client_requests.YardiClientTestRequest
import com.fireavert.integrations.yardi.shared.logic.models.YardiCredentials
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher

class YardiCommonDataClientRepositoryImpl(
    private val dataSource: YardiCommonDataClientDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
): YardiCommonDataClientRepository, ApiRepository(ioContext, tokenRefreshService) {

    override suspend fun getYardiCredentialsList(): Try<List<YardiCredentials>> {
        return dataSource.getYardiCredentialsList(preferences.token)
    }

    override suspend fun savePropertyDataBase(request: YardiSavePropertyDataRequest): Try<Boolean> {
        return dataSource.savePropertyDataBase(preferences.token, request)
    }

    override suspend fun testPing(credentialId: Int): Try<String> {
        return dataSource.testPing(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.version }
    }

    override suspend fun testVersionNumber(credentialId: Int): Try<String> {
        return dataSource.testVersionNumber(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.version }
    }

    override suspend fun testGetResidentData(credentialId: Int): Try<String> {
        return dataSource.testGetResidentData(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.residents }
    }

    override suspend fun testGetResidentByStatusData(credentialId: Int): Try<String> {
        return dataSource.testGetResidentByStatusData(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.residents }
    }

    override suspend fun testGetPropertyList(credentialId: Int): Try<String> {
        return dataSource.testGetPropertyList(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.residents }
    }

    override suspend fun testGetResidentsByUnit(credentialId: Int): Try<String> {
        return dataSource.testGetResidentsByUnit(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.residents }
    }

    override suspend fun testGetTenantStatus(credentialId: Int): Try<String> {
        return dataSource.testGetTenantStatus(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.residents }
    }

    override suspend fun testGetRateLimits(credentialId: Int): Try<String> {
        return dataSource.testGetRateLimits(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.residents }
    }

    override suspend fun testGetUnitAttachments(credentialId: Int): Try<String> {
        return dataSource.testGetUnitAttachments(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.residents }
    }

    override suspend fun testGetUnitData(credentialId: Int): Try<String> {
        return dataSource.testGetUnitData(preferences.token, YardiClientTestRequest(credentialId))
            .map { it.unitList }
    }
}