package com.fireavert.administration_page.shared.interface_adaptors

import com.fireavert.administration_page.shared.logic.AdminNavigator
import com.fireavert.administration_page.shared.presentation.AdminRouter

class RoutedAdminNavigator(private val adminRouter: AdminRouter): AdminNavigator {
    override fun navigate(destination: AdminNavigator.Destination) {
        when (destination) {
            is AdminNavigator.Destination.UserPage -> adminRouter.routeToUserPage(destination.userId)
        }
    }


}