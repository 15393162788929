package com.fireavert.components.events

import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.events.logic.models.EventType
import com.fireavert.events.logic.models.EventTypeIconOption
import com.fireavert.styles.EventsStyles
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.cssom.ClassName

external interface EventTypeProps : Props {
    var deviceType: DeviceType
    var eventType: EventType
    var alarmActive: Boolean
    var deviceTriggered: Boolean
    var deviceOffline: Boolean
    var isMobile: Boolean
    var showIcons: Boolean
}


private fun styleForIconType(iconType: EventTypeIconOption): ClassName {
    return when (iconType) {
        EventTypeIconOption.Smoke -> EventsStyles.smokeOval
        EventTypeIconOption.ShutOff -> EventsStyles.shutoffOval
        EventTypeIconOption.Leak -> EventsStyles.waterOval
        EventTypeIconOption.Flow -> EventsStyles.waterOval
        EventTypeIconOption.Reset -> EventsStyles.resetOval
        EventTypeIconOption.Tamper -> EventsStyles.tamperOval
        EventTypeIconOption.Offline -> EventsStyles.offlineOval
        EventTypeIconOption.Unknown -> EventsStyles.unknownOval
    }
}

private fun textForIconType(iconType: EventTypeIconOption): String {
    return when (iconType) {
        EventTypeIconOption.Smoke -> "Smoke"
        EventTypeIconOption.ShutOff -> "Shut Off"
        EventTypeIconOption.Reset -> "Reset"
        EventTypeIconOption.Leak -> "Leak"
        EventTypeIconOption.Flow -> "Flow"
        EventTypeIconOption.Tamper -> "Tamper"
        EventTypeIconOption.Offline -> "Offline"
        EventTypeIconOption.Unknown -> "Unknown"
    }
}


private fun ChildrenBuilder.renderSmokeIcon(active: Boolean) {
    img {
        alt = if (active) "Active Smoke" else "Inactive Smoke"
        src = "/static/event_icons/smoke_${if (active) "active" else "inactive"}.svg"
        className = EventsStyles.eventIcon
    }
}

private fun ChildrenBuilder.renderShutoffIcon(active: Boolean) {
    img {
        alt = if (active) "Active Shutoff" else "Inactive Shutoff"
        src = "/static/event_icons/shutoff_${if (active) "active" else "inactive"}.svg"
        className = EventsStyles.eventIcon
    }
}

private fun ChildrenBuilder.renderLeakIcon(active: Boolean) {
    img {
        alt = if (active) "Active Leak" else "Inactive Leak"
        src = "/static/event_icons/leak_${if (active) "active" else "inactive"}.svg"
        className = EventsStyles.eventIcon
    }
}

private fun ChildrenBuilder.renderTamperIcon(active: Boolean) {
    img {
        alt = if (active) "Active Tamper" else "Inactive Tamper"
        src = "/static/event_icons/tamper_${if (active) "active" else "inactive"}.svg"
        className = EventsStyles.eventIcon
    }
}

private fun ChildrenBuilder.renderIcons(
    deviceType: DeviceType,
    alarmActive: Boolean,
    deviceTriggered: Boolean,
    eventType: EventType
) {
    if (deviceType.isFireAvert && eventType == EventType.SmokeAlarmActive) {
        renderSmokeIcon(true)
    }
    else if (deviceType.isFireAvert && eventType == EventType.DeviceTriggered) {
        renderShutoffIcon(true)
    }
    else if (deviceType == DeviceType.WaterSensor && eventType == EventType.DeviceTriggered) {
        renderLeakIcon(true)
    }
    else if (deviceType == DeviceType.TamperSensor && eventType == EventType.DeviceTriggered) {
        renderTamperIcon(true)
    }
}

val EventTypeIcon = FC<EventTypeProps> { props ->
    val eventTypeIconOption = EventTypeIconOption.from(props.deviceType, props.eventType)
    div {
        className = EventsStyles.eventIconContainer
        div {
            className = styleForIconType(eventTypeIconOption)
            +textForIconType(eventTypeIconOption)
        }
        if (!props.isMobile && props.showIcons) {
            renderIcons(
                deviceType = props.deviceType,
                alarmActive = props.alarmActive,
                deviceTriggered = props.deviceTriggered,
                eventType = props.eventType
            )
        }
    }
}