package com.fireavert.properties.logic.property_details

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.properties.logic.models.Mode
import com.fireavert.properties.logic.models.request.PropertyModeChangeRequest
import com.fireavert.user.api.ClientUserRepository

class ClientPropertyDetails(
    private val propertyRepository: ClientPropertyRepository,
    private val clientUserRepository: ClientUserRepository,
    private val screen: PropertyDetailsScreen,
    private val logger: Logger
) : PropertyDetails {
    override suspend fun onLoad(propertyId: Int, userId: Int) {
        when (val maybeProperty = propertyRepository.getPropertyByIdAsync(propertyId)) {
            is Try.Success -> {
                screen.setPropertyName(maybeProperty.value.name)
                screen.setPropertyTotalUnits(maybeProperty.value.units)
                screen.setPropertyMode(maybeProperty.value.mode)
            }
            is Try.Error -> {
                //TODO() handle this error
                logger.e("Failed ot get Property by id $propertyId")
            }
        }
        when (val maybe = clientUserRepository.userAdminCheck(userId)) {
            is Try.Success -> {
                screen.setIdAdmin(maybe.value)
            }
            is Try.Error -> {
                //TODO() handle this error
                logger.e("Failed to check if user is admin")
            }
        }
    }

    override suspend fun turnOnTestMode(propertyId: Int, mode: Mode, duration: Int) {
        val request = PropertyModeChangeRequest(propertyId, mode, duration)
        return when (propertyRepository.updatePropertyMode(request)) {
            is Try.Success -> {
                screen.closeTestModeDialog(true)
            }
            is Try.Error -> {
                screen.setTestModeError("Failed to set the property to test mode, please try again.")
            }
        }
    }
}