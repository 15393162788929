package com.fireavert.user.api

import com.fireavert.user.logic.User
import com.fireavert.user.logic.models.responses.UserByTokenResponse

object UserMapper {

    fun mapFromUserByIdResponse(userByTokenResponse: UserByTokenResponse): User {
        return User(
            id = userByTokenResponse.id ?: 0,
            email = userByTokenResponse.email ?: "",
            phone = userByTokenResponse.phone ?: "",
            firstName = userByTokenResponse.firstName ?: "",
            lastName = userByTokenResponse.lastName ?: "",
            roles = userByTokenResponse.roles?.map { it.toRole() } ?: emptyList()
        )
    }

}

fun UserByTokenResponse.toUser(): User = UserMapper.mapFromUserByIdResponse(this)