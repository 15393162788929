package com.fireavert.administration_page.users.logic.add_user_dialog

import com.fireavert.administration_page.users.logic.add_user_dialog.models.request.SaveEditedUserRequest
import com.fireavert.administration_page.users.logic.add_user_dialog.models.request.SaveUserInfoRequest
import com.fireavert.administration_page.users.logic.user_info.UserInfoRepository
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.user.logic.models.RolesModel

class AddUserInteractor(
    private val screen: AddUserDialogScreen,
    private val userDialogRepository: UserDialogRepository,
    private val userInfoRepository: UserInfoRepository,
    private val propertyRepository: ClientPropertyRepository,
    private val logger: Logger
) {

    suspend fun onLoadNewUser() {
        screen.loading = true

        val properties = when (val maybe = propertyRepository.getProperties()) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                logger.e("Failed to load properties", maybe.exception)
                emptyList()
            }
        }

        val roleList = when (val maybe = userDialogRepository.getRoleList()) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                logger.e("Failed to load role list", maybe.exception)
                emptyList()
            }
        }

        screen.allProperties = properties
        screen.roleList = roleList



        screen.loading = false
    }

    suspend fun onLoadEditUser(userId: Int) {
        screen.loading = true
        if (userId == 0) {
            screen.loading = false
            return
        }
        // Load the initial data for the user
        onLoadNewUser()

        val userInfo = when (val maybe = userInfoRepository.getUserInfo(userId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                logger.e("Failed to load user info", maybe.exception)
                return
            }
        }

        screen.firstName = userInfo.name.split(" ")[0]
        screen.lastName = userInfo.name.split(" ")[1]
        screen.email = userInfo.email
        screen.phoneNumber = userInfo.phone
        screen.selectedRoles = userInfo.role.map { RolesModel.fromDisplayText(it) }
        screen.associatedProperties = userInfo.userLinkedToPropertyData.map { it.propertyId }
        screen.loading = false
    }

    suspend fun onEditUserSave(): Boolean {
        screen.loading = true
        val saveEditedUserRequest = SaveEditedUserRequest(
            screen.firstName,
            screen.lastName,
            screen.email,
            screen.phoneNumber,
            screen.selectedRoles,
            screen.associatedProperties
        )

        val result = when (val maybe = userDialogRepository.saveEditUser(saveEditedUserRequest)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                logger.e("Failed to save user", maybe.exception)
                false
            }
        }

        if (result) {
            screen.loading = false
            return true
        }
        else {
            screen.loading = false
            screen.errorMessage = "Failed to save the user!"
            return false
        }
    }



    suspend fun onNewUserSave(): Boolean {
        screen.loading = true

        val saveUserRequest = SaveUserInfoRequest(
            screen.firstName,
            screen.lastName,
            screen.email,
            screen.phoneNumber,
            screen.selectedRoles,
            screen.associatedProperties.map { it },
            screen.stoveTextNotifications,
            screen.stoveEmailNotifications,
            screen.leakTextNotifications,
            screen.leakEmailNotifications,
            screen.tamperTextNotifications,
            screen.tamperEmailNotifications,
            screen.smokeAlarmTextNotifications,
            screen.smokeAlarmEmailNotifications,
            screen.dailyReports,
            screen.weeklyReports,
            screen.monthlyReports,
            screen.quarterlyReports,
            screen.yearlyReports

        )

        val result = when (val maybe = userDialogRepository.saveUser(saveUserRequest)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                logger.e("Failed to save user", maybe.exception)
                false
            }
        }

        if (result) {
            screen.loading = false
            return true
        }
        else {
            screen.loading = false
            screen.errorMessage = "Failed to save the user!"
        }

        screen.loading = false
        return true
    }

}