import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import web.cssom.Auto
import web.cssom.px

external interface SearchableCheckboxListProps<T : Any> : Props {
    var items: List<T>
    var selectedItems: List<T>
    var associatedItems: List<T>
    var deletedItems: List<T>
    var onSelectionChange: (List<T>, List<T>) -> Unit
    var searchValue: String
    var getSearchString: (T) -> String
    var getPrimaryText: (T) -> String
    var getSecondaryText: (T) -> String
    var paperHeight: Int
}

val SearchableCheckboxList = FC<SearchableCheckboxListProps<*>> { props ->
    Paper {
        sx {
            height = props.paperHeight.px
            overflow = Auto.auto
        }
        List {
            @Suppress("UNCHECKED_CAST")
            props.items
                .filter { item ->
                    (props.getSearchString as (Any) -> String)(item)
                        .contains(props.searchValue, ignoreCase = true)
                }
                .forEach { item ->
                    ListItem {
                        ListItemIcon {
                            Checkbox {
                                checked = props.selectedItems.contains(item) ||
                                        props.associatedItems.contains(item)
                                onChange = { _, checked ->
                                    val newSelectedItems = if (checked) {
                                        props.selectedItems + item
                                    } else {
                                        props.selectedItems - item
                                    }
                                    val newDeletedItems = props.deletedItems - item

                                    props.onSelectionChange(
                                        newSelectedItems as List<Nothing>,
                                        newDeletedItems as List<Nothing>
                                    )
                                }
                            }
                        }
                        ListItemText {
                            primary = ReactNode((props.getPrimaryText as (Any) -> String)(item))
                            secondary = ReactNode((props.getSecondaryText as (Any) -> String)(item))
                        }
                    }
                }
        }
    }
}