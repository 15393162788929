package com.fireavert.administration_page.yardi_integration.presentation

import com.fireavert.integrations.yardi.shared.logic.models.YardiCredentials
import com.fireavert.management_companies.models.ManagementCompany

data class YardiCommonDataViewState(
    val loading: Boolean = false,
    val error: String = "",
    val availableCredentials: List<YardiCredentials> = emptyList(),
    val managementCompanyList: List<ManagementCompany> = emptyList(),
    val data: String = ""
)