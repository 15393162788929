package com.fireavert.reboot.presentation

import com.fireavert.reboot.logic.Reboot

class RebootPageController(private val reboot: Reboot) {
    suspend fun onLoad(deviceId: String?) = reboot.onLoad(deviceId = deviceId)
    suspend fun clickedSubmit(deviceId: String?) = reboot.submit(deviceId = deviceId)

    suspend fun textChanged(text: String, uuid: Boolean) {
        if (!uuid) {
            reboot.textChanged(text = text)
        } else {
            reboot.textChangedUUID(text = text)
        }
    }

    fun noIdFound() = reboot.noIdFound()
}