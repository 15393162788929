package com.fireavert.components.properties

import com.fireavert.components.targetInputValue
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.models.*
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.PropertyStyles
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import mui.material.*
import mui.system.sx
import mui.system.useMediaQuery
import react.*
import react.dom.html.ReactHTML.div
import react.dom.onChange
import web.cssom.*

external interface PropertyFormProps : Props {

    //PropertyName
    var propertyName: String
    var propertyNameError: Boolean
    var propertyNameChanged: (String) -> Unit
    var propPropertyId : Int

    //NumberOfUnits
    var numberOfUnits: Int
    var numberOfUnitsError: Boolean
    var numberOfUnitsChanged: (String) -> Unit

    var propertyClass: PropertyClass?
    var propertyClassError: Boolean
    var propertyClassChanged: (PropertyClass) -> Unit

    //Address
    var address: String
    var addressError: Boolean
    var addressChanged: (String) -> Unit

    //City
    var city: String
    var cityError: Boolean
    var cityChanged: (String) -> Unit

    //Geographical State
    var geographicalState: String
    var geographicalStateError: Boolean
    var geographicalStateChanged: (String) -> Unit

    //Zip
    var zip: String
    var zipError: Boolean
    var zipChanged: (String) -> Unit

    //Timezone ID
    var timezoneId: String
    var timezoneIdError: Boolean
    var timezoneIdChanged: (String) -> Unit
    var mainTimeZonesList: Array<String>

    //Management Company
    var managementCompanyList: Map<String, Int>
    var managementCompany: String
    var managementCompanyError: Boolean
    var managementCompanyChanged: (String, Int) -> Unit

    //Subscription Type
    var subscriptionType: SubscriptionType?
    var subscriptionTypeError: Boolean
    var subscriptionTypeChanged: (SubscriptionType) -> Unit

    //Mode
    var mode: Mode?
    var modeError: Boolean
    var modeChanged: (Mode) -> Unit

    //PropertyType
    var propertyType: PropertyType
    var propertyTypeError: Boolean
    var propertyTypeChanged: (PropertyType) -> Unit

    //Property Admins
    var addPropertyAdminClicked: () -> Unit
    var propertyAdminsChanged: (List<PropertyAdminModel>) -> Unit
    var linkPropertyAdminClicked: (PropertyAdminModel) -> Unit
    var propertyAdminModels: List<PropertyAdminModel>
    var unlinkedPropertyAdminModels: List<PropertyAdminModel>
    var removePropertyAdminFromServerClicked: (PropertyAdminModel) -> Unit
    var allowAddExistingPropertyAdmins: Boolean
    var resendPropertyAdminInviteClicked: (PropertyAdminModel) -> Unit

    //Fire Claims
    var fireClaimYearOneCost: Double
    var fireClaimYearOneClaims: Int
    var fireClaimYearTwoCost: Double
    var fireClaimYearTwoClaims: Int
    var fireClaimYearThreeCost: Double
    var fireClaimYearThreeClaims: Int
    var fireClaimYearFourCost: Double
    var fireClaimYearFourClaims: Int
    var fireClaimYearFiveCost: Double
    var fireClaimYearFiveClaims: Int
    var fireClaimsChangedOneCost : (Int, Double) -> Unit
    var fireClaimsChangedOneClaims : (Int, Int) -> Unit
    var fireClaimsChangedTwoCost : (Int, Double) -> Unit
    var fireClaimsChangedTwoClaims : (Int, Int) -> Unit
    var fireClaimsChangedThreeCost : (Int, Double) -> Unit
    var fireClaimsChangedThreeClaims : (Int, Int) -> Unit
    var fireClaimsChangedFourCost : (Int, Double) -> Unit
    var fireClaimsChangedFourClaims : (Int, Int) -> Unit
    var fireClaimsChangedFiveCost : (Int, Double) -> Unit
    var fireClaimsChangedFiveClaims : (Int, Int) -> Unit
    var fireClaimError : Boolean

    //Error message
    var errorMessage: String
}

external interface FireClaimsFormProps : Props {
    var fireClaimOneCost: Double
    var fireClaimOneClaims: Int
    var fireClaimTwoCost: Double
    var fireClaimTwoClaims: Int
    var fireClaimThreeCost: Double
    var fireClaimThreeClaims: Int
    var fireClaimFourCost: Double
    var fireClaimFourClaims: Int
    var fireClaimFiveCost: Double
    var fireClaimFiveClaims: Int
    var propertyId: Int

    var fireClaimsChangedOneCost: (Int, Double) -> Unit
    var fireClaimsChangedOneClaims: (Int, Int) -> Unit
    var fireClaimsChangedTwoCost: (Int, Double) -> Unit
    var fireClaimsChangedTwoClaims: (Int, Int) -> Unit
    var fireClaimsChangedThreeCost: (Int, Double) -> Unit
    var fireClaimsChangedThreeClaims: (Int, Int) -> Unit
    var fireClaimsChangedFourCost: (Int, Double) -> Unit
    var fireClaimsChangedFourClaims: (Int, Int) -> Unit
    var fireClaimsChangedFiveCost: (Int, Double) -> Unit
    var fireClaimsChangedFiveClaims: (Int, Int) -> Unit
}

val PropertyForm = FC<PropertyFormProps> { props ->
    val logger = getKoinInstance<Logger>()
    val FIRST_TAB = "Info"
    val SECOND_TAB = "FireClaim"
    val THIRD_TAB = "Admin"
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    var activeTab by useState(FIRST_TAB)

    var localPropertyName by useState(props.propertyName)
    var localNumberOfUnits by useState(props.numberOfUnits.toString())
    var localAddress by useState(props.address)
    var localCity by useState(props.city)
    var localZip by useState(props.zip)
    var localManagementCompany by useState(props.managementCompany)
    var selectedManagementCompanyId by useState(props)


    // We are using a local state so that we can edit any part of the text field without the cursor jumping.
    useEffect(
            props.propertyName,
            props.numberOfUnits,
            props.address,
            props.city,
            props.zip,
            props.managementCompany)
    {
        localPropertyName = props.propertyName
        localNumberOfUnits = props.numberOfUnits.toString()
        localAddress = props.address
        localCity = props.city
        localZip = props.zip
        localManagementCompany = props.managementCompany
    }

    Box {
        sx {
            width = 100.pct
            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
            }
        }

        Tabs {
            value = activeTab
            onChange = { _, newValue -> activeTab = newValue.toString() }
            ariaLabel = "wrapped label tabs example"
            variant = if (isMobile) TabsVariant.scrollable else TabsVariant.fullWidth
            allowScrollButtonsMobile = true

            sx {
                ".MuiTabs-flexContainer" {
                    width = 100.pct
                }
                ".MuiTab-root" {
                    flex = Flex(number(1.0), number(1.0), 100.pct.div(3))
                    maxWidth = 100.pct.div(3)
                    "&.Mui-selected" {
                        backgroundColor = Color("#f0f0f0")
                    }
                }
                borderBottom = Border(1.px, LineStyle.solid, Color("#E0E0E0"))
            }

            Tab {
                value = FIRST_TAB
                label = ReactNode("Property Info")
                wrapped = true
                sx {
                    borderRight = Border(1.px, LineStyle.solid, Color("#3B4354"))
                }
            }
            Tab {
                value = SECOND_TAB
                label = ReactNode("Fire Claims")
                sx {
                    borderRight = Border(1.px, LineStyle.solid, Color("#3B4354"))
                }
            }
            Tab {
                value = THIRD_TAB
                label = ReactNode("Admin/Notifications")
                sx {
                    borderRight = Border(1.px, LineStyle.solid, Color("#3B4354"))
                }
            }
        }
    }
    div {
        className = ClassName {
            width = 100.pct
            maxWidth = 694.px
            minWidth = 694.px
            minHeight = 650.px
            maxHeight = 650.px
            margin = Auto.auto
            padding = 10.px
            boxSizing = BoxSizing.borderBox

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
                padding = 5.px
                marginTop = 20.px
                minWidth = 100.pct
            }
        }
        when (activeTab) {
            FIRST_TAB -> {
                // Property Name and Number of Units
                div {
                    className = ClassName {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        gap = 10.px
                        marginBottom = 10.px

                        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                            flexDirection = FlexDirection.column
                        }
                    }

                    TextField {
                        label = ReactNode("Property Name")
                        value = localPropertyName
                        fullWidth = true
                        error = props.propertyNameError
                        onChange = { event ->
                            localPropertyName = event.targetInputValue
                            props.propertyNameChanged(event.targetInputValue)
                        }
                    }
                    div {
                        className = ClassName {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            gap = 10.px
                            marginBottom = 10.px

                            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                                flexDirection = FlexDirection.row
                            }
                        }

                        TextField {
                            label = ReactNode("# of Units")
                            value = localNumberOfUnits
                            fullWidth = true
                            error = props.numberOfUnitsError
                            onChange = { event ->
                                localNumberOfUnits = event.targetInputValue
                                props.numberOfUnitsChanged(event.targetInputValue)
                            }
                        }

                        FormControl {
                            fullWidth = true
                            InputLabel { id = "class-label"; +"Property Class" }
                            Select {
                                label = ReactNode("Property Class")
                                labelId = "class-label"
                                value = props.propertyClass ?: PropertyClass.UNKNOWN
                                error = props.propertyClassError
                                fullWidth = true
                                onChange = { event, _ ->
                                    props.propertyClassChanged(PropertyClass.valueOf(event.target.value))
                                }
                                MenuItem { +"A"; value = "A" }
                                MenuItem { +"B"; value = "B" }
                                MenuItem { +"C"; value = "C" }
                                MenuItem { +"D"; value = "D" }
                                MenuItem { +"Unknown"; value = "UNKNOWN" }
                            }
                        }
                    }
                }

                // Address
                TextField {
                    label = ReactNode("Address")
                    value = localAddress
                    fullWidth = true
                    error = props.addressError
                    onChange = { event ->
                        localAddress = event.targetInputValue
                        props.addressChanged(event.targetInputValue)
                    }
                    sx { marginBottom = 10.px }
                }

                // City, State, and Zip
                div {
                    className = ClassName {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        gap = 10.px
                        marginBottom = 10.px

                        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                            flexDirection = FlexDirection.row
                        }
                    }

                    TextField {
                        label = ReactNode("City")
                        fullWidth = true
                        value = localCity
                        error = props.cityError
                        onChange = { event ->
                            localCity = event.targetInputValue
                            props.cityChanged(event.targetInputValue)
                        }
                        className = ClassName { flexGrow = number(1.0) }
                    }

                    StateDropDown {
                        value = props.geographicalState
                        error = props.geographicalStateError
                        changed = { newState -> props.geographicalStateChanged(newState) }
                    }

                    TextField {
                        label = ReactNode("Zip")
                        value = localZip
                        error = props.zipError
                        onChange = { event ->
                            localZip = event.targetInputValue
                            props.zipChanged(event.targetInputValue)
                        }
                        className = ClassName { width = 200.px }
                    }
                }
                // Timezone
                FormControl {
                    fullWidth = true
                    className = ClassName { marginBottom = 10.px }
                    Autocomplete<AutocompleteProps<String>> {
                        onChange = { _, value, _, _ -> props.timezoneIdChanged(value.toString()) }
                        options = props.mainTimeZonesList
                        defaultValue = props.timezoneId
                        key = props.timezoneId
                        renderInput = { params ->
                            TextField.create {
                                variant = FormControlVariant.outlined
                                label = ReactNode("Timezone")
                                +params
                            }
                        }
                    }
                }

                // Timezone
                FormControl {
                    fullWidth = true
                    className = ClassName { marginBottom = 10.px }
                    Autocomplete<AutocompleteProps<String>> {
                        onChange = { _, value, _, _ -> props.managementCompanyChanged(value.toString(), props.managementCompanyList.getValue(value.toString())) }
                        options = props.managementCompanyList.keys.toTypedArray()
                        defaultValue = props.managementCompany
                        key = props.managementCompany
                        renderInput = { params ->
                            TextField.create {
                                variant = FormControlVariant.outlined
                                label = ReactNode("Management Company")
                                +params
                            }
                        }
                    }
                }

                // Subscription Type, Mode, and Property Type
                div {
                    className = ClassName {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        gap = 10.px
                        marginBottom = 10.px

                        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                            flexDirection = FlexDirection.column
                        }
                    }

                    FormControl {
                        fullWidth = true
                        InputLabel { id = "subscription-type-label"; +"Subscription Type" }
                        Select {
                            label = ReactNode("Subscription Type")
                            labelId = "subscription-type-label"
                            value = props.subscriptionType?.value ?: ""
                            error = props.subscriptionTypeError
                            fullWidth = true
                            onChange = { event, _ ->
                                props.subscriptionTypeChanged(SubscriptionType.valueOf(event.target.value))
                            }
                            MenuItem { +"Pro"; value = "Pro" }
                            MenuItem { +"Standard"; value = "Standard" }
                        }
                    }

                    FormControl {
                        fullWidth = true
                        InputLabel { id = "mode-label"; +"Mode" }
                        Select {
                            label = ReactNode("Mode")
                            labelId = "mode-label"
                            value = props.mode?.value ?: ""
                            error = props.modeError
                            fullWidth = true
                            onChange = { event, _ ->
                                props.modeChanged(Mode.valueOf(event.target.value))
                            }
                            MenuItem { +"Active"; value = "Active" }
                            MenuItem { +"Install"; value = "Install" }
                        }
                    }

                    FormControl {
                        fullWidth = true
                        InputLabel { id = "property-type-label"; +"Property Type" }
                        Select {
                            label = ReactNode("Property Type")
                            labelId = "property-type-label"
                            value = props.propertyType
                            error = props.propertyTypeError
                            fullWidth = true
                            onChange = { event, _ ->
                                props.propertyTypeChanged(PropertyType.valueOf(event.target.value))
                            }
                            MenuItem { +"Multi Family"; value = "MultiFamily" }
                            MenuItem { +"Single Family"; value = "SingleFamily" }
                        }
                    }
                }
                div {
                    className = ClassName {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        gap = 10.px
                        marginBottom = 10.px

                        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                            flexDirection = FlexDirection.column
                        }
                    }
                    // Sales Reps will go here when ready
                    FormControl {
                        fullWidth = true
                        Autocomplete<AutocompleteProps<String>> {
                            onChange = { _, value, _, _ -> (value) }
                            options = props.mainTimeZonesList
                            defaultValue = ""
                            key = ""
                            disabled = true
                            renderInput = { params ->
                                TextField.create {
                                    variant = FormControlVariant.outlined
                                    label = ReactNode("Sales Rep")
                                    +params
                                    disabled = true
                                }
                            }
                        }
                    }
                }
            }

            SECOND_TAB -> {
                // Fire Claims
                val FireClaimForm = FC<FireClaimsFormProps> { props ->
                    val currentYear = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year
                    val years = (0..4).map { currentYear - it }

                    val (claimCounts, setClaimCounts) = useState(listOf(
                        props.fireClaimOneClaims.toString(),
                        props.fireClaimTwoClaims.toString(),
                        props.fireClaimThreeClaims.toString(),
                        props.fireClaimFourClaims.toString(),
                        props.fireClaimFiveClaims.toString()
                    ))

                    val (claimCosts, setClaimCosts) = useState(listOf(
                        props.fireClaimOneCost.toString(),
                        props.fireClaimTwoCost.toString(),
                        props.fireClaimThreeCost.toString(),
                        props.fireClaimFourCost.toString(),
                        props.fireClaimFiveCost.toString()
                    ))

                    years.forEachIndexed { index, year ->
                        div {
                            className = ClassName {
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                gap = 10.px
                                marginBottom = 10.px
                            }

                            TextField {
                                label = ReactNode("$year Fire Claims")
                                value = claimCounts[index]
                                onChange = { event ->
                                    val newValue = event.target.asDynamic().value.toString()
                                    setClaimCounts { oldList ->
                                        oldList.toMutableList().apply { this[index] = newValue }
                                    }
                                }
                                onBlur = {
                                    val value = claimCounts[index].toIntOrNull() ?: 0
                                    when (index) {
                                        0 -> props.fireClaimsChangedOneClaims(year, value)
                                        1 -> props.fireClaimsChangedTwoClaims(year, value)
                                        2 -> props.fireClaimsChangedThreeClaims(year, value)
                                        3 -> props.fireClaimsChangedFourClaims(year, value)
                                        4 -> props.fireClaimsChangedFiveClaims(year, value)
                                    }
                                }
                                fullWidth = true
                            }

                            TextField {
                                label = ReactNode("$year Cost of Claims")
                                value = claimCosts[index]
                                onChange = { event ->
                                    val newValue = event.target.asDynamic().value.toString()
                                    setClaimCosts { oldList ->
                                        oldList.toMutableList().apply { this[index] = newValue }
                                    }
                                }
                                onBlur = {
                                    val value = claimCosts[index].toDoubleOrNull() ?: 0.0
                                    when (index) {
                                        0 -> props.fireClaimsChangedOneCost(year, value)
                                        1 -> props.fireClaimsChangedTwoCost(year, value)
                                        2 -> props.fireClaimsChangedThreeCost(year, value)
                                        3 -> props.fireClaimsChangedFourCost(year, value)
                                        4 -> props.fireClaimsChangedFiveCost(year, value)
                                    }
                                }
                                fullWidth = true
                            }
                        }
                    }
                }

                FireClaimForm {
                    fireClaimOneClaims = props.fireClaimYearOneClaims
                    fireClaimOneCost = props.fireClaimYearOneCost
                    fireClaimTwoClaims = props.fireClaimYearTwoClaims
                    fireClaimTwoCost = props.fireClaimYearTwoCost
                    fireClaimThreeClaims = props.fireClaimYearThreeClaims
                    fireClaimThreeCost = props.fireClaimYearThreeCost
                    fireClaimFourClaims = props.fireClaimYearFourClaims
                    fireClaimFourCost = props.fireClaimYearFourCost
                    fireClaimFiveClaims = props.fireClaimYearFiveClaims
                    fireClaimFiveCost = props.fireClaimYearFiveCost

                    propertyId = props.propPropertyId
                    fireClaimsChangedOneCost = props.fireClaimsChangedOneCost
                    fireClaimsChangedOneClaims = props.fireClaimsChangedOneClaims
                    fireClaimsChangedTwoCost = props.fireClaimsChangedTwoCost
                    fireClaimsChangedTwoClaims = props.fireClaimsChangedTwoClaims
                    fireClaimsChangedThreeCost = props.fireClaimsChangedThreeCost
                    fireClaimsChangedThreeClaims = props.fireClaimsChangedThreeClaims
                    fireClaimsChangedFourCost = props.fireClaimsChangedFourCost
                    fireClaimsChangedFourClaims = props.fireClaimsChangedFourClaims
                    fireClaimsChangedFiveCost = props.fireClaimsChangedFiveCost
                    fireClaimsChangedFiveClaims = props.fireClaimsChangedFiveClaims

                }
            }
            THIRD_TAB -> {
                AddRemovePropertyAdminContainer {
                    addPropertyAdminClicked = { props.addPropertyAdminClicked() }
                    propertyAdminsChanged = { props.propertyAdminsChanged(it) }
                    linkPropertyAdminClicked = { props.linkPropertyAdminClicked(it) }
                    unLinkedPropertyAdmins = props.unlinkedPropertyAdminModels
                    propertyAdmins = props.propertyAdminModels
                    removePropertyAdminFromServerClicked = { props.removePropertyAdminFromServerClicked(it) }
                    allowAddExisting = props.allowAddExistingPropertyAdmins
                    resendInviteClicked = props.resendPropertyAdminInviteClicked
                }
            }
        }

        // Error message
        if (props.errorMessage.isNotBlank()) {
            div {
                className = PropertyStyles.propertyError
                +"Error: ${props.errorMessage}"
            }
        }
    }
}