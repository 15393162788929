package com.fireavert.reports_page.logic.models.requests

import kotlinx.serialization.Serializable

@Serializable
data class ReportsExportDialogRequest(
    val startDate: Long,
    val endDate: Long,
    val propertyId: Int?,
    val includeOffline: Boolean
)
