package com.fireavert.events.activedeviceevents.logic

import com.fireavert.common.TableColumn
import com.fireavert.common.TableColumn.SortOrder.Neutral
import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.DeviceType.FireAvert
import com.fireavert.devices.logic.models.DeviceType.FireAvertGas
import com.fireavert.devices.logic.models.DeviceType.FireAvertAppliance
import com.fireavert.devices.logic.models.DeviceType.FlowSensor
import com.fireavert.devices.logic.models.DeviceType.TamperSensor
import com.fireavert.devices.logic.models.DeviceType.WaterSensor
import com.fireavert.events.activeevents.logic.ActiveEventsRepository
import com.fireavert.events.logic.InstantTimeStringFormatter
import com.fireavert.events.logic.models.EventType
import com.fireavert.events.logic.models.EventTypeIconOption
import com.fireavert.events.logic.models.UnitEventTotals
import com.fireavert.logging.Logger
import com.fireavert.units.logic.UnitsRepository

class ClientActiveDeviceEvents(
    private val deviceRepository: DeviceRepository,
    private val unitsRepository: UnitsRepository,
    private val activeEventsRepository: ActiveEventsRepository,
    private val screen: ActiveDeviceEventsScreen,
    private val logger: Logger
) : ActiveDeviceEvents {
    override suspend fun onLoad(propertyId: Int) {
        screen.setLoading(true)

        val activeDeviceEventsResponse = when (val maybeActiveDeviceEvents =
            activeEventsRepository.getActiveEventsForProperty(propertyId)) {
            is Error -> {
                screen.setLoading(false)
                logger.e("Failed to get active device events for propertyId $propertyId")
                null
            }
            is Success -> maybeActiveDeviceEvents.value
        } ?: return

        val unitEventTotalsMap = mutableMapOf<Int, UnitEventTotals>()

        val hasFireAvert =
            activeDeviceEventsResponse.activeDeviceEvents.any { it.deviceType == FireAvert || it.deviceType == FireAvertGas || it.deviceType == FireAvertAppliance }
        val hasWaterSensor =
            activeDeviceEventsResponse.activeDeviceEvents.any { it.deviceType == WaterSensor }
        val hasFlowSensor =
            activeDeviceEventsResponse.activeDeviceEvents.any { it.deviceType == FlowSensor }
        val hasTamperSensor =
            activeDeviceEventsResponse.activeDeviceEvents.any { it.deviceType == TamperSensor }

        for (event in activeDeviceEventsResponse.activeDeviceEvents) {
            val existingTotals = unitEventTotalsMap[event.unitId] ?: UnitEventTotals(
                alarmEvents = if (hasFireAvert) 0 else -1,
                shutoffEvents = if (hasFireAvert) 0 else -1,
                leakEvents = if (hasWaterSensor) 0 else -1,
                flowEvents = if (hasFlowSensor) 0 else -1,
                tamperEvents = if (hasTamperSensor) 0 else -1
            )
            val newAlarmEvents = when (event.deviceType) {
                FireAvert, FireAvertGas, FireAvertAppliance -> {
                    when (event.eventType) {
                        EventType.SmokeAlarmActive -> {
                            existingTotals.copy(
                                alarmEvents = existingTotals.alarmEvents + 1
                            )
                        }
                        EventType.DeviceTriggered -> {
                            existingTotals.copy(
                                shutoffEvents = existingTotals.shutoffEvents + 1
                            )
                        }
                        else -> {
                            existingTotals
                        }
                    }
                }
                WaterSensor -> {
                    if (event.eventType == EventType.DeviceTriggered) {
                        existingTotals.copy(
                            leakEvents = existingTotals.leakEvents + 1
                        )
                    } else {
                        existingTotals
                    }
                }
                FlowSensor -> {
                    if (event.eventType == EventType.DeviceTriggered) {
                        existingTotals.copy(
                            flowEvents = existingTotals.flowEvents + 1
                        )
                    } else {
                        existingTotals
                    }
                }
                TamperSensor -> {
                    if (event.eventType == EventType.DeviceTriggered) {
                        existingTotals.copy(
                            tamperEvents = existingTotals.tamperEvents + 1
                        )
                    } else {
                        existingTotals
                    }
                }
            }

            unitEventTotalsMap[event.unitId] = newAlarmEvents

        }


        screen.setAlarmOnDeviceMap(activeDeviceEventsResponse.alarmActiveDeviceMap)
        screen.setUnitEventTotalsMap(unitEventTotalsMap)
        screen.setActiveDeviceEvents(activeDeviceEventsResponse.activeDeviceEvents)
        screen.setLoading(false)
    }

    override fun getTimeStringForEvent(event: ActiveDeviceEvent, timezoneId: String): String {
        return InstantTimeStringFormatter.formatA(instant = event.timestamp, zoneId = timezoneId)
    }

    override fun stringOutputForCount(count: Int): String {
        return if (count == -1) "-" else count.toString()
    }

    override fun sortWithColumns(
        columns: Array<TableColumn>,
        events: List<ActiveDeviceEvent>,
        unitEventTotalsMap: Map<Int, UnitEventTotals>
    ): List<ActiveDeviceEvent> {

        val sortColumn = columns.firstOrNull { it.sortOrder != Neutral } ?: return events

        return when (sortColumn.text) {
            "UNIT" -> {
                TableColumn.sortList(true, sortColumn, events) { it.unit }
            }
            "DATE/TIME" -> {
                TableColumn.sortList(false, sortColumn, events) { it.timestamp }
            }
            "EVENT TYPE" -> {
                TableColumn.sortList(
                    true,
                    sortColumn,
                    events
                ) { EventTypeIconOption.from(it.deviceType, it.eventType) }
            }
            "SMOKE EVENTS" -> {
                TableColumn.sortList(false, sortColumn, events) {
                    val totals = unitEventTotalsMap[it.unitId] ?: UnitEventTotals()
                    totals.alarmEvents
                }
            }
            "STOVE SHUTOFFS" -> {
                TableColumn.sortList(false, sortColumn, events) {
                    val totals = unitEventTotalsMap[it.unitId] ?: UnitEventTotals()
                    totals.shutoffEvents
                }
            }
            "LEAK EVENTS" -> {
                TableColumn.sortList(false, sortColumn, events) {
                    val totals = unitEventTotalsMap[it.unitId] ?: UnitEventTotals()
                    totals.leakEvents
                }
            }
            "TAMPER EVENTS" -> {
                TableColumn.sortList(false, sortColumn, events) {
                    val totals = unitEventTotalsMap[it.unitId] ?: UnitEventTotals()
                    totals.tamperEvents
                }
            }
            else -> {
                events
            }
        }
    }
}