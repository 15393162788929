package com.fireavert.auth.interface_adapters

import com.fireavert.auth.logic.PageAuthenticator
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.UserRepository

class ClientPageAuthenticator(
    private val userRepository: UserRepository,
    private val preferences: Preferences): PageAuthenticator {
    override suspend fun validatePageAccess(pageName: String): Boolean {

        return when (val user = userRepository.authenticateUserPageAccess(preferences.userId, pageName)) {
            is Try.Success -> {
                user.value
            }

            is Try.Error -> {
                false
            }
        }
    }
}