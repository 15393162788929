package com.fireavert.gateways.models

import com.fireavert.units.logic.models.UnitStatus
import kotlinx.serialization.Serializable

@Serializable
data class GatewayStatusModel(
    val gatewayId: String,
    var lastUplinkReceived: Long,
    val connectionStatus: UnitStatus
)
