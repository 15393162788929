package com.fireavert.administration_page.users.logic

import com.fireavert.administration_page.users.logic.user_section.UserSectionScreen
import com.fireavert.common.TableColumn
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.user.logic.models.RolesModel

class UserSectionInteractor(
    private val screen: UserSectionScreen,
    private val userSectionRepository: UserSectionRepository,
    private val logger: Logger
) {
    fun searchAndSortTrackingRecordsByColumn(
        columns: Array<TableColumn>,
        trackingRecords: List<UserTrackingRecord>,
        searchValue: String,
        roleTypes: List<String> = emptyList()
    ): List<UserTrackingRecord> {
        // Search for the records that match the search value first
        val filteredRecords = searchUsers(searchValue, trackingRecords)

        // Filter by role types if any are selected
        val roleFilteredRecords = if (roleTypes.isNotEmpty()) {
            filterUsersByRoleType(filteredRecords, roleTypes)
        } else {
            filteredRecords
        }

        val sortColumn =
            columns.firstOrNull() { it.sortOrder != TableColumn.SortOrder.Neutral } ?: return roleFilteredRecords

        return when (sortColumn.text) {
            "Name" -> {
                TableColumn.sortList(true, sortColumn, roleFilteredRecords) { it.name }
            }

            "Email" -> {
                TableColumn.sortList(true, sortColumn, roleFilteredRecords) { it.email }
            }

            "Company" -> {
                // Fix: Sort by managementCompany instead of phone
                TableColumn.sortList(true, sortColumn, roleFilteredRecords) { it.managementCompany }
            }
            else -> roleFilteredRecords
        }
    }

    private fun filterUsersByRoleType(
        users: List<UserTrackingRecord>,
        roleTypes: List<String>
    ): List<UserTrackingRecord> {
        return users.filter { user ->
            roleTypes.any { roleType ->
                when (roleType) {
                    RolesModel.EXECUTIVE.displayText -> user.roles.contains(RolesModel.EXECUTIVE)
                    RolesModel.PROPERTY_ADMIN.displayText -> user.roles.contains(RolesModel.PROPERTY_ADMIN)
                    RolesModel.MAINTENANCE_USER.displayText -> user.roles.contains(RolesModel.MAINTENANCE_USER)
                    RolesModel.TENANT.displayText -> user.roles.contains(RolesModel.TENANT)
                    else -> false
                }
            }
        }
    }

    private fun searchUsers(searchValue: String, trackingRecords: List<UserTrackingRecord>): List<UserTrackingRecord> {
        return trackingRecords.filter { record ->
            record.name.contains(searchValue, ignoreCase = true) ||
                    record.email.contains(searchValue, ignoreCase = true) ||
                    record.phone.contains(searchValue, ignoreCase = true) ||
                    record.managementCompany.contains(searchValue, ignoreCase = true)
        }.toList()
    }

    suspend fun onLoad() {
        screen.loading = true
        val userTrackingData = when (val maybe = userSectionRepository.getUserTrackingData()) {
            is Try.Error -> {
                logger.e("Failed to get user tracking data. " + maybe.exception.message)
                screen.loading = false
                null
            }

            is Try.Success -> maybe.value
        } ?: return

        screen.trackingData = userTrackingData
        screen.loading = false
    }
}