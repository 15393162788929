package com.fireavert.components.reports

import ChartsReferenceLine
import com.fireavert.components.charts.LineChartInt
import com.fireavert.properties.logic.models.FireClaimData
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.ReportPageStyles
import emotion.react.css
import kotlinx.browser.window
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.system.sx
import mui.system.useMediaQuery
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML
import web.cssom.*

external interface ReportsEventsOverTimeChartProps: Props {
    var loadingProp: Boolean
    var lastFiveYearsProp: Array<String>
    var lastFiveYearClaimsProp: Array<FireClaimData>
    var yearStartedUsingFireAvertProp: Int
    var chartWidthProp: Int
    var propertyIdProps: Int?
}

val EventsOverTimeChart = FC<ReportsEventsOverTimeChartProps> { props ->
    //global variables for the report section
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)

    ReactHTML.div {
        className = ReportPageStyles.titleHeading
        +"Events Over Time"
    }

    ReactHTML.div {
        className = ReportPageStyles.chartContainer
        if (!props.loadingProp) {
            if (props.propertyIdProps == null) {
                ReactHTML.div {
                    className = ReportPageStyles.noDataMessage
                    +"Fire Claim Data chart is not available for all properties, please select a property."
                }
            }
            else if (props.lastFiveYearClaimsProp.isEmpty() || props.lastFiveYearClaimsProp.all { it.claimCount == 0 }) {
                ReactHTML.div {
                    className = ReportPageStyles.noDataMessage
                    +"No fire claim data available for the selected property. Please fill out the Fire Claim Data form to see the chart and estimated savings."
                }

                ReactHTML.div {
                    css {
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        width = 100.pct
                        marginBottom = 25.px
                    }

                    Button {
                        sx {
                            borderRadius = 20.px
                            backgroundColor = Color("#CF3338")
                            width = 50.pct

                        }
                        onClick = {
                            window.open("https://forms.gle/dWdcJbVS9sVatK8v7", "_blank")
                        }
                        variant = ButtonVariant.contained
                        +"Fill Out Loss Report"
                    }
                }
            } else {
                LineChartInt {
                    xAxis = arrayOf(jsObject {
                        id = "months"
                        this.data = props.lastFiveYearsProp
                        scaleType = "band"
                    })
                    series = arrayOf(jsObject {
                        this.data = props.lastFiveYearClaimsProp.map { it.claimCount }.toTypedArray()
                        label = "Fire Events"
                    })
                    width = props.chartWidthProp
                    height = if (isMobile) 300 else 500
                    axisHighlight = jsObject {
                        x = "band"
                    }
                    // Add the reference line as a child
                    children = arrayOf<ReactNode>(
                        ChartsReferenceLine.create {
                            x = props.yearStartedUsingFireAvertProp.toString()
                            label = if (isMobile) "Started\nUsing\nFireAvert" else "Started Using FireAvert"
                        }
                    )
                }
            }
        } else {
            Skeleton {
                variant = SkeletonVariant.rectangular
                width = 100.pct
                height = if (isMobile) 300.px else 500.px
            }
        }
    }

}