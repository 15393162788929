package com.fireavert.administration_page.yardi_integration.interface_adaptors

import com.fireavert.administration_page.yardi_integration.presentation.YardiCommonDataScreen
import com.fireavert.administration_page.yardi_integration.presentation.YardiCommonDataViewState
import com.fireavert.common.ViewModel
import com.fireavert.integrations.yardi.shared.logic.models.YardiCredentials
import com.fireavert.management_companies.models.ManagementCompany
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class YardiCommonDataViewModel: YardiCommonDataScreen, ViewModel() {
    private val _viewState = MutableStateFlow(YardiCommonDataViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope

    override var loading: Boolean
        get() = viewState.value.loading
        set(value) {
            _viewState.value = viewState.value.copy(loading = value)
        }
    override var error: String
        get() = viewState.value.error
        set(value) {
            _viewState.value = viewState.value.copy(error = value)
        }
    override var managementCompanyList: List<ManagementCompany>
        get() = viewState.value.managementCompanyList
        set(value) {
            _viewState.value = viewState.value.copy(managementCompanyList = managementCompanyList)
        }
    override var data: String
        get() = viewState.value.data
        set(value) {
            _viewState.value = viewState.value.copy(data = value)
        }

    override var availableCredentials: List<YardiCredentials>
        get() = viewState.value.availableCredentials
        set(value) {
            _viewState.value = viewState.value.copy(availableCredentials = value)
        }

}