package com.fireavert.router

import com.fireavert.components.error.ErrorPage
import com.fireavert.components.pages.AdministrationPageV2
import com.fireavert.components.pages.administration.*
import com.fireavert.menu.RoutePaths
import com.fireavert.menu.paths.AdministrationPagePaths
import js.objects.jso
import react.create
import react.router.RouteObject

val AdminRoutes = arrayOf<RouteObject>(
    /** Admin Page Routes **/
    jso {
        path = "${RoutePaths.administration}/users/:userFilter"
        element = AdminUserPage.create {
            addNewUserDialogIsOpen = false
            editUserDialogIsOpen = false

        }
    },
    jso {
        path = "${RoutePaths.administration}/user/:userId"
        element = AdminUserInfoPage.create {
        }
    },
    jso {
        path = AdministrationPagePaths.newUser
        element = AdminUserPage.create {
            addNewUserDialogIsOpen = true
            editUserDialogIsOpen = false
        }
    },
    jso {
        path = "${AdministrationPagePaths.editUser}/:userId"
        element = AdminUserPage.create {
            editUserDialogIsOpen = true
            addNewUserDialogIsOpen = false
        }
    },
    jso {
        path = "${RoutePaths.administration}/tools"
        element = AdminToolsPage.create {
        }
    },
    jso {
        path = "${RoutePaths.administration}/gateways"
        element = AdminGatewayPage.create {
        }
    },
    jso {
        path = "${RoutePaths.administration}/yardi/commonData"
        element = AdminYardiCommonDataPage.create {
        }
    },
//    jso {
//        path = "${RoutePaths.administration}/yardi/commonData"
//        element = AdminYardiServiceRequestsPage.create {
//        }
//    },
    jso {
        path = "${RoutePaths.administration}/companies"
        element = AdminManagementCompanyPage.create {}
    },
    jso {
        path = "${RoutePaths.administration}/companies/new"
        element = AdminManagementCompanyPage.create {
            isNewDialog = true
        }
    },
    jso {
        path = "${RoutePaths.administration}/companies/edit/{companyId}"
        element = AdminManagementCompanyPage.create {
            isEditDialog = true
        }
    },
    jso {
        path = RoutePaths.administration
        element = AdministrationPageV2.create {
        }
        ErrorBoundary = ErrorPage
    },

)