package com.fireavert.units.presentation

import com.fireavert.buildings.logic.models.BuildingObject
import com.fireavert.units.logic.models.UnitModel
data class UnitsSectionViewState(
    val unitModels: List<UnitModel> = emptyList(),
    val loading: Boolean = true,
    val showAddUnit: Boolean = false,
    val showEditUnit: Boolean = false,
    val showDeleteUnit: Boolean = false,
    val buildingList: List<BuildingObject> = emptyList(),
)
