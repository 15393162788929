package com.fireavert.gateways.models.request

import com.fireavert.gateways.models.GatewayModel
import kotlinx.serialization.Serializable

@Serializable
data class PropertyGatewayChangeRequest(
    val propertyId: Int,
    val finalGatewayList: List<GatewayModel>,
)
