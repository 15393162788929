package com.fireavert.administration_page.users.interface_adapters.user_info

import com.fireavert.administration_page.users.logic.user_info.UserInfoRepository
import com.fireavert.administration_page.users.logic.user_info.models.UserInfoResponse
import com.fireavert.administration_page.users.logic.user_info.models.UserNotificationChangeRequest
import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.logic.models.UserLinkedToProperty
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class UserInfoRepositoryImpl(
    private val dataSource: UserInfoRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
) : UserInfoRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun getUserInfo(userId: Int): Try<UserInfoResponse> = withContext(ioContext) {
        callWithRefresh { dataSource.getUserInfo(userId, preferences.token) }
            .map { it }
    }

    override suspend fun saveNotificationChanges(userId: Int, userToPropertyInfo: List<UserLinkedToProperty>) = withContext(ioContext) {
        callWithRefresh { dataSource.saveNotificationChanges(UserNotificationChangeRequest(
            userId = userId,
            userToPropertyInfo = userToPropertyInfo
        ), preferences.token) }
    }
}