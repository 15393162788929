package com.fireavert.administration_page.users.logic.user_info.models

import com.fireavert.properties.logic.models.UserLinkedToProperty
import kotlinx.serialization.Serializable

@Serializable
data class UserNotificationChangeRequest(
    val userId: Int,
    val userToPropertyInfo: List<UserLinkedToProperty>
)
