package com.fireavert.components.dialogs

import com.fireavert.components.list.SearchableCheckboxList
import com.fireavert.components.list.SearchableCheckboxListProps
import com.fireavert.components.targetInputValue
import com.fireavert.logging.Logger
import com.fireavert.management_companies.presentation.ManagementCompaniesViewModel
import com.fireavert.management_companies.presentation.ManagementCompanyController
import com.fireavert.properties.logic.models.Property
import com.fireavert.styles.StandardizedStyles
import com.fireavert.styles.global_styles.ErrorStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.div
import react.dom.onChange
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType

external interface AddNewManagementCompanyDialogProps : Props {
    var closeDialog: () -> Unit
}

val AddNewManagementCompanyDialog = FC<AddNewManagementCompanyDialogProps> { props ->
    val viewModel = getKoinInstance<ManagementCompaniesViewModel>()
    val controller = getKoinInstance<ManagementCompanyController>()
    val logger = getKoinInstance<Logger>()

    var name by useState("")
    var associatedProperties by useState(emptyList<Int>())
    var loading by useState(false)
    var properties by useState(emptyList<Property>())
    var deletedProperties by useState(emptyList<Property>())
    var selectedProperties by useState(emptySet<Property>())
    var (searchValue, setSearchValue) = useState("")
    var error by useState("")

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            associatedProperties = viewState.associatedProperties
            loading = viewState.loading
            properties = viewState.properties
            error = viewState.error
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoadNew()
        }

        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    Dialog {
        open = true
        onClose = { _, _ -> run { error = ""; props.closeDialog() } }
        maxWidth = Breakpoint.lg
        fullWidth = true
        sx {
            "& .MuiDialog-paper" {
                width = 100.pct
                maxWidth = 600.px
                height = 600.px
                margin = Auto.auto
            }
        }

        DialogTitle {
            +"Add New Management Company"
            css {
                background = NamedColor.white
                color = NamedColor.black
                fontWeight = FontWeight.bold
            }
        }
        DialogContent {
            div {
                css {marginTop = 25.px}
            }

            TextField {
                InputLabel { id = "class-label"; +"Title" }
                label = ReactNode("Company Title")
                value = name
                fullWidth = true
                type = InputType.text
                onChange = { event ->
                    name = event.targetInputValue
                }
                sx {
                    marginBottom = 20.px
                }
            }

            div {
                className = StandardizedStyles.HeaderH2
                +"Selected Properties"
            }
            div {
                css {
                    marginBottom = 16.px
                    maxHeight = 150.px
                    overflowY = Auto.auto
                }
                selectedProperties.forEach { property ->
                    div {
                        css {
                            padding = 4.px
                            borderRadius = 4.px
                            backgroundColor = Color("#f5f5f5")
                            marginBottom = 4.px
                        }
                        +property.name
                    }
                }
            }

            TextField {
                value = searchValue
                onChange = { event ->
                    val target = event.target as? HTMLInputElement
                    setSearchValue(target?.value ?: "")
                }
                placeholder = "Search properties..."
                fullWidth = true
                sx {
                    marginBottom = 2.px
                }
            }
            SearchableCheckboxList<SearchableCheckboxListProps<Property>> {
                items = properties
                selectedItems = selectedProperties.toList()
                associatedItems = selectedProperties.toList()
                deletedItems = deletedProperties
                this.searchValue = searchValue
                getSearchString = { property ->
                    "${property.name} ${property.managementCompany}"
                }
                getPrimaryText = { property -> property.name }
                getSecondaryText = { property -> property.managementCompany }
                onSelectionChange = { newSelected, newDeleted ->
                    selectedProperties = newSelected.toSet()
                    deletedProperties = newDeleted
                }
                paperHeight = 400
            }

            if (error.isNotEmpty()) {
                div {
                    className = ErrorStyles.errorDialogMessage
                    +error
                }
            }
        }
        DialogActions {
            sx {
                "& .MuiDialogActions-root" {
                    justifyContent = JustifyContent.center
                }
            }
            css {
                height = 104.px
                paddingRight = 24.px
            }
            Button {
                +"Cancel"
                color = ButtonColor.inherit
                onClick = { props.closeDialog() }
                variant = ButtonVariant.contained
                css {
                    width = 163.px
                    marginRight = 6.px
                    borderRadius = 25.px
                }
            }
            Button {
                +"Save Changes"
                color = ButtonColor.info
                onClick = {
                    viewModel.scope.launch {
                        if (controller.onSaveNew(name, selectedProperties.map { it.id })) {
                            props.closeDialog()
                        }
                        else { }
                    }
                }
                variant = ButtonVariant.contained
                css {
                    width = 163.px
                    borderRadius = 25.px
                }
            }
        }
    }
}