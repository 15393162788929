package com.fireavert.gateways.interface_adaptors

import com.fireavert.common.ViewModel
import com.fireavert.gateways.logic.GatewayPropertyScreen
import com.fireavert.gateways.models.GatewayModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class GatewayPropertyViewModel: GatewayPropertyScreen, ViewModel() {
    private val _viewState = MutableStateFlow(GatewayPropertyViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var loading: Boolean
        get() { return viewState.value.loading }
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }

    override var associatedGateways: List<GatewayModel>
        get() { return viewState.value.associatedGateways }
        set(value) {
            _viewState.value = _viewState.value.copy(associatedGateways = value.toList())
        }

    override var allGateways: List<GatewayModel>
        get() { return viewState.value.allGateways }
        set(value) {
            _viewState.value = _viewState.value.copy(allGateways = value.toList())
        }
    override var gatewayDeviceData: Map<String, List<String>>
        get() { return viewState.value.gatewayDeviceData }
        set(value) {
            _viewState.value = _viewState.value.copy(gatewayDeviceData = value.toMap())
        }
    override var gatewayDeviceDataLoading: Boolean
        get() { return viewState.value.gatewayDeviceDataLoading }
        set(value) {
            _viewState.value = _viewState.value.copy(gatewayDeviceDataLoading = value)
        }
}