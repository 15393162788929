package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import web.cssom.*

object NotificationCheckboxStyles {

    val modernSectionStyles = ClassName {
        marginBottom = 16.px  // Reduced margin
        backgroundColor = NamedColor.azure
        borderRadius = 8.px
        boxShadow = BoxShadow(0.px, 2.px, 4.px, Color("blue"))
        transition = Transition(
            property = TransitionProperty.all,
            duration = 300.ms,
            timingFunction = TransitionTimingFunction.easeOut
        )
        overflow = Overflow.hidden  // Ensure content doesn't overflow
    }

    val modernSectionHeaderStyles = ClassName {
        padding = Padding(12.px, 16.px)
        backgroundColor = Color("rgba(0, 0, 0, 0.02)")
        borderBottom = Border(1.px, LineStyle.solid, NamedColor.black)
    }

    val modernSectionContentStyles = ClassName {
        display = Display.grid
        // Start with 4 items per row by default
        gridTemplateColumns = repeat(4, 1.fr.unsafeCast<GridTemplateTracks>())

        // Media queries for responsive behavior
        media(MediaQuery("(max-width: 1200px)")) {
            gridTemplateColumns = repeat(3, 1.fr.unsafeCast<GridTemplateTracks>())
        }

        media(MediaQuery("(max-width: 900px)")) {
            gridTemplateColumns = repeat(2, 1.fr.unsafeCast<GridTemplateTracks>())
        }

        media(MediaQuery("(max-width: 600px)")){
            gridTemplateColumns = repeat(1, 1.fr.unsafeCast<GridTemplateTracks>())
        }
    }

    val modernCheckBoxCell = ClassName {
        display = Display.flex
        alignItems = AlignItems.center
        padding = Padding(8.px, 16.px)
        transition = Transition(
            property = TransitionProperty.all,
            duration = 200.ms,
            timingFunction = TransitionTimingFunction.easeInOut
        )
        borderRadius = 4.px
        minWidth = 70.px  // Ensure minimum width
        maxWidth = 0.px  // Limit maximum width
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.column
        }
    }
}