package com.fireavert.components.properties

import com.fireavert.styles.NotificationCheckboxStyles.modernCheckBoxCell
import mui.material.Switch
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import web.cssom.NamedColor
import web.cssom.number
import web.cssom.px

external interface CheckBoxCellProps: Props {
    var checked: Boolean
    var onChange: (Boolean) -> Unit
    var text: String
}

val ModernCheckBoxCell = FC<CheckBoxCellProps> { props ->
    div {
        className = modernCheckBoxCell
        Switch {
            checked = props.checked
            onChange = { _, value -> props.onChange(value) }
            sx {
                color = NamedColor.red
            }
        }

        Typography {
            sx {
                marginLeft = 8.px
                fontSize = 14.px
                color = NamedColor.black
                flexGrow = number(1.0)
            }
            +props.text
        }
    }
}