package com.fireavert.buildings.interface_adapters

import com.fireavert.buildings.logic.AddNewBuildingScreen
import com.fireavert.buildings.logic.models.BuildingObject
import com.fireavert.common.ViewModel
import com.fireavert.units.logic.models.UnitModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class AddNewBuildingViewModel : AddNewBuildingScreen, ViewModel() {
    private val _viewState = MutableStateFlow(AddNewBuildingViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var loading: Boolean
        get() { return viewState.value.loading }
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
    override var buildings: List<BuildingObject>
        get() { return viewState.value.buildings }
        set(value) {
            _viewState.value = _viewState.value.copy(buildings = value)
        }
    override var error: String
        get() { return viewState.value.error }
        set(value) {
            _viewState.value = _viewState.value.copy(error = value)
        }
    override var availableUnitList: List<UnitModel>
        get() { return viewState.value.availableUnitList }
        set(value) {
            _viewState.value = _viewState.value.copy(availableUnitList = value)
        }
}