package com.fireavert.components.dialogs

import SearchableCheckboxList
import SearchableCheckboxListProps
import com.fireavert.buildings.interface_adapters.AddNewBuildingViewModel
import com.fireavert.buildings.logic.models.BuildingObject
import com.fireavert.buildings.presentation.AddNewBuildingController
import com.fireavert.logging.Logger
import com.fireavert.styles.BuildingStyles
import com.fireavert.styles.DialogStyles
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.units.logic.models.UnitModel
import com.fireavert.units.presentation.UnitsSectionViewModel
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import mui.system.useMediaQuery
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.html.ReactHTML.div
import react.dom.onChange
import web.cssom.*

external interface AddBuildingDialogProps : Props {
    var closeDialog: () -> Unit
    var units: List<UnitModel>
    var viewModel: UnitsSectionViewModel
    var propertyId: Int
}

val AddBuildingDialog = FC<AddBuildingDialogProps> { props ->
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    val logger = getKoinInstance<Logger>()

    val controller = getKoinInstance<AddNewBuildingController>()
    val viewModel = getKoinInstance<AddNewBuildingViewModel>()
    val associatedUnits: List<UnitModel> by useState(emptyList())
    var allUnits: List<UnitModel> by useState(props.units)
    var availableUnits: List<UnitModel> by useState(props.units)
    var selectedUnits by useState(associatedUnits)
    var deletedUnits by useState(emptySet<UnitModel>())
    var buildingName: String by useState("")

    var loading: Boolean by useState(false)
    var error: String by useState("")
    var buildingsForProperty: List<BuildingObject> by useState(emptyList())
    val (openDialog, setOpenDialog) = useState(false)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
            error = viewState.error
            buildingsForProperty = viewState.buildings
            availableUnits = viewState.availableUnitList
        }.launchIn(viewModel.scope)
        val onLoadJob = viewModel.scope.launch {
            controller.getBuildingsForProperty(props.propertyId, availableUnits)
        }
        onCleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = { props.closeDialog() }
        onClose = { _, _ -> props.closeDialog() }
        maxWidth = Breakpoint.lg

        DialogTitle {
            className = DialogStyles.dialogTitle
            +"Add New Building"
        }

        DialogContent {
            className = BuildingStyles.buildingDialogContent

            div {
                className = BuildingStyles.buildingDialogContainer

                // Building Name TextField container
                div {
                    className = BuildingStyles.buildingDialogTextFieldContainer
                    TextField {
                        label = ReactNode("Building Name")
                        value = buildingName
                        fullWidth = true
                        onChange = { event ->
                            buildingName = (event.target as HTMLInputElement).value
                        }
                        css { marginBottom = 16.px }
                    }
                }

                Button {
                    variant = ButtonVariant.contained
                    onClick = {
                        // Filter units that start with the building name
                        val matchingUnits = allUnits.filter { unit ->
                            // Split the unit number by hyphen and get the first part
                            val buildingPrefix = unit.number.split("-").firstOrNull()?.trim()
                            // Check if the building name matches the prefix
                            buildingPrefix == buildingName
                        }

                        selectedUnits = matchingUnits
                    }
                    disabled = buildingName.isEmpty()
                    +"Sync Name with Units"
                }

                div {
                    className = BuildingStyles.buildingDialogAvailableUnitsContainer

                    // Available Units List
                    div {
                        className = BuildingStyles.buildingDialogAvailableUnitsList
                        div {
                            css {
                                padding = 8.px
                                fontWeight = FontWeight.bold
                            }
                            +"Available Units (Units not assigned to buildings)"
                        }
                        SearchableCheckboxList<SearchableCheckboxListProps<UnitModel>> {
                            items = availableUnits
                            selectedItems = selectedUnits.toList()
                            associatedItems = associatedUnits
                            deletedItems = deletedUnits.toList()
                            this.searchValue = ""
                            getSearchString = { unit -> unit.number }
                            getPrimaryText = { unit -> unit.number }
                            getSecondaryText = { unit -> "" }
                            onSelectionChange = { selectedItems, deletedItems ->
                                selectedUnits = selectedItems
                                deletedUnits = deletedItems.toSet()
                            }
                            paperHeight = 400
                        }
                    }

                    // Associated Units List
                    div {
                        className = BuildingStyles.buildingDialogAvailableUnitsList
                        div {
                            css {
                                padding = 8.px
                                fontWeight = FontWeight.bold
                            }
                            +"Added Units (${selectedUnits.size})"
                        }

                        // This is our list of selected units for the new building
                        Paper {
                            sx {
                                height = 400.px
                                overflow = Auto.auto
                            }
                            List {
                                selectedUnits.forEach { unit ->
                                    ListItem {
                                        ListItemText {
                                            primary = ReactNode(unit.number)
                                            secondary = ReactNode("")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div {
                css {color = NamedColor.red}
                +error
            }

            div {
                css {
                    padding = 8.px
                    fontWeight = FontWeight.bold
                }
                +"Existing Buildings (${buildingsForProperty.size})"
                Paper {
                    sx {
                        height = 400.px
                        overflow = Auto.auto
                    }
                    List {
                        buildingsForProperty.forEach { building ->
                            ListItem {
                                ListItemText {
                                    primary = ReactNode(building.name)
                                    secondary = ReactNode("")
                                }
                            }
                        }
                    }
                }
            }
        }

        DialogActions {
            className = BuildingStyles.buildingDialogActions
            Button {
                +"CANCEL"
                color = ButtonColor.inherit
                onClick = { props.closeDialog() }
                variant = ButtonVariant.contained
                css {
                    width = 180.px
                    backgroundColor = Color("#e0e0e0")
                }
            }
            Button {
                if (loading) {
                    CircularProgress {}
                } else {
                    +"SAVE BUILDING"
                }
                color = ButtonColor.primary
                disabled = loading
                onClick = {
                    props.viewModel.scope.launch {
                        if (controller.saveNewBuilding(buildingName, selectedUnits.map { it.id.toString() }, props.propertyId, allUnits)) {
                        } else {
                            setOpenDialog(true)
                            if (openDialog) {
                                ErrorDialog {
                                    message = "Failed to save building"
                                    closeCallback = { setOpenDialog(false) }
                                }
                            }
                        }
                    }
                }
                variant = ButtonVariant.contained
                css { width = 180.px }
            }
        }
    }
}