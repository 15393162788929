package com.fireavert.components.help

import com.fireavert.components.common.LoggedIn
import com.fireavert.styles.HelpStyles
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.PageStylesV2
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.system.sx
import mui.system.useMediaQuery
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import web.cssom.px
import web.window.WindowTarget

val HelpSection = FC {
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    LoggedIn {}
    div {
        className = HelpStyles.helpSectionTitle
        +"Help Center"

        a {
            href = "https://drive.google.com/file/d/1y_jum3Fd6hdaHhfrNgmMgkIUeDmbjf3u/view?usp=sharing"
            target = WindowTarget._blank
            Button {
                sx {
                    marginBottom = 10.px
                }
                className = PageStylesV2.genericButton
                color = ButtonColor.primary
                variant = ButtonVariant.contained
                +"Download the Welcome Packet"
            }
        }
    }

    div {
        className = HelpStyles.contactInfoSection
        div {
            ReactHTML.strong { +"Call Us: " }
            a {
                href = "tel:+18018743225"
                +"801-874-3225"
            }
        }
        div {
            ReactHTML.strong { +"Email Us: " }
            a {
                href = "mailto:prosupport@fireavert.com"
                +"prosupport@fireavert.com"
            }
        }

    }

    div {
        className = HelpStyles.helpSection
        div {
            className = HelpStyles.helpSectionBox
            div {
                className = HelpStyles.helpActionTitle
                +"Event Types"
            }
            div {
                className = HelpStyles.helpContainerEvents

                HelpEventCard {
                    imageSrc = "/static/active_event_smoke_icon_active.svg"
                    text = HelpDialog.smokeEventTitle
                    titleColor = "#FF7E7E"
                    quote = HelpDialog.smokeEventQuote
                    quoteColor = "red"
                    helpDialog = HelpDialog.smokeCardDetails
                    showActionsList = listOf(true, true, true)
                    isMobileView = isMobile

                }

                HelpEventCard {
                    imageSrc = "/static/active_event_leak_icon_active.svg"
                    text = HelpDialog.leakEventTitle
                    titleColor = "#58B9FF"
                    quote = HelpDialog.leakEventQuote
                    quoteColor = "blue"
                    helpDialog = HelpDialog.leakCardDetails
                    showActionsList = listOf(false, true, false)
                    isMobileView = isMobile
                }

                HelpEventCard {
                    imageSrc = "/static/active_event_tamper_icon_active.svg"
                    text = HelpDialog.tamperEventTitle
                    titleColor = "#4BD9B3"
                    quote = HelpDialog.tamperEventQuote
                    quoteColor = "green"
                    helpDialog = HelpDialog.tamperCardDetails
                    showActionsList = listOf(false, true, false)
                    isMobileView = isMobile
                }

                HelpEventCard {
                    imageSrc = "/static/active_event_offline_icon_active.svg"
                    text = HelpDialog.offlineEventTitle
                    titleColor = "#FECA57"
                    quote = HelpDialog.offlineEventQuote
                    quoteColor = "#FECA57"
                    helpDialog = HelpDialog.offlineCardDetails
                    showActionsList = listOf(false, true, false)
                    isMobileView = isMobile
                }
            }
        }

        div {
            className = HelpStyles.helpSectionBox
            div {
                className = HelpStyles.helpActionTitle
                +"Actions"
            }
            div {
                className = HelpStyles.helpContainerActions
                HelpActionCard {
                    title = "SHUTOFF"
                    imageSrc = "/static/automated_actions_stove_shutoff_icon_active.svg"
                    helpDialog = HelpDialog.shutOffActionDetails
                    isMobileView = isMobile
                }

                HelpActionCard {
                    title = "TEXT ALERT"
                    imageSrc = "/static/automated_actions_text_alerts_icon_active.svg"
                    helpDialog = HelpDialog.textAlertActionDetails
                    isMobileView = isMobile
                }

                HelpActionCard {
                    title = "RESET"
                    imageSrc = "/static/automated_actions_stove_reset_icon_active.svg"
                    helpDialog = HelpDialog.resetAlertActionDetails
                    isMobileView = isMobile
                }
            }
        }
    }
}
