package com.fireavert.components.reset_password

import com.fireavert.auth.interface_adapters.ResetPasswordController
import com.fireavert.auth.interface_adapters.ResetPasswordViewModel
import com.fireavert.components.common.NotLoggedIn
import com.fireavert.components.targetInputValue
import com.fireavert.logging.Logger
import com.fireavert.styles.AuthStyles
import com.fireavert.styles.DialogStyles
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.ReactNode
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.useEffectOnceWithCleanup
import react.useState
import web.html.ButtonType

val ResetPasswordPage = FC {
    val controller = getKoinInstance<ResetPasswordController>()
    val viewModel = getKoinInstance<ResetPasswordViewModel>()
    val logger = getKoinInstance<Logger>()

    var loading: Boolean by useState(false)
    var error: Boolean by useState(false)
    var errorMessage: String by useState("")

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
            error = viewState.error
            errorMessage = viewState.errorMessage
        }.launchIn(viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
    }
    val submitFunction = {
        viewModel.scope.launch {
            controller.clickedSubmit()
        }
    }

    NotLoggedIn {}

    Dialog {
        open = true
        maxWidth = Breakpoint.lg
        if (loading) {
            DialogContent {
                className = DialogStyles.warningDialogContent
                CircularProgress {}
            }
        } else {
            form {
                onSubmit = {
                    it.preventDefault()
                    submitFunction()
                }
                noValidate = true
                className = AuthStyles.loginForm
                div {
                    className = AuthStyles.welcomeTitle
                    +"Password Reset"
                }
                div {
                    className = AuthStyles.formBody
                    img {
                        alt = "FireAvert Logo"
                        src = "/static/logo.svg"
                        className = AuthStyles.logo
                    }
                    div {
                        className = AuthStyles.controlFields
                        TextField {
                            variant = FormControlVariant.outlined
                            margin = FormControlMargin.normal
                            required = false
                            fullWidth = true
                            id = "email"
                            label = ReactNode("Email")
                            name = "email"
                            autoComplete = "email"
                            autoFocus = true
                            this.error = error
                            onChange = { controller.emailChanged(it.targetInputValue) }
                            className = AuthStyles.textField
                        }
                        Button {
                            variant = ButtonVariant.contained
                            color = ButtonColor.primary
                            type = ButtonType.submit
                            fullWidth = true
                            onClick = { submitFunction() }
                            className = AuthStyles.loginButton
                            +"Submit Password Reset Request"
                        }
                    }
                }
            }
        }

    }
}