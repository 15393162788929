package com.fireavert.components.properties

import com.fireavert.components.targetInputValue
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.UnitStyles
import emotion.css.ClassName
import mui.material.Checkbox
import mui.material.FormControlLabel
import mui.material.TextField
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr
import react.dom.onChange
import web.cssom.*

external interface UnitFormProps: Props {
    var number: String
    var numberError: Boolean
    var numberChanged: (String) -> Unit

    var address: String
    var addressError: Boolean
    var addressChanged: (String) -> Unit

    var city: String
    var cityError: Boolean
    var cityChanged: (String) -> Unit

    var state: String
    var stateError: Boolean
    var stateChanged: (String) -> Unit

    var zip: String
    var zipError: Boolean
    var zipChanged: (String) -> Unit

    var tenantName: String
    var tenantNameError: Boolean
    var tenantNameChanged: (String) -> Unit

    var phone: String
    var phoneError: Boolean
    var phoneChanged: (String) -> Unit

    var email: String
    var emailError: Boolean
    var emailChanged: (String) -> Unit

    var notifyStoveCurrent: Boolean
    var notifyStoveCurrentChanged: (Boolean) -> Unit

    var deviceCreationData: Array<DeviceData>
    var saveError: String?

    var clickedAddNewDevice: () -> Unit
    var deleteDeviceAtIndex: (Int) -> Unit

    var deviceAtIndexChanged: (Int, DeviceData) -> Unit
}

val UnitForm = FC<UnitFormProps> { props ->

    var localUnitName by useState(props.number)
    var localUnitAddress by useState(props.address)
    var localUnitZip by useState(props.zip)
    var localUnitCity by useState(props.city)
    var localTenantName by useState(props.tenantName)
    var localTenantPhone by useState(props.phone)
    var localTenantEmail by useState(props.email)

    useEffect(
        props.number,
        props.address,
        props.city,
        props.tenantName,
        props.phone,
        props.email,
        props.zip
    )
    {
        localUnitName = props.number
        localUnitAddress = props.address
        localUnitCity = props.city
        localTenantName = props.tenantName
        localTenantPhone = props.phone
        localTenantEmail = props.email
        localUnitZip = props.zip
    }
    div {
        className = ClassName {
            width = 100.pct
            maxWidth = 990.px
            margin = Auto.auto
            padding = 10.px
            boxSizing = BoxSizing.borderBox

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
                padding = 5.px
            }
        }

        // Unit Number
        div {
            className = ClassName { marginBottom = 15.px }
            TextField {
                label = ReactNode("Unit #")
                fullWidth = true
                value = localUnitName
                error = props.numberError
                onChange = {
                    localUnitName = it.targetInputValue
                    props.numberChanged(it.targetInputValue) }
            }
        }

        // Address, City, State, Zip
        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 15.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

            TextField {
                label = ReactNode("Unit Street Address")
                fullWidth = true
                value = localUnitAddress
                error = props.addressError
                onChange = {
                    localUnitAddress = it.targetInputValue
                    props.addressChanged(it.targetInputValue) }
            }

            TextField {
                label = ReactNode("City")
                fullWidth = true
                value = localUnitCity
                error = props.cityError
                onChange = {
                    localUnitCity = it.targetInputValue
                    props.cityChanged(it.targetInputValue) }
            }

            StateDropDown {
                value = props.state
                error = props.stateError
                changed = { newState -> props.stateChanged(newState) }
            }

            TextField {
                label = ReactNode("Zip")
                fullWidth = true
                value = localUnitZip
                error = props.zipError
                onChange = {
                    localUnitZip = it.targetInputValue
                    props.zipChanged(it.targetInputValue) }
            }
        }

        // Tenant Info
        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 15.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

            TextField {
                label = ReactNode("Tenant Name")
                fullWidth = true
                value = localTenantName
                error = props.tenantNameError
                onChange = {
                    localTenantName = it.targetInputValue
                    props.tenantNameChanged(it.targetInputValue) }
            }

            TextField {
                label = ReactNode("Phone")
                fullWidth = true
                value = localTenantPhone
                error = props.phoneError
                onChange = {
                    localTenantPhone = it.targetInputValue
                    props.phoneChanged(it.targetInputValue) }
            }

            TextField {
                label = ReactNode("Email")
                fullWidth = true
                value = localTenantEmail
                error = props.emailError
                onChange = {
                    localTenantEmail = it.targetInputValue
                    props.emailChanged(it.targetInputValue) }
            }
        }

        // Notify Stove Current
        div {
            className = ClassName { marginBottom = 15.px }
            FormControlLabel {
                control = Checkbox.create {
                    checked = props.notifyStoveCurrent
                    onChange = { _, value -> props.notifyStoveCurrentChanged(value) }
                }
                label = ReactNode("Notify tenant when stove is turned on.")
            }
        }

        hr { className = UnitStyles.addUnitTableSeparator }

        // Devices Container
        AddRemoveDevicesContainer {
            deviceCreationData = props.deviceCreationData
            saveError = props.saveError
            clickedAddNewDevice = props.clickedAddNewDevice
            deleteDeviceAtIndex = props.deleteDeviceAtIndex
            deviceAtIndexChanged = props.deviceAtIndexChanged
        }

        hr { className = UnitStyles.addUnitTableSeparator }
    }
}