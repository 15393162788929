package com.fireavert.management_companies.presentation

import com.fireavert.common.ViewModel
import com.fireavert.management_companies.logic.ManagementCompanyScreen
import com.fireavert.properties.logic.models.Property
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class ManagementCompaniesViewModel :  ManagementCompanyScreen, ViewModel() {
    private val _viewState = MutableStateFlow(ManagementCompanyViewState())
    val viewState = _viewState.asStateFlow()

    override val scope: CoroutineScope = viewModelScope
    override var loading: Boolean
        get() { return viewState.value.loading }
        set(value) {
            _viewState.value = viewState.value.copy(loading = value)
        }

    override var name: String
        get() { return viewState.value.name }
        set(value) {
            _viewState.value = viewState.value.copy(name = value)
        }
    override var associatedProperties: List<Int>
        get() { return viewState.value.associatedProperties }
        set(value) {
            _viewState.value = viewState.value.copy(associatedProperties = value)
        }
    override var error: String
        get() { return viewState.value.error }
        set(value) {
            _viewState.value = viewState.value.copy(error = value)
        }

    override var properties: List<Property>
        get() { return viewState.value.properties }
        set(value) {
            _viewState.value = viewState.value.copy(properties = value)
        }

}