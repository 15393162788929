package com.fireavert.properties.presentation

import com.fireavert.properties.logic.PropertyNavigator
import com.fireavert.properties.logic.PropertyNavigator.Destination.*

class RoutedPropertyNavigator(private val propertyRouter: PropertyRouter) : PropertyNavigator {
    override fun navigate(destination: PropertyNavigator.Destination) {
        when (destination) {
            AddNewProperty -> propertyRouter.routeToAddNewPropertyDialog()
            Properties -> propertyRouter.routeToProperties()
            is Property -> propertyRouter.routeToProperty(destination.propertyId)
            is RemovePropertyAdmin -> propertyRouter.routeToRemovePropertyAdmin(
                destination.propertyId,
                destination.propertyAdminUserId
            )
            is EditProperty -> propertyRouter.routeToEditProperty(destination.propertyId)
            is EditManagementCompany -> propertyRouter.routeToEditManagementCompany(destination.managementCompanyId)
            NewManagementCompany -> propertyRouter.routeToNewManagementCompany()
            Reload -> propertyRouter.reload()
        }
    }
}