package com.fireavert.gateways.interface_adaptors

import com.fireavert.common.ViewModel
import com.fireavert.gateways.logic.GatewayDialogScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class GatewayDialogViewModel: GatewayDialogScreen, ViewModel() {
    private val _viewState = MutableStateFlow(GatewayDialogViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var loading: Boolean
        get() { return _viewState.value.loading }
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
    override var imageUploading: Boolean
        get() { return _viewState.value.imageUploading }
        set(value) { _viewState.value = _viewState.value.copy(imageUploading = value) }
    override var imageUploadError: String
        get() {
            return _viewState.value.imageUploadError
        }
        set(value) { _viewState.value = _viewState.value.copy(imageUploadError = value) }

    override var gatewayImages: Map<Int, String>
        get() { return _viewState.value.gatewayImages }
        set(value) { _viewState.value = _viewState.value.copy(gatewayImages = value) }
}