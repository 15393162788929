package com.fireavert.components.dialogs

import com.fireavert.components.datepicker.ReactDatePicker
import com.fireavert.components.filesaver.saveAs
import com.fireavert.reports_page.interface_adaptors.ReportsExportDialogController
import com.fireavert.reports_page.interface_adaptors.ReportsExportDialogViewModel
import com.fireavert.styles.GatewayStyles
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.ReportPageStyles
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import mui.system.useMediaQuery
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import react.*
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.strong
import web.cssom.*
import kotlin.js.Date

external interface ReportsExportDataProps : Props {
    var propsPropertyId: Int?
    var propsPropertyName: String?
    var propsIsAdmin: Boolean
    var onClose: () -> Unit
}

val ReportsExportDataDialog = FC<ReportsExportDataProps> { props ->
    val controller = getKoinInstance<ReportsExportDialogController>()
    val viewModel = getKoinInstance<ReportsExportDialogViewModel>()

    var listDropDownTime: Long by useState(Clock.System.now().minus(1, DateTimeUnit.DAY, TimeZone.UTC).epochSeconds)
    var startTime: Date? by useState(Date())
    var selectedDate by useState<Date?>(null)
    var easySelectOption: String by useState("")
    var showOfflineEvents: Boolean by useState(false)
    var isExportLoading: Boolean by useState(false)
    var error: String by useState("")
    var customTimePeriodMap: Map<String, String> by useState(mapOf(
        "1" to "Last 24 Hours",
        "7" to "Last 7 Days",
        "30" to "Last 30 Days",
        "90" to "Last 90 Days",
        "180" to "Last 180 Days",
        "365" to "Last 365 Days",
        "-1" to "All Time"
    ))
    var endTime: Date? by useState(startTime)
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isExportLoading = viewState.isExportLoading
            error = viewState.error
        }.launchIn(viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
    }


    Dialog {
        open = true
        onClose = { _, _ -> props.onClose() }
        onBackdropClick = { props.onClose() }
        fullWidth = true
        maxWidth = Breakpoint.lg
        sx {
            "& .MuiDialog-paper" {
                width = 100.pct
                maxWidth = 600.px
                height = 600.px
                margin = Auto.auto
            }
        }
        DialogTitle {
            sx {
                backgroundColor = Color("#D9D9D9")
            }
            ReactHTML.div {
                className = cx(GatewayStyles.gatewayHeaderWithButton, ClassName {
                    color = NamedColor.black
                })
                ReactHTML.div {
                    +"Export Data for ${props.propsPropertyName}"
                }
            }
        }

        DialogContent {
            sx {
                width = 100.pct
                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    maxWidth = 100.pct
                }
                backgroundColor = NamedColor.white
                display = Display.flex
                flexDirection = FlexDirection.column
                gap = 20.px
            }
            DialogContentText {
                +"Export data for the selected property."
            }

            div {
                ariaLabel = "Dropdown Container"
                className = ReportPageStyles.reportExportDialog
                FormControl {
                    fullWidth = true
                    InputLabel { id = "easy-select-label"; +"Easy Select" }
                    Select {
                        label = ReactNode("Easy Select")
                        value = easySelectOption
                        fullWidth = true
                        onChange = { event, _ ->
                            val selectedValue = (event.target.value as? String) ?: ""
                            easySelectOption = selectedValue
                            startTime = null
                            endTime = null
                        }
                        customTimePeriodMap.forEach { (key, value) ->
                            MenuItem {
                                +value
                                this.value = key
                            }
                        }
                    }
                }

                br {}

                div {
                    className = cx(ReportPageStyles.reportExportCenter, ClassName { padding = 25.px; fontSize = 24.px})
                    strong { +"OR" }
                }

                div {
                    div {
                        css {
                            fontSize = 16.px
                            color = Color("#939598")
                        }
                        +"Custom Date Range"
                    }
                    ReactDatePicker {
                        selectsRange = true
                        swapRange = true
                        todayButton = "Today"
                        onChange = {
                            startTime = it[0]
                            endTime = it[1]
                            easySelectOption = ""
                        }
                        this.startDate = startTime
                        this.endDate = endTime
                    }
                }

                if (props.propsIsAdmin) {
                    div {
                        +"Show Offline Events?"
                        Checkbox {
                            checked = showOfflineEvents
                            onChange = { _, checked ->
                                showOfflineEvents = checked
                            }
                        }
                    }
                }
            }

            if (isExportLoading) {
                ReactHTML.div {
                    className = ReportPageStyles.reportExportCenter
                    CircularProgress {
                        color = CircularProgressColor.info
                        size = 30.px
                    }
                }
            }
            else {
                Button {
                    sx {
                        marginTop = 16.px
                        alignSelf = AlignSelf.center
                    }
                    disabled = easySelectOption.isBlank() && (startTime == null && endTime == null)
                    variant = ButtonVariant.contained
                    onClick = {
                        viewModel.scope.launch {
                            var finalStartTime: Any? = null
                            var finalEndTime: Any? = null
                            if (easySelectOption.isNotBlank()) {
                                val days = easySelectOption.toLong()
                                finalStartTime = days
                                finalEndTime = 0
                            } else {
                                if (startTime != null && endTime != null) {
                                    finalStartTime = startTime!!.getTime().toLong()
                                    finalEndTime = endTime!!.getTime().toLong()
                                }
                            }

                            // Run the controller function and wait for the result
                            val exportedData = controller.exportReportEventData(
                                propertyId = props.propsPropertyId,
                                startDate = finalStartTime!!,
                                endDate = finalEndTime!!,
                                includeOffline = showOfflineEvents,
                                isEasySelection = easySelectOption.isNotBlank()
                            )

                            // Generate the CSV content as a string using the exported data
                            // Create a Blob from the CSV content
                            if (exportedData != null) {
                                val blob =
                                    Blob(arrayOf(exportedData), BlobPropertyBag(type = "text/csv;charset=utf-8;"))

                                // Use the saveAs function to prompt the user to download the file
                                saveAs(blob, "unit_report.csv")
                            }
                            //Close after we export
                            props.onClose()
                        }
                    }
                    +"Export Data"
                }

                div {
                    +error
                }
            }
        }
    }
}
