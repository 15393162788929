package com.fireavert.administration_page.users.logic.user_info.models

import com.fireavert.properties.logic.models.UserLinkedToProperty
import kotlinx.serialization.Serializable

@Serializable
data class UserInfoResponse(
    val id: Int,
    val name: String,
    val role: List<String>,
    val email: String,
    val phone: String,
    val managementCompany: String,
    val loginHistory: Map<String, String>,
    val userLinkedToPropertyData: List<UserLinkedToProperty>,
)
