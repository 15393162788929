package com.fireavert.administration_page.users.logic.user_info

import com.fireavert.administration_page.users.logic.UserTrackingRecord
import com.fireavert.common.TableColumn
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.models.UserLinkedToProperty
import kotlinx.datetime.*
import kotlin.time.Duration

class UserInfoInteractor(
    private val screen: UserInfoScreen,
    private val userInfoRepository: UserInfoRepository,
    private val logger: Logger
) {
    fun searchAndSortLoginHistoryRecords(
        columns: Array<TableColumn>,
        trackingRecords: List<UserTrackingRecord>,
        searchValue: String
    ): List<UserTrackingRecord> {

        //Search for the records that match the search value first
        val filteredRecords = searchUsers(searchValue, trackingRecords)
        val sortColumn =
            columns.firstOrNull() { it.sortOrder != TableColumn.SortOrder.Neutral } ?: return filteredRecords
        return when (sortColumn.text) {
            "NAME" -> {
                TableColumn.sortList(true, sortColumn, filteredRecords) { it.name }
            }

            "EMAIL" -> {
                TableColumn.sortList(true, sortColumn, filteredRecords) { it.email }
            }

            "PHONE NUMBER" -> {
                TableColumn.sortList(true, sortColumn, filteredRecords) { it.phone }
            }

            "MANAGEMENT COMPANY" -> {
                TableColumn.sortList(true, sortColumn, filteredRecords) { it.managementCompany }
            }

            else -> filteredRecords
        }.filter { true }
    }

    private fun searchUsers(searchValue: String, trackingRecords: List<UserTrackingRecord>): List<UserTrackingRecord> {
        return trackingRecords.filter { record ->
            record.name.contains(searchValue, ignoreCase = true) ||
                    record.email.contains(searchValue, ignoreCase = true) ||
                    record.phone.contains(searchValue, ignoreCase = true) ||
                    record.managementCompany.contains(searchValue, ignoreCase = true)
        }.toList()
    }

    suspend fun saveNotificationChanges(userId: Int, userToPropertyInfo: List<UserLinkedToProperty>) {
        screen.loading = true
        when (val maybe = userInfoRepository.saveNotificationChanges(userId, userToPropertyInfo)) {
            is Try.Error -> {
                logger.e("Failed to save user notification changes. " + maybe.exception.message)
                screen.loading = false
            }

            is Try.Success -> {
                screen.propertyNotificationInfo = userToPropertyInfo
                screen.loading = false
            }
        }

    }

    suspend fun onLoad(userId: Int) {
        screen.loading = true
        val userData = when (val maybe = userInfoRepository.getUserInfo(userId)) {
            is Try.Error -> {
                logger.e("Failed to get user tracking data. " + maybe.exception.message)
                screen.loading = false
                null
            }

            is Try.Success -> maybe.value
        } ?: return

        val (monthly, weekly) = calculateLoginCounts(userData.loginHistory)
        screen.monthlyLoginCount = monthly
        screen.weeklyLoginCount = weekly

        screen.propertyNotificationInfo = userData.userLinkedToPropertyData
        screen.loginHistory = userData.loginHistory
        screen.name = userData.name
        screen.email = userData.email
        screen.phone = userData.phone
        screen.role = userData.role
        screen.loading = false
    }



    fun calculateLoginCounts(loginHistory: Map<String, String>): Pair<Int, Int> {
        val now = Clock.System.now()
        val nowLocal = now.toLocalDateTime(TimeZone.UTC)

        val currentMonth = nowLocal.month.number
        val currentYear = nowLocal.year

        // Calculate start of week
        val startOfWeek = LocalDateTime(
            year = nowLocal.year,
            monthNumber = nowLocal.monthNumber,
            dayOfMonth = nowLocal.dayOfMonth - nowLocal.dayOfWeek.isoDayNumber + 1,
            hour = 0,
            minute = 0,
            second = 0,
            nanosecond = 0
        ).toInstant(TimeZone.UTC)

        // Calculate end of week using Duration
        val endOfWeek = startOfWeek.plus(Duration.parse("P7D"))

        val monthlyCount = loginHistory.count { (dateStr, _) ->
            try {
                val parts = dateStr.split(" ")
                val month = when (parts[0]) {
                    "JANUARY" -> 1
                    "FEBRUARY" -> 2
                    "MARCH" -> 3
                    "APRIL" -> 4
                    "MAY" -> 5
                    "JUNE" -> 6
                    "JULY" -> 7
                    "AUGUST" -> 8
                    "SEPTEMBER" -> 9
                    "OCTOBER" -> 10
                    "NOVEMBER" -> 11
                    "DECEMBER" -> 12
                    else -> 0
                }
                val year = parts[2].split(",")[0].toInt()

                month == currentMonth && year == currentYear
            } catch (e: Exception) {
                false
            }
        }

        val weeklyCount = loginHistory.count { (dateStr, _) ->
            try {
                val parts = dateStr.split(" ")
                val month = when (parts[0]) {
                    "JANUARY" -> 1
                    "FEBRUARY" -> 2
                    "MARCH" -> 3
                    "APRIL" -> 4
                    "MAY" -> 5
                    "JUNE" -> 6
                    "JULY" -> 7
                    "AUGUST" -> 8
                    "SEPTEMBER" -> 9
                    "OCTOBER" -> 10
                    "NOVEMBER" -> 11
                    "DECEMBER" -> 12
                    else -> 0
                }
                val day = parts[1].replace(",", "").toInt()
                val year = parts[2].toInt()

                val loginInstant = LocalDateTime(
                    year = year,
                    monthNumber = month,
                    dayOfMonth = day,
                    hour = 0,
                    minute = 0,
                    second = 0,
                    nanosecond = 0
                ).toInstant(TimeZone.UTC)

                loginInstant >= startOfWeek && loginInstant < endOfWeek
            } catch (e: Exception) {
                false
            }
        }

        return Pair(monthlyCount, weeklyCount)
    }

}