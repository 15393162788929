package com.fireavert.administration_page.users.frameworks

import com.fireavert.administration_page.users.interface_adapters.user_info.UserDialogRepositoryDataSource
import com.fireavert.administration_page.users.logic.add_user_dialog.models.request.SaveEditedUserRequest
import com.fireavert.administration_page.users.logic.add_user_dialog.models.request.SaveUserInfoRequest
import com.fireavert.administration_page.users.logic.add_user_dialog.models.response.GetAllUserRolesResponse
import com.fireavert.common.Try
import com.fireavert.properties.logic.models.response.PropertyAdminResponse
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class UserDialogRepositoryDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient,
) : UserDialogRepositoryDataSource {
    override suspend fun getRoleList(token: String): Try<GetAllUserRolesResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/user/roles/all")

            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun saveUser(request: SaveUserInfoRequest, token: String): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/user/save")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun saveEditUser(
        request: SaveEditedUserRequest,
        token: String
    ): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/user/edit/save")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }

    }

    override suspend fun getUserInfo(
        userId: Int,
        token: String
    ): Try<PropertyAdminResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/user/edit/$userId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}