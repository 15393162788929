package com.fireavert.properties.presentation.add_new_property

import com.fireavert.properties.logic.add_new_property.AddNewProperty
import com.fireavert.properties.logic.models.*

class AddNewPropertyController(private val addNewProperty: AddNewProperty) {
    fun clickedCancel() = addNewProperty.cancel()

    suspend fun clickedSave() = addNewProperty.save()

    fun propertyNameChanged(propertyName: String) = addNewProperty.propertyNameChanged(propertyName)

    fun numberOfUnitsChanged(numberOfUnits: String) =
        addNewProperty.numberOfUnitsChanged(numberOfUnits)

    fun propertyClassChanged(propertyClass: PropertyClass) =
        addNewProperty.propertyClassChanged(propertyClass)

    fun addressChanged(address: String) = addNewProperty.addressChanged(address)

    fun cityChanged(city: String) = addNewProperty.cityChanged(city)

    fun stateChanged(state: String) = addNewProperty.stateChanged(state)

    fun zipChanged(zip: String) = addNewProperty.zipChanged(zip)

    fun managementCompanyChanged(managementCompany: String) =
        addNewProperty.managementCompanyChanged(managementCompany)

    fun timezoneIdChanged(timezoneId: String) = addNewProperty.timezoneIdChanged(timezoneId)
    fun propertyAdminModelsChanged(toList: List<PropertyAdminModel>) =
        addNewProperty.propertyAdminModelsChanged(toList)
    fun subscriptionTypeChanged(subscriptionType: SubscriptionType) =
        addNewProperty.subscriptionTypeChanged(subscriptionType)
    fun modeChanged(mode: Mode) =
        addNewProperty.modeChanged(mode)

    fun propertyTypeChanged(propertyType: PropertyType) {
        addNewProperty.propertyTypeChanged(propertyType)
    }

    fun fireClaimsChangedOneClaims(year: Int, claimCount: Int) {
        addNewProperty.fireClaimsChangedOneClaims(year, claimCount)
    }

    fun fireClaimsChangedOneCost(year: Int, cost: Double) {
        addNewProperty.fireClaimsChangedOneCost(year, cost)
    }

    fun fireClaimsChangedTwoClaims(year: Int, claimCount: Int) {
        addNewProperty.fireClaimsChangedTwoClaims(year, claimCount)
    }

    fun fireClaimsChangedTwoCost(year: Int, cost: Double) {
        addNewProperty.fireClaimsChangedTwoCost(year, cost)
    }

    fun fireClaimsChangedThreeClaims(year: Int, claimCount: Int) {
        addNewProperty.fireClaimsChangedThreeClaims(year, claimCount)
    }

    fun fireClaimsChangedThreeCost(year: Int, cost: Double) {
        addNewProperty.fireClaimsChangedThreeCost(year, cost)
    }

    fun fireClaimsChangedFourClaims(year: Int, claimCount: Int) {
        addNewProperty.fireClaimsChangedFourClaims(year, claimCount)
    }

    fun fireClaimsChangedFourCost(year: Int, cost: Double) {
        addNewProperty.fireClaimsChangedFourCost(year, cost)
    }

    fun fireClaimsChangedFiveClaims(year: Int, claimCount: Int) {
        addNewProperty.fireClaimsChangedFiveClaims(year, claimCount)
    }

    fun fireClaimsChangedFiveCost(year: Int, cost: Double) {
        addNewProperty.fireClaimsChangedFiveCost(year, cost)
    }
}