package com.fireavert.buildings.logic.models.requests

import kotlinx.serialization.Serializable

@Serializable
data class SaveNewBuildingRequest(
    val buildingName: String,
    val unitIds: List<String>,
    val propertyId: Int
)
