package com.fireavert.administration_page.users.interface_adapters.add_user_dialog

import com.fireavert.administration_page.users.logic.add_user_dialog.AddUserDialogScreen
import com.fireavert.common.ViewModel
import com.fireavert.properties.logic.models.Property
import com.fireavert.user.logic.models.RolesModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class AddUserViewModel : AddUserDialogScreen, ViewModel() {
    private val _viewState = MutableStateFlow(AddUserViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var loading: Boolean
        get() { return viewState.value.loading }
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
    override var firstName: String
        get() { return viewState.value.firstName }
        set(value) {
            _viewState.value = _viewState.value.copy(firstName = value)
        }
    override var lastName: String
        get() { return viewState.value.lastName }
        set(value) {
            _viewState.value = _viewState.value.copy(lastName = value)
        }
    override var phoneNumber: String
        get() { return viewState.value.phone }
        set(value) {
            _viewState.value = _viewState.value.copy(phone = value)
        }
    override var email: String
        get() { return viewState.value.email }
        set(value) {
            _viewState.value = _viewState.value.copy(email = value)
        }
    override var associatedProperties: List<Int>
        get() { return viewState.value.associatedProperties }
        set(value) {
            _viewState.value = _viewState.value.copy(associatedProperties = value)
        }
    override var allProperties: List<Property>
        get() { return viewState.value.allProperties }
        set(value) {
            _viewState.value = _viewState.value.copy(allProperties = value)
        }

    override var roleList: List<RolesModel>
        get() { return viewState.value.roleList }
        set(value) {
            _viewState.value = _viewState.value.copy(roleList = value)
        }
    override var selectedRoles: List<RolesModel>
        get() { return viewState.value.selectedRoles }
        set(value) {
            _viewState.value = _viewState.value.copy(selectedRoles = value)
        }

    override var stoveTextNotifications: Boolean
        get() { return viewState.value.stoveShutoffTextNotifications }
        set(value) {
            _viewState.value = _viewState.value.copy(stoveShutoffTextNotifications = value)
        }
    override var stoveEmailNotifications: Boolean
        get() { return viewState.value.stoveShutoffEmailNotifications }
        set(value) {
            _viewState.value = _viewState.value.copy(stoveShutoffEmailNotifications = value)
        }
    override var leakTextNotifications: Boolean
        get() { return viewState.value.leakTextNotifications }
        set(value) {
            _viewState.value = _viewState.value.copy(leakTextNotifications = value)
        }
    override var leakEmailNotifications: Boolean
        get() { return viewState.value.leakEmailNotifications }
        set(value) {
            _viewState.value = _viewState.value.copy(leakEmailNotifications = value)
        }
    override var tamperTextNotifications: Boolean
        get() { return viewState.value.tamperTextNotifications }
        set(value) {
            _viewState.value = _viewState.value.copy(tamperTextNotifications = value)
        }
    override var tamperEmailNotifications: Boolean
        get() { return viewState.value.tamperEmailNotifications }
        set(value) {
            _viewState.value = _viewState.value.copy(tamperEmailNotifications = value)
        }
    override var smokeAlarmTextNotifications: Boolean
        get() { return viewState.value.smokeAlarmTextNotifications }
        set(value) {
            _viewState.value = _viewState.value.copy(smokeAlarmTextNotifications = value)
        }
    override var smokeAlarmEmailNotifications: Boolean
        get() { return viewState.value.smokeAlarmEmailNotifications }
        set(value) {
            _viewState.value = _viewState.value.copy(smokeAlarmEmailNotifications = value)
        }
    override var dailyReports: Boolean
        get() { return viewState.value.dailyReports }
        set(value) {
            _viewState.value = _viewState.value.copy(dailyReports = value)
        }
    override var weeklyReports: Boolean
        get() { return viewState.value.weeklyReports }
        set(value) {
            _viewState.value = _viewState.value.copy(weeklyReports = value)
        }
    override var monthlyReports: Boolean
        get() { return viewState.value.monthlyReports }
        set(value) {
            _viewState.value = _viewState.value.copy(monthlyReports = value)
        }
    override var quarterlyReports: Boolean
        get() { return viewState.value.quarterlyReports }
        set(value) {
            _viewState.value = _viewState.value.copy(quarterlyReports = value)
        }
    override var yearlyReports: Boolean
        get() { return viewState.value.yearlyReports }
        set(value) {
            _viewState.value = _viewState.value.copy(yearlyReports = value)
        }
    override var firstNameError: Boolean
        get() { return viewState.value.firstNameError }
        set(value) {
            _viewState.value = _viewState.value.copy(firstNameError = value)
        }
    override var lastNameError: Boolean
        get() { return viewState.value.lastNameError }
        set(value) {
            _viewState.value = _viewState.value.copy(lastNameError = value)
        }
    override var phoneError: Boolean
        get() { return viewState.value.phoneError }
        set(value) {
            _viewState.value = _viewState.value.copy(phoneError = value)
        }
    override var emailError: Boolean
        get() { return viewState.value.emailError }
        set(value) {
            _viewState.value = _viewState.value.copy(emailError = value)
        }
    override var errorMessage: String
        get() { return viewState.value.errorMessage }
        set(value) {
            _viewState.value = _viewState.value.copy(errorMessage = value)
        }
}