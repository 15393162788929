package com.fireavert.user.logic.models

import kotlinx.serialization.Serializable

@Serializable
enum class RolesModel(val displayText: String) {
    SUPERADMIN("Superadmin"),
    PROPERTY_ADMIN("Property Admin"),
    UI_TESTER("UI Tester"),
    V2_TESTER("V2 UI"),
    ADMIN("Admin"),
    SALES_REP("Sales Rep"),
    EXECUTIVE("Executive"),
    REGIONAL_MANAGER("Regional Manager"),
    MAINTENANCE_USER("Maintenance User"),
    TENANT("Tenant"),
    SINGLE_FAMILY_USER("Single Family User"),
    V2_UI("V2 UI"),
    Admin("Admin"),
    UNKNOWN("Unknown");

    companion object {
        // Convert from database value (enum name) to enum
        fun fromDatabaseValue(value: String) = RolesModel.valueOf(value)

        // Convert from display text to enum if needed
        fun fromDisplayText(text: String) = RolesModel.entries.firstOrNull {
            it.displayText == text
        } ?: throw IllegalArgumentException("No status found for text: $text")
    }
}