package com.fireavert.components.dialogs

import com.fireavert.components.properties.UnitForm
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.logging.Logger
import com.fireavert.styles.DialogStyles
import com.fireavert.units.presentation.AddOrEditUnitViewModel
import com.fireavert.units.presentation.EditUnitDialogController
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import org.koin.core.qualifier.named
import react.FC
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.px


val EditUnitDialog = FC {

    val propertyId = useParams()["propertyId"]?.toInt() ?: 0
    val unitId = useParams()["unitId"]?.toInt() ?: 0

    val controller = getKoinInstance<EditUnitDialogController>()
    val viewModel = getKoinInstance<AddOrEditUnitViewModel>(qualifier = named("edit"))
    val logger = getKoinInstance<Logger>()

    var isLoading: Boolean by useState(false)
    var number: String by useState("")
    var numberError: Boolean by useState(false)
    var addressError: Boolean by useState(false)
    var address: String by useState("")
    var cityError: Boolean by useState(false)
    var city: String by useState("")
    var stateError: Boolean by useState(false)
    var geographicalState: String by useState("")
    var zipError: Boolean by useState(false)
    var zip: String by useState("")
    var tenantName: String by useState("")
    var nameError: Boolean by useState(false)
    var tenantPhone: String by useState("")
    var phoneError: Boolean by useState(false)
    var tenantEmail: String by useState("")
    var emailError: Boolean by useState(false)
    var deviceCreationData: Array<DeviceData> by useState(emptyArray())
    var saveDisabled: Boolean by useState(false)
    var saveError: String? by useState(null)
    var notifyStoveCurrent: Boolean by useState(false)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isLoading = viewState.isLoading
            number = viewState.number ?: ""
            numberError = viewState.numberError
            addressError = viewState.streetError
            cityError = viewState.cityError
            stateError = viewState.stateError
            zipError = viewState.zipError
            tenantName = viewState.tenantName ?: ""
            nameError = viewState.tenantNameError
            tenantPhone = viewState.phone ?: ""
            phoneError = viewState.phoneError
            tenantEmail = viewState.email ?: ""
            emailError = viewState.emailError
            address = viewState.streetAddress ?: ""
            city = viewState.city ?: ""
            geographicalState = viewState.state ?: ""
            zip = viewState.zip ?: ""
            deviceCreationData = viewState.deviceDataStateList.map {
                DeviceData(
                    type = it.type ?: "",
                    typeError = it.typeError,
                    location = it.location ?: "",
                    locationError = it.locationError,
                    locator = it.locator ?: "",
                    locatorError = it.locatorError,
                    commVersion = it.commVersion ?: 1,
                    commVersionError = it.commVersionError,
                    deviceId = it.deviceId,
                    rebootUUID = it.rebootUUID,
                    rebootUUIDError = it.rebootUUIDError,
                    infoUUID = it.infoUUID,
                    infoUUIDError = it.infoUUIDError
                )
            }.toTypedArray()
            saveError = viewState.saveError
            saveDisabled = viewState.saveDisabled
            notifyStoveCurrent = viewState.notifyStoveCurrent
        }.launchIn(viewModel.scope)


        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyId, unitId)
        }
        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = {
            controller.clickedCancel(propertyId)
        }
        onClose = { _, _ ->
            controller.clickedCancel(propertyId)
        }
        maxWidth = Breakpoint.lg
        DialogTitle {
            className = DialogStyles.dialogTitle
            +"Edit Unit"
        }
        DialogContent {
            if (isLoading) {
                CircularProgress{}
            } else {
                UnitForm {
                    this.number = number
                    this.numberError = numberError
                    numberChanged = controller::numberChanged

                    this.address = address
                    this.addressError = addressError
                    addressChanged = controller::addressChanged

                    this.city = city
                    this.cityError = cityError
                    cityChanged = controller::cityChanged

                    this.state = geographicalState
                    this.stateError = stateError
                    stateChanged = controller::stateChanged

                    this.zip = zip
                    this.zipError = zipError
                    zipChanged = controller::zipChanged

                    this.tenantName = tenantName
                    this.tenantNameError = nameError
                    tenantNameChanged = controller::tenantNameChanged

                    this.phone = tenantPhone
                    this.phoneError = phoneError
                    phoneChanged = controller::phoneChanged


                    this.email = tenantEmail
                    this.emailError = emailError
                    emailChanged = {
                        controller.emailChanged(it)
                    }

                    this.notifyStoveCurrent = notifyStoveCurrent
                    this.notifyStoveCurrentChanged = {
                        controller.notifyStoveCurrentChanged(it)
                    }

                    this.deviceCreationData = deviceCreationData
                    this.saveError = saveError

                    clickedAddNewDevice = controller::clickedAddNewDevice
                    deleteDeviceAtIndex = controller::deleteDeviceAtIndex

                    deviceAtIndexChanged = { index, newDeviceCreationData ->
                        controller.deviceAtIndexChanged(index, deviceCreationData[index], newDeviceCreationData)
                        val newList = deviceCreationData.toMutableList()
                        newList[index] = newDeviceCreationData
                        deviceCreationData = newList.toTypedArray()
                    }
                }
                DialogActions {
                    css {
                        height = 104.px
                        paddingRight = 24.px
                    }
                    Button {
                        +"Cancel"
                        color = ButtonColor.inherit
                        onClick = {
                            controller.clickedCancel(propertyId)
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 163.px
                            marginRight = 6.px
                        }
                    }
                    Button {
                        +"Save Changes"
                        color = ButtonColor.primary
                        disabled = saveDisabled && !isLoading
                        onClick = {
                            viewModel.scope.launch {
                                controller.clickedSave(propertyId, unitId)
                            }
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 163.px
                        }
                    }
                }
            }
        }
    }
}