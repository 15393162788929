package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import web.cssom.*

object AdministrationPageStyle {

    val admimUserBoxes = ClassName {
        backgroundColor = NamedColor.white
        padding = 15.px
        borderRadius = 25.px
        width = 210.px // Fixed width
        minHeight = 100.px // Minimum height
        margin = 5.px
        display = Display.flex
        cursor = Cursor.pointer
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.spaceBetween
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 130.px
        }
        hover {
            backgroundColor = Color("#D4D7E4")
        }
    }

    val twoBoxSpace = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        gap = 10.px
    }


    val adminUserBoxValue = ClassName {
        fontSize = 36.px
        fontWeight = FontWeight.bold
        marginTop = 8.px
        textDecoration = TextDecoration.underline
    }

    val adminUserBoxTitle = ClassName {
        fontSize = 16.px
        wordBreak = WordBreak.breakWord
    }

    val adminUseBoxContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        flexWrap = FlexWrap.wrap
        justifyContent = JustifyContent.flexStart
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            justifyContent = JustifyContent.spaceBetween
        }
        "a, a:visited" {
            color = important(NamedColor.black)
        }
    }

    val adminToolsBoxContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        flexWrap = FlexWrap.wrap
        justifyContent = JustifyContent.flexStart
        gap = 15.px
        "a, a:visited" {
            color = important(NamedColor.black)
        }
    }

}

object AdminDiscoverPromoStyles {
    val promoContainer = ClassName {
        marginTop = 20.px
        borderRadius = 15.px
        overflow = Overflow.hidden
        backgroundColor = NamedColor.white

        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 100.pct
            marginTop = 25.px
        }
    }

    val imageContainer = ClassName {
        position = Position.relative
        height = 300.px
        width = 100.pct

        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            height = 200.px
        }
    }

    val promoImage = ClassName {
        width = 100.pct
        height = 100.pct
        objectFit = ObjectFit.cover
    }

    val overlay = ClassName {
        position = Position.absolute
        inset = 0.px
        padding = Padding(40.px, 40.px)
        display = Display.flex
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.center

        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            padding = Padding(20.px, 20.px)
        }
    }

    val title = ClassName {
        fontSize = 40.px
        fontWeight = FontWeight.bold
        color = NamedColor.white
        marginBottom = 30.px
        marginTop = 5.px
        fontStyle = FontStyle.italic

        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 24.px
        }
    }

    val description = ClassName {
        fontSize = 18.px
        color = NamedColor.white
        fontWeight = FontWeight.bold
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 16.px
        }
    }

    val learnMore = ClassName {
        color = NamedColor.white
        textDecoration = None.none
        fontSize = 18.px
        display = Display.inlineFlex
        alignItems = AlignItems.center
        fontWeight = FontWeight.bold
        marginTop = 15.px

        hover {
            textDecoration = TextDecoration.underline
        }
        "a, a:visited" {
            color = important(NamedColor.white)
        }
    }
}