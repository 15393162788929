package com.fireavert.gateways

import com.fireavert.common.Try
import com.fireavert.gateways.interface_adaptors.GatewayPropertyRepository
import com.fireavert.gateways.logic.GatewayPropertyScreen
import com.fireavert.gateways.models.GatewayModel

class GatewayPropertyInteractor(
    private val repository: GatewayPropertyRepository,
    private val screen: GatewayPropertyScreen,
) {
    suspend fun onLoad(propertyId: Int) {
        screen.loading = true
        screen.gatewayDeviceDataLoading = false
        val result = when (val maybe = repository.onLoad(propertyId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                return
            }
        }

        screen.associatedGateways = result


        //Get all gateways for selection
        when (val maybeAllGateways = repository.getAllGateways()) {
            is Try.Success -> {
                screen.allGateways = maybeAllGateways.value
            }

            is Try.Error -> {
                throw maybeAllGateways.exception
            }
        }

        screen.loading = false
    }

    suspend fun refreshGatewayStatus(propertyId: Int) {
        screen.loading = true
        val result = when (val maybe = repository.refreshGatewayStatus(propertyId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {

                screen.loading = false
                return
            }
        }
        screen.loading = false
        val updatedGateways = screen.associatedGateways.map { gateway ->
            val status = result.gatewayStatus.find { it.gatewayId == gateway.gatewayId }
            status?.let { gateway.copy(status = it.connectionStatus) } ?: gateway
        }
        screen.associatedGateways = updatedGateways
    }

    suspend fun saveAssociatedGateways(newGateways: List<GatewayModel>, propertyId: Int) {
        screen.loading = true
        when (val maybe = repository.saveAssociatedGateways(newGateways, propertyId)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                screen.loading = false
                return
            }
        }
        screen.loading = false
        screen.associatedGateways = newGateways
    }

    suspend fun loadGatewayDeviceData(propertyId: String) {
        screen.gatewayDeviceData = emptyMap()
        screen.gatewayDeviceDataLoading = true
        try {
            val response = when (val maybe = repository.loadGatewayDeviceData(propertyId)) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    screen.gatewayDeviceDataLoading = false
                    return
                }
            }

            screen.gatewayDeviceDataLoading = false
            screen.gatewayDeviceData = response.deviceToGateway

        } catch (_: Exception) { }

    }
}