package com.fireavert.user.api

import com.fireavert.user.logic.Permission
import com.fireavert.user.logic.models.responses.PermissionResponse

object PermissionMapper {
    fun mapFromPermissionResponse(permissionResponse: PermissionResponse): Permission {
        return Permission(
            id = permissionResponse.id ?: 0,
            key = permissionResponse.key ?: "",
            name = permissionResponse.name ?: ""
        )
    }
}

fun PermissionResponse.toPermission() : Permission = PermissionMapper.mapFromPermissionResponse(this)