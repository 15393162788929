package com.fireavert.components.administration

import YardiSavePropertyDataRequest
import com.fireavert.administration_page.yardi_integration.interface_adaptors.YardiCommonDataViewModel
import com.fireavert.administration_page.yardi_integration.logic.YardiCommonDataController
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.logging.Logger
import com.fireavert.management_companies.models.ManagementCompany
import com.fireavert.menu.RoutePaths
import com.fireavert.styles.LoadingCircleStyle
import com.fireavert.styles.StandardizedStyles
import com.fireavert.user.logic.Permission
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.ReactNode
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.onChange
import react.router.useNavigate
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType

data class CredentialOption(
    val value: String,
    val label: String
)


val YardiCommonDataSection = FC {
    val logger = getKoinInstance<Logger>()
    val navigate = useNavigate()

    // Form state
    var formData by useState(YardiSavePropertyDataRequest())

    // Accordion state
    var accordionStates by useState(mapOf(
        "settings" to false,
        "endpoints" to true
    ))
    // Edit mode state
    var editMode by useState(false)

    // Error states
    var connectionUrlKeyError by useState(false)
    var usernameError by useState(false)
    var passwordError by useState(false)
    var databaseServerError by useState(false)
    var databaseNameError by useState(false)
    var platformError by useState(false)
    var entityNameError by useState(false)
    var interfaceLicenseError by useState(false)
    var managementCompanyIdError by useState(false)
    var yardiPropertyIdError by useState(false)

    var selectedCredentials: String by useState("")
    var credentialOptions: List<CredentialOption> by useState(emptyList())

    // Loading and response state
    var loading by useState(false)
    var error by useState("")
    var responseData by useState("")
    var endpointTested by useState("")
    var managementCompanyList: List<ManagementCompany> by useState(emptyList())

    val controller = getKoinInstance<YardiCommonDataController>()
    val viewModel = getKoinInstance<YardiCommonDataViewModel>()

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
            error = viewState.error
            responseData = viewState.data
            managementCompanyList = viewState.managementCompanyList
            credentialOptions = viewState.availableCredentials.map {
                CredentialOption(it.id.toString(), it.connectionUrl)
            }
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad()
        }
        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    // Function to validate form
    fun validateForm(): Boolean {
        var isValid = true

        // Reset error states
        connectionUrlKeyError = false
        usernameError = false
        passwordError = false
        databaseServerError = false
        databaseNameError = false
        platformError = false
        entityNameError = false
        interfaceLicenseError = false
        managementCompanyIdError = false
        yardiPropertyIdError = false

        // Validate required fields
        if (formData.connectionUrlKey.isBlank()) {
            connectionUrlKeyError = true
            isValid = false
        }
        if (formData.username.isBlank()) {
            usernameError = true
            isValid = false
        }
        if (formData.password.isBlank()) {
            passwordError = true
            isValid = false
        }
        if (formData.databaseServer.isBlank()) {
            databaseServerError = true
            isValid = false
        }
        if (formData.databaseName.isBlank()) {
            databaseNameError = true
            isValid = false
        }
        if (formData.platform.isBlank()) {
            platformError = true
            isValid = false
        }
        if (formData.entityName.isBlank()) {
            entityNameError = true
            isValid = false
        }
        if (formData.interfaceLicense.isBlank()) {
            interfaceLicenseError = true
            isValid = false
        }
        if (formData.yardiPropertyId.isBlank()) {
            yardiPropertyIdError = true
            isValid = false
        }

        return isValid
    }

    // Function to toggle accordion
    fun toggleAccordion(key: String) {
        accordionStates = accordionStates.toMutableMap().apply {
            put(key, !(accordionStates[key] ?: false))
        }
    }

    Breadcrumbs {
        ariaLabel = "breadcrumb"

        Link {
            underline = LinkUnderline.hover
            color = "inherit"
            href = RoutePaths.administration

            +"Administration"
        }
        Typography {
            sx {
                color = NamedColor.black
            }
            +"Yardi Integration"
        }
    }

    // Header with Title and Edit Button
    div {
        className = ClassName {
            display = Display.flex
            justifyContent = JustifyContent.spaceBetween
            alignItems = AlignItems.center
            marginBottom = 24.px

            media(MediaQuery("(max-width: 640px)")) {
                flexDirection = FlexDirection.column
                gap = 16.px
            }
        }

        div {
            className = StandardizedStyles.HeaderH2
            +"Yardi CD Integration"
        }

        Button {
            sx {
                backgroundColor = if (!editMode) NamedColor.black else null
                color = if (!editMode) NamedColor.white else null
            }
            variant = ButtonVariant.contained
            onClick = {
                if (editMode) {
                    if (validateForm()) {
                        viewModel.scope.launch {
                            controller.savePropertyData(formData)
                            editMode = false
                        }
                        logger.i("Saving settings")
                    }
                } else {
                    editMode = true
                }
            }
            +(if (editMode) "Save Settings" else "Edit Settings")
        }
    }

    div {
        className = ClassName {
            width = 100.pct
            margin = Margin(0.px, Auto.auto)
        }

        // Integration Settings Accordion
        Accordion {
            sx {
                marginTop = 16.px
                borderRadius = 8.px
                boxShadow = BoxShadow(0.px, 2.px, 4.px, Color("rgba(0, 0, 0, 0.1)"))

                "&.MuiAccordion-root:last-of-type" {
                    borderRadius = 8.px
                }
                "&" {
                    paddingTop = 10.px
                    paddingBottom = 10.px
                    borderRadius = important(8.px)
                }
            }
            expanded = accordionStates["settings"] == true
            onChange = { _, _ -> toggleAccordion("settings") }

            AccordionSummary {
                div {
                    className = ClassName {
                        fontSize = 18.px
                        fontWeight = integer(600)
                    }
                    +"Integration Settings"
                }
            }

            AccordionDetails {
                sx {
                    padding = 24.px
                    "&.MuiAccordionDetails-root" {
                        borderTop = Border(1.px, LineStyle.solid, Color("#edeff2"))
                    }
                }

                div {
                    className = ClassName {
                        display = Display.grid
                        gap = 16.px

                    }

                    // Connection Settings
                    div {
                        className = ClassName {
                            marginBottom = 24.px

                        }

                        h3 {
                            className = ClassName {
                                fontSize = 16.px
                                fontWeight = integer(600)
                                marginBottom = 16.px
                                color = rgb(59, 59, 59)
                            }
                            +"Connection Settings"
                        }

                        div {
                            className = ClassName {
                                display = Display.grid
                                gap = 16.px
                            }

                            // Connection URL Key
                            TextField {
                                label = ReactNode("Connection URL Key")
                                fullWidth = true
                                value = formData.connectionUrlKey
                                this.error = connectionUrlKeyError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(connectionUrlKey = target.value)
                                }
                                helperText = if (connectionUrlKeyError) ReactNode("Required field") else ReactNode("")
                            }

                            // Username
                            TextField {
                                label = ReactNode("Username")
                                fullWidth = true
                                value = formData.username
                                this.error = usernameError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(username = target.value)
                                }
                                helperText = if (usernameError) ReactNode("Required field") else ReactNode("")
                            }

                            // Password
                            TextField {
                                label = ReactNode("Password")
                                fullWidth = true
                                type = InputType.text
                                value = formData.password
                                this.error = passwordError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(password = target.value)
                                }
                                helperText = if (passwordError) ReactNode("Required field") else ReactNode("")
                            }
                        }
                    }

                    // Database Settings
                    div {
                        className = ClassName {
                            marginBottom = 24.px
                        }

                        h3 {
                            className = ClassName {
                                fontSize = 16.px
                                fontWeight = integer(600)
                                marginBottom = 16.px
                                color = rgb(59, 59, 59)
                            }
                            +"Database Settings"
                        }

                        div {
                            className = ClassName {
                                display = Display.grid
                                gap = 16.px
                            }

                            // Database Server
                            TextField {
                                label = ReactNode("Database Server")
                                fullWidth = true
                                value = formData.databaseServer
                                this.error = databaseServerError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(databaseServer = target.value)
                                }
                                helperText = if (databaseServerError) ReactNode("Required field") else ReactNode("")
                            }

                            // Database Name
                            TextField {
                                label = ReactNode("Database Name")
                                fullWidth = true
                                value = formData.databaseName
                                this.error = databaseNameError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(databaseName = target.value)
                                }
                                helperText = if (databaseNameError) ReactNode("Required field") else ReactNode("")
                            }

                            // Platform
                            TextField {
                                label = ReactNode("Platform")
                                fullWidth = true
                                value = formData.platform
                                this.error = platformError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(platform = target.value)
                                }
                                helperText = if (platformError) ReactNode("Required field") else ReactNode("")
                            }
                        }
                    }

                    // Yardi Settings
                    div {
                        className = ClassName {
                            marginBottom = 24.px
                        }

                        h3 {
                            className = ClassName {
                                fontSize = 16.px
                                fontWeight = integer(600)
                                marginBottom = 16.px
                                color = rgb(59, 59, 59)
                            }
                            +"Yardi Settings"
                        }

                        div {
                            className = ClassName {
                                display = Display.grid
                                gap = 16.px
                            }

                            // Entity Name
                            TextField {
                                label = ReactNode("Entity Name")
                                fullWidth = true
                                value = formData.entityName
                                this.error = entityNameError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(entityName = target.value)
                                }
                                helperText = if (entityNameError) ReactNode("Required field") else ReactNode("")
                            }

                            // Interface License
                            TextField {
                                label = ReactNode("Interface License")
                                fullWidth = true
                                value = formData.interfaceLicense
                                this.error = interfaceLicenseError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(interfaceLicense = target.value)
                                }
                                helperText = if (interfaceLicenseError) ReactNode("Required field") else ReactNode("")
                            }

                            // Management Company ID
                            TextField {
                                label = ReactNode("Management Company ID")
                                fullWidth = true
                                type = InputType.number
                                value = formData.managementCompanyId.toString()
                                this.error = managementCompanyIdError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    val value = target.value.toIntOrNull() ?: 0
                                    formData = formData.copy(managementCompanyId = value)
                                }
                                helperText = if (managementCompanyIdError) ReactNode("Required field") else ReactNode("")
                            }

                            // Yardi Property ID
                            TextField {
                                label = ReactNode("Yardi Property ID")
                                fullWidth = true
                                value = formData.yardiPropertyId
                                this.error = yardiPropertyIdError
                                disabled = !editMode
                                onChange = { event ->
                                    val target = event.target as HTMLInputElement
                                    formData = formData.copy(yardiPropertyId = target.value)
                                }
                                helperText = if (yardiPropertyIdError) ReactNode("Required field") else ReactNode("")
                            }
                        }
                    }
                }
            }
        }

        h3 {
            className = ClassName {
                fontSize = 16.px
                fontWeight = integer(600)
                marginBottom = 16.px
                color = rgb(59, 59, 59)
            }
            +"Select Credentials"
        }

        PermissionRequired {
            permission = Permission.ViewSuperAdmins
            div {
                Select {
                    labelId = "demo-simple-select-label"
                    id = "demo-simple-select"
                    value = selectedCredentials
                    label = ReactNode("Select Credentials")
                    onChange = { event, _ ->
                        val target = event.target
                        selectedCredentials = target.value
                    }

                    credentialOptions.forEach { option ->
                        MenuItem {
                            key = option.value
                            value = option.value
                            +option.label
                        }
                    }
                }
            }
        }

        // Test Endpoints Accordion
        Accordion {
            sx {
                marginTop = 16.px
                borderRadius = 8.px
                boxShadow = BoxShadow(0.px, 2.px, 4.px, Color("rgba(0, 0, 0, 0.1)"))

                "&.MuiAccordion-root:last-of-type" {
                    borderRadius = 8.px
                }
                "&" {
                    paddingTop = 10.px
                    paddingBottom = 10.px
                    borderRadius = important(8.px)
                }
            }
            expanded = accordionStates["endpoints"] == true
            onChange = { _, _ -> toggleAccordion("endpoints") }

            AccordionSummary {
                div {
                    className = ClassName {
                        fontSize = 18.px
                        fontWeight = integer(600)
                    }
                    +"Test Endpoints"
                }
            }

            AccordionDetails {
                sx {
                    padding = 24.px
                    "&.MuiAccordionDetails-root" {
                        borderTop = Border(1.px, LineStyle.solid, Color("#edeff2"))
                    }
                }

                div {
                    className = ClassName {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        flexWrap = FlexWrap.wrap
                        gap = 16.px
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch{
                            controller.testPing(selectedCredentials.toInt())
                        } }
                        +"Test Ping"
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch{
                            controller.testGetVersionNumber(selectedCredentials.toInt())
                        } }
                        +"Get Version Number"
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch{
                            controller.testGetUnitData(selectedCredentials.toInt())
                        } }
                        +"Test Unit Data"
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        color = ButtonColor.primary
                        onClick =  { viewModel.scope.launch {
                            controller.testGetResidentData(selectedCredentials.toInt())
                        } }
                        +"Test Residents Endpoint"
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch {
                            controller.testGetResidentByStatusData(selectedCredentials.toInt())
                        } }
                        +"Test Residents By Status"
                    }
                    Button {
                        variant = ButtonVariant.outlined
                        disabled = true
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch {
                            controller.testGetPropertyList(selectedCredentials.toInt())
                        } }
                        +"Test Get Property List"
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        disabled = true
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch {
                            controller.testGetResidentsByUnit(selectedCredentials.toInt())
                        } }
                        +"Test Get Residents by Unit"
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        disabled = true
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch {
                            controller.testGetTenantStatus(selectedCredentials.toInt())
                        } }
                        +"Test Get Tenant Status"
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        disabled = true
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch {
                            controller.testGetRateLimits(selectedCredentials.toInt())
                        } }
                        +"Test Get Rate Limits"
                    }

                    Button {
                        variant = ButtonVariant.outlined
                        disabled = true
                        color = ButtonColor.primary
                        onClick = { viewModel.scope.launch {
                        } }
                        +"Test GetResidentData (Residential only) "
                    }
                }

                // Response display section within Test Endpoints
                div {
                    className = ClassName {
                        marginTop = 24.px
                        borderTop = Border(1.px, LineStyle.solid, Color("#edeff2"))
                        paddingTop = 24.px
                    }

                    Typography {
                        variant = TypographyVariant.h6
                        +"Response"
                    }

                    if (loading) {
                        div {
                            className = LoadingCircleStyle.loadingCircleAdminPage
                            CircularProgress {
                                color = CircularProgressColor.primary
                            }
                        }
                    } else if (responseData.isNotEmpty()) {
                        div {
                            className = ClassName {
                                marginTop = 16.px
                                padding = 16.px
                                backgroundColor = rgb(245, 245, 245)
                                borderRadius = 4.px
                                minHeight = 100.px
                            }

                            Typography {
                                variant = TypographyVariant.subtitle1
                                +"Endpoint: $endpointTested"
                            }

                            Typography {
                                sx {
                                    marginTop = 8.px
                                }
                                +responseData
                            }
                        }
                    } else {
                        div {
                            className = ClassName {
                                marginTop = 16.px
                                padding = 16.px
                                backgroundColor = rgb(245, 245, 245)
                                borderRadius = 4.px
                                minHeight = 100.px
                                display = Display.flex
                                alignItems = AlignItems.center
                                justifyContent = JustifyContent.center
                            }

                            Typography {
                                +"Test an endpoint to see the response"
                            }
                        }
                    }
                }
            }
        }
    }
}