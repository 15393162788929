package com.fireavert.components.unauthenticated

import com.fireavert.components.common.NotLoggedIn
import com.fireavert.components.targetInputValue
import com.fireavert.invite.interface_adapters.InviteController
import com.fireavert.invite.interface_adapters.InviteViewModel
import com.fireavert.logging.Logger
import com.fireavert.styles.AuthStyles
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import org.w3c.dom.url.URLSearchParams
import react.FC
import react.ReactNode
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.router.useLocation
import react.useEffectOnceWithCleanup
import react.useState
import web.html.ButtonType
import web.html.InputType


fun useQuery(): String? {
    val search = useLocation()
    return URLSearchParams(search).get("search")?.trim('?')
}

val InvitePage = FC {
    val queryString = useQuery() ?: ""
    val controller = getKoinInstance<InviteController>()
    val viewModel = getKoinInstance<InviteViewModel>()
    val logger = getKoinInstance<Logger>()

    var loading: Boolean by useState(false)
    var userId: Int by useState(0)
    var password: String by useState("")
    var passwordCheck: String by useState("")
    var error: Exception? by useState(null)
    var token: String by useState("")

    fun setTokenFromQueryString(queryString: String) {
        val sections = queryString.split("&")
        for (section in sections) {
            val keyValue = section.split("=")
            if (keyValue.size < 2) continue
            if (keyValue[0] == "token") {
                viewModel.scope.launch {
                    controller.tokenChanged(keyValue[1])
                }
            }
        }
    }

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
            userId = viewState.userId
            password = viewState.password
            passwordCheck = viewState.passwordCheck
            error = viewState.error
            token = viewState.token
        }.launchIn(viewModel.scope)

        setTokenFromQueryString(queryString)

        onCleanup {
            viewStateJob.cancel()
        }
    }

    NotLoggedIn {}

    val submitFunction = {
        viewModel.scope.launch {
            controller.clickedCreateNewPassword()
        }
    }

    form {

        onSubmit = {
            it.preventDefault()
            submitFunction()
        }
        noValidate = true
        className = AuthStyles.loginForm

        div {
            className = AuthStyles.welcomeTitle
            +"Create A New Password"
        }

        div {
            className = AuthStyles.formBody
            img {
                alt = "FireAvert Logo"
                src = "/static/logo.svg"
                className = AuthStyles.logo
            }
            div {
                className = AuthStyles.controlFields
                TextField {
                    value = password
                    variant = FormControlVariant.outlined
                    margin = FormControlMargin.normal
                    required = true
                    fullWidth = true
                    id = "password"
                    label = ReactNode("Password")
                    name = "password"
                    autoComplete = ""
                    type = InputType.password
                    autoFocus = true
                    this.error = (error != null)
                    disabled = loading
                    onChange = { controller.passwordChanged(it.targetInputValue) }
                    className = AuthStyles.textField
                }
                TextField {
                    value = passwordCheck
                    variant = FormControlVariant.outlined
                    margin = FormControlMargin.normal
                    required = true
                    fullWidth = true
                    name = "passwordCheck"
                    label = ReactNode("Re-Type Password")
                    type = InputType.password
                    id = "passwordCheck"
                    autoComplete = ""
                    this.error = (error != null)
                    disabled = loading
                    onChange = {
                        controller.passwordCheckChanged(it.targetInputValue)
                    }
                    className = AuthStyles.textField
                }
                div {
                    className = AuthStyles.errorMessage
                    val errorMessage = error?.message
                    if (errorMessage != null) {
                        +"${error?.message}"
                    }
                }
                Button {
                    type = ButtonType.submit
                    variant = ButtonVariant.contained
                    color = ButtonColor.primary
                    disabled = loading
                    fullWidth = true
                    onClick = { submitFunction() }
                    className = AuthStyles.loginButton
                    +"Create New Password"
                }
            }
        }
    }

}