package com.fireavert.components.common.shared

import js.objects.jso
import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import web.cssom.*

external interface DropDownSelectorProps : Props {
    var label: String
    var items: List<DropDownItem>
    var selectedValues: List<String>
    var onSelectionChange: (List<String>) -> Unit
    var isMobile: Boolean?
    var isFullWidth: Boolean
    var id: String
}


class DropDownItemImpl(
    override var value: String,
    override var label: String
) : DropDownItem

external interface DropDownItem {
    var value: String
    var label: String
}

val DropDownSelector = FC<DropDownSelectorProps> { props ->
    ReactHTML.div {
        InputLabel {
            id = props.id
            +props.label
        }
        Select {
            ariaLabel = props.label
            value = props.selectedValues.toTypedArray()
            id = props.id
            fullWidth = props.isFullWidth
            label = ReactNode(props.label)
            multiple = true
            variant = SelectVariant.outlined
            onChange = { event, _ ->
                val selectedValues = event.target.asDynamic().value as Array<String>
                props.onSelectionChange(selectedValues.toList())
            }
            renderValue = { selected ->
                ReactNode((selected as Array<*>).joinToString(", ") { value ->
                    props.items.find { it.value == value }?.label ?: value.toString()
                })
            }

            MenuProps = jso {
                PaperProps = jso {
                    sx {
                        "& .MuiMenuItem-root" {
                            whiteSpace = important(WhiteSpace.normal)
                            wordBreak = important(WordBreak.breakWord)
                        }
                    }
                }
            }

            if (props.isMobile == true) {
                sx {
                    "& .MuiSelect-select.MuiSelect-outlined" {
                        height = important(Auto.auto)
                        minHeight = important(1.4375.em)
                        maxHeight = important(None.none)
                        textOverflow = important(TextOverflow.clip)
                        whiteSpace = important(WhiteSpace.normal)
                        overflow = important(Overflow.visible)
                        wordBreak = important(WordBreak.breakWord)
                        display = important(Display.block)
                        width = important(100.pct)
                        padding = important(8.px)
                    }
                    "& .MuiOutlinedInput-notchedOutline" {
                        height = important(Auto.auto)
                    }
                    "& .MuiSelect-multiple" {
                        alignItems = important(AlignItems.flexStart)
                    }
                    "& .MuiOutlinedInput-input" {
                        height = important(Auto.auto)
                        maxHeight = important(None.none)
                        overflow = important(Overflow.visible)
                    }
                    "& .MuiSelect-icon" {
                        top = important(50.pct)
                    }
                }
            }

            props.items.forEach { item ->
                MenuItem {
                    value = item.value
                    Checkbox {
                        checked = props.selectedValues.contains(item.value)
                    }
                    ListItemText {
                        primary = ReactNode(item.label)
                    }
                }
            }
        }
    }
}