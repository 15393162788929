package com.fireavert.properties.logic.property_portfolio

import com.fireavert.common.TableColumn
import com.fireavert.common.TableColumn.SortOrder.Neutral
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.api.toProperty
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.properties.logic.models.Property
import com.fireavert.user.logic.Permission
import kotlinx.coroutines.launch

class ClientPropertyPortfolio(
    private val propertyRepository: ClientPropertyRepository,
    private val screen: PropertyPortfolioScreen,
    private val logger: Logger,
    private val preferences: Preferences
) : PropertyPortfolio {
    override fun didLoad() {
        screen.setShowCreateButton(preferences.permissions.contains(Permission.CreateProperty))
        screen.setShowEditProperty(preferences.permissions.contains(Permission.EditProperty))
        screen.setShowDeleteProperty(preferences.permissions.contains(Permission.DeleteProperty))
        screen.scope.launch {
            screen.setLoading(true)
            val properties = when (val maybeProperties = propertyRepository.getProperties()) {
                is Try.Success -> {
                    maybeProperties.value
                }
                is Try.Error -> {
                    screen.setLoading(false)
                    logger.e("Failed to get properties")
                    null
                }
            } ?: return@launch

            val managementCompanyResponse = when (val maybe = propertyRepository.getPropertiesWithManagementCompany()) {
                is Try.Success -> {
                    maybe.value
                }
                is Try.Error -> {
                    screen.setLoading(false)
                    logger.e("Failed to get properties with management companies")
                    null
                }
            } ?: return@launch
            val mapToProperty = managementCompanyResponse.mapValues { it.value.map { it.toProperty() } }
            screen.setManagementCompaniesWithProperties(mapToProperty)
            screen.setProperties(properties)
            screen.setLoading(false)
        }
    }

    override fun searchAndsortPropertiesByColumnMap(columns: Array<TableColumn>, properties: Map<String, List<Property>>, searchValue: String): Map<String, List<Property>> {
        val filteredProperties = searchPropertiesMap(searchValue, properties)
        val sortColumn = columns.firstOrNull { it.sortOrder != Neutral } ?: return filteredProperties
        return when (sortColumn.text) {
            "Management Company" -> {
                TableColumn.sortMap(true, sortColumn, filteredProperties) { mapEntry ->
                    mapEntry.key
                }
            }
            "Properties" -> {
                filteredProperties.mapValues { entry ->
                    TableColumn.sortList(true, sortColumn, entry.value) { it.name }
                }
            }
            else -> filteredProperties
        }
    }

    override fun searchAndsortPropertiesByColumn(columns: Array<TableColumn>, properties: Array<Property>, searchValue: String): Array<Property> {
        val filteredProperties = searchProperties(searchValue, properties)
        val sortColumn = columns.firstOrNull { it.sortOrder != Neutral } ?: return filteredProperties
        return when (sortColumn.text) {
            "NAME" -> {
                TableColumn.sortList(true,sortColumn, filteredProperties.toList()) { it.name }.toTypedArray()
            }
            "ADDRESS" -> {
                TableColumn.sortList(true,sortColumn, filteredProperties.toList()) { it.address }.toTypedArray()
            }
            "Property" -> {
                TableColumn.sortList(true,sortColumn, filteredProperties.toList()) { it.name }.toTypedArray()
            }
            else -> {
                filteredProperties
            }
        }
    }

    override fun filterSubPropertiesBySearchValue(
        searchValue: String,
        properties: Array<Property>
    ): Array<Property> {
        return searchProperties(searchValue, properties)
    }

    private fun searchProperties(searchValue: String, properties: Array<Property>): Array<Property> {
        return properties.filter { property ->
            property.name.contains(searchValue, ignoreCase = true) ||
                    property.address.contains(searchValue, ignoreCase = true) ||
                    property.subscriptionType.value.contains(searchValue, ignoreCase = true)
        }.toTypedArray()
    }

    private fun searchPropertiesMap(searchValue: String, properties: Map<String, List<Property>>): Map<String, List<Property>> {
        return properties.filter { property ->
            property.key.contains(searchValue, ignoreCase = true) || property.value.any { it.name.contains(searchValue, ignoreCase = true) }
        }
    }
}