package com.fireavert.components.dialogs

import com.fireavert.events.dismissevent.presentation.DismissEventDialogController
import com.fireavert.events.dismissevent.presentation.DismissEventDialogViewModel
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import react.FC
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState

val DismissEventDialog = FC {
    val propertyId = useParams()["propertyId"]?.toInt() ?: 0
    val eventId = useParams()["eventId"]?.toLong() ?: 0

    val controller = getKoinInstance<DismissEventDialogController>()
    val viewModel = getKoinInstance<DismissEventDialogViewModel>()

    var loading: Boolean by useState(false)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
        }.launchIn(viewModel.scope)

        controller.onLoad(eventId)

        onCleanup {
            viewStateJob.cancel()
        }
    }

    EventActionDialog {
        close = { controller.close(propertyId) }
        this.loading = loading
        title = "Dismissing Event"
        successMessage = "Event was dismissed"
    }
}