package com.fireavert.components.administration

import com.fireavert.common.TableColumn
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.shared.HeaderLink
import com.fireavert.components.common.shared.PageHeader
import com.fireavert.components.common.shared.PageHeaderNavigation
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.management_companies.presentation.ManagementCompaniesPageViewModel
import com.fireavert.management_companies.presentation.ManagementCompanyController
import com.fireavert.menu.RoutePaths
import com.fireavert.properties.logic.models.Property
import com.fireavert.styles.StandardizedStyles
import com.fireavert.styles.TableStyles
import com.fireavert.user.logic.Permission
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.system.sx
import react.FC
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.router.useNavigate
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*
import web.html.HTMLDivElement


val ManagementCompanySection = FC { ->
    val controller = getKoinInstance<ManagementCompanyController>()
    val viewModel = getKoinInstance<ManagementCompaniesPageViewModel>()
    val navigate = useNavigate()
    var expandedRows: Set<String> by useState(emptySet())
    val (searchValue, setSearchValue) = useState("")
    var name: String by useState("")
    var managementCompaniesWithProperties = emptyMap<String, List<Property>>()

    var loading: Boolean by useState(false)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            name = viewState.name
            loading = viewState.loading
            managementCompaniesWithProperties = viewState.managementCompaniesWithProperties
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.managementCompanyPageOnLoad()
        }
        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    PageHeaderNavigation {
        linkCalls = mapOf(
            "Administration" to object : HeaderLink {
                override var text = "Administration"
                override var permissionNeeded = Permission.ViewHeaderLinks
                override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    navigate(RoutePaths.administration)
                }
                override var isActive = true
            }
        )
    }

    PageHeader {
        pageTitle = "Management Companies"
        deleteMode = false
        linkCalls = listOf(
            object : HeaderLink {
                override var text = "Add Management Company"
                override var permissionNeeded = Permission.CreateManagementCompany
                override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    navigate("${RoutePaths.administration}/companies/new")
                }
                override var isActive = true
            }
        )
    }

    fun toggleRowExpansion(managementCompany: String) {
        expandedRows = if (expandedRows.contains(managementCompany)) {
            expandedRows - managementCompany
        } else {
            expandedRows + managementCompany
        }
    }

    var stateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "Management Company",
                sortOrder = TableColumn.SortOrder.HighToLow,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "Properties",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            ),
        )
    )

    div {
        className = StandardizedStyles.HeaderH2
        +"Management Company Information"
    }

    div {
        className = TableStyles.tableContainerNew
        div {
            className = TableStyles.table
            SortBar {
                columns = mapToControlColumns(stateColumns)
                includeLoading = true
                isNewView = true
                isLoading = loading
                columnsChanged = { stateColumns = mapToStateColumns(it) }
            }

            if (!loading) {
                val sortedProperties = controller.sortPropertiesByColumnMap(
                    stateColumns,
                    managementCompaniesWithProperties,
                    searchValue
                )

                sortedProperties.forEach { (managementCompany, properties) ->
                    div {
                        className = TableStyles.tableRowPropertyListNew
                        onClick = { toggleRowExpansion(managementCompany) }

                        div {
                            className = cx(TableStyles.tableCellNew, ClassName {
                                fontWeight = integer(700)
                                borderBottom = Border(2.px, LineStyle.solid, Color("#E0E0E0"))
                            })
                            div {
                                div {
                                    css {zIndex = integer(99) }
                                    onClick = {
                                        it.stopPropagation()
                                        navigate("${RoutePaths.administration}/companies/${managementCompany}/edit")
                                    }
                                    PermissionRequired {
                                        permission = Permission.EditProperty
                                        ReactHTML.img {
                                            height = 20.0
                                            width = 20.0
                                            src = "/static/edit_icon.svg"
                                            alt = "edit"
                                        }
                                    }
                                }
                                +managementCompany
                            }
                        }
                        div {
                            css { paddingLeft = 20.px }
                            className = cx(TableStyles.tableCellNew, ClassName {
                                borderBottom = Border(2.px, LineStyle.solid, Color("#E0E0E0"))
                            })
                            +properties.size.toString()
                        }
                        div {
                            className = cx(TableStyles.tableCellNew, ClassName {
                                borderBottom = Border(2.px, LineStyle.solid, Color("#E0E0E0"))
                            })
                            div {
                                className = TableStyles.threeDotsButtonContainer

                                ReactHTML.img {
                                    src = if (expandedRows.contains(managementCompany)) {
                                        "/static/accordion_arrow_black_up.svg"
                                    } else {
                                        "/static/accordion_arrow_black.svg"
                                    }
                                    alt = "Expand"
                                }
                            }
                        }
                    }

                    if (expandedRows.contains(managementCompany)) {
                        val updatedProperties = controller.filterSubPropertiesBySearchValue(searchValue, properties.toTypedArray())
                        updatedProperties.forEach { property ->
                            div {
                                className = TableStyles.tableRowPropertyListNew
                                div {
                                    className = cx(TableStyles.tableCell, ClassName {
                                        paddingLeft = important(5.pct)
                                        color = NamedColor.black
                                        borderBottom = Border(1.px, LineStyle.solid, Color("#E0E0E0"))
                                    })
                                    div {
                                        className = StandardizedStyles.flexRowSetup
                                        div {
                                            +property.name
                                        }
                                    }
                                }
                                div {
                                    className = TableStyles.tableCell
                                    +""
                                }
                                div {
                                    className = TableStyles.tableCell
                                    div {
                                        css {
                                            display = Display.inlineFlex  // Add this
                                            marginLeft = Auto.auto        // Push to right
                                        }
                                        ReactHTML.img {
                                            src = "/static/mobile_arrow_black.svg"
                                            alt = "Arrow"
                                            css {
                                                width = 15.px  // Ensure explicit size
                                                height = 15.px
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                Skeleton {
                    sx { borderRadius = 10.px }
                    variant = SkeletonVariant.rectangular
                    width = 100.pct
                    height = 100.pct
                }
            }
        }
    }


}


