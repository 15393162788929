package com.fireavert.components.dialogs.property

import com.fireavert.components.common.WarningIcon
import com.fireavert.components.dialogs.ErrorDialogCloseCallback
import com.fireavert.properties.presentation.property_details.PropertyDetailsController
import com.fireavert.properties.presentation.property_details.PropertyDetailsViewModel
import com.fireavert.styles.DialogStyles
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import react.*
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import web.cssom.*

external interface PropertyTestModeDialogProps : Props {
    var closeCallback: ErrorDialogCloseCallback
    var controller: PropertyDetailsController
    var propertyId: Int
    var viewModel: PropertyDetailsViewModel
}

val PropertyTestModeDialog = FC<PropertyTestModeDialogProps> { props ->
    var timeToTestFor: Int by useState(0)
    var testModeError: String by useState("")
    var closeTestMode: Boolean by useState(false)


    useEffectOnceWithCleanup {
        val viewStateJob = props.viewModel.viewState.onEach { viewState ->
            testModeError = viewState.testModeError
            closeTestMode = viewState.isTestModeDialogOpen
        }.launchIn(props.viewModel.scope)

        onCleanup {
            viewStateJob.cancel()
        }
    }

    useEffect(closeTestMode){
        if (closeTestMode) {
            props.closeCallback()
        }
    }


    Dialog {
        open = true
        onBackdropClick = { props.viewModel.setTestModeError(""); props.closeCallback() }
        onClose = { _, _ -> props.viewModel.setTestModeError(""); props.closeCallback() }
        maxWidth = Breakpoint.md
        DialogContent {

            className = DialogStyles.warningDialogContent

            WarningIcon {}
            div {
                className = DialogStyles.warningText
                +"WARNING"
            }

            div {
                className = DialogStyles.warningText
                +"You are about to turn on test mode. This will DISABLE all alerts and notifications for the selected property. Make sure you want this before proceeding."
                br{}
                br{}
                +"If you would like to proceed, please select the duration of the test mode."
                br{}
            }

            div {
                css {
                    padding = 15.px
                    display = Display.flex
                    justifyContent = JustifyContent.center
                    gap = 15.px
                }
                Button {
                    disabled = timeToTestFor == 30
                    variant = ButtonVariant.contained
                    onClick = {
                        timeToTestFor = 30
                    }
                    +"30 Minutes"
                }
                Button {
                    variant = ButtonVariant.contained
                    onClick = {
                        timeToTestFor = 45
                    }
                    disabled = timeToTestFor == 45
                    +"45 Minutes"
                }
                Button {
                    onClick = {
                        timeToTestFor = 60
                    }
                    disabled = timeToTestFor == 60
                    variant = ButtonVariant.contained
                    +"60 Minutes"
                }
            }

            div {
                className = DialogStyles.warningBold
                +testModeError
            }

            DialogActions {
                css {
                    height = 94.px
                    padding = 24.px
                    justifyContent = important(JustifyContent.center)
                }
                Button {
                    +"Turn on"
                    color = ButtonColor.inherit
                    onClick = {
                        props.viewModel.scope.launch {
                           props.controller.turnOnTestMode(props.propertyId, timeToTestFor)
                        }
                    }
                    variant = ButtonVariant.contained
                    css {
                        width = 400.px
                        backgroundColor = NamedColor.white
                    }
                }

                Button {
                    +"Cancel"
                    color = ButtonColor.inherit
                    onClick = {
                        props.closeCallback()
                    }
                    variant = ButtonVariant.contained
                    css {
                        width = 400.px
                        backgroundColor = NamedColor.white
                    }
                }
            }
        }
    }
}