package com.fireavert.components.administration

import com.fireavert.administration_page.main_page.interface_adaptors.AdministrationPageViewModel
import com.fireavert.administration_page.main_page.logic.AdministrationPageController
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.shared.HeaderLink
import com.fireavert.components.common.shared.PageHeader
import com.fireavert.menu.RoutePaths
import com.fireavert.styles.AdministrationPageStyle
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.SearchbarStyles
import com.fireavert.styles.StandardizedStyles
import com.fireavert.styles.TableStyles
import com.fireavert.user.logic.Permission
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.material.TextField
import mui.system.sx
import mui.system.useMediaQuery
import react.FC
import react.ReactNode
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.router.dom.Link
import react.router.useNavigate
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.pct
import web.cssom.px
import web.html.HTMLDivElement
import web.html.HTMLInputElement

val AdministrationContent = FC {
    val controller = getKoinInstance<AdministrationPageController>()
    val viewModel = getKoinInstance<AdministrationPageViewModel>()
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)

    var loading: Boolean by useState(false)
    var userCount: Int by useState(0)
    var fireavertAdminCount: Int by useState(0)
    var executiveUserCount: Int by useState(0)
    var regionalManagerCount: Int by useState(0)
    var propertyAdminCount: Int by useState(0)
    var maintenanceUserCount: Int by useState(0)
    var tenantCount: Int by useState(0)
    var singleFamilyUserCount: Int by useState(0)
    var propertyNameList: Map<String, Int> by useState(emptyMap())
    var isComponentMounted by useState(true)
    var navigate = useNavigate()

    val (searchValue, setSearchValue) = useState("")

    useEffectOnceWithCleanup {
        isComponentMounted = true
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            propertyNameList = viewState.propertyList
            userCount = viewState.totalUserCount
            fireavertAdminCount = viewState.fireavertAdminCount
            executiveUserCount = viewState.executiveUserCount
            regionalManagerCount = viewState.regionalManagerCount
            propertyAdminCount = viewState.propertyAdminCount
            maintenanceUserCount = viewState.maintenanceUserCount
            tenantCount = viewState.tenantCount
            singleFamilyUserCount = viewState.singleFamilyUserCount
            loading = viewState.loading
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad()
        }

        onCleanup {
            isComponentMounted = false
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }

    PermissionRequired {
        permission = Permission.ViewAdminPage
        fallback = ReactNode("You do not have permission to view this page.")
        PageHeader {
            pageTitle = "Administration"
            deleteMode = false
            linkCalls = listOf(
                object : HeaderLink {
                    override var text = "Create Management Company"
                    override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                        navigate("${RoutePaths.administration}/companies/new")
                    }
                    override var isActive = true
                    override var permissionNeeded = Permission.CreateManagementCompany
                },
                object : HeaderLink {
                    override var text = "Manage Management Companies"
                    override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                        navigate("${RoutePaths.administration}/companies")
                    }
                    override var isActive = true
                    override var permissionNeeded = Permission.CreateManagementCompany
                }
            )
        }

        div {
            className = StandardizedStyles.HeaderH2
            +"Users Within Purview"
        }

        div {
            className = AdministrationPageStyle.adminUseBoxContainer
            PermissionRequired {
                permission = Permission.ViewSuperAdminTools
                fallback = null
                AdminUserBoxes {
                    boxTitle = "Total Users"
                    boxValue = userCount
                    link = "${RoutePaths.administration}/users/all"
                    this.loading = loading
                }
            }
            PermissionRequired {
                permission = Permission.ViewSalesReps
                fallback = null
                AdminUserBoxes {
                    boxTitle = "FireAvert Employee"
                    boxValue = fireavertAdminCount
                    link = "${RoutePaths.administration}/users/fireavert"
                    this.loading = loading
                }
            }
            PermissionRequired {
                permission = Permission.ViewExecutives
                AdminUserBoxes {
                    boxTitle = "Executive Admin"
                    boxValue = executiveUserCount
                    link = "${RoutePaths.administration}/users/executive"
                    this.loading = loading
                }
            }

            PermissionRequired {
                permission = Permission.ViewRegionalManagers
                AdminUserBoxes {
                    boxTitle = "Regional Manager"
                    boxValue = regionalManagerCount
                    link = "${RoutePaths.administration}/users/regional"
                    this.loading = loading
                }
            }
            PermissionRequired {
                permission = Permission.ViewPropertyManagers
                AdminUserBoxes {
                    boxTitle = "Property Admin"
                    boxValue = propertyAdminCount
                    link = "${RoutePaths.administration}/users/property"
                    this.loading = loading
                }
            }
            PermissionRequired {
                permission = Permission.ViewMaintenanceUsers
                AdminUserBoxes {
                    boxTitle = "Maintenance Users"
                    boxValue = maintenanceUserCount
                    link = "${RoutePaths.administration}/users/maintenance"
                    this.loading = loading
                }
            }
            PermissionRequired {
                permission = Permission.ViewTenants
                AdminUserBoxes {
                    boxTitle = "Tenants"
                    boxValue = tenantCount
                    link = "${RoutePaths.administration}/users/tenant"
                    this.loading = loading
                }
            }
        }

        div {
            className = SearchbarStyles.titleWithSearchbarNew
            div {
                className = StandardizedStyles.HeaderH2
                +"Properties Within Purview"
            }

            div {
                className = SearchbarStyles.searchBarNew
                TextField {
                    sx {
                        height = 25.px
                    }
                    className = SearchbarStyles.searchBarInput(20)
                    placeholder = "Search..."
                    value = searchValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setSearchValue(target?.value ?: "")
                    }
                }
            }
        }

        div {
            div {
                className = TableStyles.newTableView
                div {
                    className = TableStyles.tableNew
                    if (loading) {
                        Skeleton {
                            variant = SkeletonVariant.rounded
                            width = 150.pct
                            height = 100.px
                        }
                    } else {
                        val sortedProperties = controller.sortPropertyRecordByColumn(propertyNameList, searchValue)
                        sortedProperties.forEach { property ->
                            div {
                                className = TableStyles.tableRowAdminListNew
                                Link {
                                    to = "${RoutePaths.properties}/${property.value}"
                                    div {
                                        className = TableStyles.mobileTableRowWithArrow
                                        +property.key
                                        div {
                                            img {
                                                src = "/static/mobile_arrow_black.svg"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div {
            className = StandardizedStyles.HeaderH2
            +"Discover"
        }

        div {
            AdminDiscoverPromo {
                this.loading = loading
                title = "The Toilet Flow Sensor"
                description = "New from FireAvert"
                imageSrc = "/static/images/toilet_flow_sensor_promo.png"
                link = "/toilet_flow_sensor"
            }
        }
        PermissionRequired {
            // For now adding the view of property manager permission since most people at this level
            // and above will need to view at least one tool
            permission = Permission.ViewPropertyManagers
            div {
                className = StandardizedStyles.HeaderH2
                +"Tools"
            }
            div {
                className = AdministrationPageStyle.adminToolsBoxContainer
                PermissionRequired {
                    permission = Permission.ViewDeviceConnectivity
                    AdminToolBoxes {
                        this.loading = loading
                        boxTitle = "Device Connectivity"
                        boxIcon = "/static/admin_icons/device_connectivity.svg"
                    }
                }
                PermissionRequired {
                    permission = Permission.ViewUserActivity
                    AdminToolBoxes {
                        this.loading = loading
                        boxTitle = "User Activity"
                        boxIcon = "/static/admin_icons/user_activity.svg"
                    }
                }
                PermissionRequired {
                    permission = Permission.ViewGatewayTool
                    AdminToolBoxes {
                        this.loading = loading
                        boxTitle = "Gateways"
                        boxIcon = "/static/admin_icons/gateways.svg"
                    }
                }
                PermissionRequired {
                    permission = Permission.ViewGoogleAnalytics
                    AdminToolBoxes {
                        this.loading = loading
                        boxTitle = "Google Analytics"
                        boxIcon = "/static/admin_icons/google_analytics.svg"
                        link = "https://analytics.google.com/analytics/"
                    }
                }
                PermissionRequired {
                    permission = Permission.ViewSuperAdminTools
                    AdminToolBoxes {
                        this.loading = loading
                        boxTitle = "Super Admin Tools"
                        boxIcon = "/static/admin_icons/super_admin_tools.svg"
                        link = "${RoutePaths.administration}/tools"
                    }
                }
                PermissionRequired {
                    permission = Permission.ViewSuperAdminTools
                    AdminToolBoxes {
                        this.loading = loading
                        boxTitle = "Yardi CD Integration"
                        boxIcon = "/static/edit_icon.svg"
                        link = "${RoutePaths.administration}/yardi/commonData"
                    }
                }
            }
        }
    }
}