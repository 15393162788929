package com.fireavert.actions

import com.fireavert.components.active_events.truncateText
import com.fireavert.components.help.HelpDialog
import com.fireavert.events.logic.models.EventLinkedAction
import com.fireavert.styles.DashboardStylesV2
import com.fireavert.styles.HelpStyles
import emotion.css.ClassName
import emotion.css.cx
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.format.char
import kotlinx.datetime.toLocalDateTime
import mui.material.IconButton
import mui.material.Tooltip
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.useState
import web.cssom.Color


data class ActionCardTableData(
    val date: String,
    val time: String,
    val property: String,
    val unit: String
) {
    companion object {
        fun fromEventLinkedAction(action: EventLinkedAction, timeZone: TimeZone): ActionCardTableData {
            val dateTime = Instant.fromEpochSeconds(action.created).toLocalDateTime(timeZone)
            return ActionCardTableData(
                date = dateTime.date.format(kotlinx.datetime.LocalDate.Companion.Format {
                    monthNumber()
                    char('/')
                    dayOfMonth()
                    char('/')
                    year()
                }),
                time = dateTime.time.format(kotlinx.datetime.LocalTime.Companion.Format {
                    this.amPmHour()
                    char(':')
                    minute()
                    amPmMarker("am", "pm")
                }),
                property = action.propertyName,
                unit = action.unitName
            )
        }
    }
}

external interface ActionCardProps : Props {
    var imgSrc: String
    var text: String
    var showLink: Boolean
    var linkText: String
    var linkHref: String
    var number: Int
    var backgroundColor: Color
    var actionCardTableData: List<ActionCardTableData>
    var isMobileVersion: Boolean

}

val ActionCard = FC<ActionCardProps> { props ->
    if (props.isMobileVersion) {
        val (isDialogOpen, setIsDialogOpen) = useState(false)
        div {
            onClick = {
                setIsDialogOpen(true)
            }
            ariaLabel = "ActionCardAndTableContainer"
            className = DashboardStylesV2.actionCardAndTableContainer
            div {
                ariaLabel = "ActionCard"
                className = DashboardStylesV2.actionCard
                div {
                    className = DashboardStylesV2.actionCardTopRow
                    ReactHTML.span {
                        +props.text
                    }
                    img {
                        src = "/static/mobile_arrow.svg"
                    }
                }
                div {
                    className = DashboardStylesV2.actionCardNumber
                    +props.number.toString()
                }
            }
        }
        if (isDialogOpen) {
            ActionCardMobileDialog {
                titleText = props.text
                actionCardTableData = props.actionCardTableData
                number = props.number
                onClose = { setIsDialogOpen(false) }
            }
        }
    } else {
        div {
            ariaLabel = "ActionCardAndTableContainer"
            className = DashboardStylesV2.actionCardAndTableContainer
            div {
                ariaLabel = "ActionCard"
                className = cx(DashboardStylesV2.actionCard, ClassName {
                    backgroundColor = props.backgroundColor
                })
                Tooltip {
                    className = HelpStyles.helpIcon
                    title = HelpDialog.toolTipText(props.text)
                    IconButton {
                        img {
                            src = "/static/info_icon_black.svg"
                            alt = "Info icon"
                        }
                    }
                }
                div {
                    className = DashboardStylesV2.actionCardTopRow
                    img {
                        src = props.imgSrc
                    }
                    div {
                        +props.text
                    }
                }
                div {
                    className = DashboardStylesV2.actionCardBottomRow
                    if (props.showLink) {
                        a {
                            href = props.linkHref
                            +props.linkText
                        }
                    }
                    div {
                        className = DashboardStylesV2.actionCardNumber
                        +props.number.toString()
                    }
                }
            }
            if (props.actionCardTableData.isNotEmpty()) {
                ActionCardTable {
                    isMobile = false
                    actionCardTableData = props.actionCardTableData
                }
            }
        }
    }
}

external interface ActionCardTableProps : ActionCardProps {
    var isMobile: Boolean
}
val ActionCardTable = FC<ActionCardTableProps> { props ->
    div {
        ariaLabel = "ActionCardTableContainer"
        className = DashboardStylesV2.actionCardTableContainer
        div {
            ariaLabel = "ActionCardTable"
            className = DashboardStylesV2.actionCardTable
            div {
                className = DashboardStylesV2.actionCardTableHeader
                div {
                    className = DashboardStylesV2.actionCardTableRowCell
                    +"Date"
                }
                div {
                    className = DashboardStylesV2.actionCardTableRowCell
                    +"Time"
                }
                div {
                    className = DashboardStylesV2.actionCardTableRowCell
                    +"Property"
                }
                div {
                    className = DashboardStylesV2.actionCardTableRowCell
                    +"Unit #"
                }
            }
            props.actionCardTableData.forEach { data ->
                div {
                    className = DashboardStylesV2.actionCardTableRow
                    div {
                        className = DashboardStylesV2.actionCardTableRowCell
                        if (props.isMobile) {
                            +data.date.substring(0, 5)
                        } else {
                            +data.date
                        }
                    }
                    div {
                        className = DashboardStylesV2.actionCardTableRowCell
                        +data.time
                    }
                    div {
                        className = DashboardStylesV2.actionCardTableRowCell
                        if (props.isMobile) {
                            +truncateText(data.property, 12)
                        } else {
                            +data.property
                        }
                    }
                    div {
                        className = DashboardStylesV2.actionCardTableRowCell
                        +data.unit
                    }
                }
            }
        }
    }
}