package com.fireavert.units.presentation

import com.fireavert.common.TableColumn
import com.fireavert.units.logic.UnitsInteractor
import com.fireavert.units.logic.models.UnitModel

class UnitsSectionController(
    private val interactor: UnitsInteractor
) {
    suspend fun onLoad(propertyId: Int) = interactor.onLoad(propertyId)
    fun clickedAddNewUnit(propertyId: Int) = interactor.showAddNewUnit(propertyId)
    fun stringOutputForCount(count: Int): String = interactor.stringOutputForCount(count)
    fun clearState() = interactor.clearState()
    fun sortWithColumns(columns: Array<TableColumn>, unitModels: Array<UnitModel>, searchValue: String): Array<UnitModel> = interactor.searchAndsortWithColumns(columns, unitModels, searchValue)
    fun clickedAddNewUnitCSV(propertyId: Int) = interactor.showAddNewUnitCSV(propertyId)
    fun clickedCommV2Conversion(propertyId: Int) = interactor.clickedCommV2Conversion(propertyId)
    suspend fun exportUnitData(propertyId: Int, unitModels: List<UnitModel>): String? = interactor.exportUnitData(propertyId, unitModels)
}