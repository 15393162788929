package com.fireavert.administration_page.users.interface_adapters.add_user_dialog

import com.fireavert.administration_page.users.interface_adapters.user_info.UserDialogRepositoryDataSource
import com.fireavert.administration_page.users.logic.add_user_dialog.UserDialogRepository
import com.fireavert.administration_page.users.logic.add_user_dialog.models.request.SaveEditedUserRequest
import com.fireavert.administration_page.users.logic.add_user_dialog.models.request.SaveUserInfoRequest
import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.logic.models.response.PropertyAdminResponse
import com.fireavert.user.logic.TokenRefreshService
import com.fireavert.user.logic.models.RolesModel
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class UserDialogRepositoryImpl(
    private val dataSource: UserDialogRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
) : UserDialogRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun getRoleList(): Try<List<RolesModel>> {
        return withContext(ioContext) {
            callWithRefresh { dataSource.getRoleList(preferences.token) }
                .map { it.roles }
        }
    }

    override suspend fun saveUser(request: SaveUserInfoRequest): Try<Boolean> {

        return withContext(ioContext) {
            callWithRefresh { dataSource.saveUser(request, preferences.token) }
                .map { it }
        }
    }

    override suspend fun saveEditUser(request: SaveEditedUserRequest): Try<Boolean> {
        return withContext(ioContext) {
            callWithRefresh { dataSource.saveEditUser(request, preferences.token) }
                .map { it }
        }
    }

    override suspend fun getUserInfo(userId: Int): Try<PropertyAdminResponse> {
        return withContext(ioContext) {
            callWithRefresh { dataSource.getUserInfo(userId, preferences.token) }
                .map { it }
        }
    }
}