package com.fireavert.administration_page.users.interface_adapters.user_info

import com.fireavert.administration_page.users.logic.UserTrackingRecord
import com.fireavert.administration_page.users.logic.user_info.UserInfoInteractor
import com.fireavert.common.TableColumn
import com.fireavert.properties.logic.models.UserLinkedToProperty

class UserInfoController(
    private val interactor: UserInfoInteractor,
    ) {
    suspend fun onLoad(userId: Int) = interactor.onLoad(userId)

    suspend fun saveNotificationChanges(userId: Int, userToPropertyInfo: List<UserLinkedToProperty>) {
        interactor.saveNotificationChanges(userId, userToPropertyInfo)
    }

    suspend fun resendEmailInvite(userId: String) {
        interactor.resendEmailInvite(userId)
    }

    fun sortLoginHistoryRecords(
        columns: Array<TableColumn>,
        trackingRecords: List<UserTrackingRecord>,
        searchValue: String
    ): List<UserTrackingRecord> = interactor.searchAndSortLoginHistoryRecords(columns, trackingRecords, searchValue)
}