package com.fireavert.devices.logic.models

data class Device(
    val id: Int,
    val deviceLocator: String,
    val location: String,
    val unit: String,
    val unitId: Int,
    val propertyId: Int,
    val tenantName: String,
    val phone: String,
    val email: String,
    val alarmEvents: Int,
    val triggerEvents: Int,
    val status: FireAvertStatus,
    val alarmIsOn: Boolean,
    val deviceIsTriggered: Boolean,
    val offline: Boolean,
    val mainBoardVersion: String = "",
    val radioBoardVersion: String = "",
    val monitoring: Boolean,
    val monitoringAccountId: Int,
    val commVersion: Int,
    val type: DeviceType,
    val ignoreBefore: Long,
    val watchdogCount: Long,
    val lastWatchdogSend: Long,
    val rebootUUID: String,
    val infoUUID: String,
    val installed: Boolean,
)