package com.fireavert.administration_page.wireless_id_finder.frameworks

import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdRepositoryDataSource
import com.fireavert.common.Try
import com.fireavert.devices.logic.response.GetDeviceRecentGatewayAssociationResponse
import com.fireavert.devices.logic.response.WirelessDeviceIdResponse
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class WirelessIdRepositoryDataSourceImpl(
    private val httpClient: HttpClient,
    private val baseUrl: String,
): WirelessIdRepositoryDataSource {
    override suspend fun loadWirelessIdData(deviceId: String, token: String): Try<WirelessDeviceIdResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/wirelessId/$deviceId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun loadGatewayDeviceData(propertyId: String, token: String): Try<GetDeviceRecentGatewayAssociationResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/lastGatewayConnected/$propertyId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun reloadAllGatewayInfo(token: String): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/gateways/reloadAllGatewayInfo")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}