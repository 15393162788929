package com.fireavert.administration_page.wireless_id_finder.interface_adapters

import com.fireavert.administration_page.wireless_id_finder.logic.WirelessIdSectionInteractor
import com.fireavert.administration_page.wireless_id_finder.logic.models.PropertyOfflineStatisticsModel
import com.fireavert.common.TableColumn

class WirelessIdSectionController(
    private val interactor: WirelessIdSectionInteractor,
) {
    suspend fun loadWirelessIdData(deviceId: String) = interactor.loadWirelessIdData(deviceId)
    suspend fun loadGatewayDeviceData(propertyId: String) = interactor.loadGatewayDeviceData(propertyId)
    suspend fun reloadAllGatewayInfo() = interactor.reloadAllGatewayInfo()

    /**
     * Sorts the provided statistics based on the column configuration.
     * Note: Filtering should happen *before* calling this in the UI if needed.
     */
    fun sortPropertyStatistics(
        columns: Array<TableColumn>,
        statistics: List<PropertyOfflineStatisticsModel>
    ): List<PropertyOfflineStatisticsModel> = interactor.sortPropertyStatistics(columns, statistics)

    suspend fun loadPropertyStatistics() = interactor.loadPropertyStatistics()
    suspend fun checkAppsheetDatabaseHealth() = interactor.checkAppsheetDatabaseHealth()

    /**
     * Generates a CSV string representation of the provided property statistics.
     */
    fun generatePropertyStatisticsCsv(
        statistics: List<PropertyOfflineStatisticsModel>
    ): String = interactor.generatePropertyStatisticsCsv(statistics)
}