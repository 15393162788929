package com.fireavert.administration_page.users.interface_adapters.user_section

import com.fireavert.administration_page.users.logic.UserTrackingRecord
import kotlinx.serialization.Serializable

@Serializable
data class UserSectionRecordDTO(
    val id: Int? = null,
    val name: String? = null,
    val email: String? = null,
    val phone: String? = null,
    val managementCompany: String? = null,
) {
    fun toUserTrackingRecord(): UserTrackingRecord {
//        val lastVisitMap: Map<Instant?, String?> = userVisitLog.mapKeys { entry ->
//            entry.key?.let { Instant.fromEpochSeconds(it) } ?: Instant.fromEpochSeconds(0)
//        }
        return UserTrackingRecord(
            id = id ?: 0,
            name = name ?: "",
            email = email ?: "",
            phone = phone ?: "",
            managementCompany = managementCompany ?: "",
        )
    }

}
