package com.fireavert.components.administration

import com.fireavert.administration_page.users.interface_adapters.user_info.UserInfoController
import com.fireavert.administration_page.users.interface_adapters.user_info.UserInfoViewModel
import com.fireavert.common.TableColumn
import com.fireavert.components.common.shared.HeaderLink
import com.fireavert.components.common.shared.PageHeaderNavigation
import com.fireavert.components.common.shared.PageHeaderUserInfo
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.properties.logic.models.UserLinkedToProperty
import com.fireavert.styles.LoadingCircleStyle
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.StandardizedStyles
import com.fireavert.styles.TableStyles
import com.fireavert.styles.UserPagesStyles
import com.fireavert.user.logic.Permission
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.CircularProgress
import mui.material.CircularProgressColor
import mui.system.useMediaQuery
import react.FC
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.router.useNavigate
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.FontWeight
import web.cssom.NamedColor
import web.cssom.TextAlign
import web.cssom.px
import web.html.HTMLDivElement


val ManagementCompanySection = FC { ->
    val controller = getKoinInstance<UserInfoController>()
    val viewModel = getKoinInstance<UserInfoViewModel>()
    var isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    val navigate = useNavigate()
    val logger = getKoinInstance<Logger>()
    var name: String by useState("")
    var email: String by useState("")
    var role: String by useState("")
    var phone: String by useState("")
    var loginHistory: Map<String, String> by useState(emptyMap())
    var propertyNotificationInfo: List<UserLinkedToProperty> by useState(emptyList())
    val (searchValue, setSearchValue) = useState("")
    val params = useParams()
    var id = params["userId"] ?: "0"
    var monthlyLoginCount: Int by useState(0)
    var weeklyLoginCount: Int by useState(0)

    var loading: Boolean by useState(false)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            name = viewState.name
            email = viewState.email
            loginHistory = viewState.loginHistory
            propertyNotificationInfo = viewState.propertyNotificationInfo
            loading = viewState.loading
            phone = viewState.phone
            role = viewState.role.first()
            weeklyLoginCount = viewState.weeklyLoginCount
            monthlyLoginCount = viewState.monthlyLoginCount
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            val userId = params["userId"] ?: "0"
            controller.onLoad(userId.toInt())
        }
        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    PageHeaderNavigation {
        linkCalls = mapOf(
            "Administration" to object : HeaderLink {
                override var text = "Administration"
                override var permissionNeeded = Permission.ViewHeaderLinks
                override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    navigate(RoutePaths.administration)
                }
                override var isActive = true
            }
        )
    }

    PageHeaderUserInfo {
        pageTitle = name
        this.role = role.map { it.toString() }
        this.email = email
        this.phone = phone
        deleteMode = false
        linkCalls = listOf(
            object : HeaderLink {
                override var text = "Edit User"
                override var permissionNeeded = Permission.EditUsers
                override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    // TODO!!!!!!!
                }
                override var isActive = true
            },
            object : HeaderLink {
                override var text = "Delete User"
                override var permissionNeeded = Permission.DeleteUser
                override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                }
                override var isActive = true
            }
        )
    }

    var loginTableColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "Date",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "Page Viewed",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            ),
        )
    )

    div {
        className = StandardizedStyles.HeaderH2
        +"Login History"
    }

    div {
        className = TableStyles.newTableView
        if (loading) {
            div {
                className = LoadingCircleStyle.loadingCircleAdminPage
                span {
                    CircularProgress {
                        color = CircularProgressColor.info
                        size = 150.px
                    }
                }
            }
        } else {
            div {
                className = TableStyles.tableNew
                SortBar {
                    this.columns = mapToControlColumns(loginTableColumns)
                    includeLoading = false
                    isLoading = loading
                    isNewView = true
                    columnsChanged = {
                        loginTableColumns = mapToStateColumns(it)
                    }
                }

//                val loginHistoryRecords =
//                    controller.sortLoginHistoryRecords(desktopColumns, emptyList(), searchValue)

                for (record in loginHistory) {
                    div {
                        className = TableStyles.tableRowNew
                        div {
                            className = TableStyles.tableCellNew
                            +record.key
                        }
                        div {
                            className = TableStyles.tableCellAlignVar(TextAlign.left)
                            +record.value
                        }
                    }
                }
            }
        }
    }

    div {
        className = StandardizedStyles.flexRowSetup
        div {
            className = UserPagesStyles.userLoginCountBoxes
            div {
                className = ClassName { color = NamedColor.black; fontWeight = FontWeight.bold }
                +"Monthly Log-in Count: "
            }
            div {
                className = ClassName { color = NamedColor.grey; marginLeft = 5.px }
                +" $monthlyLoginCount"
            }
        }

        div {
            className = UserPagesStyles.userLoginCountBoxes
            div {
                className = ClassName { color = NamedColor.black; fontWeight = FontWeight.bold }
                +"Weekly Log-in Count: "
            }
            div {
                className = ClassName { color = NamedColor.grey; marginLeft = 5.px }
                +" $weeklyLoginCount"
            }
        }
    }

    UserNotificationManager {
        propertyNotificationList = propertyNotificationInfo
        onEdit = {
            viewModel.scope.launch {controller.saveNotificationChanges(id.toInt(), it) }
        }
    }
}


