package com.fireavert.reports_page.logic

import com.fireavert.common.Try
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.DeviceType.*
import com.fireavert.events.logic.models.EventType
import com.fireavert.events.logic.models.EventType.DeviceTriggered
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.reports_page.frameworks.EventTypes
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.math.round


class ReportsPagesSectionInteractor(
    private val screen: ReportsPageScreen,
    private val propertyRepository: ClientPropertyRepository,
    private val reportsPageRepository: ReportsPageRepository,
    private val logger: Logger
) {

    suspend fun onLoad(propertyId: Int?, eventTypeValue: String, yearToGet: Int) {
        screen.loading = true
        screen.loadingYearlyEvents = true
        screen.loadingAllTimeEvents = true
        screen.loadingHighRiskTenants = true
        val properties = when(val maybe = propertyRepository.getProperties()) {
            is Try.Error -> {
                screen.loading = false
                return
            }
            is Try.Success -> {

                maybe.value
            }
        }
        screen.propertyMap = mapOf("None" to -1, "All" to null) + properties.sortedBy { it.name }.associate { it.name to it.id }
        if (propertyId == -1) {
            screen.propertyId = -1
            screen.loading = false
            screen.loadingYearlyEvents = false
            screen.loadingAllTimeEvents = false
            screen.loadingHighRiskTenants = false
            return
        }
        updateData(propertyId, eventTypeValue)
        loadHighRiskTenantData(propertyId, listOf(EventTypes.SMOKE.toString(), EventTypes.TAMPER.toString(), EventTypes.LEAK.toString(), EventTypes.SHUTOFF.toString()))
        loadYearlyEventTrends(propertyId, yearToGet)
    }

    suspend fun updateData(propertyId: Int?, eventTypeValue: String) {
        screen.propertyId = propertyId
        screen.loading = true
        screen.loadingYearlyEvents = true
        screen.loadingAllTimeEvents = true
        screen.loadingHighRiskTenants = true


        val currentAllPropertyEventValue = when (val maybe = reportsPageRepository.getAllPropertiesAverageValue()) {
            is Try.Error -> {
                screen.loading = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }

        val eventsOverTimeCount = when (val maybe = reportsPageRepository.getPropertyEventsOverTime(propertyId)) {
            is Try.Error -> {
                screen.loading = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }

        screen.yearStartedUsingFireAvert = eventsOverTimeCount.yearStarted
        screen.lastFiveYearClaims = eventsOverTimeCount.fireClaimData
        screen.allTimeAverageEventValue = (round(currentAllPropertyEventValue * 100) / 100)

        loadEventsAndActions(propertyId, eventTypeValue)
        screen.loading = false
    }

    suspend fun loadYearlyEventTrends(propertyId: Int?, yearToGet: Int) {
        screen.loadingYearlyEvents = true
        val yearlyEventsData =
            when (val maybe = reportsPageRepository.getYearlyEvents(propertyId, yearToGet)) {
                is Try.Error -> {
                    screen.loadingYearlyEvents = false
                    return
                }
                is Try.Success -> {
                    maybe.value
                }
            }

        screen.thisYearSmokeEvents = yearlyEventsData.thisYearSmokeEvents
        screen.thisYearTamperEvents = yearlyEventsData.thisYearTamperEvents
        screen.thisYearLeakEvents = yearlyEventsData.thisYearLeakEvents
        screen.thisYearStoveShutoffEvents = yearlyEventsData.thisYearStoveShutoffEvents
        screen.thisYearOfflineEvents = yearlyEventsData.thisYearOfflineEvents
        screen.loadingYearlyEvents = false
    }

    suspend fun loadEventsAndActions(propertyId: Int?, eventTypeValue: String) {
        screen.loadingAllTimeEvents = true
        val eventsAndActions =
            when (val maybe = reportsPageRepository.getEventsAndActions(propertyId, eventTypeValue)) {
                is Try.Error -> {
                    screen.loadingAllTimeEvents = false
                    return
                }
                is Try.Success -> {
                    maybe.value
                }
            }

        // Load yearly event trends separately, so we can also call the function from the UI

        screen.allTimeData = eventsAndActions.first
        screen.eventCount = eventsAndActions.second
        screen.loadingAllTimeEvents = false
    }

    suspend fun loadHighRiskTenantData(propertyId: Int?, eventTypeArray: List<String>) {
        screen.loadingHighRiskTenants = true
        val highRiskTenants = when (val maybe = reportsPageRepository.getHighRiskTenants(propertyId, eventTypeArray)) {
            is Try.Error -> {
                screen.loadingHighRiskTenants = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }

        screen.highRiskTenants = highRiskTenants.highRiskTenants
        screen.averageEventsPerMonth = (round(highRiskTenants.averageEventsPerMonth * 100) / 100)
        screen.loadingHighRiskTenants = false
    }

    suspend fun exportEventsHappeningNow(propertyId: Int?): String? {
        val csvContent: String
        if (propertyId == null) {
            val exportData = when (val maybe = reportsPageRepository.exportEventsHappeningNowAll()) {
                is Try.Error -> {
                    screen.exportError = "Error exporting events happening now"
                    return null
                }

                is Try.Success -> {
                    maybe.value
                }
            }

            csvContent = buildString {
                appendLine("Property Name, Unit Number, Device Type, Event Type, Time")
                exportData.propertiesWithEvents?.forEach { eventData ->
                    eventData.activeDeviceEvents?.forEach { unitData ->
                        appendLine(
                            "${eventData.property?.name}, ${unitData.unit}, ${unitData.deviceType}, ${convertEventTypeToString(unitData.eventType, unitData.deviceType)}, ${convertTimestampToReadableTime(unitData.timestamp)}"
                        )
                    }
                }
            }
            return csvContent
        }
        else {
            val exportData = when (val maybe = reportsPageRepository.exportEventsHappeningNow(propertyId)) {
                is Try.Error -> {
                    screen.exportError = "Error exporting events happening now"
                    return null
                }

                is Try.Success -> {
                    maybe.value
                }
            }

            csvContent = buildString {
                appendLine("Property Name, Unit Number, Device Type, Event Type, Time")
                exportData.propertyWithActiveEventsResponse.activeDeviceEvents?.forEach { unitData ->
                    appendLine(
                        "${exportData.propertyWithActiveEventsResponse.property?.name}, ${unitData.unit}, ${unitData.deviceType}, ${convertEventTypeToString(unitData.eventType, unitData.deviceType)}, ${convertTimestampToReadableTime(unitData.timestamp)}"
                    )
                }
            }
            return csvContent
        }

    }

    private fun convertTimestampToReadableTime(timestamp: Long?): String {
        if (timestamp == null) {
            return ""
        }
        val instant = Instant.fromEpochSeconds(timestamp)
        val localDateTime = instant.toLocalDateTime(TimeZone.currentSystemDefault())
        return "${localDateTime.date} ${localDateTime.time}"
    }

    private fun convertEventTypeToString(eventType: EventType?, deviceType: DeviceType?): String {
        if (eventType == DeviceTriggered) {
            return when (deviceType) {
                FireAvert, FireAvertGas, FireAvertAppliance -> "Stove Shutoff"
                WaterSensor -> "Water Leak"
                FlowSensor -> "Flow"
                TamperSensor -> "Tamper Detected"
                null -> "Triggered"
            }
        }
        return "Device Triggered"
    }
}