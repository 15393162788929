package com.fireavert.administration_page.users.logic.add_user_dialog.models.request

import com.fireavert.user.logic.models.RolesModel
import kotlinx.serialization.Serializable

@Serializable
data class SaveEditedUserRequest(
    val firstName: String = "",
    val lastName: String = "",
    val email: String = "",
    val phoneNumber: String = "",
    val roles: List<RolesModel> = emptyList(),
    val propertyIds: List<Int> = emptyList(),
)
