package com.fireavert.properties.logic.models.request

import com.fireavert.properties.logic.models.Mode
import kotlinx.serialization.Serializable

@Serializable
data class PropertyModeChangeRequest(
    val propertyId: Int,
    val mode: Mode,
    val duration: Int
)