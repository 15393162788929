package com.fireavert.styles

import emotion.css.ClassName
import web.cssom.*

object PropertyStyles {
    val propertyError = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.center
        alignItems = AlignItems.center
        height = 100.pct
        color = NamedColor.red
        fontWeight = FontWeight.bold
        maxWidth = 100.pct
        overflowX = Auto.auto
        marginTop = 15.px
    }

    val propertyNotificationHeaders = ClassName {
        fontWeight = FontWeight.bold
    }

    val propertyArchiveButton = ClassName {
        padding = 8.px
        fontWeight = FontWeight.bold
        color = NamedColor.white
        display = Display.flex
        justifyContent = JustifyContent.right
        marginRight = 20.px
        marginBottom = 30.px
    }
}