package com.fireavert.properties.logic.models

import kotlinx.serialization.Serializable

@Serializable
data class UserLinkedToProperty(
    val userId: Int,
    val propertyId: Int,
    val propertyName: String = "",

    // Stove Shutoff
    var stoveEmailNotifications: Boolean,
    var stoveTextNotifications: Boolean,

    // Tamper
    var tamperEmailNotifications: Boolean,
    var tamperTextNotifications: Boolean,
    // Leak
    var leakEmailNotifications: Boolean,
    var leakTextNotifications: Boolean,
    // Alarm
    var smokeAlarmEmailNotifications: Boolean,
    var smokeAlarmTextNotifications: Boolean,

    // Offline
    val stoveOfflineTextNotifications: Boolean,
    val stoveOfflineEmailNotifications: Boolean,
    val tamperOfflineTextNotifications: Boolean,
    val tamperOfflineEmailNotifications: Boolean,
    val leakOfflineTextNotifications: Boolean,
    val leakOfflineEmailNotifications: Boolean,

    // Reports
    var dailyReports: Boolean,
    var weeklyReports: Boolean,
    val monthlyReports: Boolean,
    var quarterlyReports: Boolean,
    var yearlyReports: Boolean,


    val email: String,
    val phone: String,
    val firstName: String,
    val lastName: String,
)
