package com.fireavert.properties.logic.models

data class Property(
    val id: Int,
    val name: String,
    val units: Int,
    val address: String,
    val city: String,
    val state: String,
    val zip: String,
    val managementCompany: String,
    val subscriptionType: SubscriptionType,
    val mode: Mode,
    val propertyType: PropertyType,
    val timezoneId: String
)

