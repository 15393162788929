package com.fireavert.components.properties

import com.fireavert.components.icons.AddIcon
//import com.fireavert.components.qrscanner.QRScannerDialog
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.UnitStyles
import com.fireavert.units.presentation.AddRemoveDevicesContainerController
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.div
import react.useState
import web.cssom.*

external interface AddRemoveDevicesContainerProps: Props {
    var deviceCreationData: Array<DeviceData>
    var clickedAddNewDevice: () -> Unit
    var deleteDeviceAtIndex: (index: Int) -> Unit
    var deviceAtIndexChanged: (index: Int, deviceCreationData: DeviceData) -> Unit
    var saveError: String?
}

val AddRemoveDevicesContainer = FC<AddRemoveDevicesContainerProps> { props ->
    val controller = getKoinInstance<AddRemoveDevicesContainerController>()

    // Add state for QR scanning
    val (qrScannerOpen, setQRScannerOpen) = useState(false)
    val (activeDeviceIndex, setActiveDeviceIndex) = useState(-1)

    val (activeField, setActiveField) = useState("")

// Update the handleQRClick function to accept both the device index and field name
    val handleQRClick = { index: Int, fieldName: String ->
        console.log("QR icon clicked for device at index $index, field: $fieldName")
        setActiveDeviceIndex(index)
        setActiveField(fieldName)
        setQRScannerOpen(true)
    }

// Update the handleQRScanned function to update the appropriate field
    val handleQRScanned = { code: String ->
        console.log("handleQRScanned called with code: $code for field: $activeField")
        if (activeDeviceIndex >= 0 && activeDeviceIndex < props.deviceCreationData.size) {
            val device = props.deviceCreationData[activeDeviceIndex]
            console.log("Updating device at index $activeDeviceIndex, field: $activeField with value: $code")

            // Update the appropriate field based on activeField
            val updatedDevice = when (activeField) {
                "locator" -> device.copy(locator = code)
                "rebootUUID" -> device.copy(rebootUUID = code)
                "infoUUID" -> device.copy(infoUUID = code)
                else -> device
            }

            props.deviceAtIndexChanged(activeDeviceIndex, updatedDevice)
        } else {
            console.error("Invalid activeDeviceIndex: $activeDeviceIndex")
        }
        setQRScannerOpen(false)
    }

    div {
        className = ClassName {
            width = 100.pct
            maxWidth = 990.px
            margin = Auto.auto
            padding = 10.px
            boxSizing = BoxSizing.borderBox

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
                padding = 5.px
            }
        }

        // Devices Title
        div {
            className = cx(UnitStyles.devicesTitle, ClassName {
                marginBottom = 15.px
            })
            +"Sensors"
        }

        // Device Rows
        props.deviceCreationData.forEachIndexed { index, deviceCreationData ->
            div {
                className = ClassName { marginBottom = 20.px }
                AddRemoveDeviceRow {
                    type = deviceCreationData.type
                    typeError = deviceCreationData.typeError
                    typeDisabled = deviceCreationData.deviceId != null
                    typeChanged = { newType ->
                        props.deviceAtIndexChanged(index, deviceCreationData.copy(type = newType))
                    }
                    commVersion = deviceCreationData.commVersion
                    commVersionError = deviceCreationData.commVersionError
                    commVersionChanged = { newCommVersion ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(commVersion = newCommVersion)
                        )
                    }
                    location = deviceCreationData.location
                    locationError = deviceCreationData.locationError
                    locationChanged = { newLocation ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(location = newLocation)
                        )
                    }
                    locator = deviceCreationData.locator
                    locatorError = deviceCreationData.locatorError
                    locatorChanged = { newLocator ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(locator = newLocator)
                        )
                    }
                    // Update the qrClicked prop to open the scanner
                    qrClicked = { fieldName -> handleQRClick(index, fieldName) }
                    deleteDeviceClicked = {
                        props.deleteDeviceAtIndex(index)
                    }
                    isAdmin = controller.isAdmin
                    rebootUUID = deviceCreationData.rebootUUID
                    rebootUUIDError = deviceCreationData.rebootUUIDError
                    rebootUUIDChanged = { newRebootUUID ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(rebootUUID = newRebootUUID)
                        )
                    }
                    infoUUID = deviceCreationData.infoUUID
                    infoUUIDError = deviceCreationData.infoUUIDError
                    infoUUIDChanged = { newInfoUUID ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(infoUUID = newInfoUUID)
                        )
                    }
                }
            }
        }

        div {
            className = ClassName {
                textAlign = TextAlign.center
                marginTop = 15.px
                marginBottom = 15.px
            }
            Button {
                +"Add New Device"
                color = ButtonColor.primary
                variant = ButtonVariant.contained
                onClick = { props.clickedAddNewDevice() }
                startIcon = AddIcon.create()
            }
        }

        props.saveError?.let { saveError ->
            div {
                className = ClassName {
                    color = NamedColor.red
                    textAlign = TextAlign.center
                    marginTop = 15.px
                }
                +saveError
            }
        }

//        QRScannerDialog {
//            open = qrScannerOpen
//            onClose = { setQRScannerOpen(false) }
//            onScan = handleQRScanned
//        }
    }
}