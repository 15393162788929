package com.fireavert.units.data

import com.fireavert.common.Try
import com.fireavert.jobs.logic.JobModel
import com.fireavert.reports_page.logic.models.ReportsExportUnitDataModel
import com.fireavert.units.api.UnitsDataSource
import com.fireavert.units.logic.models.request.MultiUnitCreationRequest
import com.fireavert.units.logic.models.request.UnitCreationRequest
import com.fireavert.units.logic.models.request.UnitModificationRequest
import com.fireavert.units.logic.models.response.*
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class ClientUnitsDataSource(
    private val httpClient: HttpClient, private val baseUrl: String
) : UnitsDataSource {
    override suspend fun getUnitsForPropertyId(
        token: String,
        propertyId: Int
    ): Try<GetUnitsByPropertyIdResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/units")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getUnitById(token: String, unitId: Int): Try<GetUnitByIdResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/$unitId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun createUnit(
        token: String,
        request: UnitCreationRequest
    ): Try<CreateUnitResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun delete(token: String, unitId: Int): Try<DeleteUnitResponse> {
        return Try.fromCoCallable {
            val response = httpClient.delete {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/${unitId}")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun modifyUnit(
        token: String,
        request: UnitModificationRequest
    ): Try<ModifyUnitResponse> {
        return Try.fromCoCallable {
            val response = httpClient.put {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/${request.unitId}")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun createMultipleUnits(
        token: String,
        request: MultiUnitCreationRequest
    ): Try<JobModel> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/multi")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getExportUnitData(token: String, propertyId: Int): Try<List<ReportsExportUnitDataModel>> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/export/$propertyId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}