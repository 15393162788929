package com.fireavert.management_companies.framework

import com.fireavert.common.Try
import com.fireavert.management_companies.models.request.UpdateManagementCompanyRequest
import com.fireavert.management_companies.models.response.ManagementCompanyDialogResponse
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class ManagementCompanyDataSourceImpl(
    private var baseUrl: String,
    private var httpClient: HttpClient,
): ManagementCompanyDataSource {
    override suspend fun getManagementCompanyDialogData(
        token: String,
        managementCompanyId: Int?
    ): Try<ManagementCompanyDialogResponse> {
        return Try.fromCoCallable {
            val request = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/managementCompanies/dialogData/${managementCompanyId ?: ""}")
            }
            if (request.status.isSuccess()) request.body()
            else throw ClientRequestException(request, "")
        }
    }

    override suspend fun saveManagementCompany(
        token: String,
        managementCompanyDialogRequest: UpdateManagementCompanyRequest
    ): Try<Boolean> {
        return Try.fromCoCallable {
            val request = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/managementCompanies/save")
                contentType(ContentType.Application.Json)
                setBody(managementCompanyDialogRequest)
            }
            if (request.status.isSuccess()) true
            else throw ClientRequestException(request, "")
        }
    }
}