package com.fireavert.management_companies.presentation

import com.fireavert.management_companies.logic.ManagementCompanyInteractor

class ManagementCompanyController(private val interactor: ManagementCompanyInteractor) {
    suspend fun onLoadNew() = interactor.onLoadNew()
    suspend fun onLoadEdit(managementCompanyId: Int) = interactor.onLoadEdit(managementCompanyId)
    suspend fun onSaveNew(name: String, propertyIdList: List<Int>): Boolean { return interactor.onSaveNew(name, propertyIdList) }
    suspend fun onSaveEdit(managementCompanyId: Int, name: String, propertyIdList: List<Int>) = interactor.onSaveEdit(managementCompanyId, name, propertyIdList)

}