package com.fireavert.administration_page.users.interface_adapters.user_info

import com.fireavert.properties.logic.models.UserLinkedToProperty

data class UserInfoViewState(
    val loading: Boolean = true,
    val name: String = "",
    val email: String = "",
    val phone: String = "",
    val role: List<String> = emptyList<String>(),
    val monthlyLoginCount: Int = 0,
    val weeklyLoginCount: Int = 0,
    val managementCompany: String = "",
    val loginHistory: Map<String, String> = emptyMap(),
    val propertyNotificationInfo: List<UserLinkedToProperty> = emptyList(),
)