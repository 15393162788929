package com.fireavert.properties.api

import com.fireavert.properties.logic.models.Mode
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.logic.models.SubscriptionType
import com.fireavert.properties.logic.models.response.GetPropertiesResponse
import com.fireavert.properties.logic.models.response.PropertyResponse

private object PropertyMapper {
    fun withGetPropertiesResponse(from: GetPropertiesResponse): List<Property> {
        val properties = from.properties ?: return emptyList()
        return properties.map { withPropertyResponse(it) }
    }

    fun withPropertyResponse(from: PropertyResponse): Property {
        return Property(
            id = from.id ?: 0,
            name = from.name ?: "",
            units = from.units ?: 0,
            address = from.address ?: "",
            city = from.city ?: "",
            state = from.state ?: "",
            zip = from.zip ?: "",
            managementCompany = from.managementCompany ?: "",
            subscriptionType = from.subscriptionType ?: SubscriptionType.Standard,
            mode = from.mode ?: Mode.Active,
            propertyType = from.propertyType,
            timezoneId = from.timezoneId ?: "",
        )
    }

    fun withProperty(from: Property): PropertyResponse {
        return PropertyResponse(
            id = from.id,
            name = from.name,
            units = from.units,
            address = from.address,
            city = from.city,
            state = from.state,
            zip = from.zip,
            managementCompany = from.managementCompany,
            subscriptionType = from.subscriptionType,
            mode = from.mode,
            propertyType = from.propertyType,
            timezoneId = from.timezoneId,
        )
    }
}

fun GetPropertiesResponse.toPropertyList(): List<Property> = PropertyMapper.withGetPropertiesResponse(this)
fun PropertyResponse.toProperty(): Property = PropertyMapper.withPropertyResponse(this)
fun Property.toPropertyResponse(): PropertyResponse = PropertyMapper.withProperty(this)