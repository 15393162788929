package com.fireavert.components.common.shared

import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.span


val TableArrow = FC {
    span {
        className = com.fireavert.styles.TableStyles.threeDotsButtonContainer
        ReactHTML.img {
            src = "/static/mobile_arrow_black.svg"
        }
    }
}