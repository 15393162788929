package com.fireavert.components.dialogs

import com.fireavert.components.common.Loading
import com.fireavert.components.common.WarningIcon
import com.fireavert.properties.logic.models.PropertyAdminModel
import com.fireavert.properties.presentation.delete_property_admin_dialog.DeletePropertyAdminDialogController
import com.fireavert.properties.presentation.delete_property_admin_dialog.DeletePropertyAdminDialogViewModel
import com.fireavert.styles.DialogStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import react.FC
import react.dom.html.ReactHTML.div
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.JustifyContent
import web.cssom.NamedColor
import web.cssom.px

val DeletePropertyAdminDialog = FC {
    val viewModel = getKoinInstance<DeletePropertyAdminDialogViewModel>()
    val controller = getKoinInstance<DeletePropertyAdminDialogController>()

    val params = useParams()
    val propertyId = params["propertyId"]?.toInt() ?: 0
    val userId = params["userId"]?.toInt() ?: 0

    var isDeleting: Boolean by useState(false)
    var isLoading: Boolean by useState(false)
    var loadFailed: Boolean by useState(false)
    var deletionFailed: Boolean by useState(false)
    var deletionAttempted: Boolean by useState(false)
    var propertyAdminModel: PropertyAdminModel? by useState(null)

    useEffectOnceWithCleanup {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isDeleting = viewState.isDeleting
            isLoading = viewState.isLoading
            loadFailed = viewState.loadFailed
            deletionFailed = viewState.deletionFailed
            deletionAttempted = viewState.deletionAttempted
            propertyAdminModel = viewState.propertyAdminModel
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyId, userId)
        }

        onCleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }


    if (isDeleting || isLoading) {
        Loading {}
    }
    else if (loadFailed) {
        ErrorDialog {
            closeCallback = {
                controller.dismissDialog(propertyId)
            }
            message = "Failed to load Delete Property Admin Dialog"
        }
    }
    else if (deletionFailed) {
        ErrorDialog {
            closeCallback = {
                controller.dismissDialog(propertyId)
            }
            message = "Failed to delete property admin ${propertyAdminModel?.firstName} ${propertyAdminModel?.lastName}"
        }
    }

    else if (deletionAttempted) {
        SuccessDialog {
            closeCallback = { controller.dismissDialog(propertyId) }
            message =
                "Successfully deleted property admin ${propertyAdminModel?.firstName} ${propertyAdminModel?.lastName}"
        }
    } else {
        Dialog {
            open = true
            onBackdropClick = { controller.dismissDialog(propertyId) }
            onClose = { _, _ -> controller.dismissDialog(propertyId) }

            val adminModel = propertyAdminModel
            DialogContent {

                className = DialogStyles.warningDialogContent

                WarningIcon {}
                div {
                    className = DialogStyles.warningText
                    +"Warning"
                }
                div {
                    className = DialogStyles.warningText
                    +"You are about to delete the property admin ${adminModel?.firstName} ${adminModel?.lastName} (${adminModel?.email}) from this property."
                }
                div {
                    className = DialogStyles.deleteWarningBold
                    +"Are you sure you want to proceed?"
                }
                DialogActions {
                    css {
                        height = 94.px
                        padding = 24.px
                        justifyContent = JustifyContent.spaceEvenly
                    }
                    Button {
                        +"No, keep the property admin"
                        color = ButtonColor.inherit
                        onClick = {
                            controller.dismissDialog(propertyId)
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            marginRight = 6.px
                            backgroundColor = NamedColor.white
                        }
                    }

                    Button {
                        +"Yes, proceed to delete"
                        color = ButtonColor.inherit
                        onClick = {
                            viewModel.scope.launch {
                                controller.clickedDeletePropertyAdmin(
                                    propertyId,
                                    userId
                                )
                            }
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            backgroundColor = NamedColor.white
                        }
                    }
                }
            }
        }
    }

}