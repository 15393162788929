package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import react.dom.html.ReactHTML.a
import web.cssom.*

object PageStylesV2 {
    val mainContainer = ClassName {
        height = 100.pct
        flexGrow = number(1.0)
        overflow = Auto.auto
        backgroundColor = Color("#EDEFF3")
    }
    val mainContainerContent = ClassName {
        width = 100.pct
        padding = Padding(26.px, 30.px, 26.px, 30.px)
        flexGrow = number(1.0)
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            padding = Padding(10.px, 15.px, 10.px, 15.px)
            overflowX = Overflow.hidden
        }
    }

    val genericButton = ClassName {
        color = important(Color("#ffffff"))
        marginRight = important(10.px)
        a {
            color = important(Color("#ffffff"))
        }
    }
}
object PageStyles {
    val flexContainer = ClassName {
        display = Display.flex
        width = 100.pct
        height = 100.pct
    }
    val mainContainer = ClassName {
        height = 100.pct
        flexGrow = number(1.0)
        overflow = Auto.auto
    }
    val mainContainerContent = ClassName {
        width = 100.pct
        padding = Padding(26.px, 30.px, 26.px, 30.px)
        backgroundColor = Color("#F2F4FA")
        flexGrow = number(1.0)
    }
    val sectionTitle = ClassName {
        fontWeight = integer(700)
        color = Color("#525F7F")
        fontSize = 22.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 36.px
            fontWeight = FontWeight.bolder
            color = NamedColor.black
        }
    }

    val sectionTitleWithRightHandButton = ClassName {
        fontWeight = integer(700)
        color = Color("#525F7F")
        fontSize = 22.px
        display =  Display.flex
        justifyContent = JustifyContent.spaceBetween
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 36.px
            fontWeight = FontWeight.bolder
            color = NamedColor.black
            flexDirection = FlexDirection.column
        }
    }

    fun test(textColor: Color): ClassName = ClassName {
        fontWeight = integer(500)
        color = textColor
        fontSize = 16.px
    }
}