package com.fireavert.user.logic.models.responses

import com.fireavert.user.logic.PageName
import com.fireavert.user.logic.models.RolesModel
import kotlinx.serialization.Serializable

@Serializable
data class RoleResponse(
    val id: Int? = 0,
    val name: RolesModel? = RolesModel.UNKNOWN,
    val permissions: List<PermissionResponse>? = emptyList(),
    val pageVersions: Map<PageName, Int> = emptyMap()
)
