package com.fireavert.components.help

import react.ReactNode

object HelpDialog {

    const val TOOL_TIP_NEVER_INSTALLED = "never installed"

    const val smokeEventQuote = "The flame icon will show when the FireAvert stove sensor detects smoke alarm " +
            "activity that continues longer than 90 seconds."

    const val leakEventQuote = "The drop icon will show when the FireAvert leak sensor detects water for at least 7 minutes."
    const val tamperEventQuote = "The tamper icon will show when a smoke alarm is removed, or its batteries have been removed. "
    const val offlineEventQuote = "The offline icon will show if any sensor within a respective apartment or home " +
            "has lost power or connection to the network."

    const val smokeEventTitle = "SMOKE EVENT"
    const val leakEventTitle = "LEAK EVENT"
    const val tamperEventTitle = "TAMPER EVENT"
    const val offlineEventTitle = "OFFLINE EVENT"

    const val smokeCardDetails = "If a smoke alarm sounds and the stove is on,the FireAvert Auto Stove Shutoff will " +
            "turn off the stove and send a text alert to the assigned manager(s) responsible for that apartment. " +
            "Additionally, the property manager(s) will be notified with text and/or email alerts each time a " +
            "smoke alarm continues to sound for more than 1.5 minutes."

    val leakCardDetails = "If a leak sensor detects continuous wetness for more than 7 minutes, " +
            "the property manager(s) will be notified with text and/or email alerts."

    val tamperCardDetails = "If a smoke alarm has been removed or it’s batteries, text and/or email alert will " +
            "be sent out to the property manager(s)."

    val offlineCardDetails = "A device will go offline if it has lost power or the connection to the gateway has been lost."

    val shutOffActionDetails = "When the FireAvert Auto Stove Shutoff hears the sound of a smoke alarm, it will " +
            "automatically shut off the stove and send you a text alert. The property manager(s)will also be " +
            "notified with a text and/or email alert when a smoke event happens and the stove is shut off."

    val textAlertActionDetails = "A text alert is a type of alert where both a tenant and a property manager " +
            "can be notified through text or email about events happening."

    val resetAlertActionDetails = "After a smoke event and stove shut off action takes place and the smoke " +
            "alarm stops sounding, the device can be rebooted from the dashboard. This icon appears if the " +
            "stove has been rebooted."

    fun toolTipText(event: String): ReactNode {
        return when (event.lowercase()) {
            "smoke events" -> ReactNode("FireAvert Pro has detected the smoke alarm going off.")
            "water events" -> ReactNode("FireAvert Pro has detected a leak sensor in a wet state.")
            "tamper events" -> ReactNode("FireAvert Pro has detected that a smoke alarm has been tampered with.")
            "offline events" -> ReactNode("An offline device cannot send signals or monitor the property.")
            "stove shutoff" -> ReactNode("FireAvert Pro has automatically shut off the stove.")
            "text alerts" -> ReactNode("FireAvert Pro has sent a text alert to the user.")
            "stove reset" -> ReactNode("FireAvert Pro has prevented a fire and been reset.")
            "never installed" -> ReactNode("The FireAvert installers attempted to enter the unit twice but were unsuccessful.")
            "neverinstalled" -> ReactNode("The FireAvert installers attempted to enter the unit twice but were unsuccessful.")
            "offline" -> ReactNode("This unit has a device that is offline.")
            "online" -> ReactNode("All devices in this unit are online!")
            //For Mode.Test
            "test" -> ReactNode("This property is in test mode! No notifications will come through and all events will be marked as tests.")
            //For Mode.Install
            "install" -> ReactNode("This property is in install mode! No notifications will come through and events will not be recorded")
            //For Mode.Active
            "active" -> ReactNode("This property is being protected. All notifications will come through and events will be recorded.")
            //For Mode.Mute
            "mute" -> ReactNode("This property is in mute mode! No notifications will come through but events will be recorded.")
            "archived" -> ReactNode("This property has been archived.")
            else -> ReactNode("No information available")
        }
    }
}

