package com.fireavert.user.logic.models.responses

import kotlinx.serialization.Serializable

@Serializable
data class PermissionResponse(
    val id: Int? = 0,
    val key: String? = "",
    val name: String? = ""
)
