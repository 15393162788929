package com.fireavert.administration_page.users.frameworks

import com.fireavert.administration_page.users.interface_adapters.user_info.UserInfoRepositoryDataSource
import com.fireavert.administration_page.users.logic.user_info.models.UserInfoResponse
import com.fireavert.administration_page.users.logic.user_info.models.UserNotificationChangeRequest
import com.fireavert.common.Try
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class UserInfoRepositoryDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient,
) : UserInfoRepositoryDataSource {
    override suspend fun getUserInfo(userId: Int, token: String): Try<UserInfoResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/userInfo/$userId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun saveNotificationChanges(request: UserNotificationChangeRequest, token: String): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/userInfo/${request.userId}")
                setBody(request)
                contentType(ContentType.Application.Json)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}