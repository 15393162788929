package com.fireavert.components.dialogs

import UserDialogMode
import com.fireavert.administration_page.users.interface_adapters.add_user_dialog.AddUserController
import com.fireavert.administration_page.users.interface_adapters.add_user_dialog.AddUserViewModel
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.shared.AddUser
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.models.Property
import com.fireavert.user.logic.Permission
import com.fireavert.user.logic.models.RolesModel
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.router.useParams
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.*

external interface AddNewUserDialogProps : Props {
    var closeDialog: () -> Unit
    var mode: UserDialogMode
}

val AddNewUserDialog = FC<AddNewUserDialogProps> { props ->

    PermissionRequired {
        permission = if (props.mode == UserDialogMode.CREATE) Permission.CreateUsers else Permission.EditUsers

        val viewModel = getKoinInstance<AddUserViewModel>()
        val controller = getKoinInstance<AddUserController>()
        val logger = getKoinInstance<Logger>()

        var firstName: String by useState("")
        var lastName: String by useState("")
        var phone: String by useState("")
        var email: String by useState("")
        var stoveTextNotifications: Boolean by useState(false)
        var stoveEmailNotifications: Boolean by useState(false)
        var leakTextNotifications: Boolean by useState(false)
        var leakEmailNotifications: Boolean by useState(false)
        var tamperTextNotifications: Boolean by useState(false)
        var tamperEmailNotifications: Boolean by useState(false)
        var smokeAlarmTextNotifications: Boolean by useState(false)
        var smokeAlarmEmailNotifications: Boolean by useState(false)
        var dailyReports: Boolean by useState(false)
        var weeklyReports: Boolean by useState(false)
        var monthlyReports: Boolean by useState(false)
        var quarterlyReports: Boolean by useState(false)
        var yearlyReports: Boolean by useState(false)
        var firstNameError: Boolean by useState(false)
        var lastNameError: Boolean by useState(false)
        var phoneError: Boolean by useState(false)
        var emailError: Boolean by useState(false)
        var selectedRoles: List<RolesModel> by useState(emptyList())
        var rolesList: List<RolesModel> by useState(emptyList())
        var userId = useParams()["userId"]?.toInt() ?: 0

        var name by useState("")
        var associatedProperties by useState(emptyList<Int>())
        var loading by useState(false)
        var allProperties by useState(emptyList<Property>())
        var (searchValue, setSearchValue) = useState("")
        var error by useState("")

        useEffectOnceWithCleanup {
            val viewStateJob = viewModel.viewState.onEach { viewState ->
                firstName = viewState.firstName
                lastName = viewState.lastName
                phone = viewState.phone
                email = viewState.email
                allProperties = viewState.allProperties
                associatedProperties = viewState.associatedProperties
                allProperties = viewState.allProperties
                loading = viewState.loading
                stoveTextNotifications = viewState.stoveShutoffTextNotifications
                stoveEmailNotifications = viewState.stoveShutoffEmailNotifications
                leakTextNotifications = viewState.leakTextNotifications
                leakEmailNotifications = viewState.leakEmailNotifications
                tamperTextNotifications = viewState.tamperTextNotifications
                tamperEmailNotifications = viewState.tamperEmailNotifications
                smokeAlarmTextNotifications = viewState.smokeAlarmTextNotifications
                smokeAlarmEmailNotifications = viewState.smokeAlarmEmailNotifications
                dailyReports = viewState.dailyReports
                weeklyReports = viewState.weeklyReports
                monthlyReports = viewState.monthlyReports
                quarterlyReports = viewState.quarterlyReports
                yearlyReports = viewState.yearlyReports
                firstNameError = viewState.firstNameError
                lastNameError = viewState.lastNameError
                phoneError = viewState.phoneError
                emailError = viewState.emailError
                rolesList = viewState.roleList
                selectedRoles = viewState.selectedRoles
            }.launchIn(viewModel.scope)

            val onLoadJob = viewModel.scope.launch {
                when (props.mode) {
                    UserDialogMode.EDIT -> controller.onLoadEditUser(userId)
                    UserDialogMode.CREATE -> controller.onLoadAddUser()
                    UserDialogMode.UNKNOWN -> {}
                }
            }

            onCleanup {
                viewStateJob.cancel()
                onLoadJob.cancel()
            }
        }

        Dialog {
            open = true
            onClose = { _, _ -> props.closeDialog() }
            maxWidth = Breakpoint.lg
            fullWidth = true
            sx {
                "& .MuiDialog-paper" {
                    width = 100.pct
                    maxWidth = 600.px
                    height = 600.px
                    margin = Auto.auto
                }
            }

            DialogTitle {
                +if (props.mode == UserDialogMode.CREATE) "Add User" else "Edit User"
                css {
                    background = NamedColor.white
                    color = NamedColor.black
                    fontWeight = important(FontWeight.bolder)
                    fontSize = important(2.rem)
                }
            }
            DialogContent {
                div {
                    css { marginTop = 25.px }
                }

                AddUser {
                    propertyList = allProperties
                    this.firstName = firstName
                    firstNameChanged = { name, valid -> viewModel.firstName = name; firstNameError = !valid }
                    this.firstNameError = firstNameError
                    this.lastName = lastName
                    lastNameChanged = { name, valid -> viewModel.lastName = name; lastNameError = !valid }
                    this.lastNameError = lastNameError
                    this.phone = phone
                    phoneChanged = { phone, valid -> viewModel.phoneNumber = phone; phoneError = !valid }
                    this.phoneError = phoneError
                    this.email = email
                    emailChanged = { email, valid -> viewModel.email = email; emailError = !valid }
                    this.emailError = emailError
                    this.stoveShutoffTextNotifications = stoveTextNotifications
                    stoveShutoffTextNotificationsChanged = { _, turnOn -> viewModel.stoveTextNotifications = turnOn }
                    this.stoveShutoffEmailNotifications = stoveEmailNotifications
                    stoveShutoffEmailNotificationsChanged = { _, turnOn -> viewModel.stoveEmailNotifications = turnOn }
                    this.leakTextNotifications = leakTextNotifications
                    leakTextNotificationsChanged = { _, turnOn -> viewModel.leakTextNotifications = turnOn }
                    this.leakEmailNotifications = leakEmailNotifications
                    leakEmailNotificationsChanged = { _, turnOn -> viewModel.leakEmailNotifications = turnOn }
                    this.tamperTextNotifications = tamperTextNotifications
                    tamperTextNotificationsChanged = { _, turnOn -> viewModel.tamperTextNotifications = turnOn }
                    this.tamperEmailNotifications = tamperEmailNotifications
                    tamperEmailNotificationsChanged = { _, turnOn -> viewModel.tamperEmailNotifications = turnOn }
                    this.smokeAlarmTextNotifications = smokeAlarmTextNotifications
                    smokeAlarmTextNotificationsChanged = { _, turnOn -> viewModel.smokeAlarmTextNotifications = turnOn }
                    this.smokeAlarmEmailNotifications = smokeAlarmEmailNotifications
                    smokeAlarmEmailNotificationsChanged =
                        { _, turnOn -> viewModel.smokeAlarmEmailNotifications = turnOn }
                    this.dailyReports = dailyReports
                    dailyReportsChanged = { _, turnOn -> viewModel.dailyReports = turnOn }
                    this.weeklyReports = weeklyReports
                    weeklyReportsChanged = { _, turnOn -> viewModel.weeklyReports = turnOn }
                    this.monthlyReports = monthlyReports
                    monthlyReportsChanged = { _, turnOn -> viewModel.monthlyReports = turnOn }
                    this.quarterlyReports = quarterlyReports
                    quarterlyReportsChanged = { _, turnOn -> viewModel.quarterlyReports = turnOn }
                    this.yearlyReports = yearlyReports
                    yearlyReportsChanged = { _, turnOn -> viewModel.yearlyReports = turnOn }
                    this.roleList = rolesList
                    this.roleListChanged = { roles -> viewModel.selectedRoles = roles }
                    this.associatedRoles = selectedRoles
                    this.associatedProperties = associatedProperties
                    this.associatedPropertiesChanged = { properties -> viewModel.associatedProperties = properties }
                    isEditMode = props.mode == UserDialogMode.EDIT
                }
            }
            DialogActions {
                sx {
                    "& .MuiDialogActions-root" {
                        justifyContent = JustifyContent.center
                    }
                }
                css {
                    height = 104.px
                    paddingRight = 24.px
                }
                Button {
                    +"Cancel"
                    color = ButtonColor.inherit
                    onClick = { props.closeDialog() }
                    variant = ButtonVariant.contained
                    css {
                        width = 163.px
                        marginRight = 6.px
                        borderRadius = 25.px
                    }
                }
                Button {
                    +"Save Changes"
                    color = ButtonColor.info
                    onClick = {
                        viewModel.scope.launch {
                            if (props.mode == UserDialogMode.EDIT) {
                                if (controller.onEditUserSave()) {
                                    props.closeDialog()
                                } else {
                                    if (controller.onNewUserSave()) {
                                        props.closeDialog()
                                    }
                                }
                            }
                        }
                    }
                    variant = ButtonVariant.contained
                    css {
                        width = 163.px
                        borderRadius = 25.px
                    }
                }
            }
        }
    }
}