package com.fireavert.administration_page.wireless_id_finder.interface_adapters

data class WirelessIdSectionViewState(
    val wirelessDeviceId: String = "",
    val propertyName: String = "",
    val unitName: String = "",
    val deviceLocationLink: String = "",
    val gatewayDeviceData: Map<String, List<String>> = emptyMap(),
    val gatewayDeviceDataLoading: Boolean = false,
)
