package com.fireavert.components.administration

import com.fireavert.styles.AdminDiscoverPromoStyles
import mui.material.Skeleton
import mui.material.SkeletonVariant
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.img
import web.window.WindowTarget


external interface AdminDiscoverPromoProps : Props {
    var imageSrc: String
    var title: String
    var description: String
    var link: String
    var loading: Boolean?
}

val AdminDiscoverPromo = FC<AdminDiscoverPromoProps> { props ->
    val isLoading = props.loading ?: false
    
    if (isLoading) {
        div {
            className = AdminDiscoverPromoStyles.promoContainer
            Skeleton {
                variant = SkeletonVariant.rounded
                width = "100%"
                height = "250px" // Approximate height of the promo
            }
        }
    } else {
        div {
            className = AdminDiscoverPromoStyles.promoContainer
    
            div {
                className = AdminDiscoverPromoStyles.imageContainer
    
                img {
                    className = AdminDiscoverPromoStyles.promoImage
                    src = props.imageSrc
                    alt = props.title
                }
    
                div {
                    className = AdminDiscoverPromoStyles.overlay
    
                    div {
                        className = AdminDiscoverPromoStyles.description
                        +props.description
                    }
    
                    h2 {
                        className = AdminDiscoverPromoStyles.title
                        +props.title
                    }
    
                    a {
                        href = "https://fireavert.com/pages/toilet-flow-sensor"
                        target = WindowTarget._blank
                        className = AdminDiscoverPromoStyles.learnMore
                        div {
                            +"Learn more >"
                        }
                    }
                }
            }
        }
    }
}