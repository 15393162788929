package com.fireavert.gateways.models

import com.fireavert.units.logic.models.UnitStatus
import kotlinx.serialization.Serializable

@Serializable
data class GatewayModel(
    val id: Int,
    val name: String,
    val gatewayId: String,
    val thingName: String,
    val description: String,
    val gatewayEUI: String,
    val lastUplink: Long,
    val status: UnitStatus,
    val propertyId: Int?,
    val latitude: Double?,
    val longitude: Double?
)
