package com.fireavert.menu.logic

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.Permission
import com.fireavert.user.logic.models.RolesModel
import kotlinx.coroutines.launch

class MenuInteractor(
    private val logger: Logger,
    private val screen: MenuScreen,
    private val preferences: Preferences,
    private val menuNotificationRepository: MenuNotificationRepository
) {
    fun didLoad() {
        screen.showAdministrationMenuItem = preferences.roles.any { it.hasAtLeastLevelOf(RolesModel.PROPERTY_ADMIN) }
        screen.setShowClientsMenuItem(preferences.permissions.contains(Permission.ReadClients))
        screen.setShowUsersMenuItem(preferences.permissions.contains(Permission.ReadUsers))
        screen.scope.launch {
            when (val dashboard = menuNotificationRepository.getDashboardNotification()) {
                is Try.Success -> {
                    screen.setDashboardNumber(dashboard.value)
                }
                is Try.Error -> {
                    screen.setDashboardNumber(0)
                }
            }
            when (val property = menuNotificationRepository.getPropertiesNotification()) {
                is Try.Success -> {
                    screen.setPropertiesNumber(property.value)
                }
                is Try.Error -> {
                    screen.setPropertiesNumber(0)
                }
            }
        }
    }
}