package com.fireavert.topnav.logic

import com.fireavert.administration_page.shared.logic.AdminNavigator
import com.fireavert.auth.logic.AuthNavigator
import com.fireavert.auth.logic.GetUser
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import kotlinx.coroutines.launch

class ClientTopNav(
    private val screen: TopNavScreen,
    private val preferences: Preferences,
    private val authNavigator: AuthNavigator,
    private val adminNavigator: AdminNavigator,
    private val getUser: GetUser,
    private val logger: Logger
) : TopNav {
    override fun onLoad() {
        screen.scope.launch {
            when (val maybeUser = getUser.execute()) {
                is Try.Success -> {

                    var roles = ""
                    if (maybeUser.value.roles.isNotEmpty()) {
                        roles += " (${maybeUser.value.roles.joinToString(", ") { role -> role.name.displayText }})"
                    }
                    screen.firstName = maybeUser.value.firstName
                    screen.lastName = maybeUser.value.lastName
                    screen.roles = roles
                    preferences.permissions =
                        maybeUser.value.roles.flatMap { role -> role.permissions.map { permission -> permission.key } }
                }
                is Try.Error -> {
                    logger.e("Failed to get user")
                    preferences.clearAuth()
                    authNavigator.navigate(AuthNavigator.Destination.Login)
                    return@launch
                }
            }
        }
    }

    override fun clickedProfile() {
        adminNavigator.navigate(AdminNavigator.Destination.UserPage(preferences.userId))
    }
}