package com.fireavert.administration_page.users.interface_adapters.add_user_dialog

import com.fireavert.properties.logic.models.Property
import com.fireavert.user.logic.models.RolesModel

data class AddUserViewState(
    val loading: Boolean = true,
    var firstName: String = "",
    var lastName: String = "",
    val email: String = "",
    val phone: String = "",
    val associatedProperties: List<Int> = emptyList(),
    val allProperties: List<Property> = emptyList(),
    val roleList: List<RolesModel> = emptyList(),
    val selectedRoles: List<RolesModel> = emptyList(),
    var stoveShutoffTextNotifications: Boolean = false,
    var stoveShutoffEmailNotifications: Boolean = false,
    var leakTextNotifications: Boolean = false,
    var leakEmailNotifications: Boolean = false,
    var tamperTextNotifications: Boolean = false,
    var tamperEmailNotifications: Boolean = false,
    var smokeAlarmTextNotifications: Boolean = false,
    var smokeAlarmEmailNotifications: Boolean = false,
    var dailyReports: Boolean = false,
    var weeklyReports: Boolean = false,
    var monthlyReports: Boolean = false,
    var quarterlyReports: Boolean = false,
    var yearlyReports: Boolean = false,
    var firstNameError: Boolean = false,
    var lastNameError: Boolean = false,
    var phoneError: Boolean = false,
    var emailError: Boolean = false,
    var errorMessage: String = "",
)