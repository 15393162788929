package com.fireavert.management_companies.presentation

import com.fireavert.common.ViewModel
import com.fireavert.management_companies.logic.ManagementCompanyPageScreen
import com.fireavert.properties.logic.models.Property
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class ManagementCompaniesPageViewModel :  ManagementCompanyPageScreen, ViewModel() {
    private val _viewState = MutableStateFlow(ManagementCompanyPageViewState())
    val viewState = _viewState.asStateFlow()
    val scope: CoroutineScope = viewModelScope

    override var loading: Boolean
        get() { return viewState.value.loading }
        set(value) {
            _viewState.value = viewState.value.copy(loading = value)
        }

    override var name: String
        get() { return viewState.value.name }
        set(value) {
            _viewState.value = viewState.value.copy(name = value)
        }
    override var managementCompaniesWithProperties: Map<String, List<Property>>
        get() { return viewState.value.managementCompaniesWithProperties }
        set(value) {
            _viewState.value = viewState.value.copy(managementCompaniesWithProperties = value)
        }
    override var error: String
        get() { return viewState.value.error }
        set(value) {
            _viewState.value = viewState.value.copy(error = value)
        }


}